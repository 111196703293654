import {useWindowDimensions} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import {GlobalStyles} from '../../styles';
import {Div} from '../base/Div';
import Icon from '../base/Icon';
import KeyboardAvoidingScrollArea from '../base/KeyboardAvoidingScrollArea';
import Modal from '../base/Modal';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import React from 'react';
import {maxContentWidth} from '../../constants/Layout';

export default function DraggableModal(props: DraggableModalProps) {
  const {height: windHeight, width} = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const initialHeight = windHeight * 0.9;

  const [visible, setVisible] = React.useState(false);

  const open = () => {
    lastHeight.value = initialHeight;
    height.value = initialHeight;
    setVisible(true);
  };

  const close = () => setVisible(false);

  const closeCallback = () => {
    'worklet';
    runOnJS(close)();
  };

  React.useEffect(() => {
    if (props.reference) {
      props.reference.current = {open, close};
    }
  }, [props.reference]);

  const lastHeight = useSharedValue(initialHeight);
  const minHeight = useSharedValue(windHeight * 0.1);
  const height = useSharedValue(initialHeight);
  const YToHeightRatio = useSharedValue(0);

  const panGesture = Gesture.Pan()
    .onUpdate((e) => {
      const h = -(e.translationY * YToHeightRatio.value);
      if (lastHeight.value + h > initialHeight) {
        height.value = initialHeight;
      } else if (lastHeight.value + h < minHeight.value) {
        closeCallback();
      } else {
        height.value = lastHeight.value + h;
      }
    })
    .onEnd(() => {
      if (height.value > initialHeight / 2) {
        height.value = initialHeight;
        lastHeight.value = initialHeight;
      } else {
        closeCallback();
      }
    });

  const animatedStyle = useAnimatedStyle(() => ({
    height: height.value,
  }));

  return (
    <Modal
      visible={visible}
      blurIntensity={1}
      onBackgroundPress={close}
      animationType="slide"
    >
      <Animated.View
        style={[
          {
            height: initialHeight,
            width: '100%',
            borderWidth: 1,
            borderColor: 'black',
            alignSelf: 'flex-end',
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            backgroundColor: GlobalStyles.colorsBasic.white500,
          },
          animatedStyle,
        ]}
        onLayout={(e) => {
          if (YToHeightRatio.value) return;
          YToHeightRatio.value =
            (windHeight - e.nativeEvent.layout.height) / e.nativeEvent.layout.y;
        }}
      >
        <GestureDetector gesture={panGesture}>
          <Div>
            <Div
              style={{
                width: 60,
                borderRadius: 10,
                height: 5,
                backgroundColor: GlobalStyles.colorsBasic.gray500,
                alignSelf: 'center',
                marginTop: 10,
                marginBottom: 20,
              }}
            />
            <Icon
              name="x-circle"
              color={GlobalStyles.svexa.green500}
              size={30}
              style={{position: 'absolute', right: 8, top: 8}}
              onPress={close}
            />
          </Div>
        </GestureDetector>
        <KeyboardAvoidingScrollArea style={{marginBottom: insets.bottom}}>
          <Div
            style={{
              width: Math.min(width, maxContentWidth),
              alignSelf: 'center',
            }}
          >
            {props.children}
          </Div>
        </KeyboardAvoidingScrollArea>
      </Animated.View>
    </Modal>
  );
}

export interface DraggableModalProps extends React.PropsWithChildren {
  reference?: React.MutableRefObject<DraggableModalRef>;
}

export interface DraggableModalRef {
  open: () => any;
  close: () => any;
}
