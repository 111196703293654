import React from 'react';
import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import Divider from '../../../components/shared/Divider';
import DraggableModal, {
  DraggableModalRef,
} from '../../../components/shared/DraggableModal';
import usePermissionsService from '../../../services/usePermissionsService';
import {IPermission} from '../../../types/IPermissions';
import {renderPermissionItem} from '../../shared/PermissionItem';
import MyAthleteDetails from './MyAthleteDetails';
import SendInvitation from './SendInvitation';

export default function MyAthletes() {
  const {useMyAthletes, useFetchPermissions} = usePermissionsService();
  useFetchPermissions(true);
  const athletes = useMyAthletes();
  const draggableRef = React.useRef<DraggableModalRef>({
    open: () => 1,
    close: () => 0,
  });
  const onViewDetails = (athlete: IPermission) => {
    setSelectedAthlete(athlete);
    draggableRef.current.open();
  };
  const [selectedAthlete, setSelectedAthlete] =
    React.useState<IPermission | null>(null);

  return (
    <Div>
      <DraggableModal reference={draggableRef}>
        <MyAthleteDetails athlete={selectedAthlete} />
      </DraggableModal>
      <Div style={{marginBottom: 10}}>
        <HeadingThree>Athletes (can view their data)</HeadingThree>
        {athletes?.length ? (
          <Div>
            <Paragraph style={{marginBottom: 10}}>
              {
                'These athletes have given you permission to see their daily ratings. You can remove one of them at any time.'
              }
            </Paragraph>
            {athletes.map((athlete, index) => {
              const selectAthlete = () => onViewDetails(athlete);
              return renderPermissionItem({
                item: athlete,
                index,
                type: athlete.id ? 'athlete' : 'athleteEmail',
                additionalAction: {label: 'View', call: selectAthlete},
              });
            })}
          </Div>
        ) : (
          <Paragraph>
            No athletes yet. Invite athletes to see their data.
          </Paragraph>
        )}
      </Div>
      <Divider />
      <SendInvitation />
    </Div>
  );
}
