import React from 'react';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AuthStackScreenProps} from '../../../types/Navigation';
import LoginTemplate from './LoginTemplate';
import * as WebBrowser from 'expo-web-browser';
import {AuthNavigationContext} from '../../../navigation/auth-navigator/AuthNavigationContext';

WebBrowser.maybeCompleteAuthSession();

export default function LoginScreen({
  navigation,
}: AuthStackScreenProps<'login'>) {
  return (
    <AuthNavigationContext.Provider value={navigation}>
      <ScreenLayout>
        <LoginTemplate />
      </ScreenLayout>
    </AuthNavigationContext.Provider>
  );
}
