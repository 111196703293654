import localForage from 'localforage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Platform} from 'react-native';
import {PersistConfig, persistReducer} from 'redux-persist';
import {platformVersionHistory} from '../../constants/Config';
import {IPlatformVersion} from '../../types/IHistoryVersion';
import {ILevels} from '../../types/ILevel';
import {IQuestion} from '../../types/IQuestion';
import {Language} from '../../types/ILanguage';

export interface ConfigState {
  language: Language;
  questions: IQuestion[];
  levels: ILevels;
  questionVersion: string;
  platformVersions: IPlatformVersion[];
}

const initialState: ConfigState = {
  language: Language.ENGLISH,
  questions: [],
  questionVersion: '3',
  levels: {},
  platformVersions: [],
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setQuestionVersion: (state: ConfigState, action: PayloadAction<string>) => {
      state.questionVersion = action.payload;
    },
    setQuestions: (state: ConfigState, action: PayloadAction<IQuestion[]>) => {
      state.questions = action.payload;
    },
    setLeves: (state: ConfigState, action: PayloadAction<ILevels>) => {
      state.levels = action.payload;
    },
    setPlatformVersions: (
      state: ConfigState,
      action: PayloadAction<IPlatformVersion[]>,
    ) => {
      state.platformVersions = action.payload;
    },
    setLanguage: (state: ConfigState, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
  },
});

const persistConfig: PersistConfig<ConfigState> = {
  key: 'config',
  storage: Platform.OS == 'web' ? localForage : AsyncStorage,
  whitelist: ['language'],
};

export const configActions = configSlice.actions;

export default persistReducer(persistConfig, configSlice.reducer);
// export default configSlice.reducer;
