import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AthleteNavigationContext} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import {AthleteStackScreenProps} from '../../../types/Navigation';
import AthleteHeader from '../shared/AthleteHeader';
import CoachesTemplate from './CoachesTemplate';

export default function CoachesScreen({
  navigation,
}: AthleteStackScreenProps<'coaches'>) {
  return (
    <AuthGuard>
      <AthleteNavigationContext.Provider value={navigation}>
        <ScreenLayout>
          <AthleteHeader />
          <Container>
            <CoachesTemplate />
          </Container>
        </ScreenLayout>
      </AthleteNavigationContext.Provider>
    </AuthGuard>
  );
}
