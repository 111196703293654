import {Platform} from 'expo-modules-core';
import React from 'react';
import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import Image from '../../../components/base/Image';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import {GlobalStyles} from '../../../styles';
import {RootStackScreenProps} from '../../../types/Navigation';
declare let window: typeof globalThis;

export default function AuthorizeOuraScreen(
  props: RootStackScreenProps<'authorize-oura'>,
) {
  const close = () => {
    if (!Platform.isDOMAvailable) return;
    if (!window) return;

    window.close();
  };

  React.useEffect(() => {
    const timeout = setTimeout(close, 1200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Div style={{flex: 1, backgroundColor: GlobalStyles.colorsBasic.white500}}>
      <Div
        style={{
          flex: 1,
          alignItems: 'center',
          paddingTop: 50,
          paddingHorizontal: 15,
        }}
      >
        <Image source={assets.images.oura} width={240} height={200} />

        {props.route.params?.status === 'success' ? (
          <Paragraph style={[style.text, {color: GlobalStyles.svexa.green500}]}>
            Successful Connection With Oura!
          </Paragraph>
        ) : null}
        {props.route.params?.status === 'failure' ? (
          <Paragraph
            style={[style.text, {color: GlobalStyles.colorsStatus.red}]}
          >
            Connection With Oura Failed!
          </Paragraph>
        ) : null}
      </Div>
    </Div>
  );
}

const style = StyleSheet.create({
  text: {
    fontSize: 30,
    fontWeight: '900',
    marginBottom: 50,
    textAlign: 'center',
  },
});
