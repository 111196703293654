import React from 'react';
import RatingsQuestions from './RatingsQuestions';
import {Div} from '../../../components/base/Div';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';

export default function AthleteAddEditRatingsTemplate({
  dateKey,
}: AthleteAddEditRatingsTemplateProps) {
  return (
    <KeyboardAvoidingScrollArea showsVerticalScrollIndicator={false}>
      <Div style={{flex: 1, marginBottom: 24, marginTop: 12}}>
        <RatingsQuestions dateKey={dateKey} />
      </Div>
    </KeyboardAvoidingScrollArea>
  );
}

export interface AthleteAddEditRatingsTemplateProps {
  dateKey: string;
}
