import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from 'react-native';

export default function KeyboardAvoidingArea(props: KeyboardAvoidingAreaProps) {
  return (
    <KeyboardAvoidingView
      {...props}
      behavior={
        props.behavior
          ? props.behavior
          : Platform.OS == 'ios'
          ? 'padding'
          : 'height'
      }
    >
      {props.children}
    </KeyboardAvoidingView>
  );
}

interface KeyboardAvoidingAreaProps extends KeyboardAvoidingViewProps {}
