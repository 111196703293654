import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import {LinkButton} from '../../../components/base/LinkButton';
import {Paragraph} from '../../../components/base/Paragraph';
import {useAuthNavigation} from '../../../navigation/auth-navigator/AuthNavigationContext';
import {GlobalStyles} from '../../../styles';
import {AppleAuth, GoogleAuth} from '../../../types/IUser';
import AppleAuthenticate from '../shared/AppleAuthenticate';
import AuthHeader from '../shared/AuthHeader';
import AuthHeading from '../shared/AuthHeading';
import GoogleAuthenticate from '../shared/GoogleAuthenticate';

export default function SignupTemplate() {
  const navigation = useAuthNavigation();

  const navigateToGoogleSignup = (auth: GoogleAuth) => {
    navigation?.push('signup-with-google', {auth});
  };
  const navigateToAppleSignup = (auth: AppleAuth) => {
    navigation?.push('signup-with-apple', {auth});
  };
  const navigateToSvexaSignup = () => {
    navigation?.push('signup-with-svexa');
  };

  return (
    <Div style={{flex: 1}}>
      <AuthHeader />
      <Div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <AuthHeading />
        <Div
          style={{
            width: '80%',
            maxWidth: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <GoogleAuthenticate
            onSuccessAuthentication={navigateToGoogleSignup}
            title="Create account with Google"
          />
          <AppleAuthenticate
            onSuccessAuthentication={navigateToAppleSignup}
            title="Create account with Apple"
          />
          <Button
            onPress={navigateToSvexaSignup}
            style={{
              width: '100%',
              borderWidth: 1,
              borderColor: GlobalStyles.colorsBasic.gray500,
              paddingHorizontal: 10,
              paddingVertical: 5,
              marginVertical: 8,
              height: 45,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 5,
              flexDirection: 'row',
            }}
          >
            <Icon name="mail" size={30} />
            <Paragraph style={{marginLeft: 20}}>
              Create account with email
            </Paragraph>
          </Button>
          <Paragraph style={{marginTop: 20}}>
            <Paragraph>Have an account?</Paragraph>{' '}
            <LinkButton onPress={() => navigation?.push('login')}>
              Sign in
            </LinkButton>
          </Paragraph>
        </Div>
      </Div>
    </Div>
  );
}
