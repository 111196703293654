import {useWindowDimensions} from 'react-native';
import {Div} from '../../../../components/base/Div';
import {maxContentWidth} from '../../../../constants/Layout';
import {IRating} from '../../../../types/IRatings';
import RatingsChartBar from './RatingsChartBar';
import RatingsChartLines from '../../../shared/BarChartLines';
import BarCharList from '../../../shared/bar-char-list/BarChartList';

export default function RatingsChart({
  ratings,
  onRatingSelected,
  numberOfBars,
  activeDateKey,
}: IRatingsChartProps) {
  const {width} = useWindowDimensions();
  const renderBar = ({item, index}: {item: IRating; index: number}) => (
    <RatingsChartBar
      key={index}
      index={index}
      rating={item}
      onPress={onRatingSelected}
      numberOfBars={numberOfBars}
      chartWidth={Math.min(width, maxContentWidth) - 50}
      activeDateKey={activeDateKey}
    />
  );

  return (
    <Div style={{marginBottom: 20, flexDirection: 'row'}}>
      <RatingsChartLines chartWidth={Math.min(width, maxContentWidth) - 50} />
      <BarCharList data={ratings} renderItem={renderBar} />
    </Div>
  );
}

interface IRatingsChartProps {
  ratings: IRating[];
  onRatingSelected: (rating: IRating) => any;
  numberOfBars: number;
  activeDateKey: string;
}
