import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AuthNavigationContext} from '../../../navigation/auth-navigator/AuthNavigationContext';
import {AuthStackScreenProps} from '../../../types/Navigation';
import SignupTemplate from './SignupTemplate';
import * as WebBrowser from 'expo-web-browser';

WebBrowser.maybeCompleteAuthSession();

export default function SignupScreen({
  navigation,
}: AuthStackScreenProps<'signup'>) {
  return (
    <AuthNavigationContext.Provider value={navigation}>
      <ScreenLayout>
        <SignupTemplate />
      </ScreenLayout>
    </AuthNavigationContext.Provider>
  );
}
