import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IGroup, IGroupAdmins, IGroupMembers} from '../../types/IGroup';
import {IRating} from '../../types/IRatings';

export interface CoachState {
  groups: {[key: string]: IGroup};
  groupAdmins: {[key: string]: IGroupAdmins};
  groupMembers: {[key: string]: IGroupMembers};
  athletesRatings: {[id: string]: {[dateKey: string]: IRating}};
}

const initialState: CoachState = {
  groups: {},
  groupAdmins: {},
  groupMembers: {},
  athletesRatings: {},
};

export const coachSlice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    setGroups: (
      state: CoachState,
      action: PayloadAction<{[key: string]: IGroup}>,
    ) => {
      state.groups = action.payload;
    },
    setGroup: (state: CoachState, action: PayloadAction<IGroup>) => {
      state.groups = {...state.groups, [action.payload.id!]: action.payload};
    },
    setGroupAdmins: (
      state: CoachState,
      action: PayloadAction<{groupId: string; admins: IGroupAdmins}>,
    ) => {
      state.groupAdmins[action.payload.groupId] = action.payload.admins;
    },
    setGroupMembers: (
      state: CoachState,
      action: PayloadAction<{groupId: string; members: IGroupMembers}>,
    ) => {
      state.groupMembers[action.payload.groupId] = action.payload.members;
    },
    setAthletesRating: (
      state: CoachState,
      action: PayloadAction<{
        athleteId: string;
        dateKey: string;
        rating: IRating;
      }>,
    ) => {
      const athlete = state.athletesRatings[action.payload.athleteId] || {};
      state.athletesRatings = {
        ...state.athletesRatings,
        [action.payload.athleteId]: {
          ...athlete,
          [action.payload.dateKey]: athlete[action.payload.dateKey]
            ? {
                ...athlete[action.payload.dateKey],
                ...action.payload.rating,
                ...(action.payload.rating.values?.length
                  ? {hasValues: true}
                  : {}),
              }
            : {
                ...action.payload.rating,
                ...(action.payload.rating.values?.length
                  ? {hasValues: true}
                  : {}),
              },
        },
      };
    },
    setMultipleAthletesRating: (
      state: CoachState,
      action: PayloadAction<{[id: string]: IRating[]}>,
    ) => {
      const athleteRatings = state.athletesRatings;
      Object.keys(action.payload).forEach((id) => {
        const athleteRating = athleteRatings[id] || {};
        action.payload[id].forEach((rating) => {
          const r = athleteRating[rating.dateKey] || {};
          athleteRating[rating.dateKey] = {...r, ...rating};
        });
        athleteRatings[id] = athleteRating;
      });
      state.athletesRatings = {...athleteRatings};
    },
  },
});

export const coachActions = coachSlice.actions;

export default coachSlice.reducer;
