import {RootStackParamList} from '../../types/Navigation';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import NotFoundScreen from '../../screens/shared/not-found/NotFoundScreen';
import AthleteNavigator from '../athlete-navigator/AthleteNavigator';
import AuthNavigator from '../auth-navigator/AuthNavigator';
import useAuthService from '../../services/useAuthService';
import CoachNavigator from '../coach-navigator/CoachNavigator';
import useUserSettingsService from '../../services/useUserSettingsService';
import AuthorizeGarminScreen from '../../screens/shared/authorize-garmin/AuthorizeGarminScreen';
import AuthorizeOuraScreen from '../../screens/shared/authorize-oura/AuthorizeOuraScreen';
import ResetPasswordScreen from '../../screens/auth/reset-password/ResetPasswordScreen';
import EmailVerificationScreen from '../../screens/shared/email-verification/EmailVerificationScreen';
import DeleteAccountInfoScreen from '../../screens/shared/delete-account-info/DeleteAccountInfoScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function RootNavigator() {
  const {useUser, useTemporaryLogout} = useAuthService();
  useTemporaryLogout();
  const defaultView = useUser()?.defaultView || 'athlete';
  const {useSetNotificationListener, useListenBadgeCount} =
    useUserSettingsService();
  useSetNotificationListener();
  useListenBadgeCount();

  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={defaultView}
    >
      <Stack.Screen name="athlete" component={AthleteNavigator} />
      <Stack.Screen name="auth" component={AuthNavigator} />
      <Stack.Screen name="coach" component={CoachNavigator} />
      <Stack.Screen name="not-found" component={NotFoundScreen} />
      <Stack.Screen name="authorize-garmin" component={AuthorizeGarminScreen} />
      <Stack.Screen name="authorize-oura" component={AuthorizeOuraScreen} />
      <Stack.Screen name="reset-password" component={ResetPasswordScreen} />
      <Stack.Screen
        name="email-verification"
        component={EmailVerificationScreen}
      />
      {/* <Stack.Screen name="delete-account" component={DeleteAccountInfoScreen} /> */}
    </Stack.Navigator>
  );
}
