import moment from 'moment';
import React from 'react';
import {StyleSheet} from 'react-native';
import Button from '../../../../components/base/Button';
import {Div} from '../../../../components/base/Div';
import Icon from '../../../../components/base/Icon';
import {Paragraph} from '../../../../components/base/Paragraph';
import DateTimPicker from '../../../../components/shared/date-time-picker/DateTimePicker';
import {minYear} from '../../../../constants/Config';
import useDateTimeProvider from '../../../../providers/useDateTimeProvider';
import useCoachService from '../../../../services/useCoachService';
import {GlobalStyles} from '../../../../styles';
import {useCurrentGroup} from '../GroupContext';
import TeamChart from './TeamChart';
import TeamRatingsOverview from './TeamRatingsOverview';

export default function TeamOverview() {
  const group = useCurrentGroup();
  const {formatDateKey} = useDateTimeProvider();
  const initDiff = 13; // 14 days span
  const {getMomentRange} = useDateTimeProvider();
  const [daysSpanDiff, setDaysSpanDiff] = React.useState(initDiff);
  const [dateSpan, setDateSpan] = React.useState(getMomentRange(initDiff));
  const [numberOfBars, setNumberOfBars] = React.useState(initDiff + 1);
  const [sort, setSort] = React.useState(-1);
  const {useFetchTeamAthleteRatings, useTeamAthletesRatings} =
    useCoachService();
  const {fetch} = useFetchTeamAthleteRatings();
  const [currentDateKey, setCurrentDateKey] = React.useState(formatDateKey());
  const athletes = useCoachService().useTeamRatingsOverview(
    currentDateKey,
    group?.id || '',
    sort,
  );

  React.useEffect(() => {
    fetch({
      span: (initDiff + 1).toString(),
      dateKey: formatDateKey(),
      groupId: group?.id || '',
    });
  }, []);

  const periodRatings = useTeamAthletesRatings(
    daysSpanDiff,
    dateSpan.to.clone(),
    group?.id || '',
  );

  const onFromDateSelected = (value: Date) => {
    const dateFrom = moment(value).startOf('day');
    let dateTo = dateSpan.to.clone().startOf('day');
    if (dateTo.diff(dateFrom, 'days') < initDiff) {
      dateTo = moment(dateFrom).add(initDiff, 'days');
    }
    if (dateTo.diff(dateFrom, 'days') > 90) {
      dateTo = moment(dateFrom).add(90, 'days');
    }
    const diff = dateTo.diff(dateFrom, 'days') + 1;
    fetch({
      span: diff.toString(),
      dateKey: dateTo.format('YYYYMMDD'),
      groupId: group?.id || '',
    });
    setDaysSpanDiff(diff - 1);
    setDateSpan({
      from: dateFrom,
      to: dateTo,
    });
  };

  const onToDateSelected = (value: Date) => {
    const dateTo = moment(value).startOf('day');
    let dateFrom = dateSpan.from.clone().startOf('day');
    if (dateTo.diff(dateFrom, 'days') < initDiff) {
      dateFrom = moment(dateTo).subtract(initDiff, 'days');
    }
    if (dateTo.diff(dateFrom, 'days') > 90) {
      dateFrom = moment(dateTo).subtract(90, 'days');
    }
    const diff = dateTo.diff(dateFrom, 'days') + 1;
    fetch({
      span: diff.toString(),
      dateKey: dateTo.format('YYYYMMDD'),
      groupId: group?.id || '',
    });
    setDaysSpanDiff(diff - 1);
    setDateSpan({
      from: dateFrom,
      to: dateTo,
    });
  };

  const selectBars = (bars: number) => {
    const newNumberOfBars = bars + numberOfBars;
    if (newNumberOfBars - 1 > daysSpanDiff) {
      const newDiff = newNumberOfBars - 1;
      fetch({
        span: newNumberOfBars.toString(),
        dateKey: dateSpan.to.format('YYYYMMDD'),
        groupId: group?.id || '',
      });
      setDateSpan({
        to: dateSpan.to,
        from: dateSpan.to.clone().subtract(newDiff, 'days'),
      });
      setDaysSpanDiff(newDiff);
    }
    setNumberOfBars(newNumberOfBars);
  };

  return (
    <Div>
      <Div style={styles.dateRange}>
        <Div>
          <Paragraph>From</Paragraph>
          <DateTimPicker
            value={dateSpan.from.toDate()}
            minDate={moment().year(minYear).startOf('year').toDate()}
            maxDate={moment().subtract(initDiff, 'days').toDate()}
            onChange={onFromDateSelected}
          />
        </Div>
        <Div>
          <Paragraph>To</Paragraph>
          <DateTimPicker
            value={dateSpan.to.toDate()}
            minDate={moment()
              .year(minYear)
              .startOf('year')
              .add(6, 'days')
              .toDate()}
            maxDate={new Date()}
            onChange={onToDateSelected}
          />
        </Div>
      </Div>
      <Div style={styles.zoomButtonGroup}>
        {numberOfBars != 21 ? (
          <Button style={styles.zoomButton} onPress={() => selectBars(7)}>
            <Icon name="zoom-out" size={15} />
            <Paragraph style={{marginLeft: 5}}>zoom out</Paragraph>
          </Button>
        ) : null}
        {numberOfBars != 7 ? (
          <Button
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginHorizontal: 12,
            }}
            onPress={() => selectBars(-7)}
          >
            <Icon name="zoom-in" size={15} />
            <Paragraph style={{marginLeft: 5}}>zoom in</Paragraph>
          </Button>
        ) : null}
      </Div>
      <Paragraph style={{marginVertical: 10}}>
        {dateSpan.from.isSame(dateSpan.to, 'months') ? (
          `${dateSpan.from.format('MMMM')}`
        ) : (
          <>
            <Paragraph>{dateSpan.from.format('MMMM')}</Paragraph>
            <Paragraph> - </Paragraph>
            <Paragraph>{dateSpan.to.format('MMMM')}</Paragraph>
          </>
        )}
      </Paragraph>
      <TeamChart
        ratings={periodRatings}
        onDateKeySelected={setCurrentDateKey}
        activeDateKey={currentDateKey}
        numberOfBars={numberOfBars}
        numberOfAthletes={athletes.length}
      />
      <Div style={{marginBottom: 12}}>
        <Paragraph>Sort by</Paragraph>
        <Div style={{flexDirection: 'row', alignItems: 'center', marginTop: 8}}>
          <Button
            title="lowest readiness"
            style={[
              styles.sortButton,
              {
                backgroundColor:
                  sort < 0 ? GlobalStyles.svexa.green500 : 'transparent',
                marginRight: 16,
              },
            ]}
            titleStyle={[
              styles.sortButtonText,
              {
                color:
                  sort < 0
                    ? GlobalStyles.colorsBasic.white500
                    : GlobalStyles.colorsBasic.black500,
              },
            ]}
            onPress={() => setSort(-1)}
          />
          <Button
            title="highest readiness"
            style={[
              styles.sortButton,
              {
                backgroundColor:
                  sort > 0 ? GlobalStyles.svexa.green500 : 'transparent',
                marginRight: 16,
              },
            ]}
            titleStyle={[
              styles.sortButtonText,
              {
                color:
                  sort > 0
                    ? GlobalStyles.colorsBasic.white500
                    : GlobalStyles.colorsBasic.black500,
              },
            ]}
            onPress={() => setSort(1)}
          />
        </Div>
      </Div>
      <TeamRatingsOverview dateKey={currentDateKey} athletes={athletes} />
    </Div>
  );
}

const styles = StyleSheet.create({
  dateRange: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  zoomButtonGroup: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  zoomButton: {
    flexDirection: 'row',
    marginRight: 24,
  },
  sortButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 16,
  },
  sortButtonText: {
    fontSize: 12,
  },
});
