import {algorithamConstants} from '../constants/Config';
import {
  IGroup,
  IGroupAdmins,
  IGroupMembers,
  RemoveMemberFromGroupDTO,
  SendGroupInviteDTO,
} from '../types/IGroup';
import {FormDataImage} from '../types/Image';
import {InviteTypes} from '../types/IPermissions';
import {IRating} from '../types/IRatings';
import {IResponse} from '../types/Request';
import BaseApi from './BaseGateway';

const CoachGateway = {
  getGroups: async (): Promise<{[key: string]: IGroup}> => {
    const groups: {[key: string]: IGroup} = await BaseApi.get(
      `/coach/${BaseApi.getEncodedUserId()}/groups?token=${BaseApi.getAccessToken()}`,
    );
    const promises: any = [];
    Object.keys(groups).forEach((k) => {
      promises.push(
        (async () => {
          groups[k].id = k;
          groups[k].logo = await CoachGateway.getGroupLogo({
            groupId: groups[k].id!,
          });
          return groups[k];
        })(),
      );
    });
    const assembledGroups: {[key: string]: IGroup} = {};
    (await Promise.all(promises)).forEach(
      (g: any) => (assembledGroups[g.id] = g),
    );
    return assembledGroups;
  },

  getGroup: async ({groupId}: {groupId: string}): Promise<IGroup | null> => {
    const group: IGroup = await BaseApi.get(
      `/coach/${BaseApi.getEncodedUserId()}/groups/${encodeURIComponent(
        groupId,
      )}?token=${BaseApi.getAccessToken()}`,
    );
    if (!group) return null;
    group.id = groupId;
    group.logo = await CoachGateway.getGroupLogo({groupId});
    return group;
  },

  getGroupLogo: async ({
    groupId,
  }: {
    groupId: string;
  }): Promise<string | null> => {
    const groupImage = await BaseApi.get<{[id: string]: string}>(
      `/coach/${BaseApi.getEncodedUserId()}/groups/${encodeURIComponent(
        groupId,
      )}/logo?token=${BaseApi.getAccessToken()}`,
    );
    if (!groupImage || !groupImage[groupId]) {
      return null;
    }
    return `data:${groupImage[groupId]}`;
  },

  uploadGroupLogo: async ({
    groupId,
    formDataImg,
  }: {
    groupId: string;
    formDataImg: FormDataImage;
  }): Promise<IGroup | null> => {
    const formData = new FormData();
    formData.append('file', formDataImg as any);
    const response: IResponse = await BaseApi.upload(
      `/fileupload/${BaseApi.getEncodedUserId()}/${encodeURIComponent(
        groupId,
      )}/logo?token=${BaseApi.getAccessToken()}`,
      formData,
    );
    if (!response) return null;
    return await CoachGateway.getGroup({groupId});
  },

  createGroup: async ({
    name,
    formDataImg,
  }: {
    name: string;
    formDataImg?: FormDataImage;
  }): Promise<IGroup | null> => {
    const groupResponse: {groupHash: string} = await BaseApi.post(
      `/groupadmin/${BaseApi.getEncodedUserId()}/create?token=${BaseApi.getAccessToken()}`,
      {groupName: name},
    );
    if (!groupResponse || !groupResponse.groupHash) return null;
    if (!formDataImg)
      return await CoachGateway.getGroup({groupId: groupResponse.groupHash});
    try {
      return await CoachGateway.uploadGroupLogo({
        groupId: groupResponse.groupHash,
        formDataImg,
      });
    } catch (err) {
      return await CoachGateway.getGroup({groupId: groupResponse.groupHash});
    }
  },

  editGroup: async ({
    groupId,
    name,
    formDataImg,
  }: {
    groupId: string;
    name: string;
    formDataImg?: FormDataImage;
  }): Promise<IGroup | null> => {
    const response: IResponse = await BaseApi.put(
      `/groupadmin/${BaseApi.getEncodedUserId()}/setname?token=${BaseApi.getAccessToken()}`,
      {groupName: name, groupId},
    );
    if (!response) return null;
    if (!formDataImg) return await CoachGateway.getGroup({groupId});
    return await CoachGateway.uploadGroupLogo({
      groupId,
      formDataImg,
    });
  },

  getGroupAdmins: async ({
    groupId,
  }: {
    groupId: string;
  }): Promise<IGroupAdmins> => {
    const groupAdmins: {[key: string]: any} = await BaseApi.get(
      `/coach/${BaseApi.getEncodedUserId()}/groups/${encodeURIComponent(
        groupId,
      )}/admins?token=${BaseApi.getAccessToken()}`,
    );
    //const photos = await CoachGateway.getGroupMembersPhotos({groupId});
    const pending = groupAdmins.pending;
    const admins = {...groupAdmins};
    delete admins.pending;
    Object.keys(admins || {}).forEach((ak) => {
      admins[ak].id = ak;
      //admins[ak].image = photos[ak] ? `data:${photos[ak]}` : null;
    });
    return {admins, pending};
  },

  getGroupMembers: async ({
    groupId,
  }: {
    groupId: string;
  }): Promise<IGroupMembers> => {
    const groupMembers: {[key: string]: any} = await BaseApi.get(
      `/coach/${BaseApi.getEncodedUserId()}/groups/${encodeURIComponent(
        groupId,
      )}/members?token=${BaseApi.getAccessToken()}`,
    );
    const photos = await CoachGateway.getGroupMembersPhotos({groupId});
    const pending = groupMembers.pending;
    const members = {...groupMembers};
    delete members.pending;
    Object.keys(members || {}).forEach((mk) => {
      members[mk].id = mk;
      members[mk].image = photos[mk] ? `data:${photos[mk]}` : null;
    });
    return {members, pending};
  },

  getGroupMembersPhotos: async ({
    groupId,
  }: {
    groupId: string;
  }): Promise<{[id: string]: string}> => {
    return await BaseApi.get(
      `/coach/${BaseApi.getEncodedUserId()}/groups/${encodeURIComponent(
        groupId,
      )}/profilephotos?token=${BaseApi.getAccessToken()}`,
    );
  },

  inviteToGroup: async ({
    groupId,
    hash,
    userId,
    userEmail,
    type,
    hasInvitation,
  }: SendGroupInviteDTO): Promise<{
    group: IGroup | null;
    members: IGroupMembers | IGroupAdmins;
  } | null> => {
    const inviteType = type === 'athletes' ? 'member' : 'admin';
    const addBy = userId ? 'add' : 'addbyemail';
    const payload: any = {
      type: inviteType,
      groupId,
      hash,
      userId,
      provider: 'all',
    };
    if (addBy == 'addbyemail') {
      delete payload.userId;
      payload.userEmail = userEmail;
      payload.addAsPendingUser = '1';
      if (!hasInvitation) {
        const inviteType: InviteTypes =
          type == 'athletes' ? 'athlete' : 'coachColleague';
        const invite = await BaseApi.put(
          `/permissions/${BaseApi.getEncodedUserId()}/invite?token=${BaseApi.getAccessToken()}`,
          {
            email: userEmail,
            type: inviteType,
            sendNotification: '1',
            provider: 'all',
          },
        );
        if (!invite) return null;
      }
    }
    const response = await BaseApi.put(
      `/groupadmin/${BaseApi.getEncodedUserId()}/${addBy}?token=${BaseApi.getAccessToken()}`,
      payload,
    );
    if (!response) return null;
    const group = await CoachGateway.getGroup({groupId});
    let members;
    if (type == 'athletes') {
      members = await CoachGateway.getGroupMembers({groupId});
    } else {
      members = await CoachGateway.getGroupAdmins({groupId});
    }
    return {group, members};
  },

  removeFromGroup: async ({
    groupId,
    type,
    hash,
    userId,
    userEmail,
  }: RemoveMemberFromGroupDTO): Promise<{
    group: IGroup | null;
    members: IGroupMembers | IGroupAdmins;
  } | null> => {
    const removeBy = userId ? 'remove' : 'removebyemail';
    const payload: any = {
      groupId,
      hash,
      type,
      userId,
    };
    if (removeBy == 'removebyemail') {
      delete payload.userId;
      payload.userEmail = userEmail;
    }
    const response = await BaseApi.put(
      `/groupadmin/${BaseApi.getEncodedUserId()}/${removeBy}?token=${BaseApi.getAccessToken()}`,
      payload,
    );
    if (!response) return null;
    const group = await CoachGateway.getGroup({groupId});
    let members;
    if (type == 'member') {
      members = await CoachGateway.getGroupMembers({groupId});
    } else {
      members = await CoachGateway.getGroupAdmins({groupId});
    }
    return {group, members};
  },

  deleteGroup: async ({
    groupId,
  }: {
    groupId: string;
  }): Promise<{[key: string]: IGroup} | null> => {
    const response = await BaseApi.put(
      `/groupadmin/${BaseApi.getEncodedUserId()}/drop?token=${BaseApi.getAccessToken()}`,
      {
        groupId,
      },
    );
    if (!response) return null;
    return await CoachGateway.getGroups();
  },

  getAthleteRatings: async ({
    athleteId,
    dateKey,
    span,
  }: {
    athleteId: string;
    dateKey: string;
    span: string;
  }): Promise<IRating[]> => {
    let path = `/coach/${BaseApi.getEncodedUserId()}/recovery/${encodeURIComponent(
      athleteId,
    )}?token=${BaseApi.getAccessToken()}&span=${span}&date_key=${dateKey}`;
    path +=
      '&supported_questions_definition=' +
      algorithamConstants.supported_questions_definition.toString();
    path +=
      '&supported_level_definition=' +
      algorithamConstants.supported_level_definition.toString();
    path +=
      '&supported_severity_definition=' +
      algorithamConstants.supported_severity_definition.toString();
    return await BaseApi.get(path);
  },

  getGroupAthletesRatings: async ({
    groupId,
    dateKey,
    span,
  }: {
    groupId: string;
    dateKey: string;
    span: string;
  }): Promise<{[id: string]: IRating[]}> => {
    let path = `/coach/${BaseApi.getEncodedUserId()}/groups/${encodeURIComponent(
      groupId,
    )}/scores?token=${BaseApi.getAccessToken()}&span=${span}&date_key=${dateKey}`;
    path +=
      '&supported_questions_definition=' +
      algorithamConstants.supported_questions_definition.toString();
    path +=
      '&supported_level_definition=' +
      algorithamConstants.supported_level_definition.toString();
    path +=
      '&supported_severity_definition=' +
      algorithamConstants.supported_severity_definition.toString();
    return await BaseApi.get(path);
  },

  sendRatingsReminder: async ({
    userIds,
  }: {
    userIds: string[];
  }): Promise<IResponse> => {
    return await BaseApi.put(
      `/notifications/${BaseApi.getEncodedUserId()}/sendnotification?token=${BaseApi.getAccessToken()}`,
      {uIds: userIds},
    );
  },
};

export default CoachGateway;
