import {
  Picker,
  PickerItemProps,
  PickerProps,
} from '@react-native-picker/picker';

export function Dropdown(props: PickerProps) {
  return <Picker {...props}>{props.children}</Picker>;
}

export function DropdownItem(props: PickerItemProps) {
  return <Picker.Item {...props} />;
}
