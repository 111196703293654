import React from 'react';
import {ITab} from '../../../types/Tab';
import List from '../List';
import TabItem from './TabItem';

export default function TabList({tabs, onTabClicked}: ITabListProps) {
  const [activeTab, setActiveTab] = React.useState<ITab>(tabs[0]);

  const _onTabClicked = (tab: ITab) => {
    if (onTabClicked) return onTabClicked(activeTab, tab, setActiveTab);
    setActiveTab(tab);
  };

  const renderTab = ({item, index}: {item: ITab; index: number}) => {
    return (
      <TabItem
        tab={item}
        index={index}
        isActive={item.title === activeTab.title}
        onPress={_onTabClicked}
      />
    );
  };

  return (
    <>
      <List
        style={{
          marginBottom: 16,
        }}
        /* contentContainerStyle={{
          width: '100%',
          flex: 1,
          justifyContent: 'space-between',
        }} */
        data={tabs}
        horizontal
        renderItem={renderTab}
      />
      {activeTab?.component}
    </>
  );
}

interface ITabListProps {
  tabs: ITab[];
  onTabClicked?: (
    activeTab: ITab,
    clickedTab: ITab,
    setTab: (tab: ITab) => any,
  ) => any;
}
