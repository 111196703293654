import * as React from 'react';
import {Div} from '../../../components/base/Div';
import Loader from '../../../components/base/Loader';
import {useAthleteNavigation} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import useRatingService from '../../../services/useRatingService';
import {GlobalStyles} from '../../../styles';
import {Paragraph} from '../../../components/base/Paragraph';

export default function RatingsMiddleware({
  children,
  persist,
}: RatingsMiddlewareProps) {
  const {useRating, useAutoFetchUserRatings} = useRatingService();
  const {formatDateKey} = useDateTimeProvider();
  const rating = useRating(formatDateKey());
  const navigation = useAthleteNavigation();

  const {loading} = useAutoFetchUserRatings({
    span: '14',
    dateKey: formatDateKey(),
  });

  React.useEffect(() => {
    if (rating && !rating.values && !persist) {
      setTimeout(
        () => navigation?.navigate('add-ratings', {dateKey: formatDateKey()}),
        10,
      );
    }
  }, [rating, persist]);

  return !!persist || !!rating?.values ? (
    <>{children}</>
  ) : !rating && loading ? (
    <Div
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: GlobalStyles.colorsBasic.white500,
      }}
    >
      <Loader size={'large'} color={GlobalStyles.svexa.green600} />
      <Paragraph style={{marginTop: 8}}>
        Checking your daily ratings...
      </Paragraph>
    </Div>
  ) : (
    <Div
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: GlobalStyles.colorsBasic.white500,
      }}
    />
  );
}

interface RatingsMiddlewareProps extends React.PropsWithChildren {
  persist?: boolean;
}
