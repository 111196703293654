import React, {useEffect} from 'react';
import useAuthService from '../../../services/useAuthService';
import {Div} from '../../../components/base/Div';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {IProfileForm} from '../../../types/IUser';
import ProfilePhoto from './ProfilePhoto';
import Form from '../../../components/shared/Form';
import Loader from '../../../components/base/Loader';
import {useProfileContext} from './ProfileContext';

export default function Profile() {
  const user = useAuthService().useUser()!;

  const onSavedData = () => setHasUnsavedChanges(false);
  const onTouched = () => setHasUnsavedChanges(true);

  const {processing, update, form, profileFormData, formReference} =
    useUserSettingsService().useUserSettings(onSavedData);

  const {setHasUnsavedChanges} = useProfileContext();

  const saveProfile = (profileForm: IProfileForm) => {
    update(profileForm);
  };

  return (
    <Div>
      {user ? (
        <Div>
          <ProfilePhoto />
          <Form
            form={form}
            formReference={formReference}
            entityState={profileFormData}
            onSubmit={saveProfile}
            onTouched={onTouched}
            submitControl={(submit) => (
              <CustomButton
                title="Save"
                onPress={submit}
                type="primary"
                style={{marginTop: 20}}
                titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                disabled={processing}
                isLoading={processing}
                loader={{size: 'small', color: 'black'}}
              />
            )}
          />
        </Div>
      ) : (
        <Div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Loader size={'large'} color={GlobalStyles.svexa.green400} />
        </Div>
      )}
    </Div>
  );
}
