import React from 'react';
import Validator from '../constants/Validator';
import {
  IForm,
  IFormBuilder,
  IFormControlState,
  IFormReference,
} from '../types/Form';

export default function useFormProvider() {
  function useFormBuilder<T>(
    form: IForm<T>,
    entityState?: T,
    onSubmit?: (entity: T) => any,
    cleanFormOnSubmit?: boolean,
    formReference?: React.MutableRefObject<IFormReference<T>>,
    onTouched?: () => any,
  ) {
    entityState = entityState || ({} as T);
    const onSubmitCb = onSubmit
      ? onSubmit
      : () => {
          return;
        };
    const [formState, setFormState] = React.useState<IFormBuilder<T>>(
      buildState<T>(form, entityState),
    );

    const [isTouched, setIsTouched] = React.useState(false);

    React.useEffect(() => {
      if (formReference) {
        formReference.current.setState = (state) => {
          setFormState(buildState(form, state));
        };
      }
    }, [formReference]);

    function validateForm<FType>(
      F: IForm<FType>,
      state: IFormBuilder<FType>,
    ): {
      hasErrors: boolean;
      validatedState: IFormBuilder<FType>;
    } {
      let hasErrors = false;
      const validatedState: IFormBuilder<FType> = {} as IFormBuilder<FType>;
      Object.keys(F).forEach((key) => {
        const k = key as keyof FType;
        if (F[k].type == 'formGroup' && F[k].controls) {
          const {validatedState: groupState, hasErrors: errors} = validateForm(
            F[k].controls!,
            state[key],
          );
          validatedState[key] = groupState;
          if (errors) {
            hasErrors = errors;
          }
          return;
        }
        validatedState[key] = {
          value: state[key].value,
          error: state[key].error,
        };
        const control = F[k];
        if (!control.validators) {
          return;
        }
        const validator = new Validator(
          state[key].value as string,
          control.label || key,
        );
        const status = validator.validate(control.validators);
        validatedState[key].error = status.error || null;
        if (!status.valid) {
          hasErrors = true;
        }
      });
      return {hasErrors, validatedState};
    }

    function populatePayload<FType>(F: IForm<FType>, FB: IFormBuilder<FType>) {
      const fPayload: FType = {} as FType;
      if (!F) {
        return fPayload;
      }
      Object.keys(FB).forEach((key) => {
        const k = key as keyof FType;
        if (F[k].type == 'formGroup') {
          const groupFormBuilder = FB[k] as any as IFormBuilder<
            FType[keyof FType]
          >;
          const groupForm = F[k].controls! as any as IForm<FType[keyof FType]>;
          fPayload[k] = populatePayload(groupForm, groupFormBuilder);
          return;
        }
        const controlFormBuilder = FB[k] as any as IFormControlState<FType>;
        let v = controlFormBuilder.value;
        if (typeof v == 'string') {
          v = v.trim();
        }
        const map = F[k].mapValue;
        if (map) {
          v = map(v);
        }
        fPayload[k] = v as any as FType[keyof FType];
      });
      return fPayload;
    }

    function submit() {
      const {validatedState, hasErrors} = validateForm(form, formState);
      setFormState(validatedState);
      if (hasErrors) {
        return;
      }
      const payload: T = populatePayload(form, formState);
      onSubmitCb(payload);

      if (cleanFormOnSubmit) {
        setFormState(cleanState(form));
      }
    }

    function onValueChange(
      path: string,
      value: string,
      alteredState?: IFormBuilder<T>,
    ) {
      const keys = path.split('.').filter((k) => k != 'controls');
      let loopState = alteredState || formState;
      keys.forEach((key, index) => {
        if (index != keys.length - 1) {
          loopState = loopState[key];
          return;
        }
        loopState[key] = {
          value,
          error: loopState[key]?.error || null,
        };
      });
      setFormState({...(alteredState || formState)});
      if (!isTouched) {
        setIsTouched(true);
        if (onTouched) onTouched();
      }
    }

    return {submit, formState, onValueChange, setFormState};
  }

  function cleanState<FType>(F: IForm<FType>): IFormBuilder<FType> {
    const state: IFormBuilder<FType> = {} as IFormBuilder<FType>;
    Object.keys(F).map((key) => {
      const k = key as keyof FType;
      if (F[k].type == 'formGroup' && F[k].controls) {
        state[k] = cleanState(F[k].controls!) as any as IFormBuilder<
          FType[keyof FType]
        >;
        return;
      }
      state[k] = {
        value: F[k].defaultValue || '',
        error: null,
      };
    });
    return state;
  }

  function buildState<FType>(
    F: IForm<FType>,
    fState: FType,
  ): IFormBuilder<FType> {
    fState = fState || ({} as FType);
    const state: IFormBuilder<FType> = {} as IFormBuilder<FType>;
    Object.keys(F).forEach((key) => {
      const k = key as keyof FType;
      if (F[k].type == 'formGroup' && F[k].controls) {
        const groupState = fState[k] as any;
        state[k] = buildState(
          F[k].controls!,
          groupState,
        ) as any as IFormBuilder<FType[keyof FType]>;
        return;
      }
      state[k] = {
        value:
          typeof fState[k] != 'undefined' ? fState[k] : F[k].defaultValue || '',
        error: null,
      };
    });
    return state;
  }

  function getNestedValueFromPath<T>(
    object: any,
    path: string,
    skipKey?: string,
  ): T {
    let value: T = object;
    if (!value) {
      return value;
    }
    const keys = path.split('.');
    keys.forEach((key) => {
      if (!key) return;
      if (skipKey && key == skipKey) return;
      value = value[key];
    });
    return value;
  }

  return {useFormBuilder, getNestedValueFromPath};
}
