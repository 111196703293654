import {StyleSheet} from 'react-native';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import Icon from '../../../components/base/Icon';
import Image from '../../../components/base/Image';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import Loader from '../../../components/base/Loader';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import {useCoachNavigation} from '../../../navigation/coach-navigator/CoachNavigationContext';
import useCoachService from '../../../services/useCoachService';
import {GlobalStyles} from '../../../styles';

export default function TeamsTemplate() {
  const {groups, fetching} = useCoachService().useGroups();
  const navigation = useCoachNavigation();
  const navigateToAddGroup = () => navigation?.navigate('add-team');
  return (
    <KeyboardAvoidingScrollArea>
      <Div
        style={{
          marginTop: 12,
          margin: 24,
          maxWidth: 600,
        }}
      >
        <HeadingTwo>Teams</HeadingTwo>
        {!Object.keys(groups).length && fetching ? (
          <Div
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader size="large" color={GlobalStyles.svexa.green400} />
          </Div>
        ) : (
          <Div style={style.groupsContainer}>
            {Object.keys(groups).map((key, index) => {
              const navigateToTeam = () =>
                navigation?.navigate('team', {id: group?.id || ''});
              const group = groups[key];
              return (
                <Button
                  style={style.groupWrapper}
                  onPress={navigateToTeam}
                  key={index.toString()}
                >
                  <Image
                    height={50}
                    width={50}
                    source={
                      group.logo
                        ? {
                            uri: group.logo,
                          }
                        : assets.images.teamAvatar
                    }
                    resizeMode={'cover'}
                    style={{marginBottom: 20, borderRadius: 25}}
                  />
                  <Paragraph
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                    style={style.groupTitle}
                  >
                    {group.name}
                  </Paragraph>
                </Button>
              );
            })}
            <Button style={style.groupWrapper} onPress={navigateToAddGroup}>
              <Div height={50} width={50} style={style.addGroup}>
                <Icon
                  name="plus"
                  color={GlobalStyles.colorsBasic.black500}
                  size={20}
                />
              </Div>
              <Paragraph
                style={style.groupTitle}
                ellipsizeMode={'tail'}
                numberOfLines={2}
              >
                Create new team
              </Paragraph>
            </Button>
          </Div>
        )}
      </Div>
    </KeyboardAvoidingScrollArea>
  );
}

const style = StyleSheet.create({
  groupWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: GlobalStyles.colorsBasic.gray100,
    backgroundColor: GlobalStyles.colorsBasic.gray100,
    borderRadius: 6,
    padding: 14,
    margin: 5,
    height: 130,
    width: 100,
  },
  groupTitle: {
    marginVertical: 'auto',
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: 13,
    color: GlobalStyles.colorsBasic.black500,
  },
  addGroup: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: GlobalStyles.colorsBasic.gray400,
    marginBottom: 20,
    borderRadius: 25,
  },
  groupsContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});
