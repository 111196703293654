import {Platform, StyleSheet, useWindowDimensions} from 'react-native';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import RangeSlider from '../../../components/shared/range-slider/RangeSlider';
import {IQuestion} from '../../../types/IQuestion';
import {GlobalStyles} from '../../../styles';
import React from 'react';
import QuestionItemDetails from '../../shared/QuestionItemDetails';
import {maxContentWidth} from '../../../constants/Layout';
import * as Haptics from 'expo-haptics';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';

export default function QuestionRating({
  question,
  index,
  onValueChanged,
  hasValues,
}: QuestionRatingProps) {
  const [questionTouched, setQuestionedTouched] = React.useState(hasValues);
  const [hintVisible, setHintVisible] = React.useState(false);
  const showHint = () => setHintVisible(true);
  const hideHint = () => setHintVisible(false);
  const {width} = useWindowDimensions();
  const {translate} = useLocalizationProvider();

  return (
    <Div
      style={[
        styles.wrapper,
        index == 0
          ? {
              borderTopWidth: 1,
              borderTopColor: GlobalStyles.colorsBasic.gray400,
            }
          : {},
      ]}
      key={index.toString()}
    >
      {hintVisible ? (
        <Div
          style={{
            position: 'absolute',
            backgroundColor: GlobalStyles.svexa.green500,
            padding: 10,
            borderRadius: 5,
            zIndex: 999,
            elevation: 99,
            bottom: Platform.OS == 'ios' ? 65 : 58,
            left: 12,
            maxWidth: Math.min(width, maxContentWidth) - 24,
            flexDirection: 'row',
          }}
        >
          <Paragraph
            translate={false}
            style={{
              fontSize: 13,
              flexShrink: 1,
              color: GlobalStyles.colorsBasic.white500,
            }}
          >
            {question.score[question.value as number].description}
          </Paragraph>
        </Div>
      ) : null}
      <Div style={{flex: 1}}>
        <Div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Paragraph style={{fontSize: 15}} translate={false}>
            {question.text.replace('&', translate('and'))}
            {question.text.endsWith('?') ? '' : ':'}
          </Paragraph>
          <QuestionItemDetails question={question} />
          <Paragraph
            translate={false}
            style={{
              fontWeight: '700',
              marginLeft: 12,
              fontSize: 18,
              color: question.score[question.value as number].color,
            }}
          >
            {question.value}
          </Paragraph>
        </Div>
        <Div
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
            Platform.OS == 'ios' ? {marginTop: -3} : {marginVertical: 5},
          ]}
        >
          <Paragraph
            translate={false}
            style={{
              marginRight: 10,
              fontSize: 15,
              color: GlobalStyles.colorsBasic.gray700,
            }}
          >
            {question.minValue}
          </Paragraph>
          <Div style={{flex: 1}}>
            <RangeSlider
              onValueChange={(value) => {
                setQuestionedTouched(true);
                onValueChanged(value);
                if (Platform.OS == 'ios')
                  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
              }}
              minimumValue={question.minValue}
              maximumValue={question.maxValue}
              value={question.value as number}
              step={1}
              minimumTrackTintColor={
                question?.score[question.value as number]?.color
              }
              maximumTrackTintColor={GlobalStyles.colorsBasic.gray300}
              thumbTintColor={
                questionTouched
                  ? question.score[question.value as number].color
                  : GlobalStyles.colorsBasic.gray300
              }
              testID={'slider-' + index.toString()}
              onSlidingStart={showHint}
              onSlidingComplete={hideHint}
            />
          </Div>
          <Paragraph
            translate={false}
            style={{
              marginLeft: 10,
              fontSize: 15,
              color: GlobalStyles.colorsBasic.gray700,
            }}
          >
            {question.maxValue}
          </Paragraph>
        </Div>
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    paddingHorizontal: 12,
    paddingTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: GlobalStyles.colorsBasic.gray400,
    borderBottomWidth: 1,
  },
});

interface QuestionRatingProps {
  question: IQuestion;
  index: number;
  onValueChanged: (value: number) => any;
  hasValues: boolean;
}
