import {createDrawerNavigator} from '@react-navigation/drawer';
import {AthleteStackParamList} from '../../types/Navigation';
import AthleteOverviewScreen from '../../screens/athlete/athlete-overview/AthleteOverviewScreen';
import AthleteSideNav from '../../screens/athlete/shared/AthleteSideNav';
import AthleteAddEditRatingsScreen from '../../screens/athlete/athlete-add-edit-ratings/AthleteAddEditRatingsScreen';
import SettingsScreen from '../../screens/shared/settings/SettingsScreen';
import FeedbackScreen from '../../screens/shared/feedback/FeedbackScreen';
import AboutScreen from '../../screens/shared/about/AboutScreen';
import CoachesScreen from '../../screens/athlete/coaches/CoachesScreen';

const Drawer = createDrawerNavigator<AthleteStackParamList>();

export default function AthleteNavigator() {
  return (
    <Drawer.Navigator
      initialRouteName="overview"
      drawerContent={({navigation}) => (
        <AthleteSideNav navigation={navigation} />
      )}
      screenOptions={{
        headerShown: false,
        unmountOnBlur: true,
        drawerStyle: {
          minWidth: 200,
          maxWidth: 400,
        },
      }}
    >
      <Drawer.Screen name="overview" component={AthleteOverviewScreen} />
      <Drawer.Screen
        name="add-ratings"
        component={AthleteAddEditRatingsScreen}
        options={{swipeEnabled: false}}
      />
      <Drawer.Screen name="settings" component={SettingsScreen} />
      <Drawer.Screen name="feedback" component={FeedbackScreen} />
      <Drawer.Screen name="about" component={AboutScreen} />
      <Drawer.Screen name="coaches" component={CoachesScreen} />
    </Drawer.Navigator>
  );
}
