import Button from '../../../../components/base/Button';
import {Div} from '../../../../components/base/Div';
import {Paragraph} from '../../../../components/base/Paragraph';
import useDateTimeProvider from '../../../../providers/useDateTimeProvider';
import useCoachService from '../../../../services/useCoachService';
import {GlobalStyles} from '../../../../styles';
import {IRating} from '../../../../types/IRatings';
import useBarChart from '../../../shared/useBarChart';

export default function TeamChartBar({
  ratings,
  index,
  onPress,
  numberOfBars,
  chartWidth,
  activeDateKey,
  numberOfAthletes,
}: TeamChartBarProps) {
  const {dateFormatFromDateKey, formatDateKey} = useDateTimeProvider();
  const dateKey = Object.keys(ratings || {})[0] || formatDateKey();
  const onBarPress = () => onPress(dateKey);
  const {getChartBarWidth, getChartBarSpace, getChartBarFontSize} =
    useBarChart(chartWidth);

  const bars = useCoachService().useTeamLevelStacks(
    ratings[dateKey] || [],
    numberOfAthletes,
  );

  const width = getChartBarWidth(numberOfBars);
  const height = 145;

  const renderInnerBar = (item: string, index: number) => (
    <Div
      key={index}
      style={{
        backgroundColor: bars[item].color,
        height: `${(bars[item].athletes * 100) / numberOfAthletes}%`,
        width,
        borderRadius: 1,
      }}
    />
  );

  return (
    <Div
      style={{justifyContent: 'flex-end', alignItems: 'center', marginTop: 5}}
      key={index}
    >
      <Button
        style={{
          width: getChartBarWidth(numberOfBars),
          marginHorizontal: getChartBarSpace(numberOfBars),
          height,
          justifyContent: 'flex-end',
          marginVertical: 5,
          borderRadius: 1,
          backgroundColor: GlobalStyles.colorsBasic.gray100,
        }}
        onPress={onBarPress}
      >
        {Object.keys(bars).map(renderInnerBar)}
      </Button>
      <Button
        style={{
          padding: 3,
          backgroundColor:
            activeDateKey == dateKey
              ? GlobalStyles.svexa.green500
              : GlobalStyles.colorsBasic.white500,
          borderRadius: 5,
        }}
        onPress={onBarPress}
      >
        <Paragraph
          style={{
            fontSize: getChartBarFontSize(numberOfBars),
            color:
              activeDateKey == dateKey
                ? GlobalStyles.colorsBasic.white500
                : GlobalStyles.colorsBasic.black500,
          }}
        >
          {dateFormatFromDateKey(dateKey, 'DD').toUpperCase()}
        </Paragraph>
      </Button>
    </Div>
  );
}

interface TeamChartBarProps {
  index: number;
  ratings: {[key: string]: IRating[]};
  onPress: (dateKey: string) => any;
  numberOfBars: number;
  numberOfAthletes: number;
  chartWidth: number;
  activeDateKey: string;
}
