import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import Divider from '../../../components/shared/Divider';
import Form from '../../../components/shared/Form';
import usePermissionsService from '../../../services/usePermissionsService';
import {GlobalStyles} from '../../../styles';
import {renderPermissionItem} from '../../shared/PermissionItem';

export default function SentInvitation() {
  const {useSendCoachInvite, useMyInvitedCoaches} = usePermissionsService();

  const invitedCoaches = useMyInvitedCoaches();
  const {send, loading, form, refetching} = useSendCoachInvite();

  return (
    <>
      <Div style={{marginBottom: 10}}>
        <HeadingThree>Invite coach to view your data</HeadingThree>
        <Form
          form={form}
          onSubmit={send}
          cleanFormOnSubmit
          submitControl={(submit) => (
            <CustomButton
              title="Send Invite"
              type="primary"
              style={{
                alignSelf: 'flex-end',
              }}
              titleStyle={{
                color: GlobalStyles.colorsBasic.white500,
              }}
              disabled={loading || refetching}
              isLoading={loading}
              loader={{size: 'small', color: GlobalStyles.colorsBasic.white500}}
              onPress={submit}
            />
          )}
        />
      </Div>
      <Divider />
      <Div style={{maxWidth: 400}}>
        <HeadingThree>Pending</HeadingThree>
        <Paragraph style={{marginBottom: 10}}>
          {
            "Coaches in pending state haven't accepted your invitation, and can not view your ratings."
          }
        </Paragraph>
        {invitedCoaches.length ? (
          <Div>
            {invitedCoaches.map((coach, index) =>
              renderPermissionItem({
                item: coach,
                index,
                type: coach.id ? 'coach' : 'coachEmail',
              }),
            )}
          </Div>
        ) : (
          <Paragraph>No coaches in pending state at the moment.</Paragraph>
        )}
      </Div>
    </>
  );
}
