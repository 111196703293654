export const GlobalStyles = {
  colorsBasic: {
    black500: '#000000',
    gray900: '#333333',
    gray800: '#525252',
    gray700: '#666666',
    gray600: '#959595',
    gray500: '#C4C4C4',
    gray400: '#D4D4D4',
    gray300: '#EAEAEA',
    gray200: '#F6F6F6',
    gray100: '#FAFAFA',
    white500: '#FFFFFF',
  },
  colorsStatus: {
    blue: '#3390FF',
    green: '#87C240',
    red: '#C24046',
    yellow: '#FFAA70',
  },
  colorsRatings: {
    green: '#45BB83',
    greenLight: '#96C384',
    yellow: '#E6CB84',
    orange: '#E29E72',
    orangeDark: '#DE7260',
    red: '#D54D36',
  },
  svexa: {
    green300: '#B7D1A1',
    green400: '#809170',
    green500: '#748466',
    green600: '#5E6B52',
    green700: '#3C4535',
  },
};
