import useAuthService from '../../../services/useAuthService';
import AppleAuthenticate from '../shared/AppleAuthenticate';

export default function LoginWithApple({shouldPersist}: LoginWithAppleProps) {
  const {login, loading} = useAuthService().useLoginWithApple(!!shouldPersist);
  return (
    <AppleAuthenticate
      onSuccessAuthentication={login}
      disabled={loading}
      isLoading={loading}
      loader={{size: 'small', color: 'black'}}
      title={'Sign in with Apple'}
    />
  );
}

interface LoginWithAppleProps {
  shouldPersist?: boolean;
}
