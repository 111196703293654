import {StyleSheet} from 'react-native';
import Button from '../../components/base/Button';
import {Div} from '../../components/base/Div';
import Loader from '../../components/base/Loader';
import {Paragraph} from '../../components/base/Paragraph';
import {invitesActionByStatus} from '../../constants/Invites';
import usePermissionsService from '../../services/usePermissionsService';
import {GlobalStyles} from '../../styles';
import {InviteTypes, IPermission} from '../../types/IPermissions';

export default function PermissionItem({
  permission,
  type,
  additionalAction,
}: PermissionItemProps) {
  const actions = invitesActionByStatus[permission.status];

  const {call, loading, refetching} =
    usePermissionsService().usePermissionActions();

  const getNameLabel = (permission: IPermission): string => {
    let name = '';
    if (permission.firstName) {
      name += permission.firstName + ' ';
    }
    if (permission.lastName) {
      name += permission.lastName;
    }
    return name || permission.email || permission.emailAddress || '';
  };

  return (
    <Button
      style={styles.shadowBox}
      onPress={additionalAction ? additionalAction.call : undefined}
      activeOpacity={additionalAction ? 0.2 : 1}
    >
      <Div style={{flex: 1}}>
        <Paragraph numberOfLines={1} ellipsizeMode={'tail'}>
          {getNameLabel(permission)}
        </Paragraph>
        <Paragraph
          numberOfLines={1}
          ellipsizeMode={'tail'}
          style={{fontSize: 10, color: GlobalStyles.colorsBasic.gray700}}
        >
          {permission.email || permission.emailAddress}
        </Paragraph>
      </Div>
      <Div style={{flexDirection: 'row'}}>
        {loading || refetching ? (
          <Loader size={'small'} />
        ) : (
          <>
            {additionalAction ? (
              <Paragraph
                onPress={additionalAction.call}
                style={{
                  color: GlobalStyles.colorsStatus.blue,
                  marginHorizontal: 5,
                }}
              >
                {additionalAction.label}
              </Paragraph>
            ) : null}
            {actions.map((action, index) => {
              const onPress = () =>
                call({
                  id: permission.id,
                  action: action.type,
                  email: permission.emailAddress,
                  type,
                });
              return (
                <Paragraph
                  onPress={onPress}
                  key={index}
                  style={{color: action.color, marginHorizontal: 5}}
                >
                  {action.label}
                </Paragraph>
              );
            })}
          </>
        )}
      </Div>
    </Button>
  );
}

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    shadowColor: GlobalStyles.colorsBasic.black500,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    padding: 10,
    marginVertical: 5,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const renderPermissionItem = ({
  item,
  index,
  type,
  additionalAction,
}: {
  item: IPermission;
  index: number;
  type: InviteTypes;
  additionalAction?: {
    label: string;
    call: () => any;
  };
}) => {
  return (
    <PermissionItem
      key={index}
      permission={item}
      index={index}
      type={type}
      additionalAction={additionalAction}
    />
  );
};

interface PermissionItemProps {
  permission: IPermission;
  index: number;
  type: InviteTypes;
  additionalAction?: {
    label: string;
    call: () => any;
  };
}
