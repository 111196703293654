import React from 'react';
import {Div} from '../../../components/base/Div';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import CustomButton from '../../../components/shared/CustomButton';
import Form from '../../../components/shared/Form';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {CoachNavigationContext} from '../../../navigation/coach-navigator/CoachNavigationContext';
import useCoachService from '../../../services/useCoachService';
import {GlobalStyles} from '../../../styles';
import {CreateGroupDTO} from '../../../types/IGroup';
import {FormDataImage} from '../../../types/Image';
import {CoachStackScreenProps} from '../../../types/Navigation';
import CoachHeaderSimple from '../shared/CoachHeaderSimple';
import TeamLogo from './TeamLogo';

export default function AddTeamScreen(
  props: CoachStackScreenProps<'add-team'>,
) {
  const onGroupCreated = () => {
    props.navigation.navigate('teams');
  };
  const [logo, setLogo] = React.useState<FormDataImage | null>(null);
  const {create, form, creating} =
    useCoachService().useCreateGroup(onGroupCreated);
  const onGroupSave = ({name}: CreateGroupDTO) => {
    const payload: any = {name};
    if (logo) payload.formDataImg = logo;
    create(payload);
  };
  return (
    <AuthGuard>
      <CoachNavigationContext.Provider value={props.navigation}>
        <ScreenLayout>
          <CoachHeaderSimple />
          <Container>
            <KeyboardAvoidingScrollArea>
              <Div
                style={{
                  marginTop: 12,
                  margin: 24,
                }}
              >
                <Div style={{marginTop: 24, alignItems: 'center'}}>
                  <TeamLogo logoData={logo} onImagePicked={setLogo} />
                  <Form
                    style={{marginTop: 12, width: 200}}
                    form={form}
                    onSubmit={onGroupSave}
                    submitControl={(submit) => (
                      <CustomButton
                        type="primary"
                        title="Save"
                        onPress={submit}
                        disabled={creating}
                        isLoading={creating}
                        titleStyle={{
                          color: GlobalStyles.colorsBasic.white500,
                        }}
                        loader={{
                          size: 'small',
                          color: GlobalStyles.colorsBasic.white500,
                        }}
                      />
                    )}
                  />
                </Div>
              </Div>
            </KeyboardAvoidingScrollArea>
          </Container>
        </ScreenLayout>
      </CoachNavigationContext.Provider>
    </AuthGuard>
  );
}
