import {useWindowDimensions} from 'react-native';
import {Div} from '../../../../components/base/Div';
import {maxContentWidth} from '../../../../constants/Layout';
import {IRating} from '../../../../types/IRatings';
import BarCharList from '../../../shared/bar-char-list/BarChartList';
import RatingsChartLines from '../../../shared/BarChartLines';
import TeamChartBar from './TeamChartBar';

export default function TeamChart({
  ratings,
  activeDateKey,
  onDateKeySelected,
  numberOfBars,
  numberOfAthletes,
}: TeamChartProps) {
  const {width} = useWindowDimensions();

  const renderBar = ({
    item,
    index,
  }: {
    item: {[key: string]: IRating[]};
    index: number;
  }) => (
    <TeamChartBar
      key={index}
      index={index}
      ratings={item}
      onPress={onDateKeySelected}
      numberOfBars={numberOfBars}
      numberOfAthletes={numberOfAthletes}
      chartWidth={Math.min(width, maxContentWidth) - 50}
      activeDateKey={activeDateKey}
    />
  );

  return (
    <Div style={{marginBottom: 20, flexDirection: 'row'}}>
      <RatingsChartLines
        chartWidth={Math.min(width, maxContentWidth) - 50}
        numberOfLines={numberOfAthletes + 1}
      />
      <BarCharList data={ratings} renderItem={renderBar} />
    </Div>
  );
}

interface TeamChartProps {
  ratings: Array<{[dateKey: string]: IRating[]}>;
  onDateKeySelected: (dateKey: string) => any;
  numberOfBars: number;
  numberOfAthletes: number;
  activeDateKey: string;
}
