import {Feather} from '@expo/vector-icons';
import {ColorValue, StyleProp, TextStyle} from 'react-native';
export default function Icon({name, size, color, onPress, style}: IconProps) {
  return (
    <Feather
      style={style}
      name={name}
      size={size}
      color={color}
      onPress={onPress}
    />
  );
}
export type IconName = keyof typeof Feather.glyphMap;
interface IconProps {
  name: IconName;
  size?: number;
  color?: ColorValue;
  onPress?: () => void;
  style?: StyleProp<TextStyle>;
}
