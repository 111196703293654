import {Moment} from 'moment';
import React from 'react';
import {addGroupMemberByEmail, createGroupForm} from '../constants/Groups';
import CoachGateway from '../gateways/CoachGateway';
import {useCoachNavigation} from '../navigation/coach-navigator/CoachNavigationContext';
import useAlertProvider from '../providers/useAlertProvider';
import useDateTimeProvider from '../providers/useDateTimeProvider';
import useLocalizationProvider from '../providers/useLocalizationProvider';
import useQuestionProvider from '../providers/useQuestionProvider';
import {useRequest} from '../providers/useRequestProvider';
import {useAppDispatch, useAppSelector} from '../store';
import {coachActions} from '../store/reducers/CoachReducer';
import {IGroupMemberWitRating} from '../types/IGroup';
import {IRating} from '../types/IRatings';
import {ratingsActions} from '../store/reducers/RatingsReducer';

export default function useCoachService() {
  const dispatch = useAppDispatch();
  const {success, danger} = useAlertProvider();
  const {useRatingsQuestions, useQuestions} = useQuestionProvider();
  const {formatDateKey} = useDateTimeProvider();
  const {translate} = useLocalizationProvider();
  const navigation = useCoachNavigation();

  function useGroups() {
    const groups = useAppSelector(({coach}) => coach.groups);
    const {execute, loading} = useRequest(CoachGateway.getGroups, {
      onCompleted: (data) => {
        if (data) dispatch(coachActions.setGroups(data));
      },
    });
    React.useEffect(() => {
      execute({});
    }, []);
    return {groups, fetch: execute, fetching: loading};
  }

  function useCreateGroup(onCreated: () => any) {
    const {execute, loading} = useRequest(CoachGateway.createGroup, {
      onCompleted: (data, payload) => {
        if (!data) return;
        if (!!payload.formDataImg && !data.logo) {
          danger('Team created, but image upload failed.');
        }
        dispatch(coachActions.setGroup(data));
        onCreated();
      },
    });
    return {create: execute, creating: loading, form: createGroupForm};
  }

  function useEditGroup() {
    const {execute, loading} = useRequest(CoachGateway.editGroup, {
      onCompleted: (data) => {
        if (!data) return;
        dispatch(coachActions.setGroup(data));
        success('Team data saved successfully!');
      },
    });
    return {update: execute, updating: loading, form: createGroupForm};
  }

  function useGroup(id: string) {
    const group = useAppSelector(({coach}) => coach.groups[id]);
    const {execute, loading} = useRequest(CoachGateway.getGroup, {
      onCompleted: (data) => {
        if (data) dispatch(coachActions.setGroup(data));
      },
    });
    React.useEffect(() => {
      execute({groupId: id});
    }, []);

    return {fetch: execute, group, fetching: loading};
  }

  function useGroupAdmins(id: string) {
    const admins = useAppSelector(({coach}) => coach.groupAdmins[id]);
    const {execute, loading} = useRequest(CoachGateway.getGroupAdmins, {
      onCompleted: (data) => {
        if (data)
          dispatch(coachActions.setGroupAdmins({groupId: id, admins: data}));
      },
    });
    React.useEffect(() => {
      execute({groupId: id});
    }, []);
    return {fetch: execute, admins, fetching: loading};
  }

  function useGroupMembers(id: string) {
    const members = useAppSelector(({coach}) => coach.groupMembers[id]);
    const {execute, loading} = useRequest(CoachGateway.getGroupMembers, {
      onCompleted: (data) => {
        if (data)
          dispatch(coachActions.setGroupMembers({groupId: id, members: data}));
      },
    });
    React.useEffect(() => {
      execute({groupId: id});
    }, []);
    return {fetch: execute, members, fetching: loading};
  }

  function useAddGroupMember(
    onComplete?: () => any,
    onError?: (error: any) => any,
  ) {
    const {execute, loading} = useRequest(CoachGateway.inviteToGroup, {
      onCompleted: (data: any, payload) => {
        if (!data) return;
        if (data.group) dispatch(coachActions.setGroup(data.group));
        if (data.members?.members) {
          dispatch(
            coachActions.setGroupMembers({
              groupId: payload.groupId,
              members: data.members,
            }),
          );
        } else if (data.members?.admins) {
          dispatch(
            coachActions.setGroupAdmins({
              groupId: payload.groupId,
              admins: data.members,
            }),
          );
        }
        if (onComplete) {
          onComplete();
        }
      },
      onError,
    });

    return {add: execute, adding: loading, form: addGroupMemberByEmail};
  }

  function useRemoveGroupMember() {
    const {execute, loading} = useRequest(CoachGateway.removeFromGroup, {
      onCompleted: (data: any, payload) => {
        if (!data) return;
        if (data.group) dispatch(coachActions.setGroup(data.group));
        if (data.members?.members) {
          dispatch(
            coachActions.setGroupMembers({
              groupId: payload.groupId,
              members: data.members,
            }),
          );
        } else if (data.members?.admins) {
          dispatch(
            coachActions.setGroupAdmins({
              groupId: payload.groupId,
              admins: data.members,
            }),
          );
        }
      },
    });

    return {remove: execute, removing: loading};
  }

  function useDeleteGroup() {
    const {execute, loading} = useRequest(CoachGateway.deleteGroup, {
      onCompleted: (data) => {
        if (data) {
          dispatch(coachActions.setGroups(data));
          navigation?.navigate('teams');
        }
      },
    });
    return {deleteGroup: execute, deleting: loading};
  }

  function useAthleteRating(athleteId: string, dateKey: string, span: string) {
    const rating = useAppSelector(({coach}) => {
      if (!coach.athletesRatings[athleteId]) return null;
      return coach.athletesRatings[athleteId][dateKey] || null;
    });
    const {execute, loading} = useRequest(CoachGateway.getAthleteRatings, {
      onCompleted: (data) => {
        if (data && data.length)
          dispatch(
            coachActions.setAthletesRating({
              athleteId,
              dateKey,
              rating: data[0],
            }),
          );
      },
    });
    React.useEffect(() => {
      if (!athleteId || !dateKey) return;
      execute({athleteId, dateKey, span});
    }, [athleteId, dateKey]);

    return {rating, loading};
  }

  function useAthleteDayRating(athleteId: string, dateKey: string) {
    const {rating} = useAthleteRating(athleteId, dateKey, '1');
    const {questions, loading} = useRatingsQuestions(rating!);
    return {questions, rating, loading};
  }

  function useAthleteRatingHistory() {
    function onSuccess(ratings: IRating[] | null) {
      if (!ratings) {
        return;
      }
      const ratingsObj: {[key: string]: IRating} = {};
      ratings.forEach((r) => {
        if (!r) {
          return;
        }
        ratingsObj[r.dateKey] = r;
      });
      dispatch(ratingsActions.setRatings(ratingsObj));
    }

    const {execute, loading} = useRequest(CoachGateway.getAthleteRatings, {
      onCompleted: onSuccess,
    });

    return {fetch: execute, loading};
  }

  function useSendRatingsReminder() {
    const {execute, loading} = useRequest(CoachGateway.sendRatingsReminder, {
      onCompleted: (data) => {
        if (data) success(translate('Reminder sent!'));
      },
    });
    return {send: execute, sending: loading};
  }

  function useFetchTeamAthleteRatings() {
    const {fetch: getQuestions, loading: gettingQuestions} = useQuestions(
      formatDateKey(),
    );
    const {execute, loading} = useRequest(
      CoachGateway.getGroupAthletesRatings,
      {
        onCompleted: (data) => {
          if (!data) return;
          dispatch(coachActions.setMultipleAthletesRating(data));
        },
      },
    );
    const fetch = (payload: {
      groupId: string;
      dateKey: string;
      span: string;
    }) => {
      getQuestions({dateKey: payload.dateKey});
      execute(payload);
    };
    return {fetch, getting: loading || gettingQuestions};
  }

  function useTeamAthletesRatings(
    days: number,
    to: Moment,
    groupId: string,
  ): Array<{[dateKey: string]: IRating[]}> {
    const group = useAppSelector(({coach}) => coach.groups[groupId]);
    const membersRatings: Array<{[dateKey: string]: IRating}> = useAppSelector(
      ({coach}) =>
        Object.keys(coach.athletesRatings)
          .filter(
            (id) =>
              !!(group?.members || []).find((memberId) => memberId === id),
          )
          .map((id) => coach.athletesRatings[id]),
    );
    const ratings: Array<{[dateKey: string]: IRating[]}> = [];
    for (let i = 0; i <= days; i++) {
      const toKey = to.subtract(i === 0 ? 0 : 1, 'days').format('YYYYMMDD');
      const membR: {[key: string]: IRating[]} = {[toKey]: []};
      membersRatings.forEach((mr) => {
        if (!mr || !mr[toKey]) return;
        membR[toKey].push(mr[toKey]);
      });
      ratings.push(membR);
    }
    return ratings;
  }

  function useTeamRatingsOverview(
    dateKey: string,
    groupId: string,
    sort: number,
  ) {
    return useAppSelector(({coach}) => {
      const members: IGroupMemberWitRating[] = [];
      const groupMembers = useAppSelector(
        ({coach}) => coach.groupMembers[groupId]?.members,
      );
      Object.keys(groupMembers || {}).forEach((id) => {
        if (!coach.athletesRatings[id]) return;
        const rating = coach.athletesRatings[id][dateKey] || null;
        members.push({...coach.groupMembers[groupId].members[id], rating});
      });
      return members.sort((a, b) => {
        if (!a.rating || !a.rating?.hasValues) return 1;
        if (!b.rating || !b.rating?.hasValues) return -1;
        if (a.rating.score - b.rating.score > 0) return sort > 0 ? -1 : 1;
        if (a.rating.score - b.rating.score < 0) return sort > 0 ? 1 : -1;
        return 0;
      });
    });
  }

  function useTeamLevelStacks(
    ratings: IRating[],
    noOfAthletes: number,
  ): {[tier: string]: {athletes: number; color: string}} {
    const bars: {
      [key: string]: {athletes: number; color: string};
    } = {
      'no-access': {athletes: 0, color: 'rgba(155,155,155,0.2)'},
      'no-rating': {athletes: 0, color: '#9B9B9B'},
      tier1: {athletes: 0, color: '#379669'},
      tier2: {athletes: 0, color: '#45BB83'},
      tier3: {athletes: 0, color: '#96C384'},
      tier4: {athletes: 0, color: '#E6CB84'},
      tier5: {athletes: 0, color: '#E29E72'},
      tier6: {athletes: 0, color: '#DE7260'},
      tier7: {athletes: 0, color: '#D54D36'},
    };

    // set no access
    bars['no-access'].athletes = noOfAthletes - ratings.length;

    ratings.forEach((rating) => {
      if (!rating) {
        bars['no-rating'].athletes += 1;
        return;
      }
      if (rating.level == -1) {
        bars['no-rating'].athletes += 1;
        return;
      }
      if (rating.level == 7) {
        bars.tier1.athletes += 1;
        return;
      }
      if (rating.level == 5 || rating.level == 6) {
        bars.tier2.athletes += 1;
        return;
      }
      if (rating.level == 4) {
        bars.tier3.athletes += 1;
        return;
      }
      if (rating.level == 3) {
        bars.tier4.athletes += 1;
        return;
      }
      if (
        rating.level == 2 ||
        rating.level == 8 ||
        rating.level == 10 ||
        rating.level == 12
      ) {
        bars.tier5.athletes += 1;
        return;
      }
      if (rating.level == 1) {
        bars.tier6.athletes += 1;
        return;
      }
      if (
        rating.level == 0 ||
        rating.level == 9 ||
        rating.level == 11 ||
        rating.level == 13
      ) {
        bars.tier7.athletes += 1;
        return;
      }
    });
    return bars;
  }

  return {
    useGroups,
    useCreateGroup,
    useGroup,
    useEditGroup,
    useGroupAdmins,
    useGroupMembers,
    useAddGroupMember,
    useRemoveGroupMember,
    useDeleteGroup,
    useAthleteRating,
    useAthleteDayRating,
    useAthleteRatingHistory,
    useSendRatingsReminder,
    useFetchTeamAthleteRatings,
    useTeamAthletesRatings,
    useTeamRatingsOverview,
    useTeamLevelStacks,
  };
}
