import React from 'react';
import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import Image from '../../../components/base/Image';
import Loader from '../../../components/base/Loader';
import UploadImage from '../../../components/shared/upload-image/UploadImage';
import assets from '../../../constants/Assets';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {GlobalStyles} from '../../../styles';
import {FormDataImage} from '../../../types/Image';

export default function ProfilePhoto() {
  const {useUserImage, useUploadProfilePhoto} = useUserSettingsService();
  const {image} = useUserImage();
  const {upload, uploading} = useUploadProfilePhoto();
  const onImagePicked = (image: FormDataImage) => {
    upload(image);
  };

  return (
    <Div style={styles.profilePhotoSection}>
      <Div style={styles.profilePhotoContainer}>
        <UploadImage
          onImagePicked={onImagePicked}
          style={styles.profileUploadButtonWrapper}
        />
        {uploading ? (
          <Div
            style={[
              styles.profilePhoto,
              {
                backgroundColor: GlobalStyles.colorsBasic.gray500,
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            <Loader size={'small'} />
          </Div>
        ) : (
          <Image
            source={image ? {uri: image} : assets.images.avatar}
            style={styles.profilePhoto}
            resizeMode={'cover'}
          />
        )}
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  profilePhotoSection: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilePhotoContainer: {
    position: 'relative',
    width: 160,
    height: 160,
  },
  profileUploadButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 4,
  },
  profilePhoto: {
    width: 160,
    height: 160,
    borderRadius: 100,
  },
});
