const assets = {
  images: {
    logo: require('../../assets/images/icon.png'),
    svexaLogo: require('../../assets/images/svexa-logo.png'),
    appleLogo: require('../../assets/images/apple-logo.png'),
    googleLogo: require('../../assets/images/google-logo.png'),
    avatar: require('../../assets/images/user-avatar.png'),
    teamAvatar: require('../../assets/images/default-team-logo.webp'),
    uploadPhoto: require('../../assets/images/upload-photo-button.png'),
    garmin: require('../../assets/images/garmin.png'),
    oura: require('../../assets/images/oura.png'),
    en: require('../../assets/images/en.png'),
    es: require('../../assets/images/es.png'),
    sv: require('../../assets/images/sv.png'),
    pl: require('../../assets/images/pl.png'),
  },
  languages: {
    en: require('../../assets/languages/en.json'),
    es: require('../../assets/languages/es.json'),
    pl: require('../../assets/languages/pl.json'),
    sv: require('../../assets/languages/sv.json'),
  },
};

export default assets;
