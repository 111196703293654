import {ImageSourcePropType, ImageStyle, StyleProp} from 'react-native';
import {FormDataImage} from '../../../types/Image';
import UploadImage, {UploadImageProps} from './UploadImage';
import * as Web from 'react-native-web';

export default function UploadImageWithPreview({
  onImagePicked,
  style,
  imageData,
  previewStyle,
  defaultPreview,
  maxSize = 100000000, // 100mb
}: UploadImageWithPreviewProps) {
  return (
    <>
      <UploadImage
        onImagePicked={onImagePicked}
        style={style}
        maxSize={maxSize}
      />
      {Web.unstable_createElement('img', {
        src: imageData
          ? imageData.uri
            ? imageData?.uri
            : URL.createObjectURL(imageData as Blob)
          : defaultPreview,
        style: previewStyle,
      })}
    </>
  );
}

interface UploadImageWithPreviewProps extends UploadImageProps {
  imageData: FormDataImage | null;
  previewStyle?: StyleProp<ImageStyle>;
  defaultPreview: ImageSourcePropType;
  maxSize?: number;
}
