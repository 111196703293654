import {Div} from '../../../../components/base/Div';
import {Paragraph} from '../../../../components/base/Paragraph';
import useCoachService from '../../../../services/useCoachService';
import usePermissionsService from '../../../../services/usePermissionsService';
import {PermissionStatus} from '../../../../types/IPermissions';
import {useCurrentGroup} from '../GroupContext';
import TeamPendingMemberCard from '../TeamPendingMemberCard';

export default function TeamPendingCoaches() {
  const group = useCurrentGroup();
  const {permissions} = usePermissionsService().useFetchPermissions(true);
  const {admins} = useCoachService().useGroupAdmins(group?.id || '');
  const notAccepted = Object.keys(admins?.admins || {})
    .filter(
      (a) =>
        !!admins?.admins[a] &&
        (permissions?.coaches?.find(
          (p) =>
            p.id == a &&
            (p.status === PermissionStatus.OUTGOING_EMAIL_INVITE ||
              p.status === PermissionStatus.OUTGOING_INVITE),
        ) ||
          permissions?.coachColleagues?.find(
            (p) =>
              p.id == a &&
              (p.status === PermissionStatus.OUTGOING_EMAIL_INVITE ||
                p.status === PermissionStatus.OUTGOING_INVITE),
          )) &&
        !admins?.pending?.find((pm) => pm == admins.admins[a].email),
    )
    .map((key) => ({...admins.admins[key], id: key}));
  return (
    <Div>
      {!admins?.pending?.length && !notAccepted?.length ? (
        <Paragraph>No pending coaches.</Paragraph>
      ) : (
        <>
          {notAccepted.map((athlete, index) => {
            return (
              <TeamPendingMemberCard
                key={index}
                title={() => (
                  <Paragraph>
                    {athlete.firstname} {athlete.lastname} ({athlete.email})
                    {'\n'}
                    <Paragraph style={{fontSize: 12, color: 'gray'}}>
                      needs to login to their account to accept invitation
                    </Paragraph>
                  </Paragraph>
                )}
                member={{id: athlete.id, type: 'member', email: athlete.email}}
              />
            );
          })}

          {admins.pending.map((email, index) => (
            <TeamPendingMemberCard
              key={index}
              title={() => (
                <Paragraph>
                  {email}
                  {'\n'}
                  <Paragraph style={{fontSize: 12, color: 'gray'}}>
                    needs to create an account with this email to accept
                    invitation
                  </Paragraph>
                </Paragraph>
              )}
              member={{id: null, type: 'admin', email}}
            />
          ))}
        </>
      )}
    </Div>
  );
}
