import {DrawerNavigationProp} from '@react-navigation/drawer';
import * as React from 'react';
import {AthleteStackParamList} from '../../types/Navigation';

export const AthleteNavigationContext =
  React.createContext<DrawerNavigationProp<AthleteStackParamList, any> | null>(
    null,
  );

export const useAthleteNavigation = () =>
  React.useContext(AthleteNavigationContext);
