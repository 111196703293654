import {configureStore} from '@reduxjs/toolkit';
import persistStore from 'redux-persist/es/persistStore';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import AuthReducer from './reducers/AuthReducer';
import ConfigReducer from './reducers/ConfigReducer';
import RatingsReducer from './reducers/RatingsReducer';
import SettingsReducer from './reducers/SettingsReducer';
import PermissionsReducer from './reducers/PermissionsReducer';
import CoachReducer from './reducers/CoachReducer';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    config: ConfigReducer,
    ratings: RatingsReducer,
    settings: SettingsReducer,
    permissions: PermissionsReducer,
    coach: CoachReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false, immutableCheck: false}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

// selector
export type AppSelector<S> = (state: RootState) => S;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//persistor
export const persistor = persistStore(store);
