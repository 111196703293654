import {StyleProp, Switch, ViewStyle} from 'react-native';
import {GlobalStyles} from '../../styles';

interface ToggleSwitch {
  isEnabled: boolean;
  toggleSwitch: (value: boolean) => void;
  style?: StyleProp<ViewStyle>;
}

const ToggleSwitch = ({isEnabled, toggleSwitch, style}: ToggleSwitch) => {
  return (
    <Switch
      trackColor={{
        false: GlobalStyles.colorsBasic.gray600,
        true: GlobalStyles.colorsStatus.green,
      }}
      thumbColor={
        isEnabled
          ? GlobalStyles.colorsBasic.white500
          : GlobalStyles.colorsBasic.gray300
      }
      ios_backgroundColor={GlobalStyles.colorsBasic.gray600}
      onValueChange={toggleSwitch}
      value={isEnabled}
      style={style}
    />
  );
};

export default ToggleSwitch;
