import React from 'react';
import CheckBox from '../../../components/base/CheckBox';
import {Div} from '../../../components/base/Div';
import Image from '../../../components/base/Image';
import Loader from '../../../components/base/Loader';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import useCoachService from '../../../services/useCoachService';
import {GlobalStyles} from '../../../styles';

export default function MyAthleteTeams({athleteId}: MyAthleteTeamsProps) {
  const {useGroups, useAddGroupMember, useRemoveGroupMember} =
    useCoachService();
  const {groups: teams} = useGroups();
  const {add, adding} = useAddGroupMember();
  const {remove, removing} = useRemoveGroupMember();
  return (
    <Div>
      {teams && Object.keys(teams).length ? (
        Object.keys(teams).map((teamKey, index) => {
          const team = teams[teamKey];
          const onValueChanged = (value: boolean) => {
            if (value)
              add({
                groupId: team.id || '',
                type: 'athletes',
                userId: athleteId,
                hash: team.hash,
              });
            else
              remove({
                groupId: team.id || '',
                type: 'member',
                userId: athleteId,
                hash: team.hash,
              });
          };
          return (
            <Div
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 8,
              }}
              key={index}
            >
              <Image
                source={
                  team?.logo ? {uri: team.logo} : assets.images.teamAvatar
                }
                height={40}
                width={40}
                resizeMode={'cover'}
                style={{borderRadius: 20}}
              />
              <Paragraph
                style={{marginHorizontal: 12, flex: 1}}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {team.name}
              </Paragraph>
              <CheckBox
                style={{marginLeft: 'auto'}}
                value={!!team.members.find((m) => m == athleteId)}
                onValueChange={onValueChanged}
              />
            </Div>
          );
        })
      ) : (
        <Paragraph>No Teams.</Paragraph>
      )}
      <Div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,0.2)',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          display: removing || adding ? 'flex' : 'none',
        }}
      >
        <Loader size={'large'} color={GlobalStyles.svexa.green500} />
      </Div>
    </Div>
  );
}

interface MyAthleteTeamsProps {
  athleteId: string;
}
