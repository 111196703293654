import React from 'react';
import {IQuestion, QuestionTypes} from '../../../types/IQuestion';
import AthleteQuestionRatingAnswer from './AthleteQuestionRatingAnswer';
import AthleteQuestionYesNoAnswer from './AthleteQuestionYesNoAnswer';

export default function AthleteQuestionAnswer(props: AnswerProps) {
  switch (props.question.questionType) {
    case QuestionTypes.rating:
      return <AthleteQuestionRatingAnswer {...props} />;
    case QuestionTypes.yesNo:
      return <AthleteQuestionYesNoAnswer {...props} />;
    default:
      return null;
  }
}
interface AnswerProps {
  question: IQuestion;
  index: number;
}
