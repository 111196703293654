import {AthleteStackScreenProps} from '../../../types/Navigation';
import AuthGuard from '../../../components/shared/AuthGuard';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import AthleteHeader from '../../athlete/shared/AthleteHeader';
import {Div} from '../../../components/base/Div';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import Profile from './Profile';
import Accounts from './Accounts';
import ConnectedDevices from './ConnectedDevices';
import Preferences from './Preferences';
import {AthleteNavigationContext} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import Container from '../../../components/shared/Container';
import {ITab} from '../../../types/Tab';
import TabList from '../../../components/shared/tab/TabList';
import * as WebBrowser from 'expo-web-browser';
import useAlertProvider from '../../../providers/useAlertProvider';
import React from 'react';
import {ProfileContext} from './ProfileContext';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';

WebBrowser.maybeCompleteAuthSession();

export default function SettingsScreen({
  navigation,
}: AthleteStackScreenProps<'settings'>) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const nativeAlert = useAlertProvider().useNativeAlert();
  const {translate} = useLocalizationProvider();
  const onTabClicked = (
    activeTab: ITab,
    clickedTab: ITab,
    setTab: (tab: ITab) => any,
  ) => {
    if (
      activeTab.title != settingsTabs[0].title ||
      clickedTab.title == settingsTabs[0].title ||
      !hasUnsavedChanges
    )
      return setTab(clickedTab);

    nativeAlert(
      translate('Unsaved changes'),
      translate(
        'You have unsaved profile changes. Are you sure you want to leave the page? All changes will be lost.',
      ),
      [
        {
          text: translate('Leave'),
          style: 'default',
          onPress: () => setTab(clickedTab),
        },
        {
          text: translate('Stay'),
          style: 'cancel',
          onPress: () => true,
        },
      ],
      {userInterfaceStyle: 'light'},
    );
  };
  return (
    <AuthGuard>
      <AthleteNavigationContext.Provider value={navigation}>
        <ProfileContext.Provider
          value={{hasUnsavedChanges, setHasUnsavedChanges}}
        >
          <ScreenLayout>
            <AthleteHeader />
            <Container>
              <KeyboardAvoidingScrollArea>
                <Div style={{margin: 24, marginTop: 12, maxWidth: 600}}>
                  <HeadingTwo>Settings</HeadingTwo>
                  <TabList tabs={settingsTabs} onTabClicked={onTabClicked} />
                </Div>
              </KeyboardAvoidingScrollArea>
            </Container>
          </ScreenLayout>
        </ProfileContext.Provider>
      </AthleteNavigationContext.Provider>
    </AuthGuard>
  );
}

const settingsTabs: ITab[] = [
  {title: 'Profile', component: <Profile />},
  {title: 'Accounts', component: <Accounts />},
  {title: 'Devices', component: <ConnectedDevices />},
  {title: 'Preferences', component: <Preferences />},
];
