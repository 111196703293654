class Map<TSource, TOutput> {
  keys: string[] = [];
  entity: any;
  propsAdded: string[] = [];
  source: any = {};

  public createMap(sourceObject: TSource) {
    this.source = sourceObject || this.source;
    this.entity = {} as TSource;
    return this;
  }

  public forMember(
    property: keyof TOutput,
    setProperty: (sourceObject: TSource) => TOutput[keyof TOutput],
  ) {
    this.entity[property] = setProperty(this.source);
    this.propsAdded.push(property as string);
    return this;
  }

  public mapDangerously(selfMapping: (sourceObject: TSource) => TOutput) {
    this.entity = selfMapping(this.source);
    return this;
  }

  public mapOtherEqually() {
    for (const prop in this.source) {
      if (
        this.source.hasOwnProperty(prop) &&
        this.propsAdded.indexOf(prop) === -1
      ) {
        this.entity[prop] = this.source[prop];
      }
    }
    return this.entity;
  }

  public mapOtherEquallyExcludingUI() {
    for (const prop in this.source) {
      if (
        this.source.hasOwnProperty(prop) &&
        prop !== 'UI' &&
        this.propsAdded.indexOf(prop) === -1
      ) {
        this.entity[prop] = this.source[prop];
        this.propsAdded.push(prop);
      }
    }
    return this.entity;
  }

  public mapOtherEquallyExcludingProps(properties: string[]) {
    for (const prop in this.source) {
      if (
        this.source.hasOwnProperty(prop) &&
        properties.indexOf(prop) === -1 &&
        this.propsAdded.indexOf(prop) === -1
      ) {
        this.entity[prop] = this.source[prop];
        this.propsAdded.push(prop);
      }
    }
    return this.entity;
  }

  public ignoreOther() {
    return this.entity;
  }

  public finish() {
    return this.entity;
  }
}

export default Map;
