import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import Divider from '../../../components/shared/Divider';
import usePermissionsService from '../../../services/usePermissionsService';
import {renderPermissionItem} from '../../shared/PermissionItem';
import SentInvitation from './SentInvitation';

export default function MyCoaches() {
  const {useMyCoaches, useFetchPermissions} = usePermissionsService();
  useFetchPermissions(true);
  const coaches = useMyCoaches();

  return (
    <Div>
      <Div style={{marginBottom: 10}}>
        <HeadingThree>Coaches (can view your data)</HeadingThree>
        {coaches?.length ? (
          <Div>
            <Paragraph style={{marginBottom: 10}}>
              You have given these coaches permission to see your daily ratings.
              If you change your mind, you can remove a coach at any time.
            </Paragraph>
            {coaches.map((coach, index) =>
              renderPermissionItem({
                item: coach,
                index,
                type: coach.id ? 'coach' : 'coachEmail',
              }),
            )}
          </Div>
        ) : (
          <Paragraph>
            No coaches yet. Invite coaches to see your data.
          </Paragraph>
        )}
      </Div>
      <Divider />
      <SentInvitation />
    </Div>
  );
}
