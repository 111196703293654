import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AuthStackScreenProps} from '../../../types/Navigation';
import SignupWithGoogleTemplate from './SignupWithGoogleTemplate';
import * as WebBrowser from 'expo-web-browser';
import {AuthNavigationContext} from '../../../navigation/auth-navigator/AuthNavigationContext';

WebBrowser.maybeCompleteAuthSession();

export default function SignupWithGoogleScreen({
  navigation,
  route,
}: AuthStackScreenProps<'signup-with-google'>) {
  return (
    <AuthNavigationContext.Provider value={navigation}>
      <ScreenLayout>
        <SignupWithGoogleTemplate auth={route.params.auth} />
      </ScreenLayout>
    </AuthNavigationContext.Provider>
  );
}
