import React from 'react';
import {StyleSheet} from 'react-native';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import Input from '../../../components/base/Input';
import KeyboardAvoidingArea from '../../../components/base/KeyboardAvoidingArea';
import Modal from '../../../components/base/Modal';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import {useAddRatingsContext} from './AddRatingsContext';

export default function HeartInfo({
  value,
  onHeartInfoSubmited,
}: HeartInfoProps) {
  const [visible, setVisible] = React.useState(false);
  const [RHRerror, setRHRError] = React.useState('');
  const [HRVerror, setHRVError] = React.useState('');
  const openModal = () => {
    setHrv(value.hrv);
    setRhr(value.rhr);
    setVisible(true);
  };
  const closeModal = () => {
    setHrv(value.hrv);
    setRhr(value.rhr);
    setVisible(false);
  };
  const {setHasUnsavedChanges} = useAddRatingsContext();
  const [rhr, setRhr] = React.useState(value.rhr);
  const [hrv, setHrv] = React.useState(value.hrv);
  const saveHeartInfo = () => {
    let RhrError = '';
    let HrvError = '';
    if (rhr) {
      const rhrNum = parseFloat(rhr);
      if (isNaN(rhrNum)) {
        RhrError = 'Resting heart rate must be a number';
      } else if (rhrNum < 25 || rhrNum > 90) {
        RhrError = 'Resting heart rate must be between 25 and 90';
      }
    }
    if (hrv) {
      const hrvNum = parseFloat(hrv);
      if (isNaN(hrvNum)) {
        HrvError = 'Heart rate variability must be a number';
      } else if (hrvNum < 5 || hrvNum > 400) {
        HrvError = 'Heart rate variability must be between 5 and 400';
      }
    }
    setRHRError(RhrError);
    setHRVError(HrvError);
    if (RhrError || HrvError) {
      return;
    }
    onHeartInfoSubmited({hrv, rhr});
    setHasUnsavedChanges(true);
    setVisible(false);
  };
  return (
    <>
      <Icon
        name="activity"
        style={{marginHorizontal: 8}}
        size={22}
        onPress={openModal}
      />
      <Modal visible={visible} onBackgroundPress={closeModal}>
        <KeyboardAvoidingArea
          style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}
        >
          <Button
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            onPress={closeModal}
          />
          <Div
            style={{
              borderWidth: 1,
              borderRadius: 6,
              borderColor: GlobalStyles.svexa.green400,
              backgroundColor: GlobalStyles.colorsBasic.white500,
              alignSelf: 'center',
              marginHorizontal: 20,
              padding: 10,
              maxWidth: '95%',
            }}
            onStartShouldSetResponder={() => true}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            <Icon
              style={{marginLeft: 'auto'}}
              name="x"
              color={GlobalStyles.svexa.green400}
              onPress={closeModal}
              size={25}
            />
            <Paragraph style={{fontSize: 12, marginVertical: 15}}>
              {
                'Your heart rate might affect your rating and readiness score. Please enter the value for better accuracy.'
              }
            </Paragraph>
            <Paragraph style={{fontSize: 14, marginBottom: 4}}>
              Resting heart rate
            </Paragraph>
            <Div style={styles.inputWrapper}>
              <Input
                style={styles.input}
                value={rhr}
                onTextChange={setRhr}
                keyboardType="numeric"
                type="number"
              />
              <Paragraph style={styles.inputInfo}>bpm</Paragraph>
            </Div>
            <Div style={styles.errorWrapper}>
              {RHRerror ? (
                <Paragraph style={styles.errorMessage}>{RHRerror}</Paragraph>
              ) : null}
            </Div>
            <Paragraph style={{fontSize: 14, marginBottom: 4}}>
              Heart rate variability
            </Paragraph>
            <Div style={styles.inputWrapper}>
              <Input
                style={styles.input}
                value={hrv}
                onTextChange={setHrv}
                keyboardType="numeric"
                type="number"
              />
              <Paragraph style={styles.inputInfo}>ms(RMSSD)</Paragraph>
            </Div>
            <Div style={styles.errorWrapper}>
              {HRVerror ? (
                <Paragraph style={styles.errorMessage}>{HRVerror}</Paragraph>
              ) : null}
            </Div>
            <CustomButton
              type="primary"
              title="Submit"
              style={{marginVertical: 20}}
              titleStyle={{color: GlobalStyles.colorsBasic.white500}}
              loader={{size: 'small', color: 'white'}}
              onPress={saveHeartInfo}
            />
          </Div>
        </KeyboardAvoidingArea>
      </Modal>
    </>
  );
}

export interface HeartInfoProps {
  onHeartInfoSubmited: (info: {rhr: string; hrv: string}) => any;
  value: {rhr: string; hrv: string};
}

const styles = StyleSheet.create({
  input: {
    fontSize: 13,
    borderWidth: 1,
    borderColor: GlobalStyles.colorsBasic.gray500,
    borderRadius: 5,
    padding: 5,
    width: '75%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorWrapper: {
    marginTop: 2,
    marginBottom: 10,
  },
  errorMessage: {
    fontSize: 11,
    color: GlobalStyles.colorsStatus.red,
  },
  inputInfo: {
    marginLeft: 10,
    fontSize: 13,
  },
});
