import React from 'react';
import {Platform} from 'react-native';
import CheckBox from '../../../components/base/CheckBox';
import {Div} from '../../../components/base/Div';
import KeyboardAvoidingArea from '../../../components/base/KeyboardAvoidingArea';
import {Paragraph} from '../../../components/base/Paragraph';
import ScrollArea from '../../../components/base/ScrollArea';
import AuthHeading from '../shared/AuthHeading';
import LoginWithApple from './LoginWithApple';
import LoginWithGoogle from './LoginWithGoogle';
import LoginWithSvexa from './LoginWithSvexa';
import {LinkButton} from '../../../components/base/LinkButton';
import {useAuthNavigation} from '../../../navigation/auth-navigator/AuthNavigationContext';
import AuthHeader from '../shared/AuthHeader';

export default function LoginTemplate() {
  const [persist, setPersist] = React.useState(true);
  const navigation = useAuthNavigation();

  return (
    <ScrollArea keyboardShouldPersistTaps="handled" style={{flex: 1}}>
      <AuthHeader />
      <KeyboardAvoidingArea
        style={{
          marginVertical: 10,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <AuthHeading />
        <Div
          style={{
            width: '80%',
            maxWidth: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoginWithGoogle shouldPersist={persist} />
          <LoginWithApple shouldPersist={persist} />
          <LoginWithSvexa
            shouldPersist={persist}
            forgotPasswordLink={() => navigation?.push('forgot-password')}
          />
          <CheckBox
            value={persist}
            onValueChange={setPersist}
            title="Keep logged in on this device"
          />
          <Paragraph style={{marginTop: 20}}>
            <Paragraph>No account?</Paragraph>{' '}
            <LinkButton onPress={() => navigation?.push('signup')}>
              Create an account
            </LinkButton>
          </Paragraph>
        </Div>
      </KeyboardAvoidingArea>
    </ScrollArea>
  );
}
