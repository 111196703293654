import jwtDecode from 'jwt-decode';
import {Platform} from 'react-native';
import environment from '../../config/environment';
import {store} from '../store';
import {
  AppleAuth,
  GoogleAuth,
  IAuth,
  IUser,
  SvexaLoginDTO,
  SvexaSignupDTO,
  SvexaResetPasswordVerifyEmail,
  AuthProviders,
  IAccountEmail,
  SvexaResetPassowrdDTO,
} from '../types/IUser';
import {IResponse} from '../types/Request';
import BaseApi from './BaseGateway';

const AuthGateway = {
  loginWithSvexa: async ({
    email,
    password,
  }: SvexaLoginDTO): Promise<IUser | null> => {
    const jwt = await BaseApi.post<{token: string}>('/auth/svexa/signin', {
      email,
      password,
    });
    return await AuthGateway.assembleAuthUser(email, AuthProviders.SVEXA, jwt);
  },

  signupWithSvexa: async (payload: SvexaSignupDTO): Promise<IResponse> => {
    return await BaseApi.post(
      `/auth/svexa/register?clientUrl=${environment.web.url}`,
      {
        ...payload,
        termsConfirmed: true,
        termsVersion: 1,
        discoverableEmail: true,
      },
    );
  },

  signupWithGoogle: async (payload: GoogleAuth): Promise<IUser | null> => {
    const data = {
      ...payload,
      termsConfirmed: true,
      termsVersion: 1,
      providerEmailSearchable: true,
    };
    const jwt = await BaseApi.post<string>(
      '/auth/google/tokenregister',
      data,
      {},
      'plain',
    );
    return await AuthGateway.assembleAuthUser(
      payload.email,
      AuthProviders.GOOGLE,
      {token: jwt.replace('?token=', '')},
    );
  },

  loginWithGoogle: async ({
    idToken,
    email,
  }: GoogleAuth): Promise<IUser | null> => {
    const jwt = await BaseApi.get<string>(
      `/auth/google/tokensignin?idToken=${idToken}&svexaAuthAction=signin`,
      {},
      'plain',
    );
    return await AuthGateway.assembleAuthUser(email, AuthProviders.GOOGLE, {
      token: jwt.replace('?token=', ''),
    });
  },

  signupWithApple: async (payload: AppleAuth): Promise<IUser | null> => {
    const data = {
      ...payload,
      termsConfirmed: true,
      termsVersion: 1,
      providerEmailSearchable: true,
    };
    const jwt = await BaseApi.post<string>(
      '/auth/apple/tokenregister',
      data,
      {},
      'plain',
    );
    return await AuthGateway.assembleAuthUser(
      payload.email,
      AuthProviders.APPLE,
      {token: jwt.replace('?token=', '')},
    );
  },

  loginWithApple: async ({
    authorizationCode,
    identityToken,
    email,
  }: AppleAuth): Promise<IUser | null> => {
    const jwt = await BaseApi.get<string>(
      `/auth/apple/tokensignin?authorizationCode=${authorizationCode}&identityToken=${identityToken}&svexaAuthAction=signin`,
      {},
      'plain',
    );
    return await AuthGateway.assembleAuthUser(email, AuthProviders.APPLE, {
      token: jwt.replace('?token=', ''),
    });
  },

  getUserById: async (id: string): Promise<IUser | null> => {
    const user = await BaseApi.get<IUser>(
      `/user/${encodeURIComponent(id)}?token=${BaseApi.getAccessToken()}`,
    );
    if (!user) {
      return null;
    }
    user.id = id;
    return user;
  },

  logout: async (): Promise<IResponse | null> => {
    const refreshToken = store.getState().auth.user?.refreshToken || '';
    if (!refreshToken) {
      return null;
    }
    if (Platform.OS == 'web') {
      return await BaseApi.get(`/auth/logout?token=${refreshToken}`);
    } else {
      const pushToken = store.getState().auth.pushToken;
      if (!pushToken) {
        return null;
      }
      const params: {
        androidDeviceToken?: string;
        iosDeviceToken?: string;
        token: string | null;
      } = {
        token: refreshToken,
      };
      if (Platform.OS == 'ios') {
        params.iosDeviceToken = pushToken;
      }
      if (Platform.OS == 'android') {
        params.androidDeviceToken = pushToken;
      }
      return await BaseApi.post('/auth/logout/mobiledevice', params);
    }
  },

  getUserFromRefreshToken: async (
    refreshToken: {token: string} | null,
  ): Promise<IUser | null> => {
    const auth = await AuthGateway.getAuthData(refreshToken);
    if (!auth) {
      return null;
    }
    const user = await AuthGateway.getUserById(auth.id);
    if (!user) {
      return null;
    }
    return {...user, ...auth};
  },

  getAuthData: async (
    refreshToken: {token: string} | null,
  ): Promise<IAuth | null> => {
    if (!refreshToken) {
      return null;
    }
    const accessToken = await AuthGateway.getAccessToken(refreshToken.token);
    if (!accessToken) {
      return null;
    }
    const jwtData = AuthGateway.getDataFromJWT(accessToken);
    if (!jwtData) {
      return null;
    }
    BaseApi.setAccessToken(accessToken);
    BaseApi.setAccessUserId(jwtData.userId);
    return {
      refreshToken: refreshToken.token,
      accessToken,
      id: jwtData.userId,
      exp: jwtData.exp,
    };
  },

  getAccessToken: async (refresh: string): Promise<string | null> => {
    const jwt = await BaseApi.get<{token: string}>(
      '/auth/refresh?token=' + refresh,
    );
    if (jwt) {
      return jwt.token;
    }
    return null;
  },

  refreshLoggedUser: async (): Promise<IUser | null> => {
    const refreshToken = store.getState().auth.user?.refreshToken || null;
    if (!refreshToken) {
      return null;
    }
    return await AuthGateway.getUserFromRefreshToken({token: refreshToken});
  },

  resetPasswordVerifyEmail: async ({
    email,
  }: SvexaResetPasswordVerifyEmail): Promise<{message: string}> => {
    return await BaseApi.post(
      `/account/jobs/resetpassword?clientUrl=${environment.web.url}`,
      {
        email,
      },
    );
  },

  resetPassword: async (payload: SvexaResetPassowrdDTO): Promise<IResponse> => {
    return await BaseApi.post(`/account/resetpassword`, payload);
  },

  getDataFromJWT: (
    accessToken: string,
  ): {userId: string; exp: number} | null => {
    try {
      const decoded = jwtDecode<any>(accessToken);
      if (!decoded) {
        return null;
      }
      return {userId: decoded.user, exp: decoded.exp};
    } catch (e) {
      // ErrorHandler.handle(e as Error);
      return null;
    }
  },

  getUserEmails: async (): Promise<IAccountEmail[]> => {
    return await BaseApi.get(
      `/account/email?token=${BaseApi.getAccessToken()}`,
    );
  },

  assembleAuthUser: async (
    email: string,
    provider: AuthProviders,
    jwt: {token: string},
  ): Promise<IUser | null> => {
    const user = await AuthGateway.getUserFromRefreshToken(jwt);
    if (!user) {
      return null;
    }
    const emails = await AuthGateway.getUserEmails();
    if (emails) {
      const currentEmail = emails.find((e) => e.email == email);
      user.isEmailPrimary = !!currentEmail?.primary;
      user.isEmailDiscoverable = !!currentEmail?.discoverable;
    }
    user.email = email;
    user.authProvider = provider;
    return user;
  },

  setPushToken: async (pushToken: string): Promise<IResponse> => {
    return await BaseApi.put(
      `/notifications/${BaseApi.getEncodedUserId()}/registerdevice/${
        Platform.OS
      }?token=${BaseApi.getAccessToken()}`,
      {deviceToken: pushToken},
    );
  },
};

export default AuthGateway;
