import React from 'react';
import {Platform, StyleSheet, useWindowDimensions} from 'react-native';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomRadioButton from '../../../components/shared/CustomRadioButton';
import {maxContentWidth} from '../../../constants/Layout';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {GlobalStyles} from '../../../styles';
import {IQuestion} from '../../../types/IQuestion';

export default function QuestionYesNo({
  index,
  question,
  onValueChanged,
}: QuestionRatingProps) {
  const [hintVisible, setHintVisible] = React.useState(false);
  const {width} = useWindowDimensions();
  const {translate} = useLocalizationProvider();
  const toogleHints = () => setHintVisible(!hintVisible);

  const setValue = (value: string) => {
    if (value == (question.yesLabel || 'Yes')) {
      onValueChanged(1);
    } else if (value == (question.noLabel || 'No')) {
      onValueChanged(0);
    }
  };
  return (
    <Div
      style={[
        styles.wrapper,
        index == 0
          ? {
              borderTopWidth: 1,
              borderTopColor: GlobalStyles.colorsBasic.gray400,
            }
          : {},
      ]}
      key={index.toString()}
    >
      {/* {hintVisible ? (
        <Div
          style={{
            position: 'absolute',
            backgroundColor: GlobalStyles.svexa.green500,
            padding: 10,
            borderRadius: 5,
            zIndex: 999,
            elevation: 99,
            bottom: Platform.OS == 'ios' ? 65 : 58,
            left: 12,
            maxWidth: Math.min(width, maxContentWidth) - 24,
            flexDirection: 'row',
          }}
        >
          <Paragraph
            translate={false}
            style={{
              fontSize: 13,
              flexShrink: 1,
              color: GlobalStyles.colorsBasic.white500,
            }}
          >
            {question.fullDescription}
            {question.longText}
          </Paragraph>
        </Div>
      ) : null} */}
      <Div style={{flex: 1}}>
        <Paragraph style={{fontSize: 15}} translate={false}>
          {question.text.replace('&', translate('and'))}
          {question.text.endsWith('?') ? '' : ':'}
        </Paragraph>
        {/* <Icon
            style={{marginLeft: 6}}
            name="info"
            color={GlobalStyles.svexa.green400}
            size={15}
            onPress={toogleHints}
          /> */}

        <Div
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
            },
          ]}
        >
          <Div style={{flex: 0.5}}>
            <CustomRadioButton
              style={{marginVertical: 2}}
              label={question.yesLabel || 'Yes'}
              value={question.yesLabel || 'Yes'}
              checked={
                question.value
                  ? question.yesLabel || 'Yes'
                  : question.noLabel || 'No'
              }
              setChecked={setValue}
            />
          </Div>
          <Div style={{flex: 0.5}}>
            <CustomRadioButton
              style={{marginVertical: 2}}
              label={question.noLabel || 'No'}
              value={question.noLabel || 'No'}
              checked={
                question.value
                  ? question.yesLabel || 'Yes'
                  : question.noLabel || 'No'
              }
              setChecked={setValue}
            />
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    paddingHorizontal: 12,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: GlobalStyles.colorsBasic.gray400,
    borderBottomWidth: 1,
  },
});

interface QuestionRatingProps {
  question: IQuestion;
  index: number;
  onValueChanged: (value: number) => any;
  hasValues: boolean;
}
