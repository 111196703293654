import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle} from 'react-native';
import {Paragraph} from './Paragraph';

export function HeadingOne(props: HeadingOneProps) {
  return (
    <Paragraph
      translate={props.translate}
      style={[styles.headingOneStyle, props.style]}
      ellipsizeMode={props.ellipsizeMode}
      numberOfLines={props.numberOfLines}
    >
      {props.children}
    </Paragraph>
  );
}
export interface HeadingOneProps extends React.PropsWithChildren {
  translate?: boolean;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
}

const styles = StyleSheet.create({
  headingOneStyle: {
    fontFamily: 'inter-black',
    fontSize: 32,
    paddingVertical: 8,
  },
});
