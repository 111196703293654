import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import React from 'react';
import {Platform} from 'react-native';
import useAlertProvider from './useAlertProvider';

export default function useNotificationProvider() {
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  const {success, danger} = useAlertProvider();

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.DEFAULT,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  async function getPushToken() {
    let token = '';
    if (!Device.isDevice || Platform.OS == 'web') {
      return token;
    }

    try {
      const permissions = await Notifications.getPermissionsAsync();
      if (permissions.status !== 'granted') {
        const {status} = await Notifications.requestPermissionsAsync();
        console.log('NOTIFICATION PERMISSION STATUS ', status);
      }
      token = (await Notifications.getDevicePushTokenAsync()).data;
    } catch (err) {
      console.log('ERROR GETTING PUSH TOKEN ', err);
      return token;
    }
    console.log('PUSH TOKEN FETCHED ', token);
    return token;
  }

  function onNotificationReceived(
    listner: (event: Notifications.NotificationResponse) => void,
  ) {
    React.useEffect(() => {
      if (lastNotificationResponse) {
        listner(lastNotificationResponse);
        success(
          `${lastNotificationResponse.notification.request.content.body}`,
          () => {},
          `${lastNotificationResponse.notification.request.content.title}`,
        );
      }
    }, [lastNotificationResponse]);
  }

  return {getPushToken, onNotificationReceived};
}
