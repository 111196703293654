import React from 'react';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import Loader from '../../../components/base/Loader';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import useCoachService from '../../../services/useCoachService';
import useConfigService from '../../../services/useConfigService';
import useQuestionProvider from '../../../providers/useQuestionProvider';
import {IRating} from '../../../types/IRatings';
import {GlobalStyles} from '../../../styles';
import {IQuestion} from '../../../types/IQuestion';
import AthleteQuestionAnswer from './AthleteQuestionAnswer';
import PastRatingsByAthleteId from '../athletes/PastRatingsByAthleteId';
import ProgressBar from '../../../components/base/ProgressBar';
import {useWindowDimensions} from 'react-native';
import {maxContentWidth} from '../../../constants/Layout';

export default function MyAthleteRatings({
  dateKey,
  athleteId,
  hideDate,
}: AthleteRatingsProps) {
  const {formatDateKey, dateFormatFromDateKey} = useDateTimeProvider();
  const levels = useConfigService().useLevels();
  const {useAthleteDayRating, useSendRatingsReminder} = useCoachService();
  const {width} = useWindowDimensions();

  const [selectedDateKey, setSelectedDateKey] = React.useState(
    dateKey || formatDateKey(),
  );
  const {rating, loading, questions} = useAthleteDayRating(
    athleteId,
    selectedDateKey || formatDateKey(),
  );
  useQuestionProvider().useQuestions(selectedDateKey);

  const onRatingSelected = (rating: IRating) =>
    setSelectedDateKey(rating.dateKey);
  const {send, sending} = useSendRatingsReminder();
  const onSend = () => send({userIds: [athleteId]});
  const renderAnswer = (item: IQuestion, index: number) => (
    <AthleteQuestionAnswer key={index} question={item} index={index} />
  );

  return (
    <Div style={{flex: 1}}>
      <Div style={{marginHorizontal: 24}}>
        <PastRatingsByAthleteId
          onRatingSelected={onRatingSelected}
          dateKey={selectedDateKey}
          athleteId={athleteId}
        />
      </Div>
      {!rating && loading ? (
        <Div style={{alignContent: 'center', justifyContent: 'center'}}>
          <Loader size="large" color={GlobalStyles.svexa.green500} />
        </Div>
      ) : null}
      {rating ? (
        <Div>
          {!hideDate ? (
            <Div style={{flexDirection: 'row', alignItems: 'center'}}>
              <Paragraph
                style={{fontSize: 16, fontWeight: '600', marginBottom: 12}}
              >
                <Paragraph>
                  {dateFormatFromDateKey(rating.dateKey, 'MMM')}
                </Paragraph>{' '}
                <Paragraph>
                  {dateFormatFromDateKey(rating.dateKey, 'Do, yyyy')}
                </Paragraph>{' '}
                <Paragraph>
                  {dateFormatFromDateKey(rating.dateKey, 'ddd')}
                </Paragraph>
                {levels[rating?.level].text === 'Add Rating' ? null : (
                  <Paragraph> - </Paragraph>
                )}
              </Paragraph>
              {levels[rating?.level].text === 'Add Rating' ? null : (
                <Paragraph
                  style={{
                    fontSize: 16,
                    marginBottom: 12,
                    color: levels[!rating ? -1 : rating.level].color,
                  }}
                >
                  {levels[rating?.level].text}
                </Paragraph>
              )}
            </Div>
          ) : null}
          {rating.values?.length ? (
            <Div style={{flex: 1}}>
              <Div style={{alignItems: 'flex-start'}}>
                <Paragraph style={{marginBottom: 5, fontWeight: '500'}}>
                  Readiness score:
                </Paragraph>
                <Div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <ProgressBar
                    progress={rating.score}
                    backgroundColor={GlobalStyles.colorsBasic.gray300}
                    height={10}
                    width={Math.min(width, maxContentWidth) - 100}
                    color={levels[rating.level]?.color}
                    borderRadius={15}
                  />
                  <Paragraph
                    translate={false}
                    style={{
                      marginLeft: 15,
                      color: levels[rating.level]?.color || 'gray',
                      fontWeight: '600',
                    }}
                  >
                    {(rating.score * 100).toFixed()}%
                  </Paragraph>
                </Div>
              </Div>

              {rating?.comments.performance_test ? (
                <Div style={{marginTop: 20}}>
                  <Paragraph style={{marginBottom: 5, fontSize: 16}}>
                    Notes from athlete:
                  </Paragraph>
                  <Paragraph style={{fontSize: 12}}>
                    {rating?.comments?.performance_test}
                  </Paragraph>
                </Div>
              ) : null}

              {questions && questions.length
                ? questions.map(renderAnswer)
                : null}
            </Div>
          ) : rating.hasValues ? null : (
            <Div
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                <Icon
                  name="alert-circle"
                  size={30}
                  color={GlobalStyles.colorsStatus.red}
                />
                <Paragraph
                  style={{
                    marginLeft: 10,
                    fontSize: 14,
                    fontWeight: '600',
                    color: GlobalStyles.colorsStatus.red,
                  }}
                >
                  {'No ratings.'}
                </Paragraph>
              </Div>
              {dateKey == formatDateKey() ? (
                <CustomButton
                  type="primary"
                  loader={{
                    size: 'small',
                    color: GlobalStyles.colorsBasic.white500,
                  }}
                  isLoading={sending}
                  disabled={sending}
                  onPress={onSend}
                  style={{width: 150}}
                  title="Send Reminder"
                  titleStyle={{
                    color: GlobalStyles.colorsBasic.white500,
                  }}
                />
              ) : null}
            </Div>
          )}
        </Div>
      ) : null}
    </Div>
  );
}

interface AthleteRatingsProps {
  dateKey?: string;
  athleteId: string;
  hideDate?: boolean;
}
