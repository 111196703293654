import React from 'react';
import {Platform} from 'react-native';
import ConfigGateway from '../gateways/ConfigGateway';
import {useRequest} from '../providers/useRequestProvider';
import {useAppSelector} from '../store';
import {configActions} from '../store/reducers/ConfigReducer';
import {SupportedPlatforms} from '../types/IConfig';

export default function useConfigService() {
  function useLevels() {
    return useAppSelector(({config}) => config.levels);
  }

  function usePlatformVersions() {
    const platformVersions = useAppSelector(
      ({config}) => config.platformVersions,
    );

    const {
      execute: fetch,
      loading,
      data,
    } = useRequest(ConfigGateway.getPlatformVersionHistory, {
      onCompleted: (data) => {
        if (!data) {
          return;
        }
        configActions.setPlatformVersions(data);
      },
    });

    React.useEffect(() => {
      fetch({platform: Platform.OS as SupportedPlatforms});
    }, []);

    return {fetch, loading, platformVersions: data};
  }

  return {useLevels, usePlatformVersions};
}
