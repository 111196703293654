import Timezone from '../../screens/athlete/shared/Timezone';
import {GlobalStyles} from '../../styles';
import {FormControlType, IFormBuilder, IFormControl} from '../../types/Form';
import CheckBox from '../base/CheckBox';
import {Div} from '../base/Div';
import Input from '../base/Input';
import {Paragraph} from '../base/Paragraph';
import CustomRadioButton from './CustomRadioButton';
import DateTimPicker from './date-time-picker/DateTimePicker';
import PasswordInput from './password/PasswordInput';
import ToggleSelect from './ToggleSelect';
import ToggleSwitch from './ToggleSwitch';

export default function FormControl<T>({
  control,
  value,
  onChange,
  submit,
  error,
  formState,
}: FormControlProps<T>) {
  const renderControl = (type: FormControlType) => {
    switch (type) {
      case 'password':
        return (
          <PasswordInput
            {...control.props}
            onTextChange={onChange}
            value={value as string}
            style={[
              control.props?.style,
              !!error
                ? {
                    borderColor: GlobalStyles.colorsStatus.red,
                    ...(control.errorStyle ? control.errorStyle : {}),
                  }
                : undefined,
            ]}
            onSubmitEditing={control.submitOnEditFinish ? submit : undefined}
          />
        );
      case 'toggleSelect':
        return (
          <ToggleSelect
            values={control.values || []}
            value={value as string}
            setValue={onChange}
          />
        );
      case 'toggleSwitch':
        return (
          <ToggleSwitch
            style={control.props?.style}
            isEnabled={!!value}
            toggleSwitch={onChange}
          />
        );
      case 'radio':
        return (control.radioButtons || []).map((rb, i) => (
          <CustomRadioButton
            key={i}
            label={rb.label}
            value={rb.value}
            checked={value as string}
            hintText={rb.hintText}
            setChecked={onChange}
          />
        ));
      case 'timeZone':
        return (
          <Timezone
            onValueChange={(v) => onChange(v as string)}
            value={value as string}
          />
        );
      case 'checkBox':
        return (
          <CheckBox
            {...control.props}
            value={!!value}
            style={control.style}
            onValueChange={onChange}
          />
        );
      case 'dateTimePicker':
        return (
          <DateTimPicker
            {...control.props}
            value={value as any as Date}
            onChange={onChange}
          />
        );
      default:
        return (
          <Input
            {...control.props}
            onTextChange={onChange}
            value={value as string}
            style={[
              control.props?.style,
              !!error
                ? {
                    borderColor: GlobalStyles.colorsStatus.red,
                    ...(control.errorStyle ? control.errorStyle : {}),
                  }
                : undefined,
            ]}
            onSubmitEditing={control.submitOnEditFinish ? submit : undefined}
          />
        );
    }
  };

  return (
    <Div
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
        control.inline ? {flex: 1} : {},
      ]}
    >
      {control.prefix ? (
        <Paragraph style={{marginRight: 15}}>
          {control.prefix(formState)}
        </Paragraph>
      ) : null}
      <Div style={{flex: 1}}>{renderControl(control.type)}</Div>
      {control.sufix ? (
        <Paragraph style={{marginLeft: 15}}>
          {control.sufix(formState)}
        </Paragraph>
      ) : null}
    </Div>
  );
}

interface FormControlProps<T> {
  control: IFormControl<T, any>;
  value: T[keyof T] | string;
  onChange: (value: any) => any;
  submit: () => any;
  error: string | null;
  formState: IFormBuilder<T>;
}
