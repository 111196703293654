import {Language, LanguageMap} from '../types/ILanguage';
import assets from './Assets';

export const shouldTranslateByDefault = true;

export const defaultLanguage: Language = Language.ENGLISH;

export const translations: LanguageMap = {
  en: assets.languages.en,
  es: assets.languages.es,
  sv: assets.languages.sv,
  pl: assets.languages.pl,
};

export const supportedLanguages: Array<{label: string; value: Language}> = [
  {label: 'English', value: Language.ENGLISH},
  {label: 'Española', value: Language.SPANISH},
  {label: 'Svenska', value: Language.SVEDISH},
  {label: 'Polski', value: Language.POLISH},
];
