import {
  Alert,
  AlertButton,
  ImageStyle,
  Platform,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';
import Toast, {ToastShowParams} from 'react-native-toast-message';
import {AlertOptions, AlertProps, AlertTypes} from '../types/Alert';
import {GlobalStyles} from '../styles';
declare let window: typeof globalThis;

export default function useAlertProvider() {
  const defaultToastOptions: ToastShowParams = {
    autoHide: true,
    type: 'basic',
    visibilityTime: 3000,
    onPress: () => Toast.hide(),
    topOffset: 60,
  };

  const alertStyle = StyleSheet.create<{
    [key in AlertTypes]: ViewStyle | TextStyle | ImageStyle;
  }>({
    success: {
      backgroundColor: GlobalStyles.colorsStatus.green,
    },
    danger: {
      backgroundColor: GlobalStyles.colorsStatus.red,
    },
  });

  function success(
    message: string,
    onPress?: () => void,
    title?: string | null,
    options?: AlertOptions,
  ) {
    return show({text: message, type: 'success', onPress, title}, options);
  }

  function danger(
    message: string,
    onPress?: () => void,
    title?: string | null,
    options?: AlertOptions,
  ) {
    return show({text: message, type: 'danger', onPress, title}, options);
  }

  function useAlertStyle(type: AlertTypes) {
    return alertStyle[type];
  }

  function show(props: AlertProps, options?: AlertOptions | null) {
    options ?? {};
    Toast.show({
      ...defaultToastOptions,
      ...options,
      props,
    });
  }

  function alertWeb(title: string, message?: string, buttons?: AlertButton[]) {
    if (!window) return;
    if (message) title += `\n${message}`;
    if (!buttons || buttons.length < 2) {
      window.alert(title);
      return;
    }
    const answer = window.confirm(title);
    // first buttton is considered confirmation, second cancelation;
    if (answer) {
      if (buttons[0].onPress) buttons[0].onPress();
    } else {
      if (buttons[1].onPress) buttons[1].onPress();
    }
  }

  function useNativeAlert() {
    return Platform.OS == 'web' ? alertWeb : Alert.alert;
  }

  return {defaultToastOptions, success, danger, useAlertStyle, useNativeAlert};
}
