import {algorithamConstants} from '../constants/Config';
import {store} from '../store';
import {GetUserRatingsDTO, IRating, SetUserRatingsDTO} from '../types/IRatings';
import BaseApi from './BaseGateway';

const RatingsGateway = {
  getUserRatings: async ({
    dateKey,
    span,
  }: GetUserRatingsDTO): Promise<IRating[]> => {
    let path = `/ratings/${BaseApi.getEncodedUserId()}/recovery?token=${BaseApi.getAccessToken()}&span=${span}&date_key=${dateKey}`;
    path +=
      '&supported_questions_definition=' +
      algorithamConstants.supported_questions_definition.toString();
    path +=
      '&supported_level_definition=' +
      algorithamConstants.supported_level_definition.toString();
    path +=
      '&supported_severity_definition=' +
      algorithamConstants.supported_severity_definition.toString();
    return await BaseApi.get(path);
  },

  setRatingsForDay: async ({
    dateKey,
    date,
    ratingsData,
  }: SetUserRatingsDTO): Promise<IRating | null> => {
    const payload: any = {...ratingsData};
    const storage = store.getState();
    const questionVersion = storage.config.questionVersion || '3';
    const userId = storage.auth.user?.id || '';

    payload.v = questionVersion;
    payload.date = date;

    const res = await BaseApi.post(
      `/ratings/${encodeURIComponent(
        userId,
      )}/${dateKey}?token=${BaseApi.getAccessToken()}`,
      payload,
    );
    if (!res) {
      return null;
    }
    const ratings = await RatingsGateway.getUserRatings({
      dateKey,
      span: '1',
    });
    return ratings[0];
  },
};

export default RatingsGateway;
