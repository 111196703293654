import {GlobalStyles} from '../styles';

export const defaultChartConfig = {
  backgroundGradientFrom: GlobalStyles.colorsBasic.white500,
  backgroundGradientFromOpacity: 1,
  backgroundGradientTo: GlobalStyles.colorsBasic.white500,
  backgroundGradientToOpacity: 1,
  color: () => 'black',
  strokeWidth: 2, // optional, default 3
  barPercentage: 0.5,
  useShadowColorFromDataset: false, // optional
  propsForBackgroundLines: {
    strokeDasharray: '', // solid background lines with no dashes
    opacity: 0.1,
  },
};

export const RatingsChartBarConfig = {
  maxWidth: 30,
  minWidth: 8,
};
