import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';

export default function KeyboardAvoidingScrollArea(
  props: KeyboardAvoidingScrollAreaProps,
) {
  return (
    <KeyboardAwareScrollView {...props} keyboardOpeningTime={250}>
      {props.children}
    </KeyboardAwareScrollView>
  );
}

interface KeyboardAvoidingScrollAreaProps
  extends KeyboardAwareScrollViewProps {}
