import React from 'react';
import {Div} from '../../../components/base/Div';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import useQuestionProvider from '../../../providers/useQuestionProvider';
import {IRating} from '../../../types/IRatings';
import AthleteDayRating from '../shared/AthleteDayRating';
import PastRatings from './PastRatings';

export default function AthleteOverviewTemplate() {
  const {formatDateKey} = useDateTimeProvider();
  const [selectedDateKey, setSelectedDateKey] = React.useState(formatDateKey());
  useQuestionProvider().useQuestions(selectedDateKey);

  const onRatingSelected = (rating: IRating) =>
    setSelectedDateKey(rating.dateKey);

  return (
    <KeyboardAvoidingScrollArea>
      <Div style={{flex: 1, margin: 24, marginTop: 12}}>
        <AthleteDayRating dateKey={selectedDateKey} addRatingEnabled />
        <PastRatings
          onRatingSelected={onRatingSelected}
          dateKey={selectedDateKey}
        />
      </Div>
    </KeyboardAvoidingScrollArea>
  );
}
