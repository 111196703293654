import React from 'react';
import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import Modal from '../../../components/base/Modal';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import useAuthService from '../../../services/useAuthService';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {GlobalStyles} from '../../../styles';

export default function DeleteAccount() {
  const {logout} = useAuthService().useLogout();

  const onDeletingCompleted = () => {
    closeModal();
    setTimeout(logout, 200);
  };

  const {deleteAccount, loading: deleting} =
    useUserSettingsService().useDeleteAccount(onDeletingCompleted);

  const [modalVisible, setModalVisible] = React.useState(false);

  const closeModal = () => setModalVisible(false);

  const openModal = () => setModalVisible(true);

  const onDeleteConfirmed = () => {
    deleteAccount();
  };

  return (
    <>
      <HeadingThree>Delete account</HeadingThree>
      <Div style={styles.deleteAccountContainer}>
        <Paragraph>
          {
            "When you delete your account, all the data in the app as well as data from connected devices associated with your account will be removed. Please consider carefully before you do, as it's irreversible."
          }
        </Paragraph>

        <CustomButton
          title="Delete account"
          onPress={openModal}
          type="danger"
          titleStyle={{color: GlobalStyles.colorsBasic.white500}}
        />
      </Div>
      <Modal visible={modalVisible} onBackgroundPress={closeModal}>
        <Div
          style={{
            backgroundColor: 'white',
            width: '80%',
            maxWidth: 400,
            paddingHorizontal: 30,
            paddingVertical: 20,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            borderColor: 'lightgrey',
            borderRadius: 10,
          }}
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <HeadingThree
            style={{paddingTop: 10, paddingBottom: 30, textAlign: 'center'}}
          >
            Are you sure you want to delete your account?
          </HeadingThree>
          <CustomButton
            type="danger"
            title="Delete"
            style={{width: '100%', marginVertical: 10}}
            onPress={onDeleteConfirmed}
            isLoading={deleting}
            disabled={deleting}
            loader={{size: 'small', color: 'black'}}
            titleStyle={{color: GlobalStyles.colorsBasic.white500}}
          />
          <CustomButton
            type="secondary"
            title="Cancel"
            style={{width: '100%', marginVertical: 10}}
            onPress={closeModal}
          />
        </Div>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  deleteAccountContainer: {
    padding: 16,
    borderColor: GlobalStyles.colorsStatus.red,
    borderWidth: 2,
    borderRadius: 5,
    paddingVertical: 5,
  },
});
