import {Div} from '../../../../components/base/Div';
import Loader from '../../../../components/base/Loader';
import {Paragraph} from '../../../../components/base/Paragraph';
import useCoachService from '../../../../services/useCoachService';
import usePermissionsService from '../../../../services/usePermissionsService';
import {GlobalStyles} from '../../../../styles';
import {PermissionStatus} from '../../../../types/IPermissions';
import {useCurrentGroup} from '../GroupContext';
import TeamAthleteCard from './TeamAthleteCard';

export default function TeamActiveAthletes() {
  const group = useCurrentGroup();
  const {permissions} = usePermissionsService().useFetchPermissions(true);
  const {members, fetching} = useCoachService().useGroupMembers(
    group?.id || '',
  );
  return (
    <Div>
      {!Object.keys(members || {}).length && fetching ? (
        <Div>
          <Loader size="small" color={GlobalStyles.svexa.green500} />
        </Div>
      ) : !Object.keys(members?.members || {}).length ? (
        <Paragraph>No athletes yet.</Paragraph>
      ) : (
        Object.keys(members?.members || {}).map((key, index) => {
          const athlete = members?.members[key];
          if (!athlete) return null;
          const permission = permissions.athletes?.find(
            (athlete) =>
              athlete.id == key && athlete.status === PermissionStatus.ACCEPTED,
          );
          if (!permission) return null;
          return <TeamAthleteCard key={index} athlete={athlete} />;
        })
      )}
    </Div>
  );
}
