import {GlobalStyles} from '../styles';
import {IForm} from '../types/Form';
import {
  SvexaLoginDTO,
  SvexaSignupDTO,
  SvexaResetPasswordVerifyEmail,
  ProviderSignupDTO,
  SvexaResetPassowrd,
} from '../types/IUser';
import Validator from './Validator';
import {inputStyle} from '../components/base/Input';

export const loginWithSvexaForm: IForm<SvexaLoginDTO> = {
  email: {
    label: 'Email',
    name: 'email',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'none',
    },
    validators: [Validator.required(), Validator.email()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  password: {
    label: 'Password',
    name: 'password',
    type: 'password',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'none',
    },
    submitOnEditFinish: true,
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};

export const signupWithSvexaForm: IForm<SvexaSignupDTO> = {
  givenName: {
    label: 'First Name',
    name: 'givenName',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'words',
    },
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  familyName: {
    label: 'Last Name',
    name: 'familyName',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'words',
    },
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  email: {
    label: 'Email',
    name: 'email',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'none',
    },
    validators: [Validator.required(), Validator.email()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  password: {
    label: 'Password',
    name: 'password',
    type: 'password',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'none',
    },
    submitOnEditFinish: true,
    hint: 'The password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    validators: [Validator.required(), Validator.password()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};

export const signupWithProvider: IForm<ProviderSignupDTO> = {
  givenName: {
    label: 'First Name',
    name: 'givenName',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'words',
    },
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  familyName: {
    label: 'Last Name',
    name: 'familyName',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'words',
    },
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};

export const resetPasswordVerifyEmailForm: IForm<SvexaResetPasswordVerifyEmail> =
  {
    email: {
      label: 'Email',
      name: 'email',
      type: 'input',
      props: {
        style: {
          fontSize: 13,
          borderWidth: 1,
          borderRadius: 5,
          padding: 5,
          height: 35,
          borderColor: GlobalStyles.colorsBasic.gray400,
        },
        autoCapitalize: 'none',
      },
      validators: [Validator.required(), Validator.email()],
      errorStyle: {
        borderColor: GlobalStyles.colorsStatus.red,
      },
    },
  };

export const resetPasswordForm: IForm<SvexaResetPassowrd> = {
  email: {
    label: 'Email',
    name: 'email',
    type: 'input',
    props: {
      style: {
        fontSize: 13,
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
        height: 35,
        borderColor: GlobalStyles.colorsBasic.gray400,
      },
      autoCapitalize: 'none',
    },
    validators: [Validator.required(), Validator.email()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  password: {
    label: 'New Password',
    name: 'password',
    type: 'password',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'none',
    },
    submitOnEditFinish: true,
    hint: 'The password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    validators: [Validator.required(), Validator.password()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};
