import React from 'react';
import {Platform} from 'react-native';
import CustomButton from '../../../components/shared/CustomButton';
import CheckBox from '../../../components/base/CheckBox';
import {Div} from '../../../components/base/Div';
import KeyboardAvoidingArea from '../../../components/base/KeyboardAvoidingArea';
import {Paragraph} from '../../../components/base/Paragraph';
import ScrollArea from '../../../components/base/ScrollArea';
import Form from '../../../components/shared/Form';
import {signupWithSvexaForm} from '../../../constants/User';
import useAuthService from '../../../services/useAuthService';
import {GlobalStyles} from '../../../styles';
import AuthHeading from '../shared/AuthHeading';
import {LinkButton} from '../../../components/base/LinkButton';
import {useAuthNavigation} from '../../../navigation/auth-navigator/AuthNavigationContext';
import AuthHeader from '../shared/AuthHeader';
import {SvexaSignupDTO} from '../../../types/IUser';

export default function SignupWithSvexaTemplate() {
  const navigation = useAuthNavigation();
  const {loading, signup} = useAuthService().useSignupWithSvexa();
  const onSignup = (payload: SvexaSignupDTO) => {
    if (!termsAccepted) {
      setTermsError(true);
      return;
    }
    signup(payload);
  };
  const setTerms = (value: boolean) => {
    if (value) setTermsError(false);
    setTermsAccepted(value);
  };
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [termsError, setTermsError] = React.useState(false);
  return (
    <ScrollArea style={{flex: 1}} keyboardShouldPersistTaps="handled">
      <AuthHeader />
      <KeyboardAvoidingArea
        style={{
          marginVertical: 10,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <AuthHeading />
        <Div
          style={{
            width: '80%',
            maxWidth: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Form
            style={{width: '100%'}}
            form={signupWithSvexaForm}
            onSubmit={onSignup}
            submitControl={(submit) => (
              <>
                <CheckBox
                  style={{marginTop: 30}}
                  onValueChange={setTerms}
                  value={termsAccepted}
                  title={() => (
                    <Paragraph
                      style={{
                        fontSize: 12,
                        marginLeft: 5,
                        marginTop: 30,
                      }}
                    >
                      <Paragraph>
                        By checking this box you agree to our
                      </Paragraph>{' '}
                      <LinkButton url="https://docs.google.com/document/d/e/2PACX-1vRRl4mN9ow3Cajw50wwJPRvphZTW3i-kOt27K92s-uU8nCimtS1V8lNrbDEylN5jaqC8Ujo5_qzMVI2/pub">
                        terms and conditions
                      </LinkButton>{' '}
                      <Paragraph>and</Paragraph>{' '}
                      <LinkButton url="https://docs.google.com/document/d/e/2PACX-1vSU_xXDhsLQItiuc6S7YFx_lpmSXfXklG5f46fR5bpwKraRyN6-IdTAAat1iFMsIxaTd4-TifkJ6lHB/pub">
                        privacy policy
                      </LinkButton>
                    </Paragraph>
                  )}
                />
                {termsError ? (
                  <Paragraph
                    style={{
                      marginTop: 10,
                      color: GlobalStyles.colorsStatus.red,
                    }}
                  >
                    {
                      'You have to agree to terms and conditions before signing up.'
                    }
                  </Paragraph>
                ) : null}
                <CustomButton
                  style={{marginTop: 30}}
                  title="Create account with email"
                  onPress={submit}
                  type="primary"
                  titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                  isLoading={loading}
                  disabled={loading}
                  loader={{size: 'small', color: 'black'}}
                />
              </>
            )}
          />
          <Paragraph style={{marginTop: 20}}>
            <Paragraph>Have an account?</Paragraph>{' '}
            <LinkButton onPress={() => navigation?.push('login')}>
              Sign in
            </LinkButton>
          </Paragraph>
        </Div>
      </KeyboardAvoidingArea>
    </ScrollArea>
  );
}
