import * as React from 'react';
import {
  Image as RNImage,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
} from 'react-native';

export default function Image({
  height,
  width,
  resizeMode,
  source,
  style,
}: ImageProps) {
  return (
    <RNImage
      style={[{height: height, width: width}, style]}
      source={source}
      resizeMode={resizeMode || 'contain'}
    />
  );
}

interface ImageProps extends React.PropsWithChildren {
  source: ImageSourcePropType;
  width?: number;
  height?: number;
  resizeMode?: ImageResizeMode;
  style?: StyleProp<ImageStyle>;
}
