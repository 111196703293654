import {Div} from '../../../../components/base/Div';
import Image from '../../../../components/base/Image';
import {Paragraph} from '../../../../components/base/Paragraph';
import CustomButton from '../../../../components/shared/CustomButton';
import assets from '../../../../constants/Assets';
import {useCoachNavigation} from '../../../../navigation/coach-navigator/CoachNavigationContext';
import usePermissionsService from '../../../../services/usePermissionsService';
import {GlobalStyles} from '../../../../styles';
import {IGroupMemberWitRating} from '../../../../types/IGroup';
import {
  IPermission,
  nonReInvitableStatuses,
  PermissionStatus,
} from '../../../../types/IPermissions';
import MyAthleteRatings from '../../shared/MyAthleteRatings';

export default function TeamAthleteRatingsOverview({
  athlete,
  permission,
}: TeamAthleteRatingsOverviewProps) {
  const navigation = useCoachNavigation();
  const {send, loading, refetching} =
    usePermissionsService().useSendAthleteInvite();
  const sendRequest = () => {
    send({email: athlete?.email || ''});
  };
  const goToAthletes = () => {
    navigation?.navigate('athletes');
  };
  return !athlete ? null : (
    <Div style={{paddingHorizontal: 12, paddingBottom: 24}}>
      <Div
        style={{flexDirection: 'row', marginBottom: 25, alignItems: 'center'}}
      >
        <Image
          height={50}
          width={50}
          style={{borderRadius: 25}}
          resizeMode={'cover'}
          source={athlete.image ? {uri: athlete.image} : assets.images.avatar}
        />
        <Div style={{flex: 1, marginLeft: 8}}>
          <Paragraph
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{fontSize: 18}}
          >
            {athlete.firstname} {athlete.lastname}
          </Paragraph>
          <Paragraph
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{fontSize: 12, color: GlobalStyles.colorsBasic.gray700}}
          >
            {athlete.email}
          </Paragraph>
        </Div>
      </Div>
      {permission && permission.status == PermissionStatus.ACCEPTED ? (
        <MyAthleteRatings
          dateKey={athlete.rating?.dateKey}
          athleteId={athlete?.id || ''}
        />
      ) : (
        <Div style={{flex: 1, alignItems: 'center', paddingTop: 40}}>
          {!permission ||
          (permission &&
            nonReInvitableStatuses.indexOf(permission.status) < 0) ? (
            <Div style={{width: 200}}>
              <Paragraph
                style={{
                  color: GlobalStyles.colorsStatus.red,
                  fontSize: 15,
                  fontWeight: '600',
                  marginBottom: 12,
                  textAlign: 'center',
                }}
              >
                No permission to see ratings!
              </Paragraph>
              <CustomButton
                type="primary"
                title="Request Permission"
                titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                loader={{
                  size: 'small',
                  color: GlobalStyles.colorsBasic.white500,
                }}
                isLoading={loading || refetching}
                disabled={loading || refetching}
                onPress={sendRequest}
              />
            </Div>
          ) : permission?.status === PermissionStatus.OUTGOING_INVITE ? (
            <Div style={{width: 200}}>
              <Paragraph
                style={{
                  color: GlobalStyles.svexa.green500,
                  fontSize: 15,
                  fontWeight: '600',
                  marginBottom: 12,
                  textAlign: 'center',
                }}
              >
                Pending permission request to see ratings...
              </Paragraph>
              <CustomButton
                type="primary"
                title="View My Athletes"
                titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                loader={{
                  size: 'small',
                  color: GlobalStyles.colorsBasic.white500,
                }}
                onPress={goToAthletes}
              />
            </Div>
          ) : null}
        </Div>
      )}
    </Div>
  );
}

interface TeamAthleteRatingsOverviewProps {
  athlete: IGroupMemberWitRating | null;
  permission?: IPermission;
}
