import React from 'react';
import {StyleProp, StyleSheet, TextStyle} from 'react-native';
import {GlobalStyles} from '../../styles';
import {Paragraph} from '../base/Paragraph';

export default function CroppedParagraph({
  text,
  maxLength = 500,
  onSeeMore,
  onSeeLess,
  style,
  translate,
}: CroppedParagraphProps) {
  const [fullTextOpened, setFullTextOpened] = React.useState(false);
  React.useEffect(() => setFullTextOpened(false), [text]);
  const onToogleFullText = () => {
    if (fullTextOpened) {
      if (onSeeLess) {
        onSeeLess();
      }
      setFullTextOpened(false);
    } else {
      if (onSeeMore) {
        onSeeMore();
      }
      setFullTextOpened(true);
    }
  };
  return !text ? (
    <Paragraph style={style} />
  ) : (
    <Paragraph style={style} translate={translate}>
      {fullTextOpened
        ? text
        : text.slice(0, maxLength - 1) +
          (text.length >= maxLength ? '...' : '')}
      {text.length >= maxLength ? (
        <Paragraph onPress={onToogleFullText} style={styles.seeMoreText}>
          {fullTextOpened ? '' : 'read more'}
        </Paragraph>
      ) : null}
    </Paragraph>
  );
}

interface CroppedParagraphProps {
  translate?: boolean;
  text: string;
  maxLength?: number;
  onSeeMore?: () => any;
  onSeeLess?: () => any;
  style?: StyleProp<TextStyle>;
}

const styles = StyleSheet.create({
  seeMoreText: {
    fontSize: 14,
    color: GlobalStyles.colorsBasic.gray600,
  },
});
