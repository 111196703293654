import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import CustomButton from '../../../components/shared/CustomButton';
import Form from '../../../components/shared/Form';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import useAuthService from '../../../services/useAuthService';
import {GlobalStyles} from '../../../styles';
import {SvexaResetPassowrd, SvexaResetPassowrdDTO} from '../../../types/IUser';
import {RootStackScreenProps} from '../../../types/Navigation';
import AuthHeader from '../shared/AuthHeader';
import AuthHeading from '../shared/AuthHeading';

export default function ResetPasswordScreen(
  props: RootStackScreenProps<'reset-password'>,
) {
  const {loading, resetPassword, form} = useAuthService().useResetPassword();

  const requestResetPassword = (formData: SvexaResetPassowrd) => {
    const payload: SvexaResetPassowrdDTO = {
      ...formData,
      token: props.route.params.token,
    };
    resetPassword(payload);
  };

  return (
    <ScreenLayout>
      <KeyboardAvoidingScrollArea
        contentContainerStyle={{height: '100%'}}
        style={{
          marginTop: 20,
        }}
      >
        <AuthHeader />
        <Div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <AuthHeading />
          <Div
            style={{
              width: '80%',
              maxWidth: 400,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HeadingThree>Reset password</HeadingThree>
            <Div
              style={{
                width: '100%',
                paddingVertical: 15,
                marginVertical: 15,
              }}
            >
              <Form
                form={form}
                onSubmit={requestResetPassword}
                submitControl={(submit) => (
                  <>
                    <CustomButton
                      type="primary"
                      title="Reset password"
                      titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                      isLoading={loading}
                      loader={{size: 'small', color: 'white'}}
                      onPress={submit}
                    />
                  </>
                )}
              />
            </Div>
          </Div>
        </Div>
      </KeyboardAvoidingScrollArea>
    </ScreenLayout>
  );
}
