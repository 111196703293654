import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {CoachNavigationContext} from '../../../navigation/coach-navigator/CoachNavigationContext';
import {CoachStackScreenProps} from '../../../types/Navigation';
import CoachHeader from '../shared/CoachHeader';
import TeamsTemplate from './TeamsTemplate';

export default function TeamsScreen({
  navigation,
}: CoachStackScreenProps<'teams'>) {
  return (
    <AuthGuard>
      <CoachNavigationContext.Provider value={navigation}>
        <ScreenLayout>
          <CoachHeader />
          <Container>
            <TeamsTemplate />
          </Container>
        </ScreenLayout>
      </CoachNavigationContext.Provider>
    </AuthGuard>
  );
}
