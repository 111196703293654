import React from 'react';
import {Div} from '../../components/base/Div';
import Icon from '../../components/base/Icon';
import Modal from '../../components/base/Modal';
import {Paragraph} from '../../components/base/Paragraph';
import {GlobalStyles} from '../../styles';
import {IQuestion} from '../../types/IQuestion';

export default function QuestionItemDetails({
  question,
}: QuestionItemDetailsProps) {
  const [visible, setVisible] = React.useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  const getRangeDescription = () => {
    const texts: Array<{score: string; description: string}> = [];
    Object.keys(question.score).forEach((s) => {
      const exists = texts.find(
        (range) => range.description === question.score[s].description,
      );
      if (!exists) {
        texts.push({
          score: `${s}`,
          description: question.score[s].description,
        });
        return;
      }
      if (exists.score.includes('-')) {
        exists.score = `${exists.score.split('-')[0]}-${s}`;
      } else {
        exists.score += '-' + s;
      }
    });
    return texts;
  };
  return (
    <>
      <Icon
        style={{marginLeft: 6}}
        name="info"
        color={GlobalStyles.svexa.green400}
        size={15}
        onPress={openModal}
      />
      <Modal visible={visible} onBackgroundPress={closeModal}>
        <Div
          style={{
            borderWidth: 1,
            borderRadius: 6,
            borderColor: GlobalStyles.svexa.green400,
            backgroundColor: GlobalStyles.colorsBasic.white500,
            padding: 10,
            marginHorizontal: 20,
            alignSelf: 'center',
            maxWidth: '95%',
          }}
          onStartShouldSetResponder={() => true}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <Icon
            style={{marginLeft: 'auto'}}
            name="x"
            color={GlobalStyles.svexa.green400}
            onPress={closeModal}
            size={25}
          />
          <Paragraph style={{fontWeight: '700', marginVertical: 10}}>
            {question.longText}
          </Paragraph>
          <Paragraph style={{marginBottom: 15}}>
            {question.fullDescription}
          </Paragraph>
          {getRangeDescription().map((range, index) => (
            <Div
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: 6,
              }}
              key={index}
            >
              <Paragraph style={{fontWeight: '600', width: 50}}>
                {range.score}
              </Paragraph>
              <Paragraph style={{flex: 1}}>{range.description}</Paragraph>
            </Div>
          ))}
        </Div>
      </Modal>
    </>
  );
}

interface QuestionItemDetailsProps {
  question: IQuestion;
}
