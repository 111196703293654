import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IPermissionsData} from '../../types/IPermissions';

export interface PermissionsState {
  permissions: IPermissionsData;
}

const initialState: PermissionsState = {
  permissions: {
    coaches: [],
    coachColleagues: [],
    athletes: [],
  },
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (
      state: PermissionsState,
      action: PayloadAction<IPermissionsData>,
    ) => {
      state.permissions = action.payload;
    },
  },
});

export const permissionsActions = permissionsSlice.actions;

export default permissionsSlice.reducer;
