import {NativeStackNavigationProp} from '@react-navigation/native-stack';
import * as React from 'react';
import {AuthStackParamList} from '../../types/Navigation';

export const AuthNavigationContext =
  React.createContext<NativeStackNavigationProp<
    AuthStackParamList,
    any
  > | null>(null);

export const useAuthNavigation = () => React.useContext(AuthNavigationContext);
