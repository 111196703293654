import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import useUserSettingsService from '../../../services/useUserSettingsService';
import Form from '../../../components/shared/Form';
import React from 'react';
import {Paragraph} from '../../../components/base/Paragraph';
import LanguagePicker from '../../../components/shared/language-picker/LanguagePicker';

const Preferences = () => {
  const {
    preferencesFormData,
    form,
    update,
    processing,
    loading,
    formReference,
  } = useUserSettingsService().useUserPreferences();

  return (
    <Div>
      <HeadingThree>Preferences</HeadingThree>
      <Div style={{marginBottom: 10}}>
        <Paragraph style={{marginBottom: 5}}>Language:</Paragraph>
        <LanguagePicker />
      </Div>
      {loading ? null : (
        <Form
          formReference={formReference}
          form={form}
          entityState={preferencesFormData}
          onSubmit={update}
          submitControl={(submit) => (
            <CustomButton
              title="Submit"
              onPress={submit}
              type="primary"
              titleStyle={{color: GlobalStyles.colorsBasic.white500}}
              isLoading={processing}
              disabled={processing}
              loader={{size: 'small', color: 'black'}}
            />
          )}
        />
      )}
    </Div>
  );
};

export default Preferences;
