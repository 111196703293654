import {BlurTint} from 'expo-blur';
import {Modal as BaseModal, Platform} from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import BlurArea from './BlurArea';
import Button from './Button';
import {SafeArea} from './SafeArea';
import Toast from 'react-native-toast-message';
import {toastConfig} from '../shared/Alert';

export default function Modal({
  visible,
  animationType,
  blurIntensity,
  blurTint,
  onBackgroundPress,
  children,
}: IModalProps) {
  return (
    <BaseModal
      presentationStyle="overFullScreen"
      statusBarTranslucent={true}
      visible={visible || false}
      animationType={animationType || 'none'}
      transparent={true}
    >
      <SafeArea
        style={[
          {flex: 1},
          Platform.OS === 'android'
            ? {
                backgroundColor: `rgba(0,0,0,${0.025 * (blurIntensity || 30)})`,
              }
            : {backgroundColor: 'transparent'},
        ]}
      >
        <GestureHandlerRootView style={{flex: 1}}>
          <BlurArea
            style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}
            intensity={blurIntensity || 30}
            tint={blurTint || 'default'}
          >
            <Button
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              onPress={onBackgroundPress}
            />
            {children}
          </BlurArea>
        </GestureHandlerRootView>
      </SafeArea>
      <Toast config={toastConfig} />
    </BaseModal>
  );
}

interface IModalProps extends React.PropsWithChildren {
  visible: boolean;
  animationType?: 'none' | 'slide' | 'fade';
  blurIntensity?: number;
  blurTint?: BlurTint;
  onBackgroundPress?: () => any;
}
