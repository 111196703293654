import 'react-native-gesture-handler';
import {StatusBar} from 'expo-status-bar';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import Navigation from './app/navigation';
import {persistor, store} from './app/store';
import {Provider} from 'react-redux';
import useAssetsProvider from './app/providers/useAssetsProvider';
import useLayoutProvider from './app/providers/useLayoutProvider';
import {PersistGate} from 'redux-persist/integration/react';
import Toast from 'react-native-toast-message';
import {toastConfig} from './app/components/shared/Alert';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

export default function App() {
  const {useCachedResources} = useAssetsProvider();
  const {useColorScheme} = useLayoutProvider();
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <GestureHandlerRootView style={{flex: 1}}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SafeAreaProvider>
              <Navigation colorScheme={colorScheme} />
              <StatusBar />
            </SafeAreaProvider>
          </PersistGate>
          <Toast config={toastConfig} />
        </Provider>
      </GestureHandlerRootView>
    );
  }
}
