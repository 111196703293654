import Constants from 'expo-constants';

export default Constants.expoConfig?.extra as IEnvironment;

interface IEnvironment {
  env: string;
  api: {
    protocol: string;
    host: string;
    version: string;
    appId: string;
    apiKey: string;
  };
  web: {
    url: string;
  };
  google: {
    expoClientId: string;
    webClientId: string;
    androidClientId: string;
    iosClientId: string;
  };
  apple: {
    clientId: string;
    redirectUri: string;
    redirectUriAndroid: string;
  };
  scheme: string;
  clientTag: string;
}
