import {Language} from './ILanguage';
import {INotificationSettings} from './INotification';

export interface IUser extends IUserApi, IAuth {
  isTemporary?: boolean;
  image?: string | null;
  email?: string | null;
  authProvider?: AuthProviders;
  isEmailDiscoverable?: boolean;
  isEmailPrimary?: boolean;
}

export interface IAuth {
  accessToken: string;
  refreshToken: string;
  exp: number;
  id: string;
}
export interface IUserApi extends IProfile {
  display_name: string;
  createdDate: number;
  modifiedDate: number;
  hash: string;
}

export interface IProfile {
  firstname: string;
  lastname: string;
  sex: Sex;
  units: Units;
  height: string;
  weight: string;
  sport_primary: string;
  team: string;
  level: string;
  residence: string;
  residenceTimeZone: string;
  residenceAltitude: string;
  defaultView?: Views;
  firstDayOfWeek?: firstDaysOfWeek;
  preferredLanguage?: Language;
}

export interface IProfileForm {
  personalDetails: {
    firstname: string;
    lastname: string;
    sex: Sex;
    units: Units;
    height: string;
    weight: string;
  };
  fitnessLevel: {
    sport_primary: string;
    team: string;
    level: string;
  };
  geographyData: {
    residence: string;
    residenceTimeZone: string;
    residenceAltitude: string;
  };
}

export enum Views {
  ATHLETE = 'athlete',
  COACH = 'coach',
}

export enum Units {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

export enum Sex {
  FEMALE = 'female',
  MALE = 'male',
}

export enum AuthProviders {
  SVEXA = 'svexa',
  APPLE = 'apple',
  GOOGLE = 'google',
}

export enum firstDaysOfWeek {
  MONDAY = 'Monday',
  SUNDAY = 'Sunday',
}

export interface IPreferences {
  notificationSettings: INotificationSettings;
  isEmailDiscoverable?: boolean;
  defaultView?: Views;
  firstDayOfWeek?: firstDaysOfWeek;
}
export interface IPreferencesForm {
  acceptsInvites: boolean;
  firstDayOfTheWeek: firstDaysOfWeek;
  defaultView: Views;
  dailyReminders: boolean;
  timeReminder: Date;
  notifications: {
    notificationApp: boolean;
    notificationEmail: boolean;
  };
}

export interface IAccountEmail {
  email: string;
  confirmed: boolean;
  primary: boolean;
  deletable: boolean;
  discoverable: boolean;
  providers: AuthProviders[];
}

export type AccountEmailAction = 'add' | 'delete' | 'modify';
export interface AccountEmailDTO {
  action: AccountEmailAction;
  discoverable: boolean;
  email: string;
  primary?: boolean;
}

export interface SvexaLoginDTO {
  email: string;
  password: string;
}

export interface SvexaSignupDTO {
  givenName: string;
  familyName: string;
  email: string;
  password: string;
}

export interface SvexaResetPasswordVerifyEmail {
  email: string;
}

export interface SvexaResetPassowrd {
  email: string;
  password: string;
}

export interface SvexaResetPassowrdDTO extends SvexaResetPassowrd {
  token: string;
}
export interface ProviderSignupDTO {
  givenName: string;
  familyName: string;
}

export interface GoogleAuth {
  idToken: string;
  givenName: string;
  familyName: string;
  email: string;
}

export interface AppleAuth {
  authorizationCode: string;
  identityToken: string;
  fullName: string;
  givenName: string;
  familyName: string;
  email: string;
}

export interface AppleSignup extends AppleAuth {
  full_name: string;
}

export interface AppleUser {
  givenName: string;
  familyName: string;
  email: string;
}

export interface AppleLogin {
  authorizationCode: string;
  identityToken: string;
  fullName: string;
}
export interface IAppleJWT {
  aud: string; // must be mobile bundleId or expo host ( host.exp.Exponent )
  auth_time: number;
  c_hash: string;
  email: string;
  email_verified: string; // "true" | "false" | "unknown"
  exp: number;
  iat: number;
  iss: string; // must be "https://appleid.apple.com";
  nonce_supported: boolean;
  sub: string;
}

export interface IAppleWebReponse {
  authorization: {
    id_token: string;
    code: string;
    state: string;
  };
  user: {name: {firstName: string; lastName: string}; email: string};
}

export interface IAppleAndroidResponse {
  id_token: string;
  code: string;
  state: string;
}

export interface IGoogleJWT {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  hd: string;
  email: string;
  email_verified: boolean;
  nonce: string;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  iat: number;
  exp: number;
  jti: string;
}
