import {Div} from '../../../components/base/Div';
import {HeadingOne} from '../../../components/base/HeadingOne';
import Logo from '../../../components/shared/Logo';

export default function AuthHeading() {
  return (
    <Div style={{marginBottom: 40, alignItems: 'center'}}>
      <HeadingOne>Readiness Advisor</HeadingOne>
      <Logo height={40} width={80} />
    </Div>
  );
}
