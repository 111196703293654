import React from 'react';
import Button, {ButtonProps} from '../../../components/base/Button';
import Image from '../../../components/base/Image';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import {GlobalStyles} from '../../../styles';

export default function AppleButton({onPress, title, style}: AppleButtonProps) {
  return (
    <Button
      style={[
        {
          flexDirection: 'row',
          width: '100%',
          borderWidth: 1,
          borderColor: GlobalStyles.colorsBasic.gray500,
          paddingHorizontal: 10,
          paddingVertical: 5,
          marginVertical: 8,
          height: 45,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 5,
        },
        style,
      ]}
      onPress={onPress}
    >
      <Image source={assets.images.appleLogo} height={30} width={30} />
      <Paragraph style={{marginLeft: 20}}>{title}</Paragraph>
    </Button>
  );
}

export interface AppleButtonProps extends ButtonProps {
  onPress?: () => void;
  title?: string;
}
