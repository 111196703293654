import {RatingsChartBarConfig} from '../../constants/Chart';

export default function useBarChart(chartWidth: number) {
  const getChartBarWidth = (numberOfBars: number) => {
    let barWidth = chartWidth / (numberOfBars * 3);
    if (barWidth < RatingsChartBarConfig.minWidth) {
      barWidth = RatingsChartBarConfig.minWidth;
    }
    if (barWidth > RatingsChartBarConfig.maxWidth) {
      barWidth = RatingsChartBarConfig.maxWidth;
    }
    return barWidth;
  };

  const getChartBarSpace = (numberOfBars: number) => {
    const barWidth = getChartBarWidth(numberOfBars);
    return (chartWidth - barWidth * numberOfBars) / numberOfBars / 2;
  };

  const getChartBarFontSize = (numberOfBars: number) => {
    switch (numberOfBars) {
      case 7:
        return 12;
      case 14:
        return 10;
      case 21:
        return 8;
      default:
        return 10;
    }
  };
  return {getChartBarWidth, getChartBarSpace, getChartBarFontSize};
}
