import React from 'react';
import {StyleSheet} from 'react-native';
import Button from '../../../../components/base/Button';
import {Div} from '../../../../components/base/Div';
import Image from '../../../../components/base/Image';
import Loader from '../../../../components/base/Loader';
import {Paragraph} from '../../../../components/base/Paragraph';
import ConfirmationModal from '../../../../components/shared/ConfirmationModal';
import DraggableModal, {
  DraggableModalRef,
} from '../../../../components/shared/DraggableModal';
import assets from '../../../../constants/Assets';
import useLocalizationProvider from '../../../../providers/useLocalizationProvider';
import useAuthService from '../../../../services/useAuthService';
import useCoachService from '../../../../services/useCoachService';
import usePermissionsService from '../../../../services/usePermissionsService';
import {GlobalStyles} from '../../../../styles';
import {IGroupMember, RemoveMemberFromGroupDTO} from '../../../../types/IGroup';
import {useCurrentGroup} from '../GroupContext';
import TeamAthleteRatings from './TeamAthleteRatings';

export default function TeamAthleteCard({athlete}: AthleteCardProps) {
  const user = useAuthService().useUser();
  const group = useCurrentGroup();
  const {permissions, fetchAll} = usePermissionsService().useFetchPermissions();
  const {remove, removing} = useCoachService().useRemoveGroupMember();
  const {translate} = useLocalizationProvider();
  const onRemove = () => {
    const payload: RemoveMemberFromGroupDTO = {
      groupId: group?.id || '',
      type: 'member',
      hash: group?.hash || '',
      userId: athlete.id,
    };
    remove(payload);
    closeModal();
  };
  const draggableRef = React.useRef<DraggableModalRef>({
    open: () => 1,
    close: () => 0,
  });
  const openRatings = () => {
    fetchAll({});
    draggableRef?.current.open();
  };
  const [visible, setVisible] = React.useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  return (
    <>
      <DraggableModal reference={draggableRef}>
        <TeamAthleteRatings
          permission={permissions.athletes?.find((p) => p.id === athlete.id)}
          athlete={athlete}
        />
      </DraggableModal>
      <Button style={styles.shadowBox} onPress={openRatings}>
        <Image
          height={40}
          width={40}
          style={{borderRadius: 20}}
          resizeMode={'cover'}
          source={athlete.image ? {uri: athlete.image} : assets.images.avatar}
        />
        <Div style={{flex: 1, marginLeft: 8}}>
          <Paragraph numberOfLines={1} ellipsizeMode={'tail'}>
            {user?.id === athlete.id
              ? 'You'
              : `${athlete.firstname} ${athlete.lastname}`}
          </Paragraph>
          <Paragraph
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{fontSize: 11, color: GlobalStyles.colorsBasic.gray700}}
          >
            {athlete.email}
          </Paragraph>
        </Div>
        {user?.id === athlete.id ? null : removing ? (
          <Loader size={'small'} color={GlobalStyles.colorsStatus.red} />
        ) : (
          <Paragraph
            onPress={openModal}
            style={{color: GlobalStyles.colorsStatus.red, marginLeft: 10}}
          >
            Remove
          </Paragraph>
        )}
      </Button>
      <ConfirmationModal
        close={closeModal}
        visible={visible}
        onConfirm={onRemove}
        title={`${translate('Are you sure you want to remove athlete')} ${
          athlete.firstname
        } ${athlete.lastname} ${translate('from')} ${group?.name}`}
        confirmButtonTitle="Remove"
        confirmationInProgress={removing}
      />
    </>
  );
}

interface AthleteCardProps {
  athlete: IGroupMember;
}

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    shadowColor: GlobalStyles.colorsBasic.black500,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    padding: 10,
    marginVertical: 5,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
