export enum Language {
  ENGLISH = 'en',
  //FRANCH = 'fr',
  //GERMAN = 'de',
  //HINDI = 'hi',
  SPANISH = 'es',
  SVEDISH = 'sv',
  POLISH = 'pl',
}

export type LanguageMap = {
  [value in Language]: {
    [key: string]: string;
  };
};
