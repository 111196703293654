import {Div} from '../../../components/base/Div';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import Loader from '../../../components/base/Loader';
import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import TabList from '../../../components/shared/tab/TabList';
import {CoachNavigationContext} from '../../../navigation/coach-navigator/CoachNavigationContext';
import useCoachService from '../../../services/useCoachService';
import {GlobalStyles} from '../../../styles';
import {CoachStackScreenProps} from '../../../types/Navigation';
import {ITab} from '../../../types/Tab';
import CoachHeaderSimple from '../shared/CoachHeaderSimple';
import {GroupContext} from './GroupContext';
import GroupInfoCard from './GroupInfoCard';
import TeamOverview from './overview/TeamOverview';
import TeamAthletes from './athletes/TeamAthletes';
import TeamSettings from './settings/TeamSettings';
import TeamCoaches from './coaches/TeamCoaches';

export default function TeamInfoScreen(props: CoachStackScreenProps<'team'>) {
  const {useGroup, useGroupMembers} = useCoachService();
  const {group, fetching} = useGroup(props.route.params.id);
  const {fetching: loading, members} = useGroupMembers(props.route.params.id);

  return (
    <AuthGuard>
      <CoachNavigationContext.Provider value={props.navigation}>
        <ScreenLayout>
          <CoachHeaderSimple />
          <Container>
            <KeyboardAvoidingScrollArea>
              <Div
                style={{
                  marginTop: 12,
                  margin: 24,
                }}
              >
                {!group || !members ? (
                  fetching || loading ? (
                    <Div
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Loader
                        size="large"
                        color={GlobalStyles.svexa.green500}
                      />
                    </Div>
                  ) : null
                ) : (
                  <Div style={{flex: 1}}>
                    <GroupInfoCard group={group} />
                    <GroupContext.Provider value={group}>
                      <TabList tabs={teamTabs} />
                    </GroupContext.Provider>
                  </Div>
                )}
              </Div>
            </KeyboardAvoidingScrollArea>
          </Container>
        </ScreenLayout>
      </CoachNavigationContext.Provider>
    </AuthGuard>
  );
}

const teamTabs: ITab[] = [
  {title: 'Overview', component: <TeamOverview />},
  {title: 'Athletes', component: <TeamAthletes />},
  {title: 'Coaches', component: <TeamCoaches />},
  {title: 'Settings', component: <TeamSettings />},
];
