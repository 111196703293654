import {Div} from '../../base/Div';
import * as Web from 'react-native-web';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
import {FormDataImage} from '../../../types/Image';
import Image from '../../base/Image';
import assets from '../../../constants/Assets';
import useAlertProvider from '../../../providers/useAlertProvider';

export default function UploadImage({
  onImagePicked,
  style,
  maxSize,
}: UploadImageProps) {
  const {danger} = useAlertProvider();
  return (
    <Div style={style}>
      {Web.unstable_createElement('input', {
        type: 'file',
        accept: 'image/jpeg, image/png',
        onChange: (event) => {
          if (!event.target?.files?.length) {
            return;
          }
          const img = event.target.files[0];
          if (!!maxSize && !!img.size && img.size > maxSize) {
            danger(`Image exceeds allowed size of ${maxSize / 1000000}mb.`);
            return;
          }
          onImagePicked(event.target.files[0]);
        },
        style: {
          opacity: 0,
          height: 55,
          width: 55,
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 2,
        },
      })}
      <Image
        source={assets.images.uploadPhoto}
        style={styles.profileUploadButton}
      />
    </Div>
  );
}

interface UploadImageProps {
  onImagePicked: (image: FormDataImage) => any;
  style?: StyleProp<ViewStyle>;
  maxSize?: number;
}

const styles = StyleSheet.create({
  profileUploadButton: {
    width: 55,
    height: 55,
    cursor: 'pointer',
  },
});
