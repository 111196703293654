import assets from '../../constants/Assets';
import Image from '../base/Image';

export default function Logo({height, width}: LogoProps) {
  // TODO(np): const logoSource = useLogo() for app customization
  return (
    <Image source={assets.images.svexaLogo} height={height} width={width} />
  );
}

interface LogoProps {
  height?: number;
  width?: number;
}
