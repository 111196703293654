import {AuthProviders} from './IUser';

export type IPermissionsData = {
  [key in PermissionTypes]: IPermission[];
};

export interface IPermission {
  firstName: string;
  lastName: string;
  id: string;
  status: PermissionStatus;
  email: string;
  emailAddress?: string;
  provider: AuthProviders;
}

export enum PermissionStatus {
  ACCEPTED = 'accepted',
  BLOCKED_BY_SELF = 'blockedBySelf',
  BLOCKED_BY_OTHER = 'blockedByOther',
  CANCELLED_BY_SELF = 'cancelledBySelf',
  CANCELLED_BY_OTHER = 'cancelledByOther',
  DECLINED_BY_SELF = 'declinedBySelf',
  DECLINED_BY_OTHER = 'declinedByOther',
  INCOMING_INVITE = 'incomingInvite',
  OUTGOING_INVITE = 'outgoingInvite',
  OUTGOING_EMAIL_INVITE = 'outgoingEmailInvite',
  REVOKED_BY_SELF = 'revokedBySelf',
  REVOKED_BY_OTHER = 'revokedByOther',
  UNBLOCKED_BY_SELF = 'unBlockedBySelf',
  UNBLOCKED_BY_OTHER = 'unBlockedByOther',
}

export const nonReInvitableStatuses: PermissionStatus[] = [
  PermissionStatus.OUTGOING_INVITE,
  PermissionStatus.BLOCKED_BY_OTHER,
  PermissionStatus.ACCEPTED,
];

export type PermissionTypes = 'athletes' | 'coaches' | 'coachColleagues';

export type PermissionTypesDTO = 'athlete' | 'coach' | 'coachColleague' | 'all';

export type InviteTypes =
  | 'athlete'
  | 'coach'
  | 'coachColleague'
  | 'coachEmail'
  | 'athleteEmail';

export type PermissionActionType =
  | 'accept'
  | 'block'
  | 'cancel'
  | 'decline'
  | 'revoke'
  | 'unblock';

export type PermissionAction = {
  type: PermissionActionType;
  color: string;
  label: string;
};

export type IPermissionActions = {
  [key in PermissionActionType]: PermissionAction;
};

export type PermissionActionMap = {
  [key in PermissionStatus]: PermissionAction[];
};
