import {StyleSheet} from 'react-native';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import Image from '../../../components/base/Image';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import {maxContentWidth} from '../../../constants/Layout';
import {useAthleteNavigation} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import useAlertProvider from '../../../providers/useAlertProvider';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {GlobalStyles} from '../../../styles';
import {useAddRatingsContext} from './AddRatingsContext';

export default function AddEditRatingsHeader({
  dateKey,
}: AddEditRatingsHeaderProps) {
  const navigation = useAthleteNavigation();
  const {dateFormatFromDateKey} = useDateTimeProvider();
  const {image} = useUserSettingsService().useUserImage();
  const {hasUnSavedChanges} = useAddRatingsContext();
  const nativeAlert = useAlertProvider().useNativeAlert();
  const {translate} = useLocalizationProvider();
  const invitationsCount = useUserSettingsService().useCoachInvitationsCount();

  const backToOverview = () =>
    navigation?.navigate('overview', {persist: true});

  const goBack = () => {
    if (!hasUnSavedChanges) {
      backToOverview();
      return;
    }
    nativeAlert(
      translate('Unsaved changes'),
      translate(
        'You have unsaved rating changes. Are you sure you want to leave the page? All changes will be lost.',
      ),
      [
        {
          text: translate('Leave'),
          style: 'default',
          onPress: backToOverview,
        },
        {
          text: translate('Stay'),
          style: 'cancel',
          onPress: () => true,
        },
      ],
      {userInterfaceStyle: 'light'},
    );
  };
  return (
    <Div style={styles.container}>
      <Div style={styles.innerHeader}>
        <Icon
          name="chevron-left"
          size={28}
          color={GlobalStyles.svexa.green700}
          onPress={goBack}
        />
        <Paragraph style={{fontWeight: '600', fontSize: 18, marginLeft: 6}}>
          <Paragraph>{dateFormatFromDateKey(dateKey, 'MMM')}</Paragraph>{' '}
          <Paragraph>{dateFormatFromDateKey(dateKey, 'Do')}</Paragraph>
        </Paragraph>
        <Button
          onPress={() => navigation?.navigate('settings')}
          style={{marginLeft: 'auto', marginRight: 15}}
        >
          <Image
            source={image ? {uri: image} : assets.images.avatar}
            style={styles.profileImage}
            resizeMode={'cover'}
          />
        </Button>
        <Div style={{position: 'relative'}}>
          <Icon
            name="menu"
            onPress={() => navigation?.toggleDrawer()}
            color="black"
            size={25}
          />
          {invitationsCount ? (
            <Div
              style={{
                position: 'absolute',
                borderRadius: 10,
                width: 20,
                height: 20,
                backgroundColor: GlobalStyles.colorsRatings.red,
                alignItems: 'center',
                justifyContent: 'center',
                left: -12,
                top: -4,
                zIndex: 99,
              }}
            >
              <Paragraph
                style={{
                  color: GlobalStyles.colorsBasic.white500,
                  fontSize: 12,
                }}
              >
                {invitationsCount}
              </Paragraph>
            </Div>
          ) : null}
        </Div>
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: GlobalStyles.colorsBasic.gray500,
    backgroundColor: GlobalStyles.colorsBasic.white500,
    borderBottomWidth: 1,
  },
  innerHeader: {
    width: '100%',
    maxWidth: maxContentWidth,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 20,
    paddingVertical: 8,
  },
  headerRight: {
    marginLeft: 'auto',
  },
  profileImage: {
    height: 28,
    width: 28,
    borderRadius: 14,
  },
});

interface AddEditRatingsHeaderProps {
  dateKey: string;
}
