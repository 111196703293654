import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRating} from '../../types/IRatings';

export interface RatingsState {
  ratings: {[dateKey: string]: IRating};
}

const initialState: RatingsState = {
  ratings: {},
};

export const ratingsSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {
    setRating: (
      state: RatingsState,
      action: PayloadAction<{dateKey: string; rating: IRating}>,
    ) => {
      state.ratings[action.payload.dateKey] = action.payload.rating;
    },
    setRatings: (
      state: RatingsState,
      action: PayloadAction<{[dateKey: string]: IRating}>,
    ) => {
      state.ratings = {...state.ratings, ...action.payload};
    },
  },
});

export const ratingsActions = ratingsSlice.actions;

export default ratingsSlice.reducer;
