import {ActivityIndicator, ColorValue} from 'react-native';

export default function Loader({size, animating, color}: LoaderProps) {
  return <ActivityIndicator size={size} animating={animating} color={color} />;
}

export interface LoaderProps {
  size?: number | 'small' | 'large' | undefined;
  animating?: boolean | undefined;
  color?: ColorValue | undefined;
}
