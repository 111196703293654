import IBarCharListProps from './IBarChartListProps';

export default function BarCharList(props: IBarCharListProps) {
  return (
    <>
      <style type="text/css">
        {
          '#bar-char-list::-webkit-scrollbar {height: 8px;} #bar-char-list::-webkit-scrollbar-track {background: #f1f1f1;border-radius: 5px; } #bar-char-list::-webkit-scrollbar-thumb {background: #888;border-radius: 5px;} #bar-char-list::-webkit-scrollbar-thumb:hover {background: #555; }'
        }
      </style>
      <div
        style={{
          overflowY: 'auto',
          overflowX: 'auto',
          display: 'flex',
          flexDirection: 'row-reverse',
          paddingBottom: 15,
        }}
        id={'bar-char-list'}
      >
        {props.data.map((item, index) => props.renderItem({item, index}))}
      </div>
    </>
  );
}
