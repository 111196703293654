import {IQuestion, QuestionTypes} from '../../../types/IQuestion';
import React from 'react';
import QuestionRating from './QuestionRating';
import QuestionYesNo from './QuestionYesNo';

export default function QuestionItem(props: QuestionItemProps) {
  switch (props.question.questionType) {
    case QuestionTypes.rating:
      return <QuestionRating {...props} />;
    case QuestionTypes.yesNo:
      return <QuestionYesNo {...props} />;
    default:
      return null;
  }
}
interface QuestionItemProps {
  question: IQuestion;
  index: number;
  onValueChanged: (value: number | boolean) => any;
  hasValues: boolean;
}
