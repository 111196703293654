import {useState} from 'react';
import {ColorValue} from 'react-native';
import {GlobalStyles} from '../../../styles';
import {Div} from '../../base/Div';
import Icon from '../../base/Icon';
import Input, {InputProps} from '../../base/Input';

export default function PasswordInput(props: PasswordInputProps) {
  const [hidden, setHidden] = useState(true);
  const onIconPress = () => {
    setHidden(!hidden);
  };
  return (
    <Div style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
      <Input
        {...props}
        style={[
          props.style,
          {flex: 1, paddingRight: (props.iconSize || 18) * 2},
        ]}
        passwordTextInput={hidden}
      />
      <Icon
        name={hidden ? 'eye-off' : 'eye'}
        size={props.iconSize || 18}
        color={props.iconColor || GlobalStyles.svexa.green500}
        onPress={onIconPress}
        style={{position: 'absolute', right: 10}}
      />
    </Div>
  );
}

export interface PasswordInputProps extends InputProps {
  iconColor?: ColorValue;
  iconSize?: number;
}
