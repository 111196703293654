import * as React from 'react';
import {Pressable, StyleProp, TextStyle, ViewStyle} from 'react-native';
import Loader, {LoaderProps} from './Loader';
import {Paragraph} from './Paragraph';

export default function Button({
  rf,
  onPress,
  onLongPress,
  style,
  disabled,
  title,
  titleStyle,
  isLoading,
  loader,
  children,
  translate,
  activeOpacity,
}: ButtonProps) {
  return (
    <Pressable
      ref={rf}
      onPress={onPress}
      onLongPress={onLongPress}
      style={({pressed}) => [
        {opacity: pressed ? activeOpacity || 0.2 : 1},
        style,
      ]}
      disabled={disabled}
    >
      {!!title && !isLoading ? (
        <Paragraph style={titleStyle} translate={translate}>
          {title}
        </Paragraph>
      ) : null}
      {!!isLoading && !!loader ? <Loader {...loader} /> : null}
      {!title ? children : null}
    </Pressable>
  );
}

export interface ButtonProps extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  onLongPress?: () => void;
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  translate?: boolean;
  rf?: React.MutableRefObject<any>;
  isLoading?: boolean;
  loader?: LoaderProps;
  activeOpacity?: number;
}
