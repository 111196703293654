import {inputStyle} from '../components/base/Input';
import {GlobalStyles} from '../styles';
import {IForm} from '../types/Form';
import {
  AuthProviders,
  firstDaysOfWeek,
  IPreferencesForm,
  IProfileForm,
  Sex,
  Units,
  Views,
} from '../types/IUser';
import Validator from './Validator';

export const FEET_TO_CM_MULTIPLIER = 30.48;
export const LBS_TO_KG_MULTIPLIER = 0.453592;
export const FEET_TO_METER_MULTIPLIER = 0.3048;
export const CM_TO_FEET_MULTIPLIER = 0.0328084;
export const METER_TO_FEET_MULTIPLIER = 100 * CM_TO_FEET_MULTIPLIER;
export const KG_TO_LBS_MULTIPLIER = 2.2046;

export function convertCmToFeet(sourceValue: string): string {
  if (sourceValue && !isNaN(Number(sourceValue))) {
    return toFixedIfNeeded(Number(sourceValue) * CM_TO_FEET_MULTIPLIER);
  } else {
    return '';
  }
}

export function convertFeetToCm(sourceValue: string): string {
  if (sourceValue && !isNaN(Number(sourceValue))) {
    return toFixedIfNeeded(Number(sourceValue) * FEET_TO_CM_MULTIPLIER);
  } else {
    return '';
  }
}

export function convertMeterToFeet(sourceValue: string): string {
  if (sourceValue && !isNaN(Number(sourceValue))) {
    return toFixedIfNeeded(Number(sourceValue) * METER_TO_FEET_MULTIPLIER);
  } else {
    return '';
  }
}

export function convertFeetToMeter(sourceValue: string): string {
  if (sourceValue && !isNaN(Number(sourceValue))) {
    return toFixedIfNeeded(Number(sourceValue) * FEET_TO_METER_MULTIPLIER);
  } else {
    return '';
  }
}

export function convertKgToLbs(sourceValue: string): string {
  if (sourceValue && !isNaN(Number(sourceValue))) {
    return toFixedIfNeeded(Number(sourceValue) * KG_TO_LBS_MULTIPLIER);
  } else {
    return '';
  }
}

export function convertLbsToKg(sourceValue: string): string {
  if (sourceValue && !isNaN(Number(sourceValue))) {
    return toFixedIfNeeded(Number(sourceValue) * LBS_TO_KG_MULTIPLIER);
  } else {
    return '';
  }
}

export function toFixedIfNeeded(value: number): string {
  if (!value) return value.toString();
  const stringValue = value.toString();
  if (new RegExp(/^\d*\.\d{3,}$/).test(stringValue)) {
    return value.toFixed(2).toString();
  }
  return value.toString();
}

export function convertCmToFeetIfImperialIsSet(
  sourceValue: string,
  selectedUnits: string,
): string {
  return selectedUnits === Units.IMPERIAL
    ? convertCmToFeet(sourceValue)
    : sourceValue || '';
}

export function convertMeterToFeetIfImperialIsSet(
  sourceValue: string,
  selectedUnits: string,
): string {
  return selectedUnits === Units.IMPERIAL
    ? convertMeterToFeet(sourceValue)
    : sourceValue || '';
}

export function convertKgToLbsIfImperialIsSet(
  sourceValue: string,
  selectedUnits: string,
): string {
  return selectedUnits === Units.IMPERIAL
    ? convertKgToLbs(sourceValue)
    : sourceValue || '';
}

export function convertFeetToCmIfImperialIsSet(
  sourceValue: string,
  selectedUnits: string,
): string {
  return selectedUnits === Units.IMPERIAL
    ? convertFeetToCm(sourceValue)
    : sourceValue || '';
}

export function convertFeetToMeterIfImperialIsSet(
  sourceValue: string,
  selectedUnits: string,
): string {
  return selectedUnits === Units.IMPERIAL
    ? convertFeetToMeter(sourceValue)
    : sourceValue || '';
}

export function convertLbsToKgIfImperialIsSet(
  sourceValue: string,
  selectedUnits: string,
): string {
  return selectedUnits === Units.IMPERIAL
    ? convertLbsToKg(sourceValue)
    : sourceValue || '';
}

export const experienceLevels = [
  {
    value: '1',
    label: '1. Novice - no or little experience of training',
  },
  {
    value: '2',
    label: '2. Infrequent - sporadic training, few hard training sessions',
  },
  {
    value: '3',
    label:
      '3. Regular - training every week, completed some races, has target time goals',
  },
  {
    value: '4',
    label:
      '4. Competitive - focused on improving times with regular, hard training sessions',
  },
  {
    value: '5',
    label:
      '5. Expert - highly focused on races and used to many training sessions every week',
  },
];

export const GMTtimeZones = [
  {
    label: 'GMT-12 - International Date Line West (IDLW)',
    value: '-12',
  },
  {
    label: 'GMT-11 - Nome Time (NT)',
    value: '-11',
  },
  {
    label: 'GMT-10 - Hawaii Standard Time (HST)',
    value: '-10',
  },
  {
    label: 'GMT-9 - Alaska Standard Time (AKST)',
    value: '-9',
  },
  {
    label: 'GMT-8 - Pacific Standard Time (PST)',
    value: '-8',
  },
  {
    label: 'GMT-7 - Mountain Standard Time (MST)',
    value: '-7',
  },
  {
    label: 'GMT-6 - Central Standard Time (CST)',
    value: '-6',
  },
  {
    label: 'GMT-5 - Eastern Standard Time (EST)',
    value: '-5',
  },
  {
    label: 'GMT-4 - Atlantic Standard Time (AST)',
    value: '-4',
  },
  {
    label: 'GMT-3 - Argentina Time (ART)',
    value: '-3',
  },
  {
    label: 'GMT-2 - Azores Time (AT)',
    value: '-2',
  },
  {
    label: 'GMT-1 - West Africa Time (WAT)',
    value: '-1',
  },
  {
    label: 'GMT+0 - Greenwich Mean Time (GMT)',
    value: '0',
  },
  {
    label: 'GMT+1 - Central European Time (CET)',
    value: '1',
  },
  {
    label: 'GMT+2 - Eastern European Time (EET)',
    value: '2',
  },
  {
    label: 'GMT+3 - Moscow Time (MSK)',
    value: '3',
  },
  {
    label: 'GMT+4 - Armenia Time (AMT)',
    value: '4',
  },
  {
    label: 'GMT+5 - Pakistan Standard Time (PKT)',
    value: '5',
  },
  {
    label: 'GMT+6 - Omsk Time (OMSK)',
    value: '6',
  },
  {
    label: 'GMT+7 - Kranoyask Time (KRAT)',
    value: '7',
  },
  {
    label: 'GMT+8 - Japan Standard Time (JST)',
    value: '9',
  },
  {
    label: 'GMT+10 - Eastern Australia Standard Time (AEST)',
    value: '10',
  },
  {
    label: 'GMT+11 - Sakhalin Time (SAKT)',
    value: '11',
  },
  {
    label: 'GMT+12 - New Zealand Standard Time (NZST)',
    value: '12',
  },
];

export const profileForm: IForm<IProfileForm> = {
  personalDetails: {
    name: 'personalDetails',
    type: 'formGroup',
    label: 'Personal Details',
    style: {
      marginTop: 15,
    },
    controls: {
      firstname: {
        label: 'First Name',
        name: 'firstname',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            width: '100%',
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
        validators: [Validator.required()],
      },
      lastname: {
        label: 'Last Name',
        name: 'lastname',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            width: '100%',
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
        validators: [Validator.required()],
      },
      sex: {
        label: 'Sex',
        name: 'sex',
        type: 'toggleSelect',
        values: [
          {label: Sex.FEMALE, value: Sex.FEMALE},
          {label: Sex.MALE, value: Sex.MALE},
        ],
        defaultValue: Sex.FEMALE,
      },
      units: {
        label: 'Unit preference for height and weight',
        name: 'units',
        type: 'toggleSelect',
        values: [
          {label: Units.METRIC, value: Units.METRIC},
          {label: Units.IMPERIAL, value: Units.IMPERIAL},
        ],
        defaultValue: Units.METRIC,
        onPreChange: (value, state) => {
          if (value === state.personalDetails?.units?.value) {
            return;
          }
          const newHeight = state.personalDetails.height.value
            ? value == Units.METRIC
              ? convertFeetToCm(state.personalDetails.height.value)
              : convertCmToFeet(state.personalDetails.height.value)
            : '';
          const newWeight = state.personalDetails.weight.value
            ? value == Units.METRIC
              ? convertLbsToKg(state.personalDetails.weight.value)
              : convertKgToLbs(state.personalDetails.weight.value)
            : '';
          const newAltitude = state.geographyData.residenceAltitude.value
            ? value == Units.METRIC
              ? convertFeetToMeter(state.geographyData.residenceAltitude.value)
              : convertMeterToFeet(state.geographyData.residenceAltitude.value)
            : '';
          return {
            ...state,
            personalDetails: {
              ...state.personalDetails,
              height: {...state.personalDetails.height, value: newHeight},
              weight: {...state.personalDetails.weight, value: newWeight},
            },
            geographyData: {
              ...state.geographyData,
              residenceAltitude: {
                ...state.geographyData.residenceAltitude,
                value: newAltitude,
              },
            },
          };
        },
      },
      height: {
        label: 'Height',
        name: 'height',
        type: 'input',
        sufix: (state) => {
          if (state.personalDetails.units.value == Units.METRIC) {
            return 'cm';
          } else {
            return 'feet';
          }
        },
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            flex: 1,
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
        validators: [Validator.number()],
      },
      weight: {
        label: 'Weight',
        name: 'weight',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            flex: 1,
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
        sufix: (state) => {
          if (state.personalDetails.units.value == Units.METRIC) {
            return 'kg';
          } else {
            return 'lbs';
          }
        },
        validators: [Validator.number()],
      },
    },
  },
  fitnessLevel: {
    name: 'fitnessLevel',
    type: 'formGroup',
    label: 'Fitness Level',
    style: {
      marginTop: 15,
      paddingTop: 15,
      borderTopWidth: 1,
      borderTopColor: GlobalStyles.colorsBasic.gray500,
    },
    controls: {
      sport_primary: {
        label: 'Sport (primary)',
        name: 'sport_primary',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            width: '100%',
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
      },
      team: {
        label: 'Team',
        name: 'team',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            width: '100%',
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
      },
      level: {
        label: 'Experience level',
        name: 'level',
        type: 'radio',
        radioButtons: experienceLevels,
        defaultValue: experienceLevels[2].value,
      },
    },
  },
  geographyData: {
    name: 'geographyData',
    type: 'formGroup',
    label: 'Geography Data',
    style: {
      marginTop: 15,
      paddingTop: 15,
      borderTopWidth: 1,
      borderTopColor: GlobalStyles.colorsBasic.gray500,
    },
    controls: {
      residence: {
        label: 'Home city',
        name: 'residence',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            width: '100%',
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
      },
      residenceTimeZone: {
        name: 'residenceTimeZone',
        type: 'timeZone',
        defaultValue: GMTtimeZones[13].value,
      },
      residenceAltitude: {
        label: 'Altitude',
        name: 'residenceAltitude',
        type: 'input',
        props: {
          style: {
            fontSize: 13,
            borderWidth: 1,
            borderRadius: 5,
            padding: 5,
            flex: 1,
            height: 35,
            borderColor: GlobalStyles.colorsBasic.gray400,
          },
        },
        sufix: (state) => {
          if (state.personalDetails.units.value == Units.METRIC) {
            return 'meters';
          } else {
            return 'feet';
          }
        },
        validators: [Validator.number()],
      },
    },
  },
};

export const preferenceForm: IForm<IPreferencesForm> = {
  acceptsInvites: {
    label: 'Accept invites from others:',
    name: 'acceptsInvites',
    type: 'toggleSelect',
    values: [
      {label: 'Yes', value: true},
      {label: 'No', value: false},
    ],
  },
  firstDayOfTheWeek: {
    label: 'First day of the week:',
    name: 'firstDayOfTheWeek',
    type: 'toggleSelect',
    values: [
      {label: firstDaysOfWeek.MONDAY, value: firstDaysOfWeek.MONDAY},
      {label: firstDaysOfWeek.SUNDAY, value: firstDaysOfWeek.SUNDAY},
    ],
  },
  defaultView: {
    label: 'Startup view:',
    name: 'defaultView',
    type: 'toggleSelect',
    values: [
      {label: 'Athlete', value: Views.ATHLETE},
      {label: 'Coach', value: Views.COACH},
    ],
  },
  dailyReminders: {
    label: 'Daily reminder',
    name: 'dailyReminders',
    type: 'toggleSwitch',
    inline: true,
    props: {
      style: {
        marginLeft: 'auto',
      },
    },
  },
  timeReminder: {
    label: 'Set time',
    name: 'timeReminder',
    type: 'dateTimePicker',
    props: {
      mode: 'time',
      dateDisplayFormat: 'hh:mm A',
      style: {
        width: 100,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: GlobalStyles.colorsBasic.gray400,
      },
      minuteInterval: 30,
    },
    visibleIf: (state) => !!state.dailyReminders?.value,
  },
  notifications: {
    label: '',
    name: 'notifications',
    type: 'formGroup',
    inline: true,
    controls: {
      notificationApp: {
        name: 'notificationApp',
        type: 'checkBox',
        inline: true,
        props: {
          title: 'App',
        },
        visibleIf: (state) => !!state.dailyReminders?.value,
      },
      notificationEmail: {
        name: 'notificationEmail',
        type: 'checkBox',
        inline: true,
        props: {
          title: 'Email',
        },
        visibleIf: (state) => !!state.dailyReminders?.value,
      },
    },
  },
};

export const authProvidersDescriptionMethod: {
  [value in AuthProviders]: string;
} = {
  svexa: 'username/password',
  google: 'Google',
  apple: 'Apple',
};

export const changePasswordForm: IForm<{
  passwordOld?: string;
  passwordNew: string;
}> = {
  passwordNew: {
    label: 'New Password',
    name: 'passwordNew',
    type: 'password',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'none',
    },
    submitOnEditFinish: true,
    hint: 'The password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
    validators: [Validator.required(), Validator.password()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};

export const sendFeedbackForm: IForm<{subject: string; text: string}> = {
  subject: {
    label: 'Subject',
    name: 'subject',
    type: 'input',
    props: {
      style: inputStyle.primaryInput,
      autoCapitalize: 'words',
    },
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
  text: {
    label: 'Message',
    name: 'text',
    type: 'input',
    props: {
      style: [inputStyle.primaryInput, {minHeight: 150, height: 'auto'}],
      autoCapitalize: 'sentences',
      multiline: true,
      numberOfLines: 10,
      scrollEnabled: false,
      textAlignVertical: 'top',
    },
    validators: [Validator.required()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};
