import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {AppleUser, IProfile, IUser} from '../../types/IUser';
import {PersistConfig, persistReducer} from 'redux-persist';
import localForage from 'localforage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Platform} from 'react-native';

export interface AuthState {
  user: IUser | null;
  userImage: string | null; // base64 profile user image
  pushToken: string | null;
  appleUsers: {[email: string]: AppleUser}; // must persist apple user data on the device based on email, during the auth process since apple will give this information only ones
}

const initialState: AuthState = {
  user: null,
  userImage: null,
  pushToken: null,
  appleUsers: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    updateUser: (state: AuthState, action: PayloadAction<Partial<IUser>>) => {
      state.user = {...state.user!, ...action.payload};
    },
    setUserImage: (state: AuthState, action: PayloadAction<string | null>) => {
      state.userImage = action.payload;
    },
    updateProfile: (
      state: AuthState,
      action: PayloadAction<IProfile | null>,
    ) => {
      if (action.payload) {
        state.user = {
          ...state.user!,
          ...action.payload,
        };
      }
    },
    setPushToken: (state: AuthState, action: PayloadAction<string | null>) => {
      state.pushToken = action.payload;
    },
    setAppleUser: (state: AuthState, action: PayloadAction<AppleUser>) => {
      state.appleUsers[action.payload.email] = action.payload;
    },
  },
});

const persistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage: Platform.OS == 'web' ? localForage : AsyncStorage,
  blacklist: ['userImage'],
};

export const authActions = authSlice.actions;

export default persistReducer(persistConfig, authSlice.reducer);
