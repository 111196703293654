import {AuthStackScreenProps} from '../../../types/Navigation';
import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import Form from '../../../components/shared/Form';
import useAuthService from '../../../services/useAuthService';
import {AuthNavigationContext} from '../../../navigation/auth-navigator/AuthNavigationContext';
import AuthHeading from '../shared/AuthHeading';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import AuthHeader from '../shared/AuthHeader';

export default function ForgotPassword({
  navigation,
}: AuthStackScreenProps<'forgot-password'>) {
  const {forgotPasswordVerifyEmail, loading, form} =
    useAuthService().useForgotPasswordVerifyEmail();
  const insets = useSafeAreaInsets();
  return (
    <AuthNavigationContext.Provider value={navigation}>
      <Div
        style={{flex: 1, backgroundColor: GlobalStyles.colorsBasic.white500}}
      >
        <KeyboardAvoidingScrollArea
          contentContainerStyle={{height: '100%'}}
          style={{
            marginTop: 20,
            marginBottom: insets.bottom,
          }}
        >
          <AuthHeader />
          <Div
            style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}
          >
            <AuthHeading />
            <Div
              style={{
                width: '80%',
                maxWidth: 400,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <HeadingThree>Forgot password</HeadingThree>
              <Paragraph>
                Please enter your email below so we can send you a link to reset
                password. If you do not receive the email, please check if you
                have entered the right email.
              </Paragraph>
              <Div
                style={{
                  width: '100%',
                  paddingVertical: 15,
                  marginVertical: 15,
                }}
              >
                <Form
                  form={form}
                  onSubmit={forgotPasswordVerifyEmail}
                  submitControl={(submit) => (
                    <>
                      <CustomButton
                        type="primary"
                        title="Submit"
                        titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                        isLoading={loading}
                        loader={{size: 'small', color: 'white'}}
                        onPress={submit}
                      />
                    </>
                  )}
                />
              </Div>
            </Div>
          </Div>
        </KeyboardAvoidingScrollArea>
      </Div>
    </AuthNavigationContext.Provider>
  );
}
