import {GlobalStyles} from '../../../styles';
import {ITab} from '../../../types/Tab';
import Button from '../../base/Button';
import {Paragraph} from '../../base/Paragraph';

export default function TabItem({
  index,
  onPress,
  tab,
  isActive,
}: ITabItemProps) {
  const onTabPress = () => onPress(tab as any);

  return (
    <Button
      key={index}
      onPress={onTabPress}
      style={{
        borderBottomWidth: 2,
        paddingVertical: 8,
        paddingHorizontal: 12,
        borderColor: isActive
          ? GlobalStyles.svexa.green500
          : GlobalStyles.colorsBasic.gray300,
      }}
    >
      <Paragraph>{tab.title}</Paragraph>
    </Button>
  );
}

interface ITabItemProps {
  tab: ITab | {title: string};
  index: number;
  isActive: boolean;
  onPress: (tab: ITab) => any;
}
