import React, {useCallback} from 'react';
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TextStyle,
  Linking,
} from 'react-native';
import useAlertProvider from '../../providers/useAlertProvider';

import {GlobalStyles} from '../../styles';
import {Paragraph} from './Paragraph';

export function LinkButton(props: LinkButtonProps) {
  const nativeAlert = useAlertProvider().useNativeAlert();
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(props.url!);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(props.url!);
    } else {
      nativeAlert(`Url ${props.url} can't be opened properly`);
    }
  }, [props.url]);

  return (
    <Paragraph
      translate={props.translate}
      ellipsizeMode={props.ellipsizeMode}
      numberOfLines={props.numberOfLines}
      style={[styles.link, props.style]}
      onPress={props.url ? handlePress : props.onPress}
    >
      {props.children}
    </Paragraph>
  );
}
export interface LinkButtonProps extends React.PropsWithChildren {
  translate?: boolean;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
  onPress?: (event: GestureResponderEvent) => void;
  url?: string;
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
    color: GlobalStyles.svexa.green500,
  },
});
