import {PropsWithChildren} from 'react';
import {StyleProp, TextStyle} from 'react-native';
import {List} from 'react-native-paper';
import {GlobalStyles} from '../../../styles';

export default function AccordionList({
  children,
  title,
  titleStyle,
}: AccordionListProps) {
  return (
    <List.Section
      title={title}
      titleStyle={titleStyle}
      style={{backgroundColor: GlobalStyles.colorsBasic.white500}}
    >
      {children}
    </List.Section>
  );
}

interface AccordionListProps extends PropsWithChildren {
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
}
