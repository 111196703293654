import {inputStyle} from '../components/base/Input';
import {GlobalStyles} from '../styles';
import {IForm} from '../types/Form';
import {AddGroupMemberDTO, CreateGroupDTO} from '../types/IGroup';
import {PermissionTypes} from '../types/IPermissions';
import Validator from './Validator';

export const createGroupForm: IForm<CreateGroupDTO> = {
  name: {
    label: 'Name',
    type: 'input',
    name: 'name',
    props: {
      style: {
        fontSize: 13,
        borderWidth: 1,
        borderRadius: 5,
        padding: 5,
        width: '100%',
        height: 35,
        borderColor: GlobalStyles.colorsBasic.gray400,
      },
    },
    validators: [Validator.required()],
  },
};

export const addGroupMemberByEmail: IForm<AddGroupMemberDTO> = {
  email: {
    label: 'Email',
    type: 'input',
    name: 'email',
    props: {
      autoCapitalize: 'none',
      style: inputStyle.primaryInput,
    },
    validators: [Validator.required(), Validator.email()],
  },
};

export const groupLabelMemberMap: {[key in PermissionTypes]: string} = {
  athletes: 'Athlete',
  coaches: 'Coach',
  coachColleagues: 'Coach colleague',
};
