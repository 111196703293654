import React from 'react';
import {Platform} from 'react-native';
import CheckBox from '../../../components/base/CheckBox';
import {Div} from '../../../components/base/Div';
import KeyboardAvoidingArea from '../../../components/base/KeyboardAvoidingArea';
import {LinkButton} from '../../../components/base/LinkButton';
import {Paragraph} from '../../../components/base/Paragraph';
import ScrollArea from '../../../components/base/ScrollArea';
import Form from '../../../components/shared/Form';
import {signupWithProvider} from '../../../constants/User';
import {useAuthNavigation} from '../../../navigation/auth-navigator/AuthNavigationContext';
import useAuthService from '../../../services/useAuthService';
import {GlobalStyles} from '../../../styles';
import {AppleAuth, ProviderSignupDTO} from '../../../types/IUser';
import AppleButton from '../shared/AppleButton';
import AuthHeader from '../shared/AuthHeader';
import AuthHeading from '../shared/AuthHeading';

export default function SignupWithAppleTemplate({
  auth,
}: SignupWithAppleTemplateProps) {
  const navigation = useAuthNavigation();
  const setTerms = (value: boolean) => {
    if (value) setTermsError(false);
    setTermsAccepted(value);
  };
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [termsError, setTermsError] = React.useState(false);
  const {signup, loading} = useAuthService().useSignupWithApple();
  const appleSignup = (form: ProviderSignupDTO) => {
    if (!termsAccepted) {
      setTermsError(true);
      return;
    }
    signup({
      ...auth,
      givenName: form.givenName,
      familyName: form.familyName,
    });
  };
  return (
    <ScrollArea style={{flex: 1}} keyboardShouldPersistTaps="handled">
      <AuthHeader />
      <KeyboardAvoidingArea
        style={{
          marginVertical: 10,
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <AuthHeading />
        <Div
          style={{
            width: '80%',
            maxWidth: 400,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Form
            style={{width: '100%'}}
            form={signupWithProvider}
            entityState={{
              givenName: auth.givenName,
              familyName: auth.familyName,
            }}
            onSubmit={appleSignup}
            submitControl={(submit) => (
              <>
                <CheckBox
                  style={{marginTop: 30}}
                  onValueChange={setTerms}
                  value={termsAccepted}
                  title={() => (
                    <Paragraph
                      style={{
                        fontSize: 12,
                        marginLeft: 5,
                        marginTop: 30,
                      }}
                    >
                      <Paragraph>
                        By checking this box you agree to our
                      </Paragraph>{' '}
                      <LinkButton url="https://docs.google.com/document/d/e/2PACX-1vRRl4mN9ow3Cajw50wwJPRvphZTW3i-kOt27K92s-uU8nCimtS1V8lNrbDEylN5jaqC8Ujo5_qzMVI2/pub">
                        terms and conditions
                      </LinkButton>{' '}
                      <Paragraph>and</Paragraph>{' '}
                      <LinkButton url="https://docs.google.com/document/d/e/2PACX-1vSU_xXDhsLQItiuc6S7YFx_lpmSXfXklG5f46fR5bpwKraRyN6-IdTAAat1iFMsIxaTd4-TifkJ6lHB/pub">
                        privacy policy
                      </LinkButton>
                    </Paragraph>
                  )}
                />
                {termsError ? (
                  <Paragraph
                    style={{
                      marginTop: 10,
                      color: GlobalStyles.colorsStatus.red,
                    }}
                  >
                    {
                      'You have to agree to terms and conditions before signing up.'
                    }
                  </Paragraph>
                ) : null}
                <AppleButton
                  style={{marginTop: 30}}
                  disabled={loading}
                  isLoading={loading}
                  loader={{size: 'small', color: 'black'}}
                  onPress={submit}
                  title="Create account with Apple"
                />
              </>
            )}
          />
          <Paragraph style={{marginTop: 20}}>
            <Paragraph>Have an account?</Paragraph>{' '}
            <Paragraph
              style={{
                textDecorationLine: 'underline',
                color: GlobalStyles.svexa.green500,
              }}
              onPress={() => navigation?.push('login')}
            >
              Sign in
            </Paragraph>
          </Paragraph>
        </Div>
      </KeyboardAvoidingArea>
    </ScrollArea>
  );
}

interface SignupWithAppleTemplateProps {
  auth: AppleAuth;
}
