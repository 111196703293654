import {Div} from '../../../components/base/Div';
import Divider from '../../../components/shared/Divider';
import usePermissionsService from '../../../services/usePermissionsService';
import AthleteInvitations from './AthleteInvitations';
import CoachColleagueInvitations from './CoachColleagueInvitations';

export default function Invitations() {
  usePermissionsService().useFetchPermissions(true);
  return (
    <Div>
      <AthleteInvitations />
      <Divider />
      <CoachColleagueInvitations />
    </Div>
  );
}
