import React from 'react';
import {StyleSheet} from 'react-native';
import Button from '../../../../components/base/Button';
import {Div} from '../../../../components/base/Div';
import Icon from '../../../../components/base/Icon';
import Image from '../../../../components/base/Image';
import {Paragraph} from '../../../../components/base/Paragraph';
import DraggableModal, {
  DraggableModalRef,
} from '../../../../components/shared/DraggableModal';
import assets from '../../../../constants/Assets';
import useConfigService from '../../../../services/useConfigService';
import usePermissionsService from '../../../../services/usePermissionsService';
import useRatingService from '../../../../services/useRatingService';
import {GlobalStyles} from '../../../../styles';
import {IGroupMemberWitRating} from '../../../../types/IGroup';
import {PermissionStatus} from '../../../../types/IPermissions';
import TeamAthleteRatingsOverview from './TeamAthleteRatingsOverview';

export default function TeamRatingsOverview({
  athletes,
}: TeamRatingsOverviewProps) {
  const {permissions, fetchAll} = usePermissionsService().useFetchPermissions();
  const [selectedAthlete, setSelectedAthlete] =
    React.useState<IGroupMemberWitRating | null>(null);
  const reference = React.useRef<DraggableModalRef>({
    open: () => 1,
    close: () => 0,
  });
  const {useTrendDirection} = useRatingService();
  const levels = useConfigService().useLevels();
  return (
    <>
      <DraggableModal reference={reference}>
        <TeamAthleteRatingsOverview
          athlete={selectedAthlete}
          permission={permissions.athletes.find(
            (p) => p.id === selectedAthlete?.id,
          )}
        />
      </DraggableModal>
      <Div style={style.wrapper}>
        {athletes.map((athlete, index) => {
          const onAthleteSelect = () => {
            fetchAll({});
            setSelectedAthlete(athlete);
            reference.current?.open();
          };
          const permission = permissions.athletes.find(
            (p) => p.id == athlete.id && p.status === PermissionStatus.ACCEPTED,
          );
          const trendArrow = useTrendDirection(
            athlete.rating?.readinessTrend5 || 0,
          );
          if (!permission) return null;
          return (
            <Button
              key={index}
              style={[style.athleteWrapper]}
              onPress={onAthleteSelect}
            >
              <Image
                height={50}
                width={50}
                source={
                  athlete.image
                    ? {
                        uri: athlete.image,
                      }
                    : assets.images.avatar
                }
                resizeMode={'cover'}
                style={{marginBottom: 8, borderRadius: 25}}
              />
              <Paragraph
                ellipsizeMode={'tail'}
                numberOfLines={2}
                style={style.name}
              >
                {athlete.firstname} {athlete.lastname}
              </Paragraph>
              <Div
                style={[
                  style.trendWrapper,
                  {
                    backgroundColor:
                      levels[!athlete.rating ? -1 : athlete.rating.level]
                        ?.color || GlobalStyles.colorsBasic.gray500,
                  },
                ]}
              >
                {athlete.rating?.hasValues ? (
                  <Icon
                    name={trendArrow}
                    color={GlobalStyles.colorsBasic.white500}
                    size={16}
                  />
                ) : null}
              </Div>
            </Button>
          );
        })}
      </Div>
    </>
  );
}

const style = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  athleteWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 1,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    backgroundColor: GlobalStyles.colorsBasic.white500,
    borderColor: GlobalStyles.colorsBasic.gray200,
    shadowColor: GlobalStyles.colorsBasic.black500,
    shadowOpacity: 0.2,
    borderRadius: 6,
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 5,
    height: 105,
    width: 100,
    justifyContent: 'space-around',
    position: 'relative',
  },
  name: {
    textAlign: 'center',
    fontSize: 12,
    color: GlobalStyles.colorsBasic.black500,
  },
  trendWrapper: {
    position: 'absolute',
    borderRadius: 24,
    borderWidth: 2,
    borderColor: GlobalStyles.colorsBasic.white500,
    height: 24,
    width: 24,
    alignItems: 'center',
    justifyContent: 'center',
    top: 28,
    right: 14,
  },
});

interface TeamRatingsOverviewProps {
  dateKey: string;
  athletes: IGroupMemberWitRating[];
}
