import {StyleSheet} from 'react-native';
import {SafeArea} from '../../../components/base/SafeArea';
import {DrawerNavigationHelpers} from '@react-navigation/drawer/lib/typescript/src/types';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import {MaterialIcons} from '@expo/vector-icons';
import Logo from '../../../components/shared/Logo';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import {Paragraph} from '../../../components/base/Paragraph';
import {LinkButton} from '../../../components/base/LinkButton';
import Divider from '../../../components/shared/Divider';
import useAuthService from '../../../services/useAuthService';
import {GlobalStyles} from '../../../styles';
import {useNavigation} from '@react-navigation/core';
import useUserSettingsService from '../../../services/useUserSettingsService';
import React from 'react';

export default function AthleteSideNav({navigation}: AthleteSideNavProps) {
  const {logout} = useAuthService().useLogout();
  const rootNavigation = useNavigation();
  const invitationsCount = useUserSettingsService().useCoachInvitationsCount();
  return (
    <SafeArea style={{backgroundColor: 'white', flex: 1}}>
      <Div style={styles.navContainer}>
        <Div style={{alignItems: 'flex-end', marginBottom: 10}}>
          <Icon
            name="x"
            color="black"
            size={18}
            onPress={() => navigation.toggleDrawer()}
          />
        </Div>
        <HeadingTwo>Readiness Advisor</HeadingTwo>
        <Divider />
        <Div style={styles.navItemsContainerOuter}>
          <Div style={styles.navItemContainer}>
            <MaterialIcons
              name="insert-chart-outlined"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            <Paragraph
              style={{
                fontSize: 18,
                color: GlobalStyles.colorsBasic.black500,
              }}
              onPress={() => navigation.navigate('overview')}
            >
              Ratings
            </Paragraph>
          </Div>
          <Div style={styles.navItemContainer}>
            <Icon
              name="users"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            {invitationsCount ? (
              <Div
                style={{
                  position: 'absolute',
                  borderRadius: 10,
                  width: 20,
                  height: 20,
                  backgroundColor: GlobalStyles.colorsRatings.red,
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 100,
                  bottom: 8,
                  zIndex: 99,
                }}
              >
                <Paragraph
                  style={{
                    color: GlobalStyles.colorsBasic.white500,
                    fontSize: 13,
                  }}
                >
                  {invitationsCount}
                </Paragraph>
              </Div>
            ) : null}
            <Paragraph
              style={styles.navLabel}
              onPress={() => navigation.navigate('coaches')}
            >
              Coaches
            </Paragraph>
          </Div>

          <Div style={styles.navItemContainer}>
            <MaterialIcons
              name="settings"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            <Paragraph
              style={styles.navLabel}
              onPress={() => navigation.navigate('settings')}
            >
              Settings
            </Paragraph>
          </Div>
          <Div style={styles.navItemContainer}>
            <MaterialIcons
              name="chat"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            <Paragraph
              style={styles.navLabel}
              onPress={() => navigation.navigate('feedback')}
            >
              Feedback
            </Paragraph>
          </Div>
          <Div style={styles.navItemContainer}>
            <MaterialIcons
              name="language"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            <Paragraph
              style={styles.navLabel}
              onPress={() => navigation.navigate('about')}
            >
              About
            </Paragraph>
          </Div>
          <Div style={styles.navItemContainer}>
            <MaterialIcons
              name="logout"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            <Paragraph style={styles.navLabel} onPress={logout}>
              Log out
            </Paragraph>
          </Div>
          <Divider />
          <Div style={styles.navItemContainer}>
            <MaterialIcons
              name="access-alarm"
              size={20}
              color={GlobalStyles.svexa.green500}
              style={styles.navItemIcon}
            />
            <Paragraph
              style={styles.navLabel}
              onPress={() =>
                rootNavigation.reset({index: 0, routes: [{name: 'coach'}]})
              }
            >
              Switch to coach view
            </Paragraph>
          </Div>
        </Div>
        <Div style={styles.navLinks}>
          <LinkButton url="https://docs.google.com/document/d/e/2PACX-1vRRl4mN9ow3Cajw50wwJPRvphZTW3i-kOt27K92s-uU8nCimtS1V8lNrbDEylN5jaqC8Ujo5_qzMVI2/pub">
            terms and conditions
          </LinkButton>
          <LinkButton url="https://docs.google.com/document/d/e/2PACX-1vSU_xXDhsLQItiuc6S7YFx_lpmSXfXklG5f46fR5bpwKraRyN6-IdTAAat1iFMsIxaTd4-TifkJ6lHB/pub">
            privacy policy
          </LinkButton>
        </Div>
        <Div style={styles.navLogo}>
          <Logo height={64} width={64} />
        </Div>
      </Div>
    </SafeArea>
  );
}

interface AthleteSideNavProps {
  navigation: DrawerNavigationHelpers;
}

const styles = StyleSheet.create({
  navContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navItemsContainerOuter: {
    flexGrow: 1,
  },
  navItemContainer: {
    flexDirection: 'row',
    marginVertical: 12,
  },
  navItemIcon: {
    marginRight: 8,
  },
  navLinks: {
    marginTop: 16,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  navLogo: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  navLabel: {
    fontSize: 18,
    color: GlobalStyles.colorsBasic.black500,
    maxWidth: 200,
    flexWrap: 'wrap',
  },
});
