import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import useAuthService from '../../../services/useAuthService';
import {GlobalStyles} from '../../../styles';
import {RootStackScreenProps} from '../../../types/Navigation';

export default function NotFoundScreen({
  navigation,
}: RootStackScreenProps<'not-found'>) {
  const user = useAuthService().useUser();
  const navigateHome = () => {
    navigation.reset({
      index: 0,
      routes: [{name: user?.defaultView || 'athlete'}],
    });
  };
  return (
    <Div style={{flex: 1, backgroundColor: GlobalStyles.colorsBasic.white500}}>
      <Div style={styles.notFoundPage}>
        <Div>
          <Paragraph style={styles.oops}>Oops!</Paragraph>
        </Div>
        <HeadingTwo>404 - PAGE NOT FOUND</HeadingTwo>
        <Paragraph style={styles.paragraph}>
          The page you are looking for might have been removed or had its name
          changed or is temporarily unavailable.
        </Paragraph>
        <CustomButton
          type="primary"
          title="Back to home"
          titleStyle={{color: GlobalStyles.colorsBasic.white500}}
          onPress={navigateHome}
        />
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  notFoundPage: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: 16,
    paddingTop: 48,
  },
  oops: {
    fontSize: 128,
    fontWeight: '900',
    color: GlobalStyles.svexa.green500,
    marginBottom: 16,
  },
  paragraph: {
    maxWidth: 600,
    fontSize: 16,
    marginBottom: 16,
  },
});
