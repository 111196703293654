import {useCallback, useEffect} from 'react';
import {Div} from '../../../components/base/Div';
import Loader from '../../../components/base/Loader';
import {AuthStackScreenProps} from '../../../types/Navigation';
import {useRequest} from '../../../providers/useRequestProvider';
import AuthGateway from '../../../gateways/AuthGateway';
import {useNavigation} from '@react-navigation/native';
import {useAppDispatch} from '../../../store';
import {Views} from '../../../types/IUser';
import useAlertProvider from '../../../providers/useAlertProvider';
import {authActions} from '../../../store/reducers/AuthReducer';

export default function TokenSignInScreen({
  route,
}: AuthStackScreenProps<'token-signin'>) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const {danger} = useAlertProvider();
  const navigateToHome = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [{name: Views.ATHLETE}],
    });
  }, [navigation]);

  const {execute} = useRequest(AuthGateway.getUserFromRefreshToken, {
    onCompleted: (data) => {
      if (!data) {
        navigateToHome();
        return;
      }
      data.isTemporary = false;
      const view =
        data.defaultView == Views.ATHLETE || data.defaultView == Views.COACH
          ? data.defaultView
          : 'athlete';
      navigation.reset({
        index: 0,
        routes: [{name: view}],
      });
      dispatch(authActions.setUser(data));
    },
    onError: (err) => {
      console.log(err);
      danger(err.message || 'Something went wrong');
      navigateToHome();
    },
  });
  useEffect(() => {
    if (!route.params?.token) {
      navigateToHome();
      return;
    }
    execute({token: route.params.token});
  }, [route.params?.token]);

  return (
    <Div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <Loader size={'large'} />
    </Div>
  );
}
