import React from 'react';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
import {RadioButton} from 'react-native-paper';
import {GlobalStyles} from '../../styles';
import {Div} from '../base/Div';
import {Paragraph} from '../base/Paragraph';

interface RadioButtonProps {
  value: string;
  hintText?: string;
  label: string | (() => React.ReactElement);
  checked: string;
  setChecked: (value: string) => void;
  style?: StyleProp<ViewStyle>;
}

const CustomRadioButton = ({
  value,
  hintText,
  label,
  checked,
  setChecked,
  style,
}: RadioButtonProps) => {
  const getLabel = (label: string | (() => React.ReactElement)) => {
    if (typeof label == 'string') {
      return (
        <Div style={{flex: 1}}>
          <Paragraph>{label}</Paragraph>
          {hintText ? (
            <Paragraph style={styles.hintText}>{hintText}</Paragraph>
          ) : null}
        </Div>
      );
    }
    return label();
  };
  return (
    <Div style={[styles.container, style]}>
      <RadioButton.Android
        color={GlobalStyles.svexa.green400}
        value={value}
        status={checked === value ? 'checked' : 'unchecked'}
        onPress={() => {
          setChecked(value);
        }}
      />
      {getLabel(label)}
    </Div>
  );
};

export default CustomRadioButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginVertical: 8,
  },
  hintText: {
    fontSize: 12,
  },
});
