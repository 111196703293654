import * as React from 'react';
import {Div} from '../../../components/base/Div';
import Form from '../../../components/shared/Form';
import useAuthService from '../../../services/useAuthService';
import {GlobalStyles} from '../../../styles';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {LinkButton} from '../../../components/base/LinkButton';
import CustomButton from '../../../components/shared/CustomButton';

export default function LoginWithSvexa({
  shouldPersist,
  forgotPasswordLink,
}: LoginWithSvexaProps) {
  const {login, loading, form} = useAuthService().useLoginWithSvexa(
    !!shouldPersist,
  );
  return (
    <Div
      style={{
        width: '100%',
        borderWidth: 1,
        borderColor: GlobalStyles.colorsBasic.gray500,
        borderRadius: 5,
        padding: 15,
        marginVertical: 15,
      }}
    >
      <HeadingThree>Sign in with email</HeadingThree>
      <Form
        form={form}
        style={{marginTop: 10}}
        onSubmit={login}
        submitControl={(submit) => (
          <>
            <Div
              style={{
                paddingBottom: 10,
              }}
            >
              <LinkButton onPress={forgotPasswordLink}>
                Forgot password?
              </LinkButton>
            </Div>
            <CustomButton
              type="primary"
              title="Sign in"
              titleStyle={{color: GlobalStyles.colorsBasic.white500}}
              isLoading={loading}
              loader={{size: 'small', color: 'white'}}
              onPress={submit}
            />
          </>
        )}
      />
    </Div>
  );
}

interface LoginWithSvexaProps {
  shouldPersist?: boolean;
  forgotPasswordLink: () => void;
}
