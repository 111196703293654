import {GlobalStyles} from '../../styles';
import {Div} from '../base/Div';
import {HeadingThree} from '../base/HeadingThree';
import Modal from '../base/Modal';
import CustomButton from './CustomButton';

export default function ConfirmationModal({
  visible,
  close,
  onConfirm,
  title,
  confirmButtonTitle,
  confirmationInProgress,
}: ConfirmationModalProps) {
  return (
    <Modal visible={visible} onBackgroundPress={close}>
      <Div
        style={{
          backgroundColor: 'white',
          width: '80%',
          maxWidth: 400,
          paddingHorizontal: 30,
          paddingVertical: 20,
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
          borderColor: 'lightgrey',
          borderRadius: 10,
        }}
      >
        <HeadingThree
          style={{paddingTop: 10, paddingBottom: 30, textAlign: 'center'}}
        >
          {title || `Are you sure you want to delete?`}
        </HeadingThree>
        <CustomButton
          type="danger"
          title={confirmButtonTitle || 'Delete'}
          style={{width: '100%', marginVertical: 10}}
          onPress={onConfirm}
          isLoading={confirmationInProgress}
          disabled={confirmationInProgress}
          loader={{size: 'small', color: 'black'}}
          titleStyle={{color: GlobalStyles.colorsBasic.white500}}
        />
        <CustomButton
          type="secondary"
          title="Cancel"
          style={{width: '100%', marginVertical: 10}}
          onPress={close}
        />
      </Div>
    </Modal>
  );
}

interface ConfirmationModalProps {
  visible: boolean;
  close: () => any;
  title?: string;
  onConfirm: () => any;
  confirmButtonTitle?: string;
  confirmationInProgress?: boolean;
}
