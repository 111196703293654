export interface IQuestion {
  text: string;
  longText: string;
  fullDescription: string;
  minDescription: string;
  maxDescription: string;
  minValue: number;
  maxValue: number;
  tag: string;
  score: IQuestionScore;
  value: number | boolean | string;
  yesLabel?: string;
  noLabel?: string;
  questionType: QuestionTypes;
  gender: GenderType;
  targetKey: string;
  targetObject: string;
}

export const MenstrualTag = 'menstrualCycle';

export enum QuestionTypes {
  rating = 'rating',
  yesNo = 'yesNo',
}

export enum GenderType {
  MALE = 'male',
  FEMALE = 'female',
  ALL = 'all',
}
export interface IQuestionScore {
  [key: number]: {
    color: string;
    description: string;
  };
}

export interface ComposedQuestionsDTO {
  version: number;
  language: string;
}
