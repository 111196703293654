import environment from '../../config/environment';
import {
  platformVersionHistory,
  platformVersionHistoryDevelop,
  platformVersionHistoryStaging,
} from '../constants/Config';
import {defaultLanguage} from '../constants/Language';
import {store} from '../store';
import {
  IConfigVersions,
  IAlgorithm,
  SupportedPlatforms,
} from '../types/IConfig';
import {IPlatformVersion} from '../types/IHistoryVersion';
import {ILevels} from '../types/ILevel';
import {IQuestion} from '../types/IQuestion';
import IRatingStrings from '../types/IRatingStrings';
import BaseApi from './BaseGateway';

const ConfigGateway = {
  getVersions: async (): Promise<IConfigVersions> => {
    return await BaseApi.get('/config/versions', BaseApi.getAuthHeaders());
  },

  getAlgorithm: async ({version}: {version: number}): Promise<IAlgorithm> => {
    return await BaseApi.get(
      `/config/algorithm?version=${version}&token=${BaseApi.getAccessToken()}`,
      BaseApi.getAuthHeaders(),
    );
  },

  getRatingStrings: async ({
    version,
    language,
  }: {
    version: number;
    language: string;
  }): Promise<IRatingStrings> => {
    return await BaseApi.get(
      `/config/ratingstrings?stringTypes=athleteAdvisor&lang=${language}&version=${version}&token=${BaseApi.getAccessToken()}`,
      BaseApi.getAuthHeaders(),
    );
  },

  getQuestions: async ({
    dateKey,
  }: {
    dateKey: string;
  }): Promise<{questions: IQuestion[]; levels: ILevels; version: string}> => {
    const language = store.getState().config.language || defaultLanguage;
    const user = store.getState().auth.user;
    let genderParam = '';
    if (user?.sex) {
      genderParam = `&gender=${user.sex}`;
    }
    return await BaseApi.get(
      `/config/v2/questions?token=${BaseApi.getAccessToken()}&date_key=${dateKey}&lang=${language}${genderParam}`,
      BaseApi.getAuthHeaders(),
      'json',
      'v2',
    );
  },

  getLevels: async ({
    dateKey,
  }: {
    dateKey: string;
  }): Promise<{levels: ILevels}> => {
    const language = store.getState().config.language || defaultLanguage;
    const user = store.getState().auth.user;
    let genderParam = '';
    if (user?.sex) {
      genderParam = `&gender=${user.sex}`;
    }
    return await BaseApi.get(
      `/config/v2/questions?token=${BaseApi.getAccessToken()}&date_key=${dateKey}&lang=${language}${genderParam}`,
      BaseApi.getAuthHeaders(),
      'json',
      'v2',
    );
  },

  getPlatformVersionHistory: async ({
    platform,
  }: {
    platform: SupportedPlatforms;
  }): Promise<IPlatformVersion[]> => {
    // NOTE(np): hardcoded to read from config for now
    let version: any = {};
    switch (environment.env) {
      case 'production':
        version = platformVersionHistory;
        break;
      case 'staging':
        version = platformVersionHistoryStaging;
      default:
        version = platformVersionHistoryDevelop;
        break;
    }

    return version[platform];
    /* return await BaseApi.get(
      `/config/${platform}versions?token=${BaseApi.getAccessToken()}`,
    ); */
  },
};

export default ConfigGateway;
