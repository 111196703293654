import moment, {Moment} from 'moment';

export default function useDateTimeProvider() {
  function formatDateKey(date?: Date | string): string {
    return moment(date || new Date()).format('YYYYMMDD');
  }

  function formatDate(date: Date, format?: string): string {
    return moment(date).format(format || 'DD/MM/YYYY');
  }

  function dateFormatFromDateKey(dateKey: string, format?: string): string {
    return moment(dateKey, 'YYYYMMDD').format(format);
  }

  function dateFromDateKey(dateKey: string): Date {
    return moment(dateKey, 'YYYYMMDD').toDate();
  }

  function getMomentRange(period: number): {from: Moment; to: Moment} {
    return {
      from: moment().startOf('day').subtract(period, 'days'),
      to: moment().startOf('day'),
    };
  }

  function getDateKeysForMonth(date: Date = new Date()): string[] {
    const dateKeys: string[] = [];
    const startOfMonth = moment(date).startOf('month').startOf('day');
    const endOfMonth = moment(date).endOf('month').startOf('day');
    while (!startOfMonth.isSame(endOfMonth)) {
      dateKeys.push(startOfMonth.format('YYYYMMDD'));
      startOfMonth.add(1, 'day');
    }
    return dateKeys;
  }

  function getDateKeysForTimePeriod(from: Moment, days: number): string[] {
    const start = from.clone();
    const dateKeys: string[] = [];
    for (let i = 0; i <= days; i++) {
      dateKeys.push(start.add(i === 0 ? 0 : 1, 'days').format('YYYYMMDD'));
    }
    return dateKeys;
  }

  function toISOWithOffset(date?: Date): string {
    date = date || new Date();

    const yyyy = zeroPad(date.getFullYear(), 4);
    const mm = zeroPad(date.getMonth() + 1);
    const dd = zeroPad(date.getDate());
    const hh = zeroPad(date.getHours());
    const mi = zeroPad(date.getMinutes());
    const ss = zeroPad(date.getSeconds());
    const offset = offsetToStr(date.getTimezoneOffset());
    return yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + mi + ':' + ss + offset;
  }

  function zeroPad(int: number, length = 2) {
    return ('0000' + int).slice(-length);
  }

  function offsetToStr(offset: number) {
    if (offset === 0) {
      return 'Z';
    }
    const offsetInHours = offset / 60;
    const hours = Math.floor(offsetInHours);
    const minutes = 60 * (offsetInHours - hours);
    // offset is GMT relative to your time, we want the other direction
    const sign = hours >= 0 ? '-' : '+';
    return sign + zeroPad(Math.abs(hours)) + ':' + zeroPad(minutes);
  }

  return {
    formatDateKey,
    formatDate,
    dateFormatFromDateKey,
    dateFromDateKey,
    getDateKeysForMonth,
    getDateKeysForTimePeriod,
    toISOWithOffset,
    getMomentRange,
  };
}
