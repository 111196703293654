import React from 'react';
import {StyleSheet} from 'react-native';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import Input from '../../../components/base/Input';
import KeyboardAvoidingArea from '../../../components/base/KeyboardAvoidingArea';
import Modal from '../../../components/base/Modal';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import {useAddRatingsContext} from './AddRatingsContext';

export default function Note({value, onNoteSubmited}: NoteProps) {
  const [visible, setVisible] = React.useState(false);
  const [note, setNote] = React.useState(value);
  const {setHasUnsavedChanges} = useAddRatingsContext();
  const openModal = () => {
    setNote(value);
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };
  const saveNote = () => {
    onNoteSubmited(note);
    setHasUnsavedChanges(true);
    setVisible(false);
  };
  return (
    <>
      <Icon
        name="message-square"
        style={{marginHorizontal: 8}}
        size={22}
        onPress={openModal}
      />
      <Modal visible={visible} onBackgroundPress={closeModal}>
        <KeyboardAvoidingArea
          style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}
        >
          <Button
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            onPress={closeModal}
          />
          <Div
            style={{
              borderWidth: 1,
              borderRadius: 6,
              borderColor: GlobalStyles.svexa.green400,
              backgroundColor: GlobalStyles.colorsBasic.white500,
              alignSelf: 'center',
              minWidth: 300,
              width: '80%',
              padding: 10,
              maxWidth: 400,
            }}
            onStartShouldSetResponder={() => true}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            <Icon
              style={{marginLeft: 'auto'}}
              name="x"
              color={GlobalStyles.svexa.green400}
              onPress={closeModal}
              size={25}
            />
            <Paragraph style={{fontSize: 14, marginBottom: 10}}>
              Notes (Optional)
            </Paragraph>
            <Input
              textAlignVertical={'top'}
              numberOfLines={5}
              multiline={true}
              scrollEnabled={false}
              style={styles.textArea}
              value={note}
              onTextChange={setNote}
            />
            <CustomButton
              type="primary"
              title="Submit"
              style={{marginVertical: 20}}
              titleStyle={{color: GlobalStyles.colorsBasic.white500}}
              loader={{size: 'small', color: 'white'}}
              onPress={saveNote}
            />
          </Div>
        </KeyboardAvoidingArea>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  textArea: {
    fontSize: 13,
    borderWidth: 1,
    borderColor: GlobalStyles.colorsBasic.gray500,
    borderRadius: 5,
    padding: 5,
    height: 100,
    width: '100%',
  },
});

interface NoteProps {
  value: string;
  onNoteSubmited: (note: string) => any;
}
