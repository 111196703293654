import BaseApi from './BaseGateway';
import {
  AccountEmailDTO,
  IAccountEmail,
  IPreferences,
  IProfile,
  IUser,
} from '../types/IUser';
import {IBadgeCount, INotificationSettings} from '../types/INotification';
import {IResponse} from '../types/Request';
import {store} from '../store';
import {
  DataSourceProvider,
  DataSourceProviderConnected,
  DataSourceProviderResponse,
  DataSourceProviders,
  IDataProviderSettings,
  IDataSourceStatus,
} from '../types/IDataSource';
import {FormDataImage} from '../types/Image';
import environment from '../../config/environment';

const SettingsGateway = {
  getUserSettings: async (): Promise<IUser> => {
    const path = `/user/${BaseApi.getEncodedUserId()}?token=${BaseApi.getAccessToken()}`;
    return await BaseApi.get(path);
  },

  updateUserSettings: async (
    payload: Partial<IProfile>,
  ): Promise<IProfile | null> => {
    const path = `/user/${BaseApi.getEncodedUserId()}?token=${BaseApi.getAccessToken()}`;

    const result: IResponse = await BaseApi.put(path, payload);
    if (!result) {
      return null;
    }
    return await SettingsGateway.getUserSettings();
  },

  getProfilePhoto: async (): Promise<string | null> => {
    const image: string = await BaseApi.get(
      `/user/${BaseApi.getEncodedUserId()}/profilephoto?token=${BaseApi.getAccessToken()}`,
      {},
      'plain',
    );
    if (!image) {
      return null;
    }
    return `data:${image}`;
  },

  uploadProfilePhoto: async (
    formDataImg: FormDataImage,
  ): Promise<string | null> => {
    const formData = new FormData();
    formData.append('file', formDataImg as any);
    const response: IResponse = await BaseApi.upload(
      `/fileupload/${BaseApi.getEncodedUserId()}/profilephoto?token=${BaseApi.getAccessToken()}`,
      formData,
    );
    if (!response) {
      return null;
    }
    return await SettingsGateway.getProfilePhoto();
  },

  getReminderSettings: async (): Promise<INotificationSettings> => {
    return await BaseApi.get(
      `/notifications/${BaseApi.getEncodedUserId()}/reminderconfiguration?token=${BaseApi.getAccessToken()}`,
    );
  },

  updateReminderSettings: async (
    payload: INotificationSettings,
  ): Promise<INotificationSettings | null> => {
    const response: IResponse = await BaseApi.put(
      `/notifications/${BaseApi.getEncodedUserId()}/reminderconfiguration?token=${BaseApi.getAccessToken()}`,
      payload,
    );
    if (!response) {
      return null;
    }
    return await SettingsGateway.getReminderSettings();
  },

  getNotificationBadgeCount: async (): Promise<IBadgeCount | null> => {
    const token = BaseApi.getAccessToken();
    if (!token) return null;
    return await BaseApi.get(
      `/notifications/${BaseApi.getEncodedUserId()}/badgecount?token=${token}`,
    );
  },

  getUserEmails: async (): Promise<IAccountEmail[]> => {
    return await BaseApi.get(
      `/account/email?token=${BaseApi.getAccessToken()}`,
    );
  },

  updateUserEmail: async (
    payload: AccountEmailDTO,
  ): Promise<IAccountEmail[] | null> => {
    const response = await BaseApi.post(
      `/account/email?token=${BaseApi.getAccessToken()}`,
      payload,
    );
    if (!response) {
      return null;
    }
    return await SettingsGateway.getUserEmails();
  },

  updatePreferences: async (
    preferences: IPreferences,
  ): Promise<{
    accounts: IAccountEmail[] | null;
    profile: IProfile | null;
    notificationSettings: INotificationSettings | null;
  }> => {
    const user = store.getState().auth.user!;
    const language = store.getState().config.language;
    const notificationSettings = await SettingsGateway.updateReminderSettings(
      preferences.notificationSettings,
    );
    const profile = await SettingsGateway.updateUserSettings({
      defaultView: preferences.defaultView,
      firstDayOfWeek: preferences.firstDayOfWeek,
      preferredLanguage: language,
    });
    const accounts = await SettingsGateway.updateUserEmail({
      action: 'modify',
      email: user.email || '',
      primary: !!user.isEmailPrimary,
      discoverable: !!preferences.isEmailDiscoverable,
    });
    return {
      accounts,
      profile,
      notificationSettings,
    };
  },

  changePassword: async (payload: {
    passwordOld?: string;
    passwordNew: string;
  }): Promise<IResponse> => {
    payload.passwordOld = payload.passwordNew;
    return await BaseApi.post(
      `/account/changepassword?token=${BaseApi.getAccessToken()}`,
      payload,
    );
  },

  sendFeedback: async (payload: {
    subject: string;
    text: string;
  }): Promise<IResponse> => {
    return await BaseApi.post(
      `/messages/${BaseApi.getEncodedUserId()}/feedback?token=${BaseApi.getAccessToken()}`,
      payload,
    );
  },

  getDataSourceStatus: async (): Promise<DataSourceProvider[]> => {
    const integrations: DataSourceProviderResponse = await BaseApi.get(
      `/datasources/available?token=${BaseApi.getAccessToken()}&client=${
        environment.clientTag
      }`,
    );
    const connected: DataSourceProviderConnected = await BaseApi.get(
      `/datasources/connected?token=${BaseApi.getAccessToken()}&client=${
        environment.clientTag
      }`,
    );

    const connections =
      connected?.integrations?.map((c) => ({
        ...c,
        isConnected: true,
      })) || [];
    integrations?.availableIntegrations?.forEach((ia) => {
      if (
        !connections.find(
          (c) => c.provider === ia.provider && c.integrator === ia.integrator,
        )
      )
        connections.push(ia);
    });
    // dirty hack until We figure out what to do with connected devices
    return connections.filter((cp) =>
      [DataSourceProviders.GARMIN, DataSourceProviders.OURA].includes(
        cp.provider.toLowerCase() as any,
      ),
    );
  },

  getDataSourceSettings: async (): Promise<IDataProviderSettings> => {
    return await BaseApi.get(
      `/datasources/settings?application=athleteAdvisor&token=${BaseApi.getAccessToken()}`,
    );
  },

  getDataSourceIntegrationUrl: async (
    provider: DataSourceProviders,
  ): Promise<{
    status: string;
    authRedirectUrl: string;
    code: string;
    message: string;
  }> => {
    let redirectUri = environment.web.url;
    if (provider.toLowerCase() == DataSourceProviders.GARMIN) {
      redirectUri += '/authorize-garmin';
    } else if (provider.toLowerCase() == DataSourceProviders.OURA) {
      redirectUri += '/authorize-oura';
    }
    return await BaseApi.get(
      `/datasources/authenticate?token=${BaseApi.getAccessToken()}&provider=${provider.toUpperCase()}&integrator=SVEXA&client=${
        environment.clientTag
      }&successRedirectUrl=${redirectUri}?status=success&errorRedirectUrl=${redirectUri}?status=failure`,
    );
  },

  getDataSourceIntegrationUrls: async (): Promise<
    Partial<{
      [value in DataSourceProviders]: string;
    }>
  > => {
    const urls = await Promise.all([
      SettingsGateway.getDataSourceIntegrationUrl(DataSourceProviders.GARMIN),
      SettingsGateway.getDataSourceIntegrationUrl(DataSourceProviders.OURA),
    ]);

    const mappURls: any = {};
    if (urls[0]?.authRedirectUrl) {
      mappURls[DataSourceProviders.GARMIN] = urls[0]?.authRedirectUrl;
    }

    if (urls[1]?.authRedirectUrl) {
      mappURls[DataSourceProviders.OURA] = urls[1]?.authRedirectUrl;
    }

    return mappURls;
  },

  disconnectDataSourceProvider: async (
    provider: DataSourceProviders | string,
  ): Promise<DataSourceProvider[]> => {
    await BaseApi.delete(
      `/datasources/disconnect?token=${BaseApi.getAccessToken()}&provider=${provider.toUpperCase()}&client=${
        environment.clientTag
      }&integrator=SVEXA`,
    );
    return await SettingsGateway.getDataSourceStatus();
  },
};

export default SettingsGateway;
