import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import usePermissionsService from '../../../services/usePermissionsService';
import {renderPermissionItem} from '../../shared/PermissionItem';

export default function Invitations() {
  const {useCoachesRequests, useFetchPermissions} = usePermissionsService();
  useFetchPermissions(true);
  const coachesRequests = useCoachesRequests();
  return (
    <Div style={{marginBottom: 15}}>
      <HeadingThree>Invitations to share your data</HeadingThree>
      {coachesRequests.length ? (
        <>
          <Paragraph style={{marginBottom: 10}}>
            These coaches have asked to see your daily ratings.
          </Paragraph>
          {coachesRequests.map((coach, index) =>
            renderPermissionItem({
              item: coach,
              index,
              type: coach.id ? 'coach' : 'coachEmail',
            }),
          )}
        </>
      ) : (
        <Paragraph>No invitations.</Paragraph>
      )}
    </Div>
  );
}
