import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import usePermissionsService from '../../../services/usePermissionsService';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {renderPermissionItem} from '../../shared/PermissionItem';

export default function AthleteInvitations() {
  const invitations = usePermissionsService().useAthleteInvitations();
  const {translate} = useLocalizationProvider();
  return (
    <Div>
      <HeadingThree>
        {translate('Athletes who invite you to view their data.')}
      </HeadingThree>
      {invitations.length ? (
        invitations.map((item, index) =>
          renderPermissionItem({
            item,
            index,
            type: item.id ? 'athlete' : 'athleteEmail',
          }),
        )
      ) : (
        <Paragraph>No invitations.</Paragraph>
      )}
    </Div>
  );
}
