import React from 'react';

interface IAddRatingsContext {
  hasUnSavedChanges: boolean;
  setHasUnsavedChanges: (hasUnSavedChanges: boolean) => any;
}

export const AddRatingsContext = React.createContext<IAddRatingsContext>({
  hasUnSavedChanges: false,
  setHasUnsavedChanges: (hasUnSavedChanges: boolean) => hasUnSavedChanges,
});

export const useAddRatingsContext = () => React.useContext(AddRatingsContext);
