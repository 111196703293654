import {Moment} from 'moment';
import React from 'react';
import {IconName} from '../components/base/Icon';
import RatingsGateway from '../gateways/RatingsGateway';
import {useAthleteNavigation} from '../navigation/athlete-navigator/AthleteNavigationContext';
import useDateTimeProvider from '../providers/useDateTimeProvider';
import useQuestionProvider from '../providers/useQuestionProvider';
import {useRequest} from '../providers/useRequestProvider';
import {useAppDispatch, useAppSelector} from '../store';
import {ratingsActions} from '../store/reducers/RatingsReducer';
import {GetUserRatingsDTO, IRating, SetDayRatingsDTO} from '../types/IRatings';
import useAlertProvider from '../providers/useAlertProvider';

export default function useRatingService() {
  const dispatch = useAppDispatch();
  const {toISOWithOffset, dateFromDateKey} = useDateTimeProvider();
  const {useRatingsQuestions} = useQuestionProvider();
  const {danger} = useAlertProvider();

  function useRating(dateKey: string) {
    return useAppSelector(({ratings}) => ratings.ratings[dateKey]);
  }

  function useAutoFetchUserRatings(payload: GetUserRatingsDTO) {
    function onSuccess(ratings: IRating[] | null) {
      if (!ratings) {
        return;
      }
      const ratingsObj: {[key: string]: IRating} = {};
      ratings.forEach((r) => {
        if (!r) {
          return;
        }
        ratingsObj[r.dateKey] = r;
      });
      dispatch(ratingsActions.setRatings(ratingsObj));
    }

    const {execute, loading} = useRequest(RatingsGateway.getUserRatings, {
      onCompleted: onSuccess,
      onError: (error) => {
        danger(error?.message || 'Something went wrong.');
      },
    });

    React.useEffect(() => {
      execute(payload);
    }, []);

    return {fetch: execute, loading};
  }

  function useFetchUserRatings() {
    function onSuccess(ratings: IRating[] | null) {
      if (!ratings) {
        return;
      }
      const ratingsObj: {[key: string]: IRating} = {};
      ratings.forEach((r) => {
        if (!r) {
          return;
        }
        ratingsObj[r.dateKey] = r;
      });
      dispatch(ratingsActions.setRatings(ratingsObj));
    }

    const {execute, loading} = useRequest(RatingsGateway.getUserRatings, {
      onCompleted: onSuccess,
    });

    return {fetch: execute, loading};
  }

  function useSetDayRatings(dateKey: string) {
    useAutoFetchUserRatings({dateKey, span: '1'});
    const rating = useRating(dateKey);
    const {questions, setQuestions} = useRatingsQuestions(rating);
    const navigation = useAthleteNavigation();
    const {execute, loading} = useRequest(RatingsGateway.setRatingsForDay, {
      onCompleted: (data) => {
        if (!data) {
          return;
        }
        dispatch(
          ratingsActions.setRating({dateKey: data.dateKey, rating: data}),
        );
        navigation?.goBack();
      },
    });

    function setDayRatings(payload: SetDayRatingsDTO) {
      const date = toISOWithOffset(dateFromDateKey(payload.dateKey));
      execute({...payload, date});
    }

    return {setDayRatings, loading, rating, questions, setQuestions};
  }

  function useReversePeriodRatings(days: number, to: Moment): IRating[] {
    return useAppSelector(({ratings}) => {
      const userRatings: IRating[] = [];
      for (let i = 0; i <= days; i++) {
        const r =
          ratings.ratings[
            to.subtract(i === 0 ? 0 : 1, 'days').format('YYYYMMDD')
          ];
        if (!!r) {
          userRatings.push(r);
        }
      }
      return userRatings;
    });
  }

  function useTrendDirection(trend: number): IconName {
    switch (trend) {
      case 1:
        return 'arrow-up';
      case 2:
        return 'arrow-up-right';
      case 3:
        return 'arrow-right';
      case 4:
        return 'arrow-down-right';
      case 5:
        return 'arrow-down';
      default:
        return 'arrow-right';
    }
  }

  return {
    useAutoFetchUserRatings,
    useRating,
    useTrendDirection,
    useSetDayRatings,
    useFetchUserRatings,
    useReversePeriodRatings,
  };
}
