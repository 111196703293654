import * as Web from 'react-native-web';
import moment from 'moment';
import {StyleProp, ViewStyle} from 'react-native';

export default function DateTimPicker({
  value,
  minDate,
  maxDate,
  onChange,
  mode,
  style,
  minuteInterval,
}: DateTimPickerProps) {
  const format = mode == 'time' ? 'HH:mm' : 'YYYY-MM-DD';
  return Web.unstable_createElement('input', {
    type: mode || 'date',
    value: moment(value).format(format),
    onChange: (event) => {
      if (!event.target.value) {
        return;
      }
      const newValue: Date =
        mode == 'time'
          ? moment(event.target.value, 'HH:mm').toDate()
          : new Date(event.target.value);
      if (onChange) {
        onChange(newValue);
      }
    },
    max: moment(maxDate).format(format),
    min: moment(minDate).format(format),
    pattern: /\d{4}-\d{2}-\d{2}/,
    style: [
      {
        padding: 5,
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 5,
        marginVertical: 5,
        fontSize: 14,
      },
      style ? style : {},
    ],
    minuteinterval: minuteInterval,
  });
}

export interface DateTimPickerProps {
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (date: Date) => any;
  dateDisplayFormat?: string;
  mode?: 'time' | 'date' | 'datetime' | 'countdown';
  style?: StyleProp<ViewStyle>;
  minuteInterval?: 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30;
}
