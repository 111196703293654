import React from 'react';
import {StyleSheet} from 'react-native';
import {Div} from '../../../../components/base/Div';
import Loader from '../../../../components/base/Loader';
import {Paragraph} from '../../../../components/base/Paragraph';
import ConfirmationModal from '../../../../components/shared/ConfirmationModal';
import useLocalizationProvider from '../../../../providers/useLocalizationProvider';
import useAuthService from '../../../../services/useAuthService';
import useCoachService from '../../../../services/useCoachService';
import {GlobalStyles} from '../../../../styles';
import {IGroupMember, RemoveMemberFromGroupDTO} from '../../../../types/IGroup';
import {useCurrentGroup} from '../GroupContext';

export default function TeamCoachCard({coach}: CoachCardProps) {
  const user = useAuthService().useUser();
  const group = useCurrentGroup();
  const {remove, removing} = useCoachService().useRemoveGroupMember();
  const {translate} = useLocalizationProvider();
  const onRemove = () => {
    const payload: RemoveMemberFromGroupDTO = {
      groupId: group?.id || '',
      type: 'admin',
      hash: group?.hash || '',
      userId: coach.id,
    };
    remove(payload);
    close();
  };
  const [visible, setVisible] = React.useState(false);
  const close = () => setVisible(false);
  const open = () => setVisible(true);
  return (
    <>
      <Div style={styles.shadowBox}>
        {/* NO API FOR IMAGE */}
        {/* <Image
        height={40}
        width={40}
        style={{borderRadius: 20}}
        resizeMode={'stretch'}
        source={coach.image ? {uri: coach.image} : assets.images.avatar}
      /> */}
        <Div style={{flex: 1, marginLeft: 8}}>
          <Paragraph numberOfLines={1} ellipsizeMode={'tail'}>
            {user?.id === coach.id
              ? 'You'
              : `${coach.firstname} ${coach.lastname}`}
          </Paragraph>
          <Paragraph
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{fontSize: 11, color: GlobalStyles.colorsBasic.gray700}}
          >
            {coach.email}
          </Paragraph>
        </Div>
        {user?.id === coach.id ? null : removing ? (
          <Loader size={'small'} color={GlobalStyles.colorsStatus.red} />
        ) : (
          <Paragraph
            onPress={open}
            style={{color: GlobalStyles.colorsStatus.red, marginLeft: 10}}
          >
            Remove
          </Paragraph>
        )}
      </Div>
      <ConfirmationModal
        close={close}
        visible={visible}
        onConfirm={onRemove}
        title={`${translate('Are you sure you want to remove coach')} ${
          coach.firstname
        } ${coach.lastname} ${translate('from')} ${group?.name}`}
        confirmButtonTitle="Remove"
        confirmationInProgress={removing}
      />
    </>
  );
}

interface CoachCardProps {
  coach: IGroupMember;
}

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    shadowColor: GlobalStyles.colorsBasic.black500,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    padding: 10,
    marginVertical: 5,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
