import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {GlobalStyles} from '../../../styles';
import {IQuestion} from '../../../types/IQuestion';

export default function AthleteQuestionYesNoAnswer({
  question,
  index,
}: YesNoAnswerProps) {
  const {translate} = useLocalizationProvider();
  return (
    <Div style={styles.wrapper} key={index.toString()}>
      <Div style={{flex: 1}}>
        <Div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 12,
          }}
        >
          <Paragraph style={{fontSize: 15}} translate={false}>
            {question.text.replace('&', translate('and'))}
            {question.text.endsWith('?') ? '' : ':'}
          </Paragraph>
        </Div>
        <Div
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
          ]}
        >
          <Paragraph style={{fontWeight: 'bold', fontSize: 15, marginTop: 6}}>
            {question.value
              ? question.yesLabel || 'Yes'
              : question.noLabel || 'No'}
          </Paragraph>
        </Div>
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    paddingTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface YesNoAnswerProps {
  question: IQuestion;
  index: number;
}
