import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import usePermissionsService from '../../../services/usePermissionsService';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {renderPermissionItem} from '../../shared/PermissionItem';

export default function CoachColleagueInvitations() {
  const invitations = usePermissionsService().useCoachColleagueInvitations();
  const {translate} = useLocalizationProvider();
  return (
    <Div>
      <HeadingThree>
        {translate('Coaches who invite you to be their colleague.')}
      </HeadingThree>
      {invitations.length ? (
        invitations.map((item, index) =>
          renderPermissionItem({
            item,
            index,
            type: item.id ? 'coachColleague' : 'coachEmail',
          }),
        )
      ) : (
        <Paragraph>No invitations.</Paragraph>
      )}
    </Div>
  );
}
