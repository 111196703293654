import * as React from 'react';
import {GestureResponderEvent, StyleProp, Text, TextStyle} from 'react-native';
import {shouldTranslateByDefault} from '../../constants/Language';
import useLocalizationProvider from '../../providers/useLocalizationProvider';

export function Paragraph({
  ellipsizeMode,
  translate: doTranslate = shouldTranslateByDefault,
  numberOfLines,
  style,
  onPress,
  children,
}: ParagraphProps) {
  const {translate} = useLocalizationProvider();
  return (
    <Text
      ellipsizeMode={ellipsizeMode}
      numberOfLines={numberOfLines}
      style={style}
      onPress={onPress}
    >
      {doTranslate ? translate(children) : children}
    </Text>
  );
}
export interface ParagraphProps extends React.PropsWithChildren {
  translate?: boolean;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
  onPress?: (event: GestureResponderEvent) => void;
}
