import React from 'react';
import {StyleSheet, useWindowDimensions} from 'react-native';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import ProgressBar from '../../../components/base/ProgressBar';
import {maxContentWidth} from '../../../constants/Layout';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {GlobalStyles} from '../../../styles';
import {IQuestion} from '../../../types/IQuestion';
import QuestionItemDetails from '../../shared/QuestionItemDetails';

export default function AthleteQuestionRatingAnswer({
  question,
  index,
}: RatingAnswerProps) {
  const {width} = useWindowDimensions();
  const {translate} = useLocalizationProvider();

  return (
    <Div style={styles.wrapper} key={index.toString()}>
      <Div style={{flex: 1}}>
        <Div
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 12,
          }}
        >
          <Paragraph style={{fontSize: 15}} translate={false}>
            {question.text.replace('&', translate('and'))}
            {question.text.endsWith('?') ? '' : ':'}
          </Paragraph>
          <QuestionItemDetails question={question} />
          <Paragraph
            translate={false}
            style={{
              fontWeight: '700',
              marginLeft: 12,
              fontSize: 18,
              color: question.score[question.value as number].color,
            }}
          >
            {question.value}
          </Paragraph>
        </Div>
        {/* <Paragraph translate={false} style={{fontSize: 12, marginVertical: 3}}>
          {question.score[question.value as number].description}
        </Paragraph> */}
        <Div
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 2,
            },
          ]}
        >
          <Paragraph
            translate={false}
            style={{
              marginRight: 10,
              fontSize: 15,
              color: GlobalStyles.colorsBasic.gray700,
            }}
          >
            {question.minValue}
          </Paragraph>
          <Div style={{flex: 1}}>
            <ProgressBar
              progress={(question.value as number) / question.maxValue}
              backgroundColor={GlobalStyles.colorsBasic.gray300}
              height={10}
              width={Math.min(width, maxContentWidth) - 80}
              color={question.score[question.value as number].color}
              borderRadius={15}
            />
          </Div>
          <Paragraph
            translate={false}
            style={{
              marginLeft: 10,
              fontSize: 15,
              color: GlobalStyles.colorsBasic.gray700,
            }}
          >
            {question.maxValue}
          </Paragraph>
        </Div>
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    paddingTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface RatingAnswerProps {
  question: IQuestion;
  index: number;
}
