import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AuthNavigationContext} from '../../../navigation/auth-navigator/AuthNavigationContext';
import {AuthStackScreenProps} from '../../../types/Navigation';
import SignupWithSvexaTemplate from './SignupWithSvexaTemplate';

export default function SignupWithSvexaScreen({
  navigation,
}: AuthStackScreenProps<'signup-with-svexa'>) {
  return (
    <AuthNavigationContext.Provider value={navigation}>
      <ScreenLayout>
        <SignupWithSvexaTemplate />
      </ScreenLayout>
    </AuthNavigationContext.Provider>
  );
}
