import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import Image from '../../../components/base/Image';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import useAuthService from '../../../services/useAuthService';

import {GlobalStyles} from '../../../styles';
import {useAthleteNavigation} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import {maxContentWidth} from '../../../constants/Layout';
import Button from '../../../components/base/Button';
import useUserSettingsService from '../../../services/useUserSettingsService';

export default function AthleteHeader() {
  const user = useAuthService().useUser();
  const {image} = useUserSettingsService().useUserImage();
  const navigation = useAthleteNavigation();
  const goToProfile = () => navigation?.navigate('settings');
  const invitationsCount = useUserSettingsService().useCoachInvitationsCount();
  return (
    <Div style={styles.container}>
      <Div style={styles.innerHeader}>
        <Button onPress={goToProfile}>
          <Image
            source={image ? {uri: image} : assets.images.avatar}
            style={styles.profileImage}
            resizeMode={'cover'}
          />
        </Button>
        <Div style={{flex: 1, marginLeft: 10}}>
          <Paragraph
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={styles.greeting}
          >
            <Paragraph>Hi</Paragraph>, {user?.firstname} {user?.lastname}
          </Paragraph>
          <Paragraph
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={styles.email}
          >
            {user?.email}
          </Paragraph>
        </Div>
        <Div style={{position: 'relative'}}>
          <Icon
            name="menu"
            onPress={() => navigation?.toggleDrawer()}
            color="black"
            size={25}
          />
          {invitationsCount ? (
            <Div
              style={{
                position: 'absolute',
                borderRadius: 10,
                width: 20,
                height: 20,
                backgroundColor: GlobalStyles.colorsRatings.red,
                alignItems: 'center',
                justifyContent: 'center',
                left: -12,
                top: -4,
                zIndex: 99,
              }}
            >
              <Paragraph
                style={{
                  color: GlobalStyles.colorsBasic.white500,
                  fontSize: 12,
                }}
              >
                {invitationsCount}
              </Paragraph>
            </Div>
          ) : null}
        </Div>
      </Div>
    </Div>
  );
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: GlobalStyles.colorsBasic.gray500,
    backgroundColor: GlobalStyles.colorsBasic.white500,
    borderBottomWidth: 1,
  },
  innerHeader: {
    width: '100%',
    maxWidth: maxContentWidth,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  profileImage: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  greeting: {
    marginRight: 16,
    marginBottom: 5,
    fontSize: 17,
  },
  email: {
    marginRight: 16,
    fontSize: 13,
    color: GlobalStyles.colorsBasic.gray900,
  },
});
