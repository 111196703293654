import {StyleSheet} from 'react-native';

import {AthleteStackScreenProps} from '../../../types/Navigation';
import AuthGuard from '../../../components/shared/AuthGuard';
import ScreenLayout from '../../../components/shared/ScreenLayout';

import AthleteHeader from '../../athlete/shared/AthleteHeader';
import {Div} from '../../../components/base/Div';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';

import {GlobalStyles} from '../../../styles';
import {AthleteNavigationContext} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import Container from '../../../components/shared/Container';
import useUserSettingsService from '../../../services/useUserSettingsService';
import Form from '../../../components/shared/Form';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';

export default function FeedbackScreen({
  navigation,
}: AthleteStackScreenProps<'feedback'>) {
  const {send, sending, form} = useUserSettingsService().useSendFeedback();

  return (
    <AuthGuard>
      <AthleteNavigationContext.Provider value={navigation}>
        <ScreenLayout>
          <AthleteHeader />
          <Container>
            <KeyboardAvoidingScrollArea>
              <Div style={styles.pageContainer}>
                <HeadingTwo>Feedback</HeadingTwo>
                <Paragraph>
                  We would love to hear what you think. Send a message below or
                  shoot us an email to advisor@svexa.com.
                </Paragraph>
                <Form
                  style={{marginTop: 15}}
                  form={form}
                  cleanFormOnSubmit
                  onSubmit={send}
                  submitControl={(submit) => (
                    <CustomButton
                      type="primary"
                      titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                      title={'Send'}
                      onPress={submit}
                      isLoading={sending}
                      disabled={sending}
                      loader={{size: 'small', color: 'black'}}
                    />
                  )}
                />
              </Div>
            </KeyboardAvoidingScrollArea>
          </Container>
        </ScreenLayout>
      </AthleteNavigationContext.Provider>
    </AuthGuard>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    flexDirection: 'column',
    margin: 24,
    marginTop: 12,
    maxWidth: 600,
  },
  textAreaContainer: {
    borderColor: GlobalStyles.colorsBasic.gray500,
    borderWidth: 1,
    borderRadius: 5,
    padding: 8,
    marginVertical: 16,
  },
  textArea: {
    height: 150,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});
