import * as React from 'react';
import {
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  ReturnKeyTypeOptions,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputIOSProps,
  TextStyle,
} from 'react-native';
import {GlobalStyles} from '../../styles';

export default function Input(props: InputProps) {
  const setValue = (text: string) => {
    if (props.type && !!text.length) {
      const rule = new RegExp(InputTypeRegex[props.type]);
      if (!rule.test(text)) return;
    }
    if (props.onTextChange) {
      props.onTextChange(text);
    }
  };

  return (
    <TextInput
      textContentType={props.textContentTypeIOS || 'none'}
      style={props.style}
      onChangeText={setValue}
      placeholderTextColor={props.placeHolderTextColor}
      secureTextEntry={props.passwordTextInput}
      multiline={props.multiline || false}
      returnKeyType={props.returnKeyType}
      defaultValue={props.defaultValue}
      autoCapitalize={props.autoCapitalize}
      value={props.value}
      onSubmitEditing={props.onSubmitEditing}
      numberOfLines={props.numberOfLines}
      focusable={props.focusable}
      autoCorrect={props.autoCorrect || false}
      textAlignVertical={props.textAlignVertical || 'auto'}
      scrollEnabled={props.scrollEnabled}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      keyboardType={props.keyboardType}
    >
      {props.children}
    </TextInput>
  );
}
export interface InputProps extends React.PropsWithChildren {
  value: string;
  onTextChange?: (value: string) => void;
  placeholder?: string;
  placeHolderTextColor?: string;
  translate?: boolean;
  textContentTypeIOS?: TextInputIOSProps['textContentType'];
  passwordTextInput?: boolean;
  style?: StyleProp<TextStyle>;
  multiline?: boolean;
  returnKeyType?: ReturnKeyTypeOptions;
  defaultValue?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  onSubmitEditing?: () => void;
  numberOfLines?: number;
  focusable?: boolean;
  autoCorrect?: boolean;
  textAlignVertical?: 'auto' | 'top' | 'bottom' | 'center';
  scrollEnabled?: boolean;
  onFocus?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => any;
  onBlur?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => any;
  keyboardType?: KeyboardTypeOptions;
  type?: InputType;
}

type InputType = 'number' | 'text' | 'number-and-punctuation';

const InputTypeRegex: {[key in InputType]: RegExp} = {
  number: /^[0-9]*$/,
  text: /^[\s\S]*$/,
  'number-and-punctuation': /^\d+(.\d+)?$/,
};

export const inputStyle = StyleSheet.create({
  primaryInput: {
    fontSize: 13,
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    height: 35,
    borderColor: GlobalStyles.colorsBasic.gray400,
  },
});
