import React from 'react';
import {invitesForm} from '../constants/Invites';
import PermissionsGateway from '../gateways/PermissionsGateway';
import useAlertProvider from '../providers/useAlertProvider';
import {useRequest} from '../providers/useRequestProvider';
import {useAppDispatch, useAppSelector} from '../store';
import {permissionsActions} from '../store/reducers/PermissionsReducer';
import {PermissionStatus, PermissionTypes} from '../types/IPermissions';

export default function usePermissionsService() {
  const dispatch = useAppDispatch();
  const {success} = useAlertProvider();

  function useFetchPermissions(autoCall?: boolean) {
    const permissions = useAppSelector(
      ({permissions}) => permissions.permissions,
    );
    const {execute, loading} = useRequest(PermissionsGateway.getAllPermisions, {
      onCompleted: (data) => {
        if (data) {
          dispatch(permissionsActions.setPermissions(data));
        }
      },
    });
    if (autoCall) {
      React.useEffect(() => {
        execute({});
      }, []);
    }
    return {fetchAll: execute, loading, permissions};
  }

  function useMyCoaches() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.coaches.filter(
        (p) => p.status == PermissionStatus.ACCEPTED,
      ),
    );
  }

  function useMyInvitedCoaches() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.coaches.filter(
        (p) =>
          p.status == PermissionStatus.OUTGOING_EMAIL_INVITE ||
          p.status == PermissionStatus.OUTGOING_INVITE,
      ),
    );
  }

  function useSendCoachInvite() {
    const {fetchAll, loading: fetching} = useFetchPermissions();
    const onSuccess = () => {
      success('Invite sent!');
      fetchAll({});
    };

    const {execute, loading} = useRequest(PermissionsGateway.sendCoachInvite, {
      onCompleted: onSuccess,
    });

    return {send: execute, loading, refetching: fetching, form: invitesForm};
  }

  function useCoachesRequests() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.coaches.filter(
        (p) =>
          p.status == PermissionStatus.INCOMING_INVITE ||
          p.status == PermissionStatus.BLOCKED_BY_SELF ||
          p.status == PermissionStatus.UNBLOCKED_BY_SELF,
      ),
    );
  }

  function useAthleteInvitations() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.athletes.filter(
        (p) =>
          p.status == PermissionStatus.INCOMING_INVITE ||
          p.status == PermissionStatus.BLOCKED_BY_SELF ||
          p.status == PermissionStatus.UNBLOCKED_BY_SELF,
      ),
    );
  }

  function useCoachColleagueInvitations() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.coachColleagues.filter(
        (p) =>
          p.status == PermissionStatus.INCOMING_INVITE ||
          p.status == PermissionStatus.BLOCKED_BY_SELF ||
          p.status == PermissionStatus.UNBLOCKED_BY_SELF,
      ),
    );
  }

  function usePermissionActions() {
    const {fetchAll, loading: refetching} = useFetchPermissions();

    const onSuccess = () => {
      success('Success!');
      fetchAll({});
    };

    const {execute, loading} = useRequest(
      PermissionsGateway.invokePermissionAction,
      {onCompleted: onSuccess},
    );

    return {call: execute, loading, refetching};
  }

  function useAcceptedPermissions(type: PermissionTypes) {
    return useAppSelector(({permissions}) =>
      permissions.permissions[type].filter(
        (p) => p.status == PermissionStatus.ACCEPTED,
      ),
    );
  }

  function useMyAthletes() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.athletes.filter(
        (p) => p.status == PermissionStatus.ACCEPTED,
      ),
    );
  }

  function useMyInvitedAthletes() {
    return useAppSelector(({permissions}) =>
      permissions.permissions.athletes.filter(
        (p) =>
          p.status == PermissionStatus.OUTGOING_EMAIL_INVITE ||
          p.status == PermissionStatus.OUTGOING_INVITE,
      ),
    );
  }

  function useSendAthleteInvite() {
    const {fetchAll, loading: fetching} = useFetchPermissions();
    const onSuccess = () => {
      success('Invite sent!');
      fetchAll({});
    };

    const {execute, loading} = useRequest(
      PermissionsGateway.sendAthleteInvite,
      {
        onCompleted: onSuccess,
      },
    );

    return {send: execute, loading, refetching: fetching, form: invitesForm};
  }

  return {
    useMyCoaches,
    useMyAthletes,
    useSendCoachInvite,
    useSendAthleteInvite,
    useMyInvitedCoaches,
    useMyInvitedAthletes,
    useFetchPermissions,
    useCoachesRequests,
    usePermissionActions,
    useAthleteInvitations,
    useCoachColleagueInvitations,
    useAcceptedPermissions,
  };
}
