import {Platform} from 'react-native';
import environment from '../../config/environment';
import {store} from '../store';
import {ResponseType} from '../types/Request';

export class BaseGateway {
  public static instance: BaseGateway;
  public accessToken = '';
  public accessUserId = '';
  public apiUri = `${environment.api.protocol}://${environment.api.host}`;

  public static getInstance(): BaseGateway {
    if (this.instance) {
      return this.instance;
    }
    return new BaseGateway();
  }

  public async post<T>(
    url: string,
    payload: any,
    headers?: any,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    return await this.api(
      url,
      {
        body: JSON.stringify(payload),
        headers: this.getHeaders(headers),
        method: 'POST',
      },
      responseType,
    );
  }

  public async put<T>(url: string, payload?: any, headers?: any): Promise<T> {
    return await this.api(url, {
      body: !!payload ? JSON.stringify(payload) : null,
      headers: this.getHeaders(headers),
      method: 'PUT',
    });
  }

  public async delete<T>(url: string, headers?: any): Promise<T> {
    return await this.api(url, {
      headers: this.getHeaders(headers),
      method: 'DELETE',
    });
  }

  public async get<T>(
    url: string,
    headers?: any,
    responseType: ResponseType = 'json',
    apiVersion: string = environment.api.version,
  ): Promise<T> {
    return await this.api(
      url,
      {headers: this.getHeaders(headers), method: 'GET'},
      responseType,
      apiVersion,
    );
  }

  public async upload<T>(
    url: string,
    payload: FormData,
    method = 'POST',
    headers?: any,
  ): Promise<T> {
    return this.api(url, {
      body: payload,
      headers,
      method,
    }).then((res) => res);

    // Android image upload hack (from react native 0.62) in expo managed projects
    /* const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
          return null;
        }
        if (xhr.status >= 300) {
          reject({message: "Couldn't upload the file"});
        } else {
          resolve(xhr.response);
        }
      };
      xhr.open(method, this.apiUri + url);
      xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      xhr.responseType = 'json';
      xhr.send(payload);
    }); */
  }

  public setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  public getAccessToken(): string {
    if (!this.accessToken) {
      const localStorageState = store.getState();
      this.accessToken = localStorageState.auth.user?.accessToken || '';
    }
    return this.accessToken;
  }

  public setAccessUserId(userId: string) {
    this.accessUserId = userId;
  }

  public getAccessUserId(): string {
    if (!this.accessUserId) {
      const localStorageState = store.getState();
      this.accessUserId = localStorageState.auth.user?.id || '';
    }
    return this.accessUserId;
  }

  public getEncodedUserId(): string {
    return encodeURIComponent(this.getAccessUserId());
  }

  public async api(
    path: string,
    options: RequestInit,
    responseType: ResponseType = 'json',
    apiVersion: string = environment.api.version,
  ): Promise<any> {
    return fetch(this.apiUri + apiVersion + path, options)
      .then(async (res: Response) => {
        return res.text().then((text) => {
          if (res.status >= 300) throw JSON.parse(text);
          if (!text.length) return null;
          if (responseType == 'plain') return text;
          return JSON.parse(text);
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  public getHeaders(headers: any = {}): HeadersInit_ {
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json; charset=utf-8';
    }
    return headers;
  }

  public getAuthHeaders(): HeadersInit_ {
    return {
      'X-Api-Key': environment.api.apiKey,
      'X-App-Id': environment.api.appId,
    };
  }
}

const BaseApi = BaseGateway.getInstance();

export default BaseApi;
