import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {AuthStackParamList} from '../../types/Navigation';
import LoginScreen from '../../screens/auth/login/LoginScreen';
import SignupScreen from '../../screens/auth/signup/SignupScreen';
import SignupWithGoogleScreen from '../../screens/auth/signup-with-google/SignupWithGoogleScreen';
import SignupWithAppleScreen from '../../screens/auth/signup-with-apple/SignupWithAppleScreen';
import SignupWithSvexaScreen from '../../screens/auth/signup-with-svexa/SignupWithSvexaScreen';
import ForgotPasswordScreen from '../../screens/auth/forgot-password/ForgotPasswordScreen';
import TokenSignInScreen from '../../screens/auth/token-signin/TokenSignInScreen';
const Stack = createNativeStackNavigator<AuthStackParamList>();

export default function AuthNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName="login"
    >
      <Stack.Screen name="login" component={LoginScreen} />
      <Stack.Screen name="signup" component={SignupScreen} />
      <Stack.Screen
        name="signup-with-google"
        component={SignupWithGoogleScreen}
      />
      <Stack.Screen
        name="signup-with-apple"
        component={SignupWithAppleScreen}
      />
      <Stack.Screen
        name="signup-with-svexa"
        component={SignupWithSvexaScreen}
      />
      <Stack.Screen name="token-signin" component={TokenSignInScreen} />
      <Stack.Screen
        name="forgot-password"
        component={ForgotPasswordScreen}
        options={{animation: 'slide_from_bottom', presentation: 'modal'}}
      />
    </Stack.Navigator>
  );
}
