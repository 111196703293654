import React from 'react';
import {Div} from '../../../components/base/Div';
import {HeadingOne} from '../../../components/base/HeadingOne';
import {HeadingThree} from '../../../components/base/HeadingThree';
import Image from '../../../components/base/Image';
import Loader from '../../../components/base/Loader';
import assets from '../../../constants/Assets';
import {GlobalStyles} from '../../../styles';
import {RootStackScreenProps} from '../../../types/Navigation';

export default function EmailVerificationScreen(
  props: RootStackScreenProps<'email-verification'>,
) {
  const [showRedirect, setShowRedirect] = React.useState(false);
  React.useEffect(() => {
    const loaderTimeout = setTimeout(() => setShowRedirect(true), 1500);
    const redirectTimeout = setTimeout(() => {
      props.navigation.reset({index: 0, routes: [{name: 'auth'}]});
    }, 2800);
    return () => {
      clearTimeout(loaderTimeout);
      clearTimeout(redirectTimeout);
    };
  }, []);
  return (
    <Div
      style={{
        flex: 1,
        backgroundColor: GlobalStyles.colorsBasic.white500,
        alignItems: 'center',
        paddingHorizontal: 15,
      }}
    >
      <Div style={{marginTop: 50, alignItems: 'center'}}>
        <Image source={assets.images.svexaLogo} height={100} width={200} />
        <Div style={{marginTop: 20}}>
          <HeadingOne style={{textAlign: 'center', marginBottom: 30}}>
            {'Email Address Verification'}
          </HeadingOne>
          <HeadingThree style={{textAlign: 'center', marginBottom: 30}}>
            {props.route.params?.status == 'success'
              ? 'Email verified successfullly!'
              : 'Email verification failed.'}
          </HeadingThree>
          {showRedirect ? (
            <>
              <HeadingThree style={{textAlign: 'center', marginBottom: 10}}>
                {'Redirecting to login page...'}
              </HeadingThree>
              <Loader size="large" color={GlobalStyles.svexa.green500} />
            </>
          ) : null}
        </Div>
      </Div>
    </Div>
  );
}
