import {validatorFunc} from '../types/Form';

class Validator {
  field: string;
  value: string;
  constructor(value: string, field: string) {
    this.field = field;
    this.value = value;
  }
  public static required(error?: string): validatorFunc {
    return (value: string, field: string) => {
      if (!value) {
        return {
          valid: false,
          error: error || `${field} is required.`,
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static email(error?: string): validatorFunc {
    return Validator.regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      error || 'Invalid email',
    );
  }

  public static password(error?: string): validatorFunc {
    return Validator.regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      error ||
        'At least 8 characters, 1 upper case, 1 lower case, 1 number and 1 special character required',
    );
  }

  public static regex(pattern: string | RegExp, error?: string): validatorFunc {
    return (value: string, field: string) => {
      const regex = new RegExp(pattern);
      if (!regex.test(value)) {
        return {
          valid: false,
          error: error || `${field} does not match schema ${pattern}.`,
        };
      }
      return {
        valid: true,
      };
    };
  }

  public static number(error?: string): validatorFunc {
    return (value: string, field: string) => {
      if (!value) {
        return {valid: true};
      }
      const num = parseFloat(value);
      if (isNaN(num)) {
        return {
          valid: false,
          error: error || `${field} must be a number.`,
        };
      }
      return {
        valid: true,
      };
    };
  }

  public validate(validators: validatorFunc[]): {
    valid: boolean;
    error?: string;
  } {
    for (const validFunc of validators) {
      const validated = validFunc(this.value, this.field);
      if (!validated.valid) {
        return validated;
      }
    }
    return {
      valid: true,
    };
  }
}

export default Validator;
