import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import {Dropdown, DropdownItem} from '../../../components/shared/Dropdown';
import {GMTtimeZones} from '../../../constants/Settings';
import {GlobalStyles} from '../../../styles';

export default function Timezone({value, onValueChange}: TimezoneProps) {
  return (
    <Div>
      <Paragraph style={styles.timeZoneLabel}>Time zone</Paragraph>
      <Div style={styles.timeZoneContainer}>
        <Dropdown
          itemStyle={{
            fontSize: 13,
          }}
          selectedValue={value}
          onValueChange={onValueChange}
        >
          {GMTtimeZones.map(({label, value}, index) => (
            <DropdownItem
              style={{fontSize: 13}}
              key={index}
              label={label}
              value={value}
            />
          ))}
        </Dropdown>
      </Div>
    </Div>
  );
}

type ItemValue = string | number;

interface TimezoneProps {
  value: ItemValue;
  onValueChange: (value: ItemValue, index: number) => any;
}

const styles = {
  timeZoneContainer: {
    borderWidth: 1,
    borderColor: GlobalStyles.colorsBasic.gray400,
    borderRadius: 5,
    marginBottom: 10,
  },
  timeZoneLabel: {
    marginBottom: 10,
    fontSize: 14,
  },
};
