import * as React from 'react';
import {FlatList, FlatListProps, RefreshControl} from 'react-native';
import Colors from '../../constants/Colors';

export default function List(props: ListProps) {
  return (
    <FlatList
      {...props}
      refreshControl={
        !!props.onRefresh ? (
          <RefreshControl
            onRefresh={props.onRefresh}
            refreshing={!!props.refreshing}
            tintColor={Colors.light.tint}
          />
        ) : undefined
      }
      style={props.style}
      contentContainerStyle={props.contentContainerStyle}
    />
  );
}

interface ListProps extends FlatListProps<any> {
  onRefresh?: () => void;
  refreshing?: boolean;
}
