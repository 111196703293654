import moment from 'moment';
import {useState} from 'react';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import DateTimPicker from '../../../components/shared/date-time-picker/DateTimePicker';
import List from '../../../components/shared/List';
import TabItem from '../../../components/shared/tab/TabItem';
import {minYear} from '../../../constants/Config';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import {GlobalStyles} from '../../../styles';
import {IPermission} from '../../../types/IPermissions';
import MyAthleteRatings from '../shared/MyAthleteRatings';
import MyAthleteTeams from './MyAthleteTeams';

export default function MyAthleteDetails({athlete}: MyAthleteDetailsProps) {
  const {formatDateKey, dateFromDateKey} = useDateTimeProvider();
  const [dateKey, setDateKey] = useState(formatDateKey());
  const [tab, setTab] = useState(0);
  const selectTab = ({title}: {title: string}) => setTab(tabs.indexOf(title));
  const renderTab = ({item, index}: {item: string; index: number}) => (
    <TabItem
      tab={{title: item}}
      isActive={tab == tabs.indexOf(item)}
      index={index}
      onPress={selectTab}
    />
  );

  const tabs = ['Ratings', 'Teams'];

  return (
    <Div style={{paddingHorizontal: 12, paddingBottom: 24}}>
      {athlete ? (
        <>
          <Div style={{marginBottom: 12}}>
            <Paragraph
              style={{fontSize: 18}}
              numberOfLines={1}
              ellipsizeMode={'tail'}
            >
              {athlete.firstName} {athlete.lastName}
            </Paragraph>
            <Paragraph
              numberOfLines={1}
              ellipsizeMode={'tail'}
              style={{fontSize: 12, color: GlobalStyles.colorsBasic.gray700}}
            >
              {athlete.email}
            </Paragraph>
          </Div>
          <List
            style={{
              marginBottom: 16,
            }}
            data={tabs}
            horizontal
            renderItem={renderTab}
          />
          <Div style={{display: tab == 0 ? 'flex' : 'none'}}>
            <DateTimPicker
              style={{maxWidth: 120, marginBottom: 20}}
              maxDate={new Date()}
              value={dateFromDateKey(dateKey)}
              onChange={(d) => setDateKey(formatDateKey(d))}
              minDate={moment().year(minYear).startOf('year').toDate()}
            />
            <MyAthleteRatings
              dateKey={dateKey}
              athleteId={athlete.id}
              hideDate
            />
          </Div>
          <Div style={{display: tab == 1 ? 'flex' : 'none'}}>
            <MyAthleteTeams athleteId={athlete.id} />
          </Div>
        </>
      ) : null}
    </Div>
  );
}

interface MyAthleteDetailsProps {
  athlete: IPermission | null;
}
