import {StyleSheet} from 'react-native';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import Image from '../../../components/base/Image';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import {maxContentWidth} from '../../../constants/Layout';
import {useCoachNavigation} from '../../../navigation/coach-navigator/CoachNavigationContext';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {GlobalStyles} from '../../../styles';

export default function CoachHeaderSimple({
  title = 'Teams',
}: CoachHeaderSimpleProps) {
  const navigation = useCoachNavigation();
  const {image} = useUserSettingsService().useUserImage();
  const back = () => (navigation?.canGoBack() ? navigation.goBack() : {});
  const goToSettings = () => navigation?.navigate('settings');
  const invitationsCount = useUserSettingsService().useAthleteInvitationCount();
  return (
    <Div style={styles.container}>
      <Div style={styles.innerHeader}>
        <Icon
          name="chevron-left"
          size={28}
          color={GlobalStyles.colorsBasic.black500}
          onPress={back}
        />
        <Paragraph style={{fontWeight: '600', fontSize: 18, marginLeft: 6}}>
          {title}
        </Paragraph>
        <Button
          onPress={goToSettings}
          style={{marginLeft: 'auto', marginRight: 15}}
        >
          <Image
            source={image ? {uri: image} : assets.images.avatar}
            style={styles.profileImage}
            resizeMode={'cover'}
          />
        </Button>
        <Div style={{position: 'relative'}}>
          <Icon
            name="menu"
            onPress={() => navigation?.toggleDrawer()}
            color="black"
            size={25}
          />
          {invitationsCount ? (
            <Div
              style={{
                position: 'absolute',
                borderRadius: 10,
                width: 20,
                height: 20,
                backgroundColor: GlobalStyles.colorsRatings.red,
                alignItems: 'center',
                justifyContent: 'center',
                left: -12,
                top: -4,
                zIndex: 99,
              }}
            >
              <Paragraph
                style={{
                  color: GlobalStyles.colorsBasic.white500,
                  fontSize: 12,
                }}
              >
                {invitationsCount}
              </Paragraph>
            </Div>
          ) : null}
        </Div>
      </Div>
    </Div>
  );
}

interface CoachHeaderSimpleProps {
  title?: string;
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: GlobalStyles.colorsBasic.gray500,
    backgroundColor: GlobalStyles.colorsBasic.white500,
    borderBottomWidth: 1,
  },
  innerHeader: {
    width: '100%',
    maxWidth: maxContentWidth,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 20,
    paddingVertical: 8,
  },
  headerRight: {
    marginLeft: 'auto',
  },
  profileImage: {
    height: 28,
    width: 28,
    borderRadius: 14,
  },
});
