import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AthleteNavigationContext} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import {AthleteStackScreenProps} from '../../../types/Navigation';
import AthleteHeader from '../shared/AthleteHeader';
import AthleteOverviewTemplate from './AthleteOverviewTemplate';
import RatingsMiddleware from './RatingsMiddleware';

export default function AthleteOverviewScreen({
  navigation,
  route,
}: AthleteStackScreenProps<'overview'>) {
  return (
    <AuthGuard>
      <AthleteNavigationContext.Provider value={navigation}>
        <RatingsMiddleware persist={route.params?.persist}>
          <ScreenLayout>
            <AthleteHeader />
            <Container>
              <AthleteOverviewTemplate />
            </Container>
          </ScreenLayout>
        </RatingsMiddleware>
      </AthleteNavigationContext.Provider>
    </AuthGuard>
  );
}
