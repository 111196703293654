import {StyleProp, ViewStyle} from 'react-native';
import * as Web from 'react-native-web';

export default function RangeSlider(props: RangeSlider) {
  const percentage =
    (props.value * 100) /
    ((props.maximumValue || 100) - (props.minimumValue || 0));
  return (
    <>
      {Web.unstable_createElement('style', {
        type: 'text/css',
        children: `
        input[type="range"] {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            cursor: pointer;
        }
        /***** Track Styles *****/
        /***** Chrome, Safari, Opera, and Edge Chromium *****/
        input[type="range"]::-webkit-slider-runnable-track {
          height: 5px;
          border-radius: 5px;
        }
        
        /******** Firefox ********/
        input[type="range"]::-moz-range-track {
          height: 5px;
          border-radius: 2.5px;
        }

        /***** Thumb Styles *****/
        /***** Chrome, Safari, Opera, and Edge Chromium *****/
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          margin-top: -7.5px; /* Centers thumb on the track */
          height: 20px;
          width: 20px;
          border-radius: 100%;    
        }
        /***** Thumb Styles *****/
        /***** Firefox *****/
        input[type="range"]::-moz-range-thumb {
            background-color: ${props.thumbTintColor};
            height: 20px;
            width: 20px;
            border: none; /*Removes extra border that FF applies*/
            border-radius: 100%;
        }
        /***** INDIVIDUAL STYLE *****/
        #${props.testID}::-webkit-slider-runnable-track { 
            background: linear-gradient(to right, ${props.minimumTrackTintColor} 0%, ${props.minimumTrackTintColor} ${percentage}%, ${props.maximumTrackTintColor} ${percentage}%, ${props.maximumTrackTintColor} 100%);
        }
        #${props.testID}::-moz-range-track { 
            background: linear-gradient(to right, ${props.minimumTrackTintColor} 0%, ${props.minimumTrackTintColor} ${percentage}%, ${props.maximumTrackTintColor} ${percentage}%, ${props.maximumTrackTintColor} 100%);
        }
        #${props.testID}::-webkit-slider-thumb { 
            background-color: ${props.thumbTintColor};
        }
        #${props.testID}::-moz-range-thumb { 
            background-color: ${props.thumbTintColor};
        } `,
      })}
      {Web.unstable_createElement('input', {
        type: 'range',
        id: props.testID,
        value: props.value,
        min: props.minimumValue,
        max: props.maximumValue,
        step: props.step,
        onChange: (event) => {
          if (props.onValueChange) {
            props.onValueChange(event.target.value);
          }
        },
        onMouseDown: (event) => {
          if (props.onSlidingStart) {
            // props.onSlidingStart(event.target?.value || 0);
            props.onSlidingStart(true);
          }
        },
        onTouchStart: (event) => {
          if (props.onSlidingStart) {
            // props.onSlidingStart(event.target?.value || 0);
            props.onSlidingStart(true);
          }
        },
        onMouseUp: (event) => {
          if (props.onSlidingComplete) {
            //props.onSlidingComplete(event.target?.value || 0);
            props.onSlidingComplete(false);
          }
        },
        onTouchEnd: (event) => {
          if (props.onSlidingComplete) {
            //props.onSlidingComplete(event.target?.value || 0);
            props.onSlidingComplete(false);
          }
        },
      })}
    </>
  );
}

interface RangeSlider {
  value: number;
  minimumValue?: number;
  maximumValue?: number;
  step?: number;
  minimumTrackTintColor?: string;
  maximumTrackTintColor?: string;
  thumbTintColor?: string;
  onValueChange?: (value: number) => any;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  onSlidingStart?: (value: number | boolean) => void;
  onSlidingComplete?: (value: number | boolean) => void;
}
