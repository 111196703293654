import React from 'react';
import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {AthleteNavigationContext} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import {AthleteStackScreenProps} from '../../../types/Navigation';
import AddEditRatingsHeader from './AddEditRatingsHeader';
import {AddRatingsContext} from './AddRatingsContext';
import AthleteAddEditRatingsTemplate from './AthleteAddEditRatingsTemplate';

export default function AthleteAddEditRatingsScreen({
  navigation,
  route,
}: AthleteStackScreenProps<'add-ratings'>) {
  const [hasChanges, setHasChanges] = React.useState(false);
  return (
    <AuthGuard>
      <AthleteNavigationContext.Provider value={navigation}>
        <AddRatingsContext.Provider
          value={{
            hasUnSavedChanges: hasChanges,
            setHasUnsavedChanges: setHasChanges,
          }}
        >
          <ScreenLayout>
            <AddEditRatingsHeader dateKey={route.params.dateKey} />
            <Container>
              <AthleteAddEditRatingsTemplate dateKey={route.params.dateKey} />
            </Container>
          </ScreenLayout>
        </AddRatingsContext.Provider>
      </AthleteNavigationContext.Provider>
    </AuthGuard>
  );
}
