import {GlobalStyles} from '../styles';
import {IForm} from '../types/Form';
import {IPermissionActions, PermissionActionMap} from '../types/IPermissions';
import Validator from './Validator';

export const invitesForm: IForm<{email: string}> = {
  email: {
    label: 'Email',
    name: 'email',
    type: 'input',
    props: {
      style: {
        borderWidth: 1,
        borderColor: GlobalStyles.colorsBasic.gray500,
        borderRadius: 5,
        width: '100%',
        padding: 5,
      },
      autoCapitalize: 'none',
    },
    validators: [Validator.required(), Validator.email()],
    errorStyle: {
      borderColor: GlobalStyles.colorsStatus.red,
    },
  },
};

export const invitesActions: IPermissionActions = {
  accept: {
    type: 'accept',
    color: GlobalStyles.colorsStatus.blue,
    label: 'Accept',
  },
  block: {
    type: 'block',
    color: GlobalStyles.colorsStatus.red,
    label: 'Block',
  },
  cancel: {
    type: 'cancel',
    color: GlobalStyles.colorsStatus.red,
    label: 'Cancel',
  },
  decline: {
    type: 'decline',
    color: GlobalStyles.colorsStatus.red,
    label: 'Decline',
  },
  revoke: {
    type: 'revoke',
    color: GlobalStyles.colorsStatus.red,
    label: 'Revoke',
  },
  unblock: {
    type: 'unblock',
    color: GlobalStyles.colorsStatus.blue,
    label: 'Unblock',
  },
};

export const invitesActionByStatus: PermissionActionMap = {
  accepted: [invitesActions['revoke']],
  blockedBySelf: [invitesActions['unblock']],
  blockedByOther: [],
  cancelledBySelf: [],
  cancelledByOther: [],
  declinedByOther: [],
  declinedBySelf: [],
  incomingInvite: [
    invitesActions['accept'],
    invitesActions['decline'],
    invitesActions['block'],
  ],
  outgoingEmailInvite: [invitesActions['cancel']],
  outgoingInvite: [invitesActions['cancel']],
  revokedBySelf: [],
  revokedByOther: [],
  unBlockedBySelf: [invitesActions['block']],
  unBlockedByOther: [],
};
