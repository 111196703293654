import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

export function SafeArea({children, style}: SafeAreaProps) {
  return <SafeAreaView style={style}>{children}</SafeAreaView>;
}

interface SafeAreaProps extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}
