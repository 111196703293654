import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {maxContentWidth} from '../../constants/Layout';
import {Div} from '../base/Div';

export default function Container({children, style}: IContainerProps) {
  return (
    <Div
      style={[
        {
          flex: 1,
          width: '100%',
          maxWidth: maxContentWidth,
          alignSelf: 'center',
        },
        style,
      ]}
    >
      {children}
    </Div>
  );
}

interface IContainerProps extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}
