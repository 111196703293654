import {Div} from '../../../../components/base/Div';
import Loader from '../../../../components/base/Loader';
import {Paragraph} from '../../../../components/base/Paragraph';
import useAuthService from '../../../../services/useAuthService';
import useCoachService from '../../../../services/useCoachService';
import usePermissionsService from '../../../../services/usePermissionsService';
import {GlobalStyles} from '../../../../styles';
import {PermissionStatus} from '../../../../types/IPermissions';
import {useCurrentGroup} from '../GroupContext';
import TeamCoachCard from './TeamCoachCard';

export default function TeamActiveCoaches() {
  const group = useCurrentGroup();
  const {permissions} = usePermissionsService().useFetchPermissions(true);
  const {admins, fetching} = useCoachService().useGroupAdmins(group?.id || '');
  const user = useAuthService().useUser();
  return (
    <Div>
      {!Object.keys(admins || {}).length && fetching ? (
        <Div>
          <Loader size="small" color={GlobalStyles.svexa.green500} />
        </Div>
      ) : !Object.keys(admins?.admins || {}).length ? (
        <Paragraph>No coaches yet.</Paragraph>
      ) : (
        Object.keys(admins?.admins || {}).map((key, index) => {
          const admin = admins?.admins[key];
          if (!admin) return null;
          const coachPermission = permissions.coaches?.find(
            (coach) =>
              coach.id == key && coach.status === PermissionStatus.ACCEPTED,
          );
          const coachColleaguePermission = permissions.coachColleagues?.find(
            (coach) =>
              coach.id == key && coach.status === PermissionStatus.ACCEPTED,
          );
          if (!coachColleaguePermission && !coachPermission && key !== user?.id)
            return null;
          return <TeamCoachCard key={index} coach={admin} />;
        })
      )}
    </Div>
  );
}
