import {createDrawerNavigator} from '@react-navigation/drawer';
import {CoachStackParamList} from '../../types/Navigation';
import FeedbackScreen from '../../screens/shared/feedback/FeedbackScreen';
import AboutScreen from '../../screens/shared/about/AboutScreen';
import CoachSideNav from '../../screens/coach/shared/CoachSideNav';
import TeamsScreen from '../../screens/coach/teams/TeamsScreen';
import AddTeamScreen from '../../screens/coach/add-team/AddTeamScreen';
import TeamInfoScreen from '../../screens/coach/team-info/TeamInfoScreen';
import AthletesScreen from '../../screens/coach/athletes/AthletesScreen';
import SettingsScreen from '../../screens/shared/settings/SettingsScreen';

const Drawer = createDrawerNavigator<CoachStackParamList>();

export default function CoachNavigator() {
  return (
    <Drawer.Navigator
      initialRouteName="teams"
      drawerContent={({navigation}) => <CoachSideNav navigation={navigation} />}
      screenOptions={{
        headerShown: false,
        unmountOnBlur: true,
        drawerStyle: {
          minWidth: 200,
          maxWidth: 400,
        },
      }}
    >
      <Drawer.Screen name="teams" component={TeamsScreen} />
      <Drawer.Screen name="add-team" component={AddTeamScreen} />
      <Drawer.Screen name="team" component={TeamInfoScreen} />
      <Drawer.Screen name="athletes" component={AthletesScreen} />
      <Drawer.Screen name="settings" component={SettingsScreen} />
      <Drawer.Screen name="feedback" component={FeedbackScreen} />
      <Drawer.Screen name="about" component={AboutScreen} />
    </Drawer.Navigator>
  );
}
