import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import Image from '../../../components/base/Image';
import {Paragraph} from '../../../components/base/Paragraph';
import assets from '../../../constants/Assets';
import {IGroup} from '../../../types/IGroup';

export default function GroupInfoCard({group}: GroupInfoCardProps) {
  return (
    <Div style={style.cardWrapper}>
      <Image
        height={60}
        width={60}
        resizeMode={'cover'}
        style={{borderRadius: 30}}
        source={group.logo ? {uri: group.logo} : assets.images.teamAvatar}
      />
      <Div
        style={{
          marginLeft: 15,
        }}
      >
        <HeadingThree>{group.name}</HeadingThree>
        <Paragraph>
          <Paragraph>Athletes:</Paragraph> {group.members.length}
        </Paragraph>
      </Div>
    </Div>
  );
}

interface GroupInfoCardProps {
  group: IGroup;
}

const style = StyleSheet.create({
  cardWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 20,
    marginTop: 12,
  },
});
