import React, {useEffect} from 'react';
import {Div} from '../../../components/base/Div';
import CustomButton from '../../../components/shared/CustomButton';
import {
  convertFeetToMeterIfImperialIsSet,
  convertMeterToFeetIfImperialIsSet,
  GMTtimeZones,
} from '../../../constants/Settings';
import useRatingService from '../../../services/useRatingService';
import {GlobalStyles} from '../../../styles';
import {IQuestion} from '../../../types/IQuestion';
import {SetDayRatingsDTO} from '../../../types/IRatings';
import {useAddRatingsContext} from './AddRatingsContext';
import HeartInfo from './HeartInfo';
import Note from './Note';
import QuestionItem from './QuestionItem';
import TravelInfo from './TravelInfo';
import useAuthService from '../../../services/useAuthService';

export default function RatingsQuestions({dateKey}: RatingsQuestionsProps) {
  const user = useAuthService().useUser();
  const RATINGS_OBJECT_NAME = 'ratings';
  const {setDayRatings, loading, questions, setQuestions, rating} =
    useRatingService().useSetDayRatings(dateKey);

  const [travelInfo, setTravelInfo] = React.useState({
    timeZone: '',
    altitude: '',
  });

  useEffect(() => {
    setTravelInfo({
      timeZone:
        rating?.additionalData?.timeZone ||
        user?.residenceTimeZone ||
        GMTtimeZones[13].value,
      altitude:
        convertMeterToFeetIfImperialIsSet(
          rating?.additionalData?.altitude,
          user!.units,
        ) ||
        user?.residenceAltitude ||
        '',
    });
  }, [user, rating]);

  const {setHasUnsavedChanges} = useAddRatingsContext();

  const [heartInfo, setHeartInfo] = React.useState({
    rhr: rating?.additionalData?.rhr || '',
    hrv: rating?.additionalData?.hrv || '',
  });

  const [note, setNote] = React.useState(
    rating?.comments?.performance_test || '',
  );

  const submitRatings = () => {
    const altitudeInMeters = convertFeetToMeterIfImperialIsSet(
      travelInfo.altitude,
      user!.units,
    );
    const payload: SetDayRatingsDTO = {
      ratingsData: {
        additionalData: {
          ...heartInfo,
          timeZone: travelInfo.timeZone,
          altitude: altitudeInMeters,
        },
        ratings: {
          load: 0,
          muscle: 0,
          mental: 0,
          injury: 0,
          illness: 0,
          sleep: 0,
          food: 0,
          mood: 0,
          energy: 0,
        },
      },

      dateKey,
    };
    if (!!note) {
      payload.ratingsData.comments = {performance_test: note};
    }

    questions.forEach((q) => {
      if (!payload.ratingsData[q.targetObject]) {
        payload.ratingsData[q.targetObject] = {};
      }

      const isRangeQuestion =
        q.targetObject === RATINGS_OBJECT_NAME && q.questionType === 'rating';
      if (isRangeQuestion) {
        payload.ratingsData[q.targetObject][q.targetKey] =
          parseInt(q.value as string) || 0;
      } else {
        payload.ratingsData[q.targetObject][q.targetKey] = q.value;
      }
    });

    console.log('payload for form:', payload);
    setDayRatings(payload);
  };

  const renderQuestion = ({item, index}: {item: IQuestion; index: number}) => {
    const onValueChanged = (value: number | boolean) => {
      if (!questions[index]) {
        return;
      }
      questions[index].value = value;
      setQuestions([...questions]);
      setHasUnsavedChanges(true);
    };
    return (
      <QuestionItem
        key={index}
        question={item}
        index={index}
        onValueChanged={onValueChanged}
        hasValues={!!rating?.values}
      />
    );
  };

  return rating ? (
    <Div style={{flex: 1}}>
      <Div
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 12,
          paddingHorizontal: 8,
        }}
      >
        <HeartInfo value={heartInfo} onHeartInfoSubmited={setHeartInfo} />
        <TravelInfo value={travelInfo} onTravelInfoSubmited={setTravelInfo} />
        <Note value={note} onNoteSubmited={setNote} />
      </Div>
      {questions.map((q, i) => renderQuestion({item: q, index: i}))}
      <CustomButton
        type="primary"
        title="Save"
        style={{marginHorizontal: 12, marginTop: 20}}
        titleStyle={{color: GlobalStyles.colorsBasic.white500}}
        loader={{size: 'small', color: 'white'}}
        isLoading={loading}
        disabled={loading}
        onPress={submitRatings}
      />
    </Div>
  ) : null;
}

interface RatingsQuestionsProps {
  dateKey: string;
}
