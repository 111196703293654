import {StyleSheet} from 'react-native';
import {Div} from '../base/Div';
import {ToastShowParams} from 'react-native-toast-message';
import {AlertProps} from '../../types/Alert';
import useAlertProvider from '../../providers/useAlertProvider';
import {Paragraph} from '../base/Paragraph';
import {GlobalStyles} from '../../styles';

export default function Alert({text, type, title}: AlertProps) {
  const style = useAlertProvider().useAlertStyle(type);
  return (
    <Div style={[styles.alertContainer, style]}>
      {!!title ? <Paragraph>{title}</Paragraph> : null}
      <Paragraph translate style={styles.alertText}>
        {text}
      </Paragraph>
    </Div>
  );
}

export const toastConfig = {
  basic: (toast: ToastShowParams) => Alert(toast.props),
};

const styles = StyleSheet.create({
  alertContainer: {
    width: '90%',
    padding: 16,
    borderRadius: 5,
    alignSelf: 'center',
    maxWidth: 800,
  },
  alertText: {
    color: GlobalStyles.colorsBasic.white500,
  },
});
