import {StyleSheet} from 'react-native';
import {GlobalStyles} from '../../../styles';
import useAuthService from '../../../services/useAuthService';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import Input from '../../../components/base/Input';
import {Units} from '../../../types/IUser';

interface AltitudeProps {
  value: string;
  onValueChange: (value: string) => any;
}

const styles = StyleSheet.create({
  input: {
    fontSize: 13,
    borderWidth: 1,
    borderColor: GlobalStyles.colorsBasic.gray500,
    borderRadius: 5,
    padding: 5,
    width: '75%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  inputInfo: {
    marginLeft: 10,
    fontSize: 13,
  },
  altitudeLabel: {
    marginBottom: 10,
    fontSize: 14,
  },
});

export default function Altitude(props: AltitudeProps) {
  const user = useAuthService().useUser();
  return (
    <Div>
      <Paragraph style={styles.altitudeLabel}>Altitude</Paragraph>
      <Div style={styles.inputWrapper}>
        <Input
          style={styles.input}
          value={props.value}
          onTextChange={props.onValueChange}
          keyboardType="numbers-and-punctuation"
          type="number-and-punctuation"
        />
        <Paragraph style={styles.inputInfo}>
          {user?.units == Units.IMPERIAL ? 'feet' : 'meters'}
        </Paragraph>
      </Div>
    </Div>
  );
}
