import React from 'react';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import KeyboardAvoidingArea from '../../../components/base/KeyboardAvoidingArea';
import Modal from '../../../components/base/Modal';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import Altitude from '../shared/Altitude';
import Timezone from '../shared/Timezone';
import {useAddRatingsContext} from './AddRatingsContext';

export default function TravelInfo({
  value,
  onTravelInfoSubmited,
}: TravelInfoProps) {
  const [visible, setVisible] = React.useState(false);
  const [AltitudeError, setAltitudeError] = React.useState('');
  const openModal = () => {
    setTimeZone(value.timeZone);
    setAltitude(value.altitude);
    setVisible(true);
  };
  const closeModal = () => {
    setTimeZone(value.timeZone);
    setAltitude(value.altitude);
    setVisible(false);
  };
  const [timeZone, setTimeZone] = React.useState<number | string>(
    value.timeZone,
  );
  const [altitude, setAltitude] = React.useState(value.altitude);
  const {setHasUnsavedChanges} = useAddRatingsContext();
  const saveTravelInfo = () => {
    setAltitudeError('');
    if (altitude) {
      const altNum = parseFloat(altitude);
      if (isNaN(altNum)) {
        setAltitudeError('Altitude must be a number');
        return;
      } else if (altNum > 9999.99) {
        setAltitudeError('Altitude must be less then 10 000');
        return;
      }
    }
    onTravelInfoSubmited({timeZone: timeZone.toString(), altitude});
    setHasUnsavedChanges(true);
    setVisible(false);
  };
  return (
    <>
      <Icon
        name="compass"
        style={{marginHorizontal: 8}}
        size={22}
        onPress={openModal}
      />
      <Modal visible={visible} onBackgroundPress={closeModal}>
        <KeyboardAvoidingArea
          style={{flex: 1, flexDirection: 'row', justifyContent: 'center'}}
        >
          <Button
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            onPress={closeModal}
          />
          <Div
            style={{
              borderWidth: 1,
              borderRadius: 6,
              borderColor: GlobalStyles.svexa.green400,
              backgroundColor: GlobalStyles.colorsBasic.white500,
              alignSelf: 'center',
              marginHorizontal: 20,
              padding: 10,
              maxWidth: '95%',
            }}
            onStartShouldSetResponder={() => true}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            <Icon
              style={{marginLeft: 'auto'}}
              name="x"
              color={GlobalStyles.svexa.green400}
              onPress={closeModal}
              size={25}
            />
            <Paragraph style={{fontSize: 12, marginVertical: 15}}>
              {
                "When you're traveling, the weather, altitude and time zone might affect your readiness score. Please enter your current city's data for better accuracy."
              }
            </Paragraph>
            <Timezone value={timeZone} onValueChange={setTimeZone} />
            <Altitude value={altitude} onValueChange={setAltitude} />
            {AltitudeError ? (
              <Paragraph
                style={{fontSize: 11, color: GlobalStyles.colorsStatus.red}}
              >
                {AltitudeError}
              </Paragraph>
            ) : null}
            <CustomButton
              type="primary"
              title="Submit"
              style={{marginVertical: 20}}
              titleStyle={{color: GlobalStyles.colorsBasic.white500}}
              loader={{size: 'small', color: 'white'}}
              onPress={saveTravelInfo}
            />
          </Div>
        </KeyboardAvoidingArea>
      </Modal>
    </>
  );
}

export interface TravelInfoProps {
  onTravelInfoSubmited: (info: {timeZone: string; altitude: string}) => any;
  value: {timeZone: string; altitude: string};
}
