import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';
import {Paragraph} from '../../../components/base/Paragraph';
import TabList from '../../../components/shared/tab/TabList';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {GlobalStyles} from '../../../styles';
import {ITab} from '../../../types/Tab';
import Invitations from './Invitations';
import MyAthletes from './MyAthletes';

export default function AthletesTemplate() {
  const invitationsCount = useUserSettingsService().useAthleteInvitationCount();
  return (
    <KeyboardAvoidingScrollArea>
      <Div style={{marginTop: 12, margin: 24, maxWidth: 600}}>
        <HeadingTwo>Athletes</HeadingTwo>
        <Div>
          {invitationsCount ? (
            <Div style={style.countBadge}>
              <Paragraph style={style.countNumber}>
                {invitationsCount}
              </Paragraph>
            </Div>
          ) : null}
          <TabList tabs={athletesTabs} />
        </Div>
      </Div>
    </KeyboardAvoidingScrollArea>
  );
}

const athletesTabs: ITab[] = [
  {title: 'My Athletes', component: <MyAthletes />},
  {title: 'Invitations', component: <Invitations />},
];

const style = StyleSheet.create({
  countBadge: {
    position: 'absolute',
    borderRadius: 10,
    width: 20,
    height: 20,
    backgroundColor: GlobalStyles.colorsRatings.red,
    alignItems: 'center',
    justifyContent: 'center',
    left: 175,
    top: -8,
    zIndex: 99,
  },
  countNumber: {
    color: GlobalStyles.colorsBasic.white500,
    fontSize: 13,
  },
});
