import * as React from 'react';
import {ColorValue} from 'react-native';
import {ProgressBar as PaperProgressBar} from 'react-native-paper';

export default function ProgressBar({
  progress,
  color,
  backgroundColor,
  height,
  width,
  border,
  borderRadius,
}: ProgressBarProp) {
  return (
    <PaperProgressBar
      progress={progress}
      style={{
        backgroundColor,
        height,
        width,
        borderWidth: border,
        borderRadius,
      }}
      color={color}
    />
  );
}

export interface ProgressBarProp {
  progress: number;
  color: string;
  backgroundColor: ColorValue;
  height?: string | number;
  width?: string | number;
  border?: number;
  borderRadius?: number;
}
