import {StyleSheet} from 'react-native';
import {Div} from '../base/Div';
import {GlobalStyles} from '../../styles';

export default function Divider() {
  return <Div style={styles.divider}></Div>;
}

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: GlobalStyles.colorsBasic.gray500,
    marginVertical: 16,
  },
});
