import useAuthService from '../../../services/useAuthService';
import {GoogleAuth} from '../../../types/IUser';
import GoogleButton, {GoogleButtonProps} from './GoogleButton';

export default function GoogleAuthenticate({
  onSuccessAuthentication,
  title,
}: GoogleAuthenticateProps) {
  const {authenticate} = useAuthService().useGoogleAuth(
    onSuccessAuthentication,
  );
  return <GoogleButton onPress={authenticate} title={title} />;
}

interface GoogleAuthenticateProps extends GoogleButtonProps {
  onSuccessAuthentication: (auth: GoogleAuth) => any;
}
