import {DrawerNavigationProp} from '@react-navigation/drawer';
import * as React from 'react';
import {CoachStackParamList} from '../../types/Navigation';

export const CoachNavigationContext = React.createContext<DrawerNavigationProp<
  CoachStackParamList,
  any
> | null>(null);

export const useCoachNavigation = () =>
  React.useContext(CoachNavigationContext);
