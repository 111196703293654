import * as React from 'react';
import useAuthService from '../../services/useAuthService';
import {GlobalStyles} from '../../styles';
import {Div} from '../base/Div';
import Loader from '../base/Loader';
import {Paragraph} from '../base/Paragraph';

export default function AuthGuard({children}: AuthGuardProps) {
  const {user, hasValidToken} = useAuthService().useRefreshAuthUser();

  return !user ? null : (
    <>
      {hasValidToken ? (
        children
      ) : (
        <Div
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: GlobalStyles.colorsBasic.white500,
          }}
        >
          <Loader size={'large'} color={GlobalStyles.svexa.green600} />
          <Paragraph style={{marginTop: 8}}>
            Establishing auth session...
          </Paragraph>
        </Div>
      )}
    </>
  );
}

interface AuthGuardProps extends React.PropsWithChildren {}
