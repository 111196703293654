import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import useFormProvider from '../../providers/useFormProvider';
import {GlobalStyles} from '../../styles';
import {
  IForm,
  IFormControl,
  IFormControlState,
  IFormReference,
} from '../../types/Form';
import Button from '../base/Button';
import {Div} from '../base/Div';
import {HeadingThree} from '../base/HeadingThree';
import {Paragraph} from '../base/Paragraph';
import FormControl from './FormControl';

export default function Form<T>(props: FormProps<T>) {
  const {useFormBuilder, getNestedValueFromPath} = useFormProvider();
  const {submit, formState, onValueChange, setFormState} = useFormBuilder(
    props.form,
    props.entityState,
    props.onSubmit,
    props.cleanFormOnSubmit,
    props.formReference,
    props.onTouched,
  );

  function renderControl(F: IForm<T>, controlPath: string) {
    const control: IFormControl<T, any> = getNestedValueFromPath(
      F,
      controlPath,
    );
    const controlState: IFormControlState<T> = getNestedValueFromPath(
      formState,
      controlPath,
      'controls',
    );
    return !control.visibleIf ||
      (control.visibleIf && control.visibleIf(formState)) ? (
      <Div
        style={[
          {
            marginBottom: 10,
          },
          control.inline
            ? {flexDirection: 'row', alignItems: 'center', flex: 1}
            : {},
        ]}
        key={controlPath}
      >
        {control.label ? (
          <Paragraph style={{marginBottom: 5}}>{control.label}</Paragraph>
        ) : null}
        <FormControl
          control={control}
          value={controlState.value}
          error={controlState.error}
          onChange={(value: string) => {
            let alteredState;
            if (control.onPreChange) {
              alteredState = control.onPreChange(value, formState);
            }
            onValueChange(controlPath, value, alteredState);
          }}
          submit={submit}
          formState={formState}
        />
        {control.hint && !controlState.error ? (
          <Paragraph
            style={{
              color: GlobalStyles.colorsStatus.blue,
              fontSize: 12,
              marginTop: 1,
            }}
          >
            {control.hint}
          </Paragraph>
        ) : null}
        {controlState.error ? (
          <Paragraph
            style={{
              color: GlobalStyles.colorsStatus.red,
              fontSize: 12,
              marginTop: 1,
            }}
          >
            {controlState.error}
          </Paragraph>
        ) : null}
      </Div>
    ) : null;
  }

  function renderGroup(F: IForm<T>, groupPath: string) {
    const group: IFormControl<T, any> = getNestedValueFromPath(F, groupPath);
    return (
      <Div style={group.style} key={groupPath}>
        {group.label ? (
          <HeadingThree style={{marginBottom: 15}}>{group.label}</HeadingThree>
        ) : null}
        {group.controls ? (
          <Div
            style={
              group.inline
                ? {
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }
                : {}
            }
          >
            {renderControls(F, groupPath + '.controls')}
          </Div>
        ) : null}
      </Div>
    );
  }

  function renderControls(F: IForm<T>, keyPath: string) {
    const f: IForm<T> = getNestedValueFromPath(F, keyPath);
    return Object.keys(f).map((fKey) => {
      const path = keyPath ? keyPath + '.' + fKey : fKey;
      const k = fKey as keyof T;
      if (f[k].type == 'formGroup') {
        return renderGroup(F, path);
      }
      return renderControl(F, path);
    });
  }

  return (
    <Div style={props.style}>
      {renderControls(props.form, '')}
      {!!props.submitControl ? (
        props.submitControl(submit)
      ) : (
        <Button title="Submit" onPress={submit} />
      )}
    </Div>
  );
}

interface FormProps<T> {
  form: IForm<T>;
  onSubmit: (form: T) => any;
  entityState?: T;
  style?: StyleProp<ViewStyle>;
  submitControl?: (submit: () => any) => React.ReactElement;
  cleanFormOnSubmit?: boolean;
  formReference?: React.MutableRefObject<IFormReference<T>>;
  onTouched?: () => any;
}
