import React, {useEffect} from 'react';
import environment from '../../../../config/environment';
import useAuthService from '../../../services/useAuthService';
import {AppleAuth} from '../../../types/IUser';
import AppleButton, {AppleButtonProps} from './AppleButton';
declare let window: typeof globalThis;
declare let document: typeof globalThis;

export default function AppleAuthenticate({
  onSuccessAuthentication,
  title,
  disabled,
  isLoading,
  loader,
}: AppleAuthenticateProps) {
  const auth = async () => {
    await window.AppleID.auth.init({
      clientId: environment.apple.clientId,
      redirectURI: window.location.href,
      scope: 'name email',
      state: 'auth request',
      usePopup: true,
    });
    try {
      const data = await window.AppleID.auth.signIn();
      authenticate(data);
    } catch (error) {
      console.log('APPLE WEB AUTH ERROR', error);
    }
  };
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const {authenticate} = useAuthService().useAppleAuthWeb(
    onSuccessAuthentication,
  );
  return (
    <AppleButton
      onPress={auth}
      title={title}
      disabled={disabled}
      isLoading={isLoading}
      loader={loader}
    />
  );
}

interface AppleAuthenticateProps extends AppleButtonProps {
  onSuccessAuthentication: (auth: AppleAuth) => void;
}
