import React from 'react';
import {Div} from '../../../../components/base/Div';
import {HeadingThree} from '../../../../components/base/HeadingThree';
import Modal from '../../../../components/base/Modal';
import {Paragraph} from '../../../../components/base/Paragraph';
import CustomButton from '../../../../components/shared/CustomButton';
import useCoachService from '../../../../services/useCoachService';
import {GlobalStyles} from '../../../../styles';
import {useCurrentGroup} from '../GroupContext';

export default function DeleteTeam() {
  const group = useCurrentGroup();
  const {deleteGroup, deleting} = useCoachService().useDeleteGroup();
  const [visible, setVisible] = React.useState(false);
  const close = () => setVisible(false);
  const open = () => setVisible(true);
  const onDeleteGroup = () => {
    deleteGroup({groupId: group?.id || ''});
  };
  return (
    <Div
      style={{
        paddingHorizontal: 12,
      }}
    >
      <Paragraph>
        By deleting the team, you can no longer access to the ratings of any
        member in the team.
      </Paragraph>
      <Paragraph>This action is irreversible and can not be undone.</Paragraph>
      <CustomButton
        type="danger"
        title="Delete"
        style={{marginTop: 20, width: 100}}
        titleStyle={{color: GlobalStyles.colorsBasic.white500}}
        onPress={open}
      />
      <Modal visible={visible} onBackgroundPress={close}>
        <Div
          style={{
            backgroundColor: 'white',
            width: '80%',
            maxWidth: 400,
            paddingHorizontal: 30,
            paddingVertical: 20,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            borderColor: 'lightgrey',
            borderRadius: 10,
          }}
        >
          <HeadingThree
            style={{paddingTop: 10, paddingBottom: 30, textAlign: 'center'}}
          >
            <Paragraph>Are you sure you want to delete team</Paragraph>
            <Paragraph> {group?.name}</Paragraph>
          </HeadingThree>
          <CustomButton
            type="danger"
            title="Delete"
            style={{width: '100%', marginVertical: 10}}
            onPress={onDeleteGroup}
            isLoading={deleting}
            disabled={deleting}
            loader={{size: 'small', color: 'black'}}
            titleStyle={{color: GlobalStyles.colorsBasic.white500}}
          />
          <CustomButton
            type="secondary"
            title="Cancel"
            style={{width: '100%', marginVertical: 10}}
            onPress={close}
          />
        </Div>
      </Modal>
    </Div>
  );
}
