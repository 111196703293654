import {StyleSheet} from 'react-native';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import Divider from '../../../components/shared/Divider';
import CustomButton from '../../../components/shared/CustomButton';
import Image from '../../../components/base/Image';
import assets from '../../../constants/Assets';
import {GlobalStyles} from '../../../styles';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {AuthProviders} from '../../../types/IUser';
import Icon from '../../../components/base/Icon';
import Loader from '../../../components/base/Loader';
import useAuthService from '../../../services/useAuthService';
import {authProvidersDescriptionMethod} from '../../../constants/Settings';
import Form from '../../../components/shared/Form';
import DeleteAccount from './DeleteAccount';

const Accounts = () => {
  const {useConnectedAccounts, useChangePassword} = useUserSettingsService();
  const {loading, accounts} = useConnectedAccounts();
  const {processing, change, form} = useChangePassword();
  const user = useAuthService().useUser();

  /* const additionalAccounts = (accounts: IAccountEmail[]) =>
    accounts.filter((a) => a.email != user?.email || a.providers.length > 1); */

  return accounts ? (
    <Div>
      <HeadingThree>Account</HeadingThree>
      <Div style={styles.section}>
        <Paragraph>
          <Paragraph>You signed in with</Paragraph>{' '}
          <Paragraph style={styles.signinMethods}>
            {
              authProvidersDescriptionMethod[
                user?.authProvider || AuthProviders.SVEXA
              ]
            }{' '}
            <Paragraph>login method</Paragraph>
          </Paragraph>{' '}
          <Paragraph>with the following email:</Paragraph>
        </Paragraph>
        <Div style={styles.accountsWrapper}>
          {getProviderComponent(user?.authProvider || AuthProviders.SVEXA)}
          <Paragraph style={{marginLeft: 5}}>{user?.email}</Paragraph>
        </Div>
        {user?.email && user?.authProvider == AuthProviders.SVEXA ? (
          <Div style={styles.section}>
            <Paragraph style={styles.changePasswordParagraph}>
              You can change the password for username/password login method any
              time.
            </Paragraph>
            <Form
              style={{marginTop: 15}}
              form={form}
              onSubmit={change}
              cleanFormOnSubmit
              submitControl={(submit) => (
                <CustomButton
                  title="Change password"
                  onPress={submit}
                  type="primary"
                  titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                  isLoading={processing}
                  disabled={processing}
                  loader={{size: 'small', color: 'black'}}
                />
              )}
            />
          </Div>
        ) : null}
        {/* NOT NEEDED FOR NOW */}
        {/* <Divider />
        <Div>
          <HeadingThree>Connected accounts</HeadingThree>
          {additionalAccounts(accounts).length ? (
            additionalAccounts(accounts).map((ac, i) => (
              <Div key={i}>
                {ac.providers.map((provider, ip) => (
                  <Div style={styles.accountsWrapper} key={ip}>
                    {getProviderComponent(provider)}
                    <Paragraph style={{marginLeft: 5}}>{ac.email}</Paragraph>
                  </Div>
                ))}
              </Div>
            ))
          ) : (
            <Paragraph>No connected accounts yet.</Paragraph>
          )}
        </Div>

        <Div style={styles.accounts}>
          <HeadingThree>Connect an account</HeadingThree>
          <AppleButton title="Connect Apple Account" />
          <GoogleButton title="Connect Google Account" />
        </Div>*/}
      </Div>

      <Divider />

      <DeleteAccount />
    </Div>
  ) : loading ? (
    <Div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <Loader size="large" color={GlobalStyles.svexa.green400} />
    </Div>
  ) : (
    <></>
  );
};

export default Accounts;

const getProviderComponent = (provider: AuthProviders) => {
  switch (provider) {
    case AuthProviders.APPLE:
      return (
        <Image
          style={styles.accountsWrapperImage}
          source={assets.images.appleLogo}
          height={25}
          width={25}
        />
      );
    case AuthProviders.GOOGLE:
      return (
        <Image
          style={styles.accountsWrapperImage}
          source={assets.images.googleLogo}
          height={25}
          width={25}
        />
      );
    default:
      return <Icon name="mail" size={25} />;
  }
};

const styles = StyleSheet.create({
  section: {
    paddingVertical: 5,
  },
  signinMethods: {
    fontWeight: '700',
  },
  accountsWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  accountsWrapperImage: {
    marginRight: 8,
  },
  changePasswordParagraph: {
    marginTop: 5,
  },
  email: {
    fontWeight: '700',
    paddingVertical: 8,
  },
  accounts: {
    marginTop: 15,
  },
});
