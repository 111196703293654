import {StyleSheet} from 'react-native';
import {GlobalStyles} from '../../styles';
import Button from '../base/Button';
import {Div} from '../base/Div';
import {Paragraph} from '../base/Paragraph';

interface ToggleSelectProps<T> {
  value: T;
  values: Array<{label: string; value: T}>;
  setValue: (value: T) => void;
  width?: number;
}

function ToggleSelect<T>({
  value,
  values,
  setValue,
  width,
}: ToggleSelectProps<T>) {
  return (
    <Div style={[styles.container, width ? {width} : {width: 250}]}>
      {values.map((item, index) => (
        <Button
          key={index}
          style={[
            styles.toggleItem,
            item.value === value ? styles.activeToggleItem : {},
            {flex: 1 / values.length},
          ]}
          onPress={() => setValue(item.value)}
        >
          <Paragraph
            style={[
              styles.toggleItemText,
              item.value === value ? styles.activeToggleItemText : {},
            ]}
          >
            {item.label}
          </Paragraph>
        </Button>
      ))}
    </Div>
  );
}

export default ToggleSelect;

const styles = StyleSheet.create({
  container: {
    borderColor: GlobalStyles.colorsBasic.gray300,
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    backgroundColor: GlobalStyles.colorsBasic.gray300,
    flexDirection: 'row',
  },
  toggleItem: {
    padding: 5,
  },
  toggleItemText: {
    fontSize: 14,
    textAlign: 'center',
    color: GlobalStyles.colorsBasic.gray500,
  },
  activeToggleItem: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    borderRadius: 5,
  },
  activeToggleItemText: {
    color: GlobalStyles.colorsBasic.black500,
  },
});
