import {StyleSheet} from 'react-native';
import {List} from 'react-native-paper';

import {AthleteStackScreenProps} from '../../../types/Navigation';
import AuthGuard from '../../../components/shared/AuthGuard';
import ScreenLayout from '../../../components/shared/ScreenLayout';

import AthleteHeader from '../../athlete/shared/AthleteHeader';
import {Div} from '../../../components/base/Div';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import {Paragraph} from '../../../components/base/Paragraph';
import {GlobalStyles} from '../../../styles';
import {AthleteNavigationContext} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import Container from '../../../components/shared/Container';
import useConfigService from '../../../services/useConfigService';
import KeyboardAvoidingScrollArea from '../../../components/base/KeyboardAvoidingScrollArea';

export default function AboutScreen({
  navigation,
}: AthleteStackScreenProps<'about'>) {
  const {platformVersions} = useConfigService().usePlatformVersions();

  return (
    <AuthGuard>
      <AthleteNavigationContext.Provider value={navigation}>
        <ScreenLayout>
          <AthleteHeader />
          <Container>
            <KeyboardAvoidingScrollArea>
              <Div style={styles.pageContainer}>
                <HeadingTwo>About svexa</HeadingTwo>
                <Paragraph style={styles.paragraph}>
                  At svexa, we work every day to enable our partners to extract
                  the most from their technology and data. In recent years the
                  collection of data has become a global mantra, but we believe
                  there is much more potential to unlock.
                </Paragraph>
                <Paragraph style={styles.paragraph}>
                  Our broader vision is that sports continues to be a valuable
                  driver of positive change in communities across the globe, and
                  that we should use our scientific expertise and resources to
                  enable that positive impact as much as we can.
                </Paragraph>
                <HeadingTwo>Version history</HeadingTwo>
                <List.Section>
                  {platformVersions?.map((item) => (
                    <List.Accordion
                      key={item.version}
                      title={`Version ${item.version}`}
                      titleStyle={{color: GlobalStyles.colorsBasic.black500}}
                      style={styles.listAccordion}
                    >
                      {item.descriptions.map((description, index) => (
                        <List.Item
                          left={() => <Div style={styles.listStyle} />}
                          style={{
                            marginHorizontal: 5,
                          }}
                          key={item.version + index}
                          titleNumberOfLines={10}
                          title={description}
                          titleStyle={{fontSize: 14}}
                        />
                      ))}
                    </List.Accordion>
                  ))}
                </List.Section>
              </Div>
            </KeyboardAvoidingScrollArea>
          </Container>
        </ScreenLayout>
      </AthleteNavigationContext.Provider>
    </AuthGuard>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    flexDirection: 'column',
    margin: 24,
    marginTop: 12,
    maxWidth: 600,
  },
  paragraph: {
    marginVertical: 16,
  },
  listAccordion: {
    color: GlobalStyles.colorsBasic.black500,
    backgroundColor: GlobalStyles.colorsBasic.gray200,
    marginBottom: 5,
  },
  listStyle: {
    width: 8,
    height: 8,
    backgroundColor: GlobalStyles.svexa.green400,
    borderRadius: 8,
    alignSelf: 'center',
  },
});
