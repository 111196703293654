import {ReactNode} from 'react';
import {defaultLanguage, translations} from '../constants/Language';
import {useAppDispatch, useAppSelector} from '../store';
import * as Localization from 'expo-localization';
import {configActions} from '../store/reducers/ConfigReducer';
import {Language} from '../types/ILanguage';

export default function useLocalizationProvider() {
  const dispatch = useAppDispatch();
  function useLanguage() {
    return useAppSelector(({config}) => config.language);
  }

  function setLanguage(lang: Language) {
    dispatch(configActions.setLanguage(lang));
  }

  const language = useLanguage();

  function translate<T = string | ReactNode>(child: T): T {
    if (typeof child !== 'string') {
      return child;
    }
    let translationJson = translations[language];
    if (!translationJson) {
      translationJson = translations[defaultLanguage];
    }
    let text = translationJson[child];
    if (!text) {
      text = translations[defaultLanguage][child];
    }
    if (!text) {
      return child;
    }
    return text as any as T;
  }

  function getLocales() {
    return Localization.getLocales();
  }

  function getCalendars() {
    return Localization.getCalendars();
  }

  return {translate, useLanguage, getLocales, getCalendars, setLanguage};
}
