import React from 'react';
import ConfigGateway from '../gateways/ConfigGateway';
import {useAppDispatch, useAppSelector} from '../store';
import {configActions} from '../store/reducers/ConfigReducer';
import {IQuestion, QuestionTypes} from '../types/IQuestion';
import {IRating} from '../types/IRatings';
import useLocalizationProvider from './useLocalizationProvider';
import {useRequest} from './useRequestProvider';

export default function useQuestionProvider() {
  const language = useLocalizationProvider().useLanguage();
  const dispatch = useAppDispatch();
  function useQuestions(dateKey: string) {
    const questions = useAppSelector(({config}) => config.questions);
    const levels = useAppSelector(({config}) => config.levels);
    const {execute, loading} = useRequest(ConfigGateway.getQuestions, {
      onCompleted: (data) => {
        if (data) {
          dispatch(configActions.setQuestions(data.questions));
          dispatch(configActions.setLeves(data.levels));
          dispatch(configActions.setQuestionVersion(data.version));
        }
      },
    });

    React.useEffect(() => {
      if (!dateKey) return;
      execute({dateKey});
    }, [dateKey, language]);

    return {fetch: execute, loading, questions, levels};
  }

  function useRatingsQuestions(rating: IRating) {
    const {questions: ratingsQuestions, loading} = useQuestions(
      rating?.dateKey,
    );

    function addRatingValuesToQuestions() {
      const valueQuestions: IQuestion[] = [];
      (ratingsQuestions || []).forEach((q) => {
        let defaultValue = 0;
        switch (q.questionType) {
          case QuestionTypes.yesNo:
            defaultValue = 0;
            break;
          case QuestionTypes.rating:
            defaultValue = q.minValue || 0;
            break;
          default:
            break;
        }
        let value = defaultValue;
        if (
          rating &&
          rating[q.targetObject] &&
          rating[q.targetObject][q.targetKey] != undefined &&
          rating[q.targetObject][q.targetKey] != null
        ) {
          value = rating[q.targetObject][q.targetKey];
        }
        valueQuestions.push({
          ...q,
          value,
        });
      });
      return valueQuestions;
    }

    const [questions, setQuestions] = React.useState(
      addRatingValuesToQuestions(),
    );

    React.useEffect(() => {
      setQuestions(addRatingValuesToQuestions());
    }, [rating, ratingsQuestions]);

    return {questions, setQuestions, loading};
  }

  return {useQuestions, useRatingsQuestions};
}
