import {StyleSheet} from 'react-native';
import Button, {ButtonProps} from '../base/Button';
import {GlobalStyles} from '../../styles';

export default function CustomButton(props: CustomButtonProps) {
  const buttonStyle = StyleSheet.create({
    basic: {
      paddingVertical: 10,
      paddingHorizontal: 15,
      marginVertical: 8,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },
    primary: {
      backgroundColor: GlobalStyles.svexa.green500,
    },
    secondary: {
      borderWidth: 1,
      borderColor: GlobalStyles.svexa.green500,
    },
    tertiary: {
      backgroundColor: 'transparent',
    },
    disable: {
      backgroundColor: GlobalStyles.colorsBasic.gray400,
    },
    danger: {
      backgroundColor: GlobalStyles.colorsStatus.red,
    },
  });
  return (
    <Button
      {...props}
      style={[buttonStyle.basic, buttonStyle[props.type], props.style]}
    />
  );
}

interface CustomButtonProps extends ButtonProps {
  type: 'primary' | 'secondary' | 'tertiary' | 'disable' | 'danger';
}
