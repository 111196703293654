import React from 'react';
import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import useRatingService from '../../../services/useRatingService';
import RatingsChart from './ratings-chart/RatingsChart';
import {Paragraph} from '../../../components/base/Paragraph';
import {HeadingThree} from '../../../components/base/HeadingThree';
import moment from 'moment';
import DateTimPicker from '../../../components/shared/date-time-picker/DateTimePicker';
import {IRating} from '../../../types/IRatings';
import Icon from '../../../components/base/Icon';
import Button from '../../../components/base/Button';
import {minYear} from '../../../constants/Config';

export default function PastRatings({
  onRatingSelected,
  dateKey,
}: IPastRatingsProps) {
  const initDiff = 13; // 14 days span
  const {getMomentRange} = useDateTimeProvider();
  const [daysSpanDiff, setDaysSpanDiff] = React.useState(initDiff);
  const [dateSpan, setDateSpan] = React.useState(getMomentRange(initDiff));
  const [numberOfBars, setNumberOfBars] = React.useState(initDiff + 1);
  const {useFetchUserRatings, useReversePeriodRatings} = useRatingService();
  const {fetch} = useFetchUserRatings();

  const periodRatings = useReversePeriodRatings(
    daysSpanDiff,
    dateSpan.to.clone(),
  );

  const onFromDateSelected = (value: Date) => {
    const dateFrom = moment(value).startOf('day');
    let dateTo = dateSpan.to.clone().startOf('day');
    if (dateTo.diff(dateFrom, 'days') < initDiff) {
      dateTo = moment(dateFrom).add(initDiff, 'days');
    }
    if (dateTo.diff(dateFrom, 'days') > 90) {
      dateTo = moment(dateFrom).add(90, 'days');
    }
    const diff = dateTo.diff(dateFrom, 'days') + 1;
    fetch({
      span: diff.toString(),
      dateKey: dateTo.format('YYYYMMDD'),
    });
    setDaysSpanDiff(diff - 1);
    setDateSpan({
      from: dateFrom,
      to: dateTo,
    });
  };

  const onToDateSelected = (value: Date) => {
    const dateTo = moment(value).startOf('day');
    let dateFrom = dateSpan.from.clone().startOf('day');
    if (dateTo.diff(dateFrom, 'days') < initDiff) {
      dateFrom = moment(dateTo).subtract(initDiff, 'days');
    }
    if (dateTo.diff(dateFrom, 'days') > 90) {
      dateFrom = moment(dateTo).subtract(90, 'days');
    }
    const diff = dateTo.diff(dateFrom, 'days') + 1;
    fetch({
      span: diff.toString(),
      dateKey: dateTo.format('YYYYMMDD'),
    });
    setDaysSpanDiff(diff - 1);
    setDateSpan({
      from: dateFrom,
      to: dateTo,
    });
  };

  const selectBars = (bars: number) => {
    const newNumberOfBars = bars + numberOfBars;
    if (newNumberOfBars - 1 > daysSpanDiff) {
      const newDiff = newNumberOfBars - 1;
      fetch({
        span: newNumberOfBars.toString(),
        dateKey: dateSpan.to.format('YYYYMMDD'),
      });
      setDateSpan({
        to: dateSpan.to,
        from: dateSpan.to.clone().subtract(newDiff, 'days'),
      });
      setDaysSpanDiff(newDiff);
    }
    setNumberOfBars(newNumberOfBars);
  };

  return (
    <Div>
      <HeadingThree>Your history</HeadingThree>
      <Div style={styles.dateRange}>
        <Div>
          <Paragraph>From</Paragraph>
          <DateTimPicker
            value={dateSpan.from.toDate()}
            minDate={moment().year(minYear).startOf('year').toDate()}
            maxDate={moment().subtract(initDiff, 'days').toDate()}
            onChange={onFromDateSelected}
          />
        </Div>
        <Div>
          <Paragraph>To</Paragraph>
          <DateTimPicker
            value={dateSpan.to.toDate()}
            minDate={moment()
              .year(minYear)
              .startOf('year')
              .add(6, 'days')
              .toDate()}
            maxDate={new Date()}
            onChange={onToDateSelected}
          />
        </Div>
      </Div>
      <Div style={styles.zoomButtonGroup}>
        {numberOfBars != 21 ? (
          <Button style={styles.zoomButton} onPress={() => selectBars(7)}>
            <Icon name="zoom-out" size={15} />
            <Paragraph style={{marginLeft: 5}}>zoom out</Paragraph>
          </Button>
        ) : null}
        {numberOfBars != 7 ? (
          <Button
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginHorizontal: 12,
            }}
            onPress={() => selectBars(-7)}
          >
            <Icon name="zoom-in" size={15} />
            <Paragraph style={{marginLeft: 5}}>zoom in</Paragraph>
          </Button>
        ) : null}
      </Div>
      <Paragraph style={{marginVertical: 10}}>
        {dateSpan.from.isSame(dateSpan.to, 'months') ? (
          `${dateSpan.from.format('MMMM')}`
        ) : (
          <>
            <Paragraph>{dateSpan.from.format('MMMM')}</Paragraph>
            <Paragraph> - </Paragraph>
            <Paragraph>{dateSpan.to.format('MMMM')}</Paragraph>
          </>
        )}
      </Paragraph>
      <RatingsChart
        ratings={periodRatings}
        onRatingSelected={onRatingSelected}
        numberOfBars={numberOfBars}
        activeDateKey={dateKey}
      />
    </Div>
  );
}

const styles = StyleSheet.create({
  dateRange: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  zoomButtonGroup: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  zoomButton: {
    flexDirection: 'row',
    marginRight: 24,
  },
});

interface IPastRatingsProps {
  onRatingSelected: (rating: IRating) => any;
  dateKey: string;
}
