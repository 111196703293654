import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import Divider from '../../../components/shared/Divider';
import Form from '../../../components/shared/Form';
import usePermissionsService from '../../../services/usePermissionsService';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {GlobalStyles} from '../../../styles';
import {renderPermissionItem} from '../../shared/PermissionItem';

export default function SendInvitation() {
  const {useSendAthleteInvite, useMyInvitedAthletes} = usePermissionsService();
  const {translate} = useLocalizationProvider();
  const invitedAthletes = useMyInvitedAthletes();
  const {send, loading, form, refetching} = useSendAthleteInvite();

  return (
    <>
      <Div style={{marginBottom: 10}}>
        <HeadingThree>
          {translate('Ask an athlete to view their data.')}
        </HeadingThree>
        <Form
          form={form}
          onSubmit={send}
          cleanFormOnSubmit
          submitControl={(submit) => (
            <CustomButton
              title="Send Invite"
              type="primary"
              style={{
                alignSelf: 'flex-end',
              }}
              titleStyle={{
                color: GlobalStyles.colorsBasic.white500,
              }}
              disabled={loading || refetching}
              isLoading={loading}
              loader={{size: 'small', color: GlobalStyles.colorsBasic.white500}}
              onPress={submit}
            />
          )}
        />
      </Div>
      <Divider />
      <Div style={{maxWidth: 400}}>
        <HeadingThree>Pending</HeadingThree>
        <Paragraph style={{marginBottom: 10}}>
          {
            "Athletes in pending state haven't accepted your invitation, and you can't view their ratings."
          }
        </Paragraph>
        {invitedAthletes.length ? (
          <Div>
            {invitedAthletes.map((athlete, index) =>
              renderPermissionItem({
                item: athlete,
                index,
                type: athlete.id ? 'athlete' : 'athleteEmail',
              }),
            )}
          </Div>
        ) : (
          <Paragraph>No athletes in pending state at the moment.</Paragraph>
        )}
      </Div>
    </>
  );
}
