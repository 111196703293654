import React from 'react';
import {Div} from '../../../../components/base/Div';
import {HeadingThree} from '../../../../components/base/HeadingThree';
import Icon from '../../../../components/base/Icon';
import DraggableModal, {
  DraggableModalRef,
} from '../../../../components/shared/DraggableModal';
import {GlobalStyles} from '../../../../styles';
import {useCurrentGroup} from '../GroupContext';
import InviteGroupMember from '../InviteGroupMember';
import TabList from '../../../../components/shared/tab/TabList';
import {ITab} from '../../../../types/Tab';
import TeamActiveCoaches from './TeamActiveCoaches';
import TeamPendingCoaches from './TeamPendingCoaches';
import {useAppSelector} from '../../../../store';

export default function TeamCoaches() {
  const group = useCurrentGroup();
  const admins = useAppSelector(
    ({coach}) => coach.groupAdmins[group?.id || ''],
  );
  const addMembersRef = React.useRef<DraggableModalRef>({
    open: () => 1,
    close: () => 0,
  });
  const closeModal = () => addMembersRef.current?.close();
  return (
    <Div>
      <Div style={{marginBottom: 10}}>
        <Div style={{flexDirection: 'row', alignItems: 'center'}}>
          <HeadingThree>Coaches:</HeadingThree>
          <Icon
            name="user-plus"
            color={GlobalStyles.svexa.green500}
            size={22}
            style={{marginLeft: 'auto'}}
            onPress={addMembersRef.current?.open}
          />
        </Div>
        <TabList tabs={CoachesTabList} />
      </Div>
      <DraggableModal reference={addMembersRef}>
        <InviteGroupMember
          type="coachColleagues"
          onComplete={closeModal}
          members={admins?.admins || {}}
        />
      </DraggableModal>
    </Div>
  );
}

const CoachesTabList: ITab[] = [
  {title: 'Active', component: <TeamActiveCoaches />},
  {title: 'Pending', component: <TeamPendingCoaches />},
];
