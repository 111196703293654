import {Div} from '../../../../components/base/Div';
import Accordion from '../../../../components/shared/accordion/Accordion';
import AccordionList from '../../../../components/shared/accordion/AccordionList';
import {GlobalStyles} from '../../../../styles';
import DeleteTeam from './DeleteTeam';
import EditTeam from './EditTeam';

export default function TeamSettings() {
  return (
    <Div>
      <AccordionList>
        <Accordion
          title="Team Profile"
          style={{borderTopColor: GlobalStyles.colorsBasic.white500}}
        >
          <EditTeam />
        </Accordion>
        <Accordion
          title="Delete team"
          titleStyle={{color: GlobalStyles.colorsRatings.red}}
        >
          <DeleteTeam />
        </Accordion>
      </AccordionList>
    </Div>
  );
}
