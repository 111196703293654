/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import {LinkingOptions} from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {Platform} from 'react-native';

import {RootStackParamList} from '../types/Navigation';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  enabled: Platform.OS !== 'android',
  config: {
    screens: {
      'not-found': '*',
      'authorize-garmin': 'authorize-garmin',
      'authorize-oura': 'authorize-oura',
      'reset-password': 'password-reset-change',
      'email-verification': 'email-verification',
      /* 'delete-account': 'delete-account', */
      athlete: {
        path: 'athlete',
        initialRouteName: 'overview',
        screens: {
          overview: 'overview',
          'add-ratings': 'add-ratings',
          settings: 'settings',
          feedback: 'feedback',
          about: 'about',
          coaches: 'coaches',
        },
      },
      auth: {
        path: 'auth',
        initialRouteName: 'login',
        screens: {
          login: 'login',
          signup: 'signup',
          'signup-with-google': 'signup-with-google',
          'signup-with-apple': 'signup-with-apple',
          'signup-with-svexa': 'signup-with-svexa',
          'forgot-password': 'forgot-password',
          'token-signin': 'token-signin',
        },
      },
      coach: {
        path: 'coach',
        initialRouteName: 'teams',
        screens: {
          teams: 'teams',
          team: 'team',
          'add-team': 'add-team',
          athletes: 'athletes',
          settings: 'settings',
        },
      },
    },
  },
};

export default linking;
