import ExpoCheckbox from 'expo-checkbox';
import {StyleProp, ViewStyle} from 'react-native';
import {Div} from './Div';
import {Paragraph} from './Paragraph';
import {GlobalStyles} from '../../styles';
import React from 'react';

export default function CheckBox({
  value,
  onValueChange,
  disabled,
  style,
  title,
}: CheckBoxProps) {
  function renderTitle(
    title?: string | (() => React.ReactElement),
  ): React.ReactElement | null {
    if (!title) {
      return null;
    }
    if (typeof title === 'string') {
      return <Paragraph style={{marginLeft: 5}}>{title}</Paragraph>;
    }
    return title();
  }

  return (
    <Div style={{flexDirection: 'row', alignItems: 'center'}}>
      <ExpoCheckbox
        value={value}
        onValueChange={onValueChange}
        disabled={disabled}
        style={style}
        color={value ? GlobalStyles.svexa.green500 : undefined}
      />
      {renderTitle(title)}
    </Div>
  );
}

export interface CheckBoxProps {
  value: boolean;
  onValueChange: (value: boolean) => any;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  title?: string | (() => React.ReactElement);
}
