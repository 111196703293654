import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import UploadImageWithPreview from '../../../components/shared/upload-image/UploadImageWithPreview';
import assets from '../../../constants/Assets';
import {FormDataImage} from '../../../types/Image';

export default function TeamLogo({logoData, onImagePicked}: TeamLogoProps) {
  return (
    <Div style={styles.profilePhotoSection}>
      <Div style={styles.profilePhotoContainer}>
        <UploadImageWithPreview
          style={styles.profileUploadButtonWrapper}
          onImagePicked={onImagePicked}
          defaultPreview={assets.images.teamAvatar}
          previewStyle={styles.profilePhoto}
          imageData={logoData}
        />
      </Div>
    </Div>
  );
}

interface TeamLogoProps {
  logoData: FormDataImage | null;
  onImagePicked: (image: FormDataImage) => any;
}

const styles = StyleSheet.create({
  profilePhotoSection: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profilePhotoContainer: {
    position: 'relative',
    width: 160,
    height: 160,
  },
  profileUploadButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 4,
  },
  profilePhoto: {
    width: 160,
    height: 160,
    borderRadius: 100,
  },
});
