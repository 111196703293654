import {useNavigation} from '@react-navigation/core';
import {
  loginWithSvexaForm,
  resetPasswordForm,
  resetPasswordVerifyEmailForm,
} from '../constants/User';
import AuthGateway from '../gateways/AuthGateway';
import {useRequest} from '../providers/useRequestProvider';
import useAlertProvider from '../providers/useAlertProvider';
import {useAppDispatch, useAppSelector} from '../store';
import {authActions} from '../store/reducers/AuthReducer';
import {
  AppleAuth,
  AppleUser,
  GoogleAuth,
  IAppleAndroidResponse,
  IAppleJWT,
  IAppleWebReponse,
  IGoogleJWT,
  IUser,
  Views,
} from '../types/IUser';
import * as Google from 'expo-auth-session/providers/google';
import * as AppleAuthentication from 'expo-apple-authentication';
import environment from '../../config/environment';
import jwtDecode from 'jwt-decode';
import React from 'react';
import useAppProvider from '../providers/useAppProvider';
import useNotificationProvider from '../providers/useNotificationProvider';
import {configActions} from '../store/reducers/ConfigReducer';
import useLocalizationProvider from '../providers/useLocalizationProvider';
import {IResponse} from '../types/Request';

export default function useAuthService() {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const {success, danger} = useAlertProvider();
  const {getPushToken} = useNotificationProvider();
  const {translate} = useLocalizationProvider();
  const appleUsers = useAppSelector(({auth}) => auth.appleUsers);

  function useUser() {
    return useAppSelector(({auth}) => auth.user);
  }

  function useTemporaryLogout() {
    const {appState: state} = useAppProvider().useAppState();
    const user = useUser();
    const {logout} = useLogout();
    React.useEffect(() => {
      if (state === 'inactive' && user?.isTemporary) {
        logout({});
      }
    }, [state]);
  }

  function useLogout() {
    const {execute: logout} = useRequest(AuthGateway.logout, {
      onCompleted: () => {
        dispatch(authActions.setUser(null));
        dispatch(authActions.setUserImage(null));
        dispatch(authActions.setPushToken(null));
        navigation.reset({
          index: 0,
          routes: [{name: 'auth'}],
        });
      },
    });

    return {logout};
  }

  async function assignPushToken() {
    const token = await getPushToken();
    if (!token) return;
    try {
      await AuthGateway.setPushToken(token);
      dispatch(authActions.setPushToken(token));
    } catch (err) {
      console.log('Error on push token assign! ', err);
      return;
    }
  }

  function onSignupError(response: IResponse | null) {
    const errorText = translate('Registration error');
    const uknownErrorText = translate('Unknown error');
    const translatedErrorMessage = translate(response?.message);
    const errorSubtype = response?.subType ? response?.subType : 999;
    danger(
      response?.message
        ? `${errorText}: ${translatedErrorMessage} (${errorSubtype})`
        : uknownErrorText,
    );
  }

  async function onLoginSuccess(user: IUser | null, persist: boolean) {
    if (!user) {
      return;
    }
    assignPushToken();
    user.isTemporary = !persist;
    dispatch(authActions.setUser(user));
    if (user.preferredLanguage) {
      dispatch(configActions.setLanguage(user.preferredLanguage));
    }
    const view =
      user.defaultView == Views.ATHLETE || user.defaultView == Views.COACH
        ? user.defaultView
        : 'athlete';
    navigation.reset({
      index: 0,
      routes: [{name: view}],
    });
  }

  function onLoginError(response: IResponse | null) {
    const errorText = translate('Login error');
    const uknownErrorText = translate('Unknown error');
    const translatedErrorMessage = translate(response?.message);
    const errorSubtype = response?.subType ? response?.subType : 999;
    danger(
      response?.message
        ? `${errorText}: ${translatedErrorMessage} (${errorSubtype})`
        : uknownErrorText,
    );
  }

  function useLoginWithSvexa(persist: boolean) {
    const {data, loading, execute} = useRequest(AuthGateway.loginWithSvexa, {
      onCompleted: (user) => onLoginSuccess(user, persist),
      onError: onLoginError,
    });
    return {user: data, loading, login: execute, form: loginWithSvexaForm};
  }

  function useSignupWithSvexa() {
    function onSuccess(response: IResponse | null) {
      console.log('onSuccess', response);
      success(
        translate(
          'Account created, please verify your email and proceed to login.',
        ),
      );
      setTimeout(
        () => navigation.reset({index: 0, routes: [{name: 'auth'}]}),
        800,
      );
    }

    const {loading, execute} = useRequest(AuthGateway.signupWithSvexa, {
      onCompleted: onSuccess,
      onError: onSignupError,
    });

    return {loading, signup: execute};
  }

  function useForgotPasswordVerifyEmail() {
    function onSuccess() {
      success(translate('E-mail sent, please check your inbox'));
      navigation.canGoBack()
        ? navigation.goBack()
        : navigation.navigate('auth', {screen: 'login'});
    }
    const {loading, execute} = useRequest(
      AuthGateway.resetPasswordVerifyEmail,
      {onCompleted: onSuccess},
    );

    return {
      loading,
      forgotPasswordVerifyEmail: execute,
      form: resetPasswordVerifyEmailForm,
    };
  }

  function useResetPassword() {
    function onSuccess() {
      success(translate('Password changed successfully'));
      navigation.reset({index: 0, routes: [{name: 'auth'}]});
    }

    const {loading, execute} = useRequest(AuthGateway.resetPassword, {
      onCompleted: onSuccess,
    });

    return {
      form: resetPasswordForm,
      loading,
      resetPassword: execute,
    };
  }

  function useGoogleAuth(onAuthentication: (googleAuth: GoogleAuth) => void) {
    const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
      expoClientId: environment.google.expoClientId,
      webClientId: environment.google.webClientId,
      androidClientId: environment.google.androidClientId,
      iosClientId: environment.google.iosClientId,
      selectAccount: true,
    });
    async function authenticate() {
      await promptAsync();
    }
    React.useEffect(() => {
      if (!response) {
        return;
      }

      if (response.type === 'success' && response.params.id_token) {
        try {
          const decode = jwtDecode<IGoogleJWT>(response.params.id_token);
          onAuthentication({
            idToken: response.params.id_token,
            email: decode.email,
            givenName: decode.given_name || '',
            familyName: decode.family_name || '',
          });
        } catch (err) {
          danger('Google authorization failed');
        }
      }

      if (response.type === 'error') {
        danger('Google authorization failed');
        return;
      }
    }, [response]);

    return {authenticate};
  }

  function useLoginWithGoogle(persist: boolean) {
    const {loading, execute} = useRequest(AuthGateway.loginWithGoogle, {
      onCompleted: (user) => onLoginSuccess(user, persist),
      onError: onLoginError,
    });

    return {loading, login: execute};
  }

  function useSignupWithGoogle() {
    const {loading, execute} = useRequest(AuthGateway.signupWithGoogle, {
      onCompleted: (user) => onLoginSuccess(user, true),
      onError: onSignupError,
    });

    return {signup: execute, loading};
  }

  function getAndSetAppleUser(fetchedAppleUser: AppleUser): AppleUser {
    if (!fetchedAppleUser.email) return fetchedAppleUser;
    const appleUser = appleUsers[fetchedAppleUser.email];
    if (!appleUser) {
      dispatch(authActions.setAppleUser(fetchedAppleUser));
      return fetchedAppleUser;
    }
    appleUser.givenName = fetchedAppleUser.givenName || appleUser.givenName;
    appleUser.familyName = fetchedAppleUser.familyName || appleUser.familyName;
    dispatch(authActions.setAppleUser(appleUser));
    return appleUser;
  }

  function useAppleAuthNative(onAuthentication: (auth: AppleAuth) => void) {
    async function authenticate() {
      try {
        const credential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
          ],
        });
        const decoded = jwtDecode<IAppleJWT>(credential.identityToken!);
        const email = decoded?.email || credential.email || '';
        const givenName = credential.fullName?.givenName || '';
        const familyName = credential.fullName?.familyName || '';
        const appleUser = getAndSetAppleUser({email, givenName, familyName});
        onAuthentication({
          identityToken: credential.identityToken || '',
          authorizationCode: credential.authorizationCode || '',
          email: appleUser.email || '',
          givenName: appleUser.givenName || '',
          familyName: appleUser.familyName || '',
          fullName:
            typeof credential.fullName === 'string'
              ? credential.fullName
              : (credential.fullName?.givenName || '') +
                (credential.fullName?.familyName || ''),
        });
      } catch (e) {
        const error = e as any as {code: string; message: string};
        if (error.code === 'ERR_CANCELED') {
          return;
        } else {
          danger('Apple authorization failed');
        }
      }
    }

    return {authenticate};
  }

  function useAppleAuthWeb(onAuthentication: (auth: AppleAuth) => void) {
    function authenticate(paylod: IAppleWebReponse) {
      const decoded = jwtDecode<IAppleJWT>(paylod.authorization.id_token);
      const email = decoded?.email || paylod.user?.email || '';
      const givenName = paylod.user?.name?.firstName || '';
      const familyName = paylod.user?.name?.lastName || '';
      const appleUser = getAndSetAppleUser({email, givenName, familyName});
      onAuthentication({
        identityToken: paylod.authorization?.id_token || '',
        authorizationCode: paylod.authorization?.code || '',
        email: appleUser.email || '',
        givenName: appleUser.givenName || '',
        familyName: appleUser.familyName || '',
        fullName:
          (paylod.user?.name?.firstName || '') +
          (paylod.user?.name?.firstName && paylod.user?.name?.lastName
            ? ' '
            : '') +
          (paylod.user?.name?.lastName || ''),
      });
    }
    return {authenticate};
  }

  function useAppleAuthAndroid(onAuthentication: (auth: AppleAuth) => void) {
    async function authenticate(payload: IAppleAndroidResponse) {
      const decoded = jwtDecode<IAppleJWT>(payload.id_token);
      const email = decoded?.email || '';
      const appleUser = getAndSetAppleUser({
        email,
        givenName: '',
        familyName: '',
      });
      onAuthentication({
        identityToken: payload.id_token || '',
        authorizationCode: payload.code,
        email: appleUser.email || '',
        givenName: appleUser.givenName || '',
        familyName: appleUser.familyName || '',
        fullName: '',
      });
    }
    return {authenticate};
  }

  function useSignupWithApple() {
    const {loading, execute} = useRequest(AuthGateway.signupWithApple, {
      onCompleted: (user) => onLoginSuccess(user, true),
      onError: onSignupError,
    });

    return {signup: execute, loading};
  }

  function useLoginWithApple(persist: boolean) {
    const {loading, execute} = useRequest(AuthGateway.loginWithApple, {
      onCompleted: (user) => onLoginSuccess(user, persist),
      onError: onLoginError,
    });

    return {loading, login: execute};
  }

  function useRefreshAuthUser() {
    const {appState} = useAppProvider().useAppState();
    const user = useUser();

    const {execute} = useRequest(AuthGateway.refreshLoggedUser, {
      onCompleted: (data) => {
        if (data) {
          dispatch(authActions.updateUser(data));
        }
      },
      onError: (error) => {
        dispatch(authActions.setUser(null));
      },
    });

    function refresh() {
      execute({});
    }

    function isTokenValid() {
      return (user?.exp || 0) > Date.now() / 1000;
    }

    React.useEffect(() => {
      if (!user) {
        navigation.reset({index: 0, routes: [{name: 'auth'}]});
        return;
      }
      if (appState != 'active') {
        return;
      }
      if (!isTokenValid()) {
        refresh();
      }
    }, [appState, user]);

    return {hasValidToken: isTokenValid(), user};
  }

  return {
    useUser,
    useLoginWithSvexa,
    useSignupWithSvexa,
    useGoogleAuth,
    useForgotPasswordVerifyEmail,
    useResetPassword,
    useLoginWithGoogle,
    useSignupWithGoogle,
    useAppleAuthNative,
    useAppleAuthWeb,
    useAppleAuthAndroid,
    useLoginWithApple,
    useSignupWithApple,
    useTemporaryLogout,
    useLogout,
    useRefreshAuthUser,
  };
}
