import {ColorSchemeName, useColorScheme as _useColorScheme} from 'react-native';

export default function useLayoutProvider() {
  // The useColorScheme value is always either light or dark, but the built-in
  // type suggests that it can be null. This will not happen in practice, so this
  // makes it a bit easier to work with.
  function useColorScheme(): NonNullable<ColorSchemeName> {
    return _useColorScheme() as NonNullable<ColorSchemeName>;
  }

  return {useColorScheme};
}
