import {useWindowDimensions} from 'react-native';
import {Div} from '../../../components/base/Div';
import LanguagePicker from '../../../components/shared/language-picker/LanguagePicker';
import {maxContentWidth} from '../../../constants/Layout';

export default function AuthHeader() {
  const {width} = useWindowDimensions();
  return (
    <Div
      style={{
        paddingTop: 12,
        paddingBottom: 6,
        paddingRight: Math.max((width - maxContentWidth) / 2, 0) + 16,
        alignItems: 'flex-end',
      }}
    >
      <LanguagePicker />
    </Div>
  );
}
