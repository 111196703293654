import Button from '../../../../components/base/Button';
import {Div} from '../../../../components/base/Div';
import {Paragraph} from '../../../../components/base/Paragraph';
import useDateTimeProvider from '../../../../providers/useDateTimeProvider';
import useConfigService from '../../../../services/useConfigService';
import {GlobalStyles} from '../../../../styles';
import {IRating} from '../../../../types/IRatings';
import useBarChart from '../../../shared/useBarChart';

export default function RatingsChartBar({
  index,
  rating,
  onPress,
  numberOfBars,
  chartWidth,
  activeDateKey,
}: RatingsChartBarProps) {
  const levels = useConfigService().useLevels();
  const {dateFormatFromDateKey} = useDateTimeProvider();
  const onBarPress = () => onPress(rating);
  const {getChartBarWidth, getChartBarSpace, getChartBarFontSize} =
    useBarChart(chartWidth);

  return (
    <Div
      style={{justifyContent: 'flex-end', alignItems: 'center', marginTop: 5}}
      key={index}
    >
      <Button
        style={{
          width: getChartBarWidth(numberOfBars),
          marginHorizontal: getChartBarSpace(numberOfBars),
          height: 145,
          justifyContent: 'flex-end',
          marginVertical: 5,
          borderRadius: 8,
          backgroundColor: GlobalStyles.colorsBasic.gray300,
        }}
        onPress={onBarPress}
      >
        <Div
          style={{
            backgroundColor: rating?.values
              ? levels[!rating ? -1 : rating.level]?.color
              : 'transparent',
            height: rating.values ? 145 * rating.score : 145,
            width: getChartBarWidth(numberOfBars),
            borderRadius: 8,
          }}
        />
      </Button>
      <Button
        style={{
          padding: 3,
          backgroundColor:
            activeDateKey == rating.dateKey
              ? GlobalStyles.svexa.green500
              : GlobalStyles.colorsBasic.white500,
          borderRadius: 5,
        }}
        onPress={onBarPress}
      >
        <Paragraph
          style={{
            fontSize: getChartBarFontSize(numberOfBars),
            color:
              activeDateKey == rating.dateKey
                ? GlobalStyles.colorsBasic.white500
                : GlobalStyles.colorsBasic.black500,
          }}
        >
          {dateFormatFromDateKey(rating.dateKey, 'DD').toUpperCase()}
        </Paragraph>
      </Button>
    </Div>
  );
}

interface RatingsChartBarProps {
  index: number;
  rating: IRating;
  onPress: (rating: IRating) => any;
  numberOfBars: number;
  chartWidth: number;
  activeDateKey: string;
}
