import * as React from 'react';
import useAlertProvider from '../providers/useAlertProvider';
import {RequestOptions} from '../types/Request';
import useLocalizationProvider from './useLocalizationProvider';

export function useRequest<TData, TPayload>(
  request: (payload: TPayload) => Promise<TData | null>,
  options: RequestOptions<TData, TPayload> = {},
) {
  // REQUEST STATE
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<TData | null>(null);
  const [error, setError] = React.useState<any>(null);

  const {danger} = useAlertProvider();
  const {translate} = useLocalizationProvider();

  const {
    cleanup = () => {
      console.log('Cleanup started!');
    },
    onCompleted = () => {
      console.log('Requested completed!');
    },
    onError = (error: any) => {
      console.log(error?.details?.body || error?.message);
      if (error.response?.status && error.response?.status !== 401) {
        danger(translate(error?.message || 'Server Error'));
      }
    },
    pollInterval,
  } = options;

  // const intervalInstance = React.useRef<NodeJS.Timer | null>(null);
  // for long polling not needed now
  // const cleanupFn = () => {
  //   if (intervalInstance.current) clearInterval(intervalInstance.current);
  //   cleanup();
  // };

  // cb for the request - memoized and won't recreate on rebuilds
  // NOTE(np): potentially not more efficient that a regular function
  const execute = React.useCallback(
    (payload: TPayload) => {
      const fetchFn = async () => {
        // onErrorFn and onCompletedFn init - has to be inside the cb
        const onErrorFn = (error: unknown) => {
          setError(error);
          onError(error);
        };

        const onCompletedFn = (data: TData | null, payload: TPayload) => {
          setData(data);
          onCompleted(data, payload);
        };

        // body of the request - executes the request and updates the request state
        try {
          setLoading(true);
          const response = await request(payload);
          onCompletedFn(response, payload);
        } catch (error) {
          onErrorFn(error);
        } finally {
          setLoading(false);
        }
      };
      fetchFn();

      // inteded for long polling
      // if (pollInterval) {
      //   intervalInstance.current = setInterval(() => {
      //     fetchFn();
      //   }, pollInterval);
      // }
    },
    [onCompleted, onError, pollInterval, request],
  );

  // Do cleanup on unmounting for logn polling
  // React.useEffect(() => {
  //   return () => cleanupFn();
  // }, []);

  return {loading, error, data, execute};
}
