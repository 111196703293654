import useAuthService from '../../../services/useAuthService';
import GoogleAuthenticate from '../shared/GoogleAuthenticate';

export default function LoginWithGoogle({shouldPersist}: LoginWithGoogleProps) {
  const {login, loading} = useAuthService().useLoginWithGoogle(!!shouldPersist);
  return (
    <GoogleAuthenticate
      onSuccessAuthentication={login}
      disabled={loading}
      isLoading={loading}
      loader={{size: 'small', color: 'black'}}
      title={'Sign in with Google'}
    />
  );
}

interface LoginWithGoogleProps {
  shouldPersist?: boolean;
}
