import AuthGuard from '../../../components/shared/AuthGuard';
import Container from '../../../components/shared/Container';
import ScreenLayout from '../../../components/shared/ScreenLayout';
import {CoachNavigationContext} from '../../../navigation/coach-navigator/CoachNavigationContext';
import {CoachStackScreenProps} from '../../../types/Navigation';
import CoachHeader from '../shared/CoachHeader';
import AthletesTemplate from './AthletesTemplate';

export default function AthletesScreen({
  navigation,
}: CoachStackScreenProps<'athletes'>) {
  return (
    <AuthGuard>
      <CoachNavigationContext.Provider value={navigation}>
        <ScreenLayout>
          <CoachHeader />
          <Container>
            <AthletesTemplate />
          </Container>
        </ScreenLayout>
      </CoachNavigationContext.Provider>
    </AuthGuard>
  );
}
