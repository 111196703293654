import Map from './Map';

import {MapperRule, UserMapperRegister} from './IMappers';
import {
  firstDaysOfWeek,
  IPreferences,
  IPreferencesForm,
  IProfile,
  IProfileForm,
  IUser,
  Sex,
  Units,
  Views,
} from '../types/IUser';
import moment from 'moment';
import {
  CM_TO_FEET_MULTIPLIER,
  convertCmToFeetIfImperialIsSet,
  convertFeetToCmIfImperialIsSet,
  convertFeetToMeterIfImperialIsSet,
  convertKgToLbsIfImperialIsSet,
  convertLbsToKgIfImperialIsSet,
  convertMeterToFeetIfImperialIsSet,
  GMTtimeZones,
} from '../constants/Settings';

const mapUserToProfileForm: MapperRule<IUser, IProfileForm> = (
  from: IUser,
): IProfileForm => {
  return new Map<IUser, IProfileForm>()
    .createMap(from)
    .forMember('personalDetails', (source) => ({
      firstname: source.firstname || '',
      lastname: source.lastname || '',
      sex: source.sex || Sex.FEMALE,
      units: source.units || Units.METRIC,
      height: convertCmToFeetIfImperialIsSet(source.height, source.units),
      weight: convertKgToLbsIfImperialIsSet(source.weight, source.units),
    }))
    .forMember('fitnessLevel', (source) => ({
      sport_primary: source.sport_primary || '',
      team: source.team || '',
      level: source.level || '3',
    }))
    .forMember('geographyData', (source) => ({
      residence: source.residence || '',
      residenceTimeZone: source.residenceTimeZone || GMTtimeZones[13].value,
      residenceAltitude: convertMeterToFeetIfImperialIsSet(
        source.residenceAltitude,
        source.units,
      ),
    }))
    .finish();
};

const mapProfileFormToProfile: MapperRule<IProfileForm, IProfile> = (
  from: IProfileForm,
): IProfile => {
  return new Map<IProfileForm, IProfile>()
    .createMap(from)
    .mapDangerously((source) => ({
      ...source.personalDetails,
      ...source.fitnessLevel,
      ...source.geographyData,
    }))
    .forMember('height', (source) =>
      convertFeetToCmIfImperialIsSet(
        source.personalDetails.height,
        source.personalDetails.units,
      ),
    )
    .forMember('weight', (source) =>
      convertLbsToKgIfImperialIsSet(
        source.personalDetails.weight,
        source.personalDetails.units,
      ),
    )
    .forMember('residenceAltitude', (source) =>
      convertFeetToMeterIfImperialIsSet(
        source.geographyData.residenceAltitude,
        source.personalDetails.units,
      ),
    )
    .finish();
};

const mapPreferencesToPreferencesFrom: MapperRule<
  IPreferences,
  IPreferencesForm
> = (from: IPreferences): IPreferencesForm => {
  return new Map<IPreferences, IPreferencesForm>()
    .createMap(from)
    .mapDangerously((source) => ({
      acceptsInvites: !!source.isEmailDiscoverable,
      firstDayOfTheWeek: source.firstDayOfWeek || firstDaysOfWeek.MONDAY,
      defaultView: source.defaultView || Views.ATHLETE,
      dailyReminders: !!source.notificationSettings?.reminderMorningRatings,
      notifications: {
        notificationApp: !!source.notificationSettings?.notificationApp,
        notificationEmail: !!source.notificationSettings?.notificationEmail,
      },
      timeReminder: new Date(),
    }))
    .forMember('timeReminder', (source) => {
      return moment(
        (source.notificationSettings?.reminderMorningRatingTime?.hours?.toString() ||
          '10') +
          ':' +
          (source.notificationSettings?.reminderMorningRatingTime?.minutes?.toString() ||
            '00'),
        'HH:mm',
      ).toDate();
    })
    .finish();
};

const mapPreferencesFormToPreferences: MapperRule<
  IPreferencesForm,
  IPreferences
> = (from: IPreferencesForm): IPreferences => {
  return new Map<IPreferencesForm, IPreferences>()
    .createMap(from)
    .mapDangerously((source) => ({
      isEmailDiscoverable: !!source.acceptsInvites,
      firstDayOfWeek: source.firstDayOfTheWeek,
      defaultView: source.defaultView,
      notificationSettings: {
        reminderMorningRatings: source.dailyReminders,
        notificationApp: source.notifications.notificationApp,
        notificationEmail: source.notifications.notificationEmail,
        reminderMorningRatingSecAfterMidnight: moment(source.timeReminder).diff(
          moment(source.timeReminder).startOf('day'),
          'seconds',
        ),
        reminderMorningRatingTime: {
          hours: parseInt(moment(source.timeReminder).format('HH')),
          minutes: parseInt(moment(source.timeReminder).format('mm')),
        },
      },
    }))
    .finish();
};

const userMappers: UserMapperRegister = {
  UserToProfileForm: mapUserToProfileForm,
  ProfileFormToProfile: mapProfileFormToProfile,
  PreferenceToPreferenceForm: mapPreferencesToPreferencesFrom,
  PreferenceFormToPreference: mapPreferencesFormToPreferences,
};

export default userMappers;
