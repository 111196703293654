import React from 'react';
import Button from '../../../components/base/Button';
import {Div} from '../../../components/base/Div';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {HeadingTwo} from '../../../components/base/HeadingTwo';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import CustomRadioButton from '../../../components/shared/CustomRadioButton';
import Form from '../../../components/shared/Form';
import ORSeparator from '../../../components/shared/ORSeparator';
import {groupLabelMemberMap} from '../../../constants/Groups';
import useCoachService from '../../../services/useCoachService';
import usePermissionsService from '../../../services/usePermissionsService';
import {GlobalStyles} from '../../../styles';
import {
  AddGroupMemberDTO,
  IGroupMember,
  SendGroupInviteDTO,
} from '../../../types/IGroup';
import {PermissionTypes} from '../../../types/IPermissions';
import {useCurrentGroup} from './GroupContext';

export default function InviteGroupMember({
  type,
  members: existingMembers,
  onComplete,
}: InviteGroupMemberProps) {
  const {useFetchPermissions, useAcceptedPermissions} = usePermissionsService();
  useFetchPermissions(true);
  const members = useAcceptedPermissions(type);
  const [memberId, setMemberId] = React.useState('');
  const group = useCurrentGroup();
  const [error, setError] = React.useState('');
  const [submitType, setSubmitType] = React.useState<'new' | 'existing'>();
  const onError = (error: any) => {
    setError(
      error?.message ||
        `Failed to add ${groupLabelMemberMap[type].toLowerCase()}`,
    );
    setTimeout(() => setError(''), 2000);
  };
  const {add, adding, form} = useCoachService().useAddGroupMember(
    onComplete,
    onError,
  );
  const onSubmit = ({email}: AddGroupMemberDTO) => {
    setSubmitType('new');
    const payload: SendGroupInviteDTO = {
      groupId: group?.id || '',
      hash: group?.hash || '',
      userEmail: email,
      type,
      hasInvitation: !!members.find((m) => m.email == email),
    };
    add(payload);
  };
  const onExistingSubmited = () => {
    if (!memberId) return;
    setSubmitType('existing');
    const payload: SendGroupInviteDTO = {
      groupId: group?.id || '',
      hash: group?.hash || '',
      userId: memberId,
      type,
      hasInvitation: true,
    };
    add(payload);
  };

  return (
    <Div style={{paddingHorizontal: 12, paddingBottom: 24}}>
      <HeadingTwo>{group?.name}</HeadingTwo>
      <HeadingThree>
        {`Add new ${groupLabelMemberMap[type].toLowerCase()}`}
      </HeadingThree>
      <Form
        style={{marginTop: 12}}
        onSubmit={onSubmit}
        form={form}
        submitControl={(submit) => (
          <CustomButton
            onPress={submit}
            type="primary"
            title={`Add new ${groupLabelMemberMap[type].toLowerCase()}`}
            titleStyle={{color: GlobalStyles.colorsBasic.white500}}
            disabled={adding}
            isLoading={submitType == 'new' && adding}
            loader={{size: 'small', color: GlobalStyles.colorsBasic.white500}}
          />
        )}
      />
      {members && members.filter((m) => !existingMembers[m.id]).length ? (
        <>
          <ORSeparator />
          <HeadingThree>
            {`Add existing ${groupLabelMemberMap[type].toLowerCase()}`}
          </HeadingThree>
          {members
            .filter((m) => !existingMembers[m.id])
            .map((member, index) => (
              <CustomRadioButton
                label={() => (
                  <Button
                    onPress={() => setMemberId(member.id)}
                    activeOpacity={1}
                  >
                    <Paragraph
                      ellipsizeMode="tail"
                      numberOfLines={1}
                      style={{flex: 1}}
                    >
                      {member.firstName} {member.lastName}
                    </Paragraph>
                    <Paragraph
                      ellipsizeMode="tail"
                      numberOfLines={1}
                      style={{flex: 1, fontSize: 11}}
                    >
                      {member.email}
                    </Paragraph>
                  </Button>
                )}
                key={index}
                checked={memberId}
                setChecked={(value) => setMemberId(value)}
                value={member.id}
              />
            ))}
          <CustomButton
            type="primary"
            title={`Add existing ${groupLabelMemberMap[type].toLowerCase()}`}
            onPress={onExistingSubmited}
            disabled={adding}
            isLoading={submitType == 'existing' && adding}
            loader={{size: 'small', color: GlobalStyles.colorsBasic.white500}}
            titleStyle={{color: GlobalStyles.colorsBasic.white500}}
          />
        </>
      ) : null}
      {error ? (
        <Div
          style={{
            position: 'absolute',
            top: 0,
            left: 10,
            right: 10,
            borderRadius: 5,
            backgroundColor: GlobalStyles.colorsStatus.red,
            paddingHorizontal: 5,
            paddingVertical: 10,
          }}
        >
          <Paragraph style={{color: GlobalStyles.colorsBasic.white500}}>
            {error}
          </Paragraph>
        </Div>
      ) : null}
    </Div>
  );
}

interface InviteGroupMemberProps {
  type: PermissionTypes;
  members: {[key: string]: IGroupMember};
  onComplete: () => any;
}
