import environment from '../../config/environment';
import {
  InviteTypes,
  IPermissionsData,
  PermissionActionType,
  PermissionTypesDTO,
} from '../types/IPermissions';
import {IResponse} from '../types/Request';
import BaseApi from './BaseGateway';

const PermissionsGateway = {
  getPermissionsList: async ({
    type,
  }: {
    type: PermissionTypesDTO;
  }): Promise<IPermissionsData> => {
    return BaseApi.get(
      `/permissions/${BaseApi.getEncodedUserId()}/list?token=${BaseApi.getAccessToken()}&type=${type}`,
    );
  },

  getAllPermisions: async (): Promise<IPermissionsData> => {
    return await PermissionsGateway.getPermissionsList({type: 'all'});
  },

  sendInvite: async ({
    email,
    type,
    callbackUri,
  }: {
    email: string;
    type: InviteTypes;
    callbackUri: string;
  }): Promise<IResponse> => {
    return await BaseApi.put(
      `/permissions/${BaseApi.getEncodedUserId()}/invite?token=${BaseApi.getAccessToken()}&client=${
        environment.clientTag
      }&clientUrl=${callbackUri}`,
      {email, type, sendNotification: '1', provider: 'all'},
    );
  },

  sendCoachInvite: async ({email}: {email: string}): Promise<IResponse> => {
    return await PermissionsGateway.sendInvite({
      email,
      type: 'coach',
      callbackUri: environment.web.url + '/coach/athletes',
    });
  },

  sendAthleteInvite: async ({email}: {email: string}): Promise<IResponse> => {
    return await PermissionsGateway.sendInvite({
      email,
      type: 'athlete',
      callbackUri: environment.web.url + '/athlete/coaches',
    });
  },

  invokePermissionAction: async ({
    id,
    action,
    type,
    email,
  }: {
    id?: string;
    action: PermissionActionType;
    type: InviteTypes;
    email?: string;
  }): Promise<IResponse> => {
    const payload: any = {type};
    if (id) {
      payload.uId = id;
    } else {
      payload.email = email;
    }
    return await BaseApi.put(
      `/permissions/${BaseApi.getEncodedUserId()}/${action}?token=${BaseApi.getAccessToken()}`,
      payload,
    );
  },

  invokeCoachAction: async ({
    id,
    action,
    email,
  }: {
    id?: string;
    action: PermissionActionType;
    email?: string;
  }): Promise<IResponse> => {
    return await PermissionsGateway.invokePermissionAction({
      id,
      action,
      type: id ? 'coach' : 'coachEmail',
      email,
    });
  },
};

export default PermissionsGateway;
