import {StyleSheet} from 'react-native';
import {HeadingThree} from '../../../components/base/HeadingThree';
import {Div} from '../../../components/base/Div';
import {Paragraph} from '../../../components/base/Paragraph';
import Image from '../../../components/base/Image';
import assets from '../../../constants/Assets';
import useUserSettingsService from '../../../services/useUserSettingsService';
import {DataSourceStatus} from '../../../types/IDataSource';
import CustomButton from '../../../components/shared/CustomButton';
import {GlobalStyles} from '../../../styles';
import Loader from '../../../components/base/Loader';

const Authorization = () => {
  const {
    dataSourceStatus,
    connect,
    disconnect,
    disconnecting,
    disconnectingProvider,
    fetch,
    loadingProviders,
  } = useUserSettingsService().useDataSources();

  return (
    <Div
      onAppFocus={() => {
        fetch({});
      }}
    >
      <HeadingThree>Connected devices</HeadingThree>
      <Paragraph>
        {
          'In order to provide the best service for you, we need to access the data on your fitness devices. Please connect to allow authorization of your desired devices and follow the instruction to set it up.'
        }
      </Paragraph>
      <Div
        style={{
          marginTop: 15,
          marginBottom: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Paragraph style={{marginRight: 15}}>Status:</Paragraph>
        <Div
          style={[
            styles.statusIndicator,
            {backgroundColor: GlobalStyles.colorsStatus.green, marginRight: 5},
          ]}
        />
        <Paragraph style={{marginRight: 15}}>Connected</Paragraph>
        <Div
          style={[
            styles.statusIndicator,
            {backgroundColor: GlobalStyles.colorsBasic.gray500, marginRight: 5},
          ]}
        />
        <Paragraph style={{marginRight: 15}}>Disconnected</Paragraph>
      </Div>
      {dataSourceStatus ? (
        dataSourceStatus.map((provider, index) => (
          <Div key={index} style={styles.deviceContainer}>
            <Div style={{flexDirection: 'row', alignItems: 'center'}}>
              <Div
                style={[
                  styles.statusIndicator,
                  {
                    backgroundColor:
                      provider.status == DataSourceStatus.CONNECTED
                        ? GlobalStyles.colorsStatus.green
                        : GlobalStyles.colorsBasic.gray500,
                  },
                ]}
              />
              {provider.icon ? (
                <Image
                  source={{uri: provider.icon}}
                  width={40}
                  height={40}
                  style={{borderRadius: 40}}
                  resizeMode="stretch"
                />
              ) : assets.images[provider.provider.toLowerCase()] ? (
                <Image
                  source={assets.images[provider.provider.toLowerCase()]}
                  width={140}
                  height={35}
                />
              ) : (
                <Paragraph style={{fontSize: 24}}>
                  {provider.provider}
                </Paragraph>
              )}
            </Div>
            {provider.status == DataSourceStatus.CONNECTED ||
            provider.isConnected ? (
              <CustomButton
                type="secondary"
                title="Disconnect"
                isLoading={
                  disconnecting && disconnectingProvider == provider.provider
                }
                disabled={disconnecting}
                loader={{
                  size: 'small',
                  color: GlobalStyles.svexa.green500,
                }}
                titleStyle={{color: GlobalStyles.svexa.green500}}
                style={{minWidth: 100}}
                onPress={() => disconnect(provider.provider.toLowerCase())}
              />
            ) : (
              <CustomButton
                type="primary"
                title="Connect"
                style={{minWidth: 100}}
                titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                onPress={() => connect(provider.provider.toLowerCase())}
              />
            )}
          </Div>
        ))
      ) : loadingProviders ? (
        <Div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 32,
          }}
        >
          <Loader size={'large'} />
        </Div>
      ) : null}
    </Div>
  );
};

export default Authorization;

const styles = StyleSheet.create({
  deviceContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    marginVertical: 16,
  },
  statusIndicator: {
    height: 10,
    width: 10,
    borderRadius: 10,
    marginRight: 8,
  },
});
