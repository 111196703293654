import {PropsWithChildren} from 'react';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';
import {List} from 'react-native-paper';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {GlobalStyles} from '../../../styles';

export default function Accordion({
  title,
  children,
  titleStyle,
  style,
}: AccordionProps) {
  const {translate} = useLocalizationProvider();
  return (
    <List.Accordion
      title={translate(title)}
      titleStyle={[
        {color: GlobalStyles.colorsBasic.black500, paddingLeft: 0},
        titleStyle,
      ]}
      style={[
        {
          backgroundColor: GlobalStyles.colorsBasic.white500,
          borderTopWidth: 1,
          borderTopColor: GlobalStyles.colorsBasic.gray300,
          paddingLeft: 0,
        },
        style,
      ]}
    >
      {children}
    </List.Accordion>
  );
}

interface AccordionProps extends PropsWithChildren {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
}
