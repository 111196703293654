import {Div} from '../../../components/base/Div';
import Icon from '../../../components/base/Icon';
import {Paragraph} from '../../../components/base/Paragraph';
import CustomButton from '../../../components/shared/CustomButton';
import useDateTimeProvider from '../../../providers/useDateTimeProvider';
import useConfigService from '../../../services/useConfigService';
import useRatingService from '../../../services/useRatingService';

import ProgressBar from '../../../components/base/ProgressBar';
import {StyleSheet, useWindowDimensions} from 'react-native';
import {useAthleteNavigation} from '../../../navigation/athlete-navigator/AthleteNavigationContext';
import {GlobalStyles} from '../../../styles';
import Button from '../../../components/base/Button';
import CroppedParagraph from '../../../components/shared/CroppedParagraph';
import React from 'react';
import {maxContentWidth} from '../../../constants/Layout';

export default function AthleteDayRating({
  dateKey,
  addRatingEnabled,
}: AthleteDayRatingProps) {
  const dateTime = useDateTimeProvider();
  const {useRating, useTrendDirection} = useRatingService();
  const rating = useRating(dateKey);
  const navigation = useAthleteNavigation();
  const navigateToAddRatings = () => {
    navigation?.navigate('add-ratings', {dateKey});
  };
  const levels = useConfigService().useLevels();
  const {width} = useWindowDimensions();

  return (
    <Div style={{width: '100%'}}>
      <Paragraph style={{fontSize: 20, fontWeight: '600'}}>
        <Paragraph>{dateTime.dateFormatFromDateKey(dateKey, 'MMM')}</Paragraph>{' '}
        <Paragraph>
          {dateTime.dateFormatFromDateKey(dateKey, 'Do, yyyy')}
        </Paragraph>{' '}
        <Paragraph>{dateTime.dateFormatFromDateKey(dateKey, 'ddd')}</Paragraph>
      </Paragraph>
      <Div style={{width: '100%'}}>
        {rating ? (
          rating.values ? (
            <Div>
              <Paragraph
                style={{marginTop: 15, marginBottom: 5, fontWeight: '500'}}
              >
                Readiness score:
              </Paragraph>
              <Div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <ProgressBar
                  progress={rating.score}
                  backgroundColor={GlobalStyles.colorsBasic.gray300}
                  height={15}
                  width={Math.min(width, maxContentWidth) - 100}
                  color={levels[rating.level]?.color}
                  borderRadius={15}
                />
                <Paragraph
                  translate={false}
                  style={{
                    marginLeft: 15,
                    color: levels[rating.level]?.color || 'gray',
                    fontWeight: '600',
                  }}
                >
                  {(rating.score * 100).toFixed()}%
                </Paragraph>
              </Div>
              <Div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 8,
                }}
              >
                <Paragraph style={{fontWeight: '500', marginRight: 10}}>
                  Current trend:
                </Paragraph>
                <Icon
                  name={useTrendDirection(rating.readinessTrend5)}
                  size={20}
                />
              </Div>

              <Paragraph
                style={{marginTop: 15, marginBottom: 5, fontWeight: '500'}}
              >
                Status:
              </Paragraph>
              <Paragraph
                translate={false}
                style={{
                  fontWeight: '700',
                  marginBottom: 5,
                }}
              >
                {levels[rating.level]?.text}
              </Paragraph>
              <CroppedParagraph
                translate={false}
                text={levels[rating.level]?.description}
                maxLength={Math.min(width, maxContentWidth) / 3}
              />
              {addRatingEnabled ? (
                <Button
                  style={styles.button}
                  titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                  title={'Edit'}
                  onPress={navigateToAddRatings}
                />
              ) : null}
            </Div>
          ) : (
            <Div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginVertical: 72.5,
              }}
            >
              <Div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginVertical: 8,
                }}
              >
                <Icon
                  name="alert-circle"
                  size={30}
                  color={GlobalStyles.colorsStatus.red}
                />
                <Paragraph
                  style={{
                    marginLeft: 10,
                    fontSize: 14,
                    fontWeight: '600',
                    color: GlobalStyles.colorsStatus.red,
                  }}
                >
                  {'No ratings for this day.'}
                </Paragraph>
              </Div>
              {addRatingEnabled ? (
                <CustomButton
                  style={{alignSelf: 'center'}}
                  type="primary"
                  titleStyle={{color: GlobalStyles.colorsBasic.white500}}
                  title={'Add ratings'}
                  onPress={navigateToAddRatings}
                />
              ) : null}
            </Div>
          )
        ) : null}
      </Div>
    </Div>
  );
}

interface AthleteDayRatingProps {
  dateKey: string;
  addRatingEnabled?: boolean;
}

const styles = StyleSheet.create({
  button: {
    width: 100,
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    borderColor: GlobalStyles.svexa.green500,
    backgroundColor: GlobalStyles.svexa.green500,
    marginTop: 24,
    alignItems: 'center',
  },
});
