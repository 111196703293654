import React from 'react';
import {AppState, AppStateStatus} from 'react-native';

export default function useAppProvider() {
  function useAppState() {
    const [state, setState] = React.useState<AppStateStatus>('active');

    React.useEffect(() => {
      const listener = AppState.addEventListener('change', (nextState) => {
        setState(nextState);
      });
      return () => listener.remove();
    }, []);

    return {appState: state};
  }

  return {useAppState};
}
