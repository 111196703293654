import React, {useCallback} from 'react';
import {useRequest} from '../providers/useRequestProvider';
import {useAppDispatch, useAppSelector} from '../store';
import SettingsGateway from '../gateways/SettingsGateway';
import {settingsActions} from '../store/reducers/SettingsReducer';
import useAlertProvider from '../providers/useAlertProvider';
import {authActions} from '../store/reducers/AuthReducer';
import {
  changePasswordForm,
  preferenceForm,
  profileForm,
  sendFeedbackForm,
} from '../constants/Settings';
import mappers from '../mappers/MapperRegistry';
import {
  IAccountEmail,
  IPreferences,
  IPreferencesForm,
  IProfile,
  IProfileForm,
} from '../types/IUser';
import AuthGateway from '../gateways/AuthGateway';
import {IFormReference} from '../types/Form';
import {
  DataSourceProvider,
  DataSourceProviders,
  IDataSourceStatus,
} from '../types/IDataSource';
import * as WebBrowser from 'expo-web-browser';
import useNotificationProvider from '../providers/useNotificationProvider';
import {Platform} from 'react-native';
import * as Device from 'expo-device';
import useAppProvider from '../providers/useAppProvider';
import PermissionsGateway from '../gateways/PermissionsGateway';
import {permissionsActions} from '../store/reducers/PermissionsReducer';
import {PermissionStatus} from '../types/IPermissions';
import Constants from 'expo-constants';
import environment from '../../config/environment';

export default function useUserSettingsService() {
  const dispatch = useAppDispatch();
  const {success, danger} = useAlertProvider();
  const user = useAppSelector(({auth}) => auth.user);
  const {onNotificationReceived} = useNotificationProvider();

  function _useFetchUserSettings() {
    const {loading, execute} = useRequest(SettingsGateway.getUserSettings, {
      onCompleted: (settings) => {
        dispatch(authActions.updateProfile(settings));
      },
    });
    return {fetch: execute, loading};
  }

  function _useFetchReminderSettings() {
    const {loading, execute} = useRequest(SettingsGateway.getReminderSettings, {
      onCompleted: (notificationSettings) => {
        dispatch(settingsActions.setReminderSettings(notificationSettings));
      },
    });
    return {fetch: execute, loading};
  }

  function _useUpdateUserSettings(onComplete?: () => any) {
    const {loading, execute} = useRequest(SettingsGateway.updateUserSettings, {
      onCompleted: (profile) => {
        if (profile) {
          success('Profile data saved successfully!');
          if (onComplete) onComplete();
          dispatch(authActions.updateProfile(profile));
        }
      },
    });
    return {update: execute, processing: loading};
  }

  function useUserSettings(onComplete?: () => any) {
    const profileFormData = mappers.UserToProfileForm(user);
    const {fetch} = _useFetchUserSettings();
    const {update, processing} = _useUpdateUserSettings(onComplete);

    const updateProfile = (profileForm: IProfileForm) => {
      const profile: IProfile = mappers.ProfileFormToProfile(profileForm);
      update(profile);
    };

    const formReference = React.useRef<IFormReference<IProfileForm>>({
      setState: (state) => state,
    });

    React.useEffect(() => {
      fetch({});
    }, []);
    return {
      update: updateProfile,
      processing,
      form: profileForm,
      profileFormData,
      formReference,
    };
  }

  function useUserPreferences() {
    const {notificationSettings, loading: fetchingNotificationSettings} =
      useReminderSettings();
    const {accounts, loading: fetchingAccounts} = useConnectedAccounts();
    const {loading: fetchingUser} = _useFetchUserSettings();

    const formReference = React.useRef<IFormReference<IPreferencesForm>>({
      setState: (state) => state,
    });

    const {execute, loading} = useRequest(SettingsGateway.updatePreferences, {
      onCompleted: (data) => {
        let currentEmail: IAccountEmail | undefined;
        if (data?.accounts) {
          currentEmail = data.accounts.find((a) => a.email == user?.email);
          dispatch(settingsActions.setConnectedAccounts(data.accounts));
        }
        if (data?.profile) {
          dispatch(
            authActions.setUser({
              ...user!,
              ...data.profile,
              isEmailDiscoverable: !!currentEmail?.discoverable,
              isEmailPrimary: !!currentEmail?.primary,
            }),
          );
        }
        if (data?.notificationSettings) {
          dispatch(
            settingsActions.setReminderSettings({
              ...notificationSettings!,
              ...data.notificationSettings,
            }),
          );
        }
        success('Preferences saved successfully!');
        const newPerformanceFormData: IPreferencesForm =
          mappers.PreferenceToPreferenceForm({
            isEmailDiscoverable: !!currentEmail?.discoverable,
            firstDayOfWeek: data?.profile?.firstDayOfWeek,
            defaultView: data?.profile?.defaultView,
            notificationSettings: {
              ...notificationSettings!,
              ...data?.notificationSettings,
            },
          });
        formReference.current.setState(newPerformanceFormData);
      },
    });

    const update = (form: IPreferencesForm) => {
      const preferences: IPreferences =
        mappers.PreferenceFormToPreference(form);
      preferences.notificationSettings.hash = notificationSettings?.hash;
      execute(preferences);
    };

    const preferencesFormData: IPreferencesForm =
      mappers.PreferenceToPreferenceForm({
        isEmailDiscoverable: !!user?.isEmailDiscoverable,
        firstDayOfWeek: user?.firstDayOfWeek,
        defaultView: user?.defaultView,
        notificationSettings,
      });

    return {
      update,
      processing: loading,
      loading:
        (!notificationSettings && fetchingNotificationSettings) ||
        (!accounts.length && fetchingAccounts) ||
        (!user && fetchingUser),
      preferencesFormData,
      form: preferenceForm,
      formReference,
    };
  }

  function useReminderSettings() {
    const notificationSettings = useAppSelector(
      ({settings}) => settings.notificationSettings,
    );
    const {fetch, loading} = _useFetchReminderSettings();
    React.useEffect(() => {
      fetch({});
    }, []);
    return {loading, notificationSettings};
  }

  function useUserImage() {
    const image = useAppSelector(({auth}) => auth.userImage);
    const {execute, loading} = useRequest(SettingsGateway.getProfilePhoto, {
      onCompleted: (image) => dispatch(authActions.setUserImage(image)),
    });
    React.useEffect(() => {
      execute({});
    }, []);
    return {execute, image, loading};
  }

  function useUploadProfilePhoto() {
    const {execute, loading} = useRequest(SettingsGateway.uploadProfilePhoto, {
      onCompleted: (image) => dispatch(authActions.setUserImage(image)),
    });

    return {upload: execute, uploading: loading};
  }

  function useConnectedAccounts() {
    const accounts = useAppSelector(
      ({settings}) => settings.conntectedAccounts,
    );

    const onAccountsChange = (accounts: IAccountEmail[] | null) => {
      if (!accounts) {
        return;
      }
      dispatch(settingsActions.setConnectedAccounts(accounts));
      const currentEmail = accounts.find((a) => a.email == user?.email);
      if (currentEmail) {
        dispatch(
          authActions.setUser({
            ...user!,
            isEmailDiscoverable: !!currentEmail.discoverable,
            isEmailPrimary: !!currentEmail.primary,
          }),
        );
      }
    };

    const {execute, loading} = useRequest(AuthGateway.getUserEmails, {
      onCompleted: onAccountsChange,
    });

    const {execute: update, loading: processing} = useRequest(
      SettingsGateway.updateUserEmail,
      {
        onCompleted: onAccountsChange,
      },
    );

    React.useEffect(() => execute({}), []);
    return {loading, accounts, update, processing};
  }

  function useChangePassword() {
    const {execute, loading} = useRequest(SettingsGateway.changePassword, {
      onCompleted: (data) => {
        if (data) {
          success('Password changed successfully!');
        }
      },
    });

    return {change: execute, processing: loading, form: changePasswordForm};
  }

  function useSendFeedback() {
    const {execute, loading: sending} = useRequest(
      SettingsGateway.sendFeedback,
      {
        onCompleted: (data) => {
          if (data) {
            success("Thank you! You're feedback was sent successfully!");
          }
        },
      },
    );

    const send = useCallback((payload: {subject: string; text: string}) => {
      const fullMailText = `
      ${
        environment.env !== 'production'
          ? 'THIS IS TEST PLEASE IGNORE IT\n\n'
          : ''
      }
      Application: Readiness Advisor\n
      Version: ${Constants.expoConfig?.version}\n
      Platform: ${Platform.OS}\n
      User email: ${user?.email || 'no email'}\n
      Subject: ${payload.subject}\n
      Feedback:
      ${[payload.text]}`;

      execute({text: fullMailText, subject: payload.subject});
    }, []);

    return {send, sending, form: sendFeedbackForm};
  }

  function useDeleteAccount(onCompleted: () => any) {
    const {execute, loading} = useRequest(SettingsGateway.sendFeedback, {
      onCompleted: (data) => {
        if (data) {
          onCompleted();
          success('Request to delete account was sent successfully!');
        }
      },
    });

    function deleteAccount() {
      execute({
        subject: 'DELETE ACCOUNT',
        text: 'Please delete my account and all the data associated with this account.',
      });
    }

    return {deleteAccount, loading};
  }

  function useDataSources() {
    const dataSourceStatus = useAppSelector(
      ({settings}) => settings.dataSourceStatus,
    );
    const urls = useAppSelector(
      ({settings}) => settings.dataSourceIntegrationUrls,
    );

    function onDataSourceStatusFetched(data: DataSourceProvider[] | null) {
      if (!data) {
        return;
      }
      dispatch(settingsActions.setDataSourceStatus(data));
    }

    const {execute, loading} = useRequest(SettingsGateway.getDataSourceStatus, {
      onCompleted: onDataSourceStatusFetched,
    });

    const {execute: fetchUrls, loading: fetching} = useRequest(
      SettingsGateway.getDataSourceIntegrationUrls,
      {
        onCompleted: (data) => {
          if (data) {
            dispatch(settingsActions.setDataSourceUrls(data));
          }
        },
        onError: (error) => {
          // error on login request
          // console.log('DATA SOURCE URL ERROR', error);
        },
      },
    );

    async function connect(provider: DataSourceProviders | string) {
      try {
        await WebBrowser.openAuthSessionAsync(urls[provider]);
      } catch (error: any) {
        danger(error?.message || `Authentication with ${provider} failed.`);
      } finally {
        execute({});
      }
    }

    const [disconnectingProvider, setDisconnectingProvider] = React.useState<
      null | DataSourceProviders | string
    >(null);

    const {execute: executeDissconect, loading: disconnecting} = useRequest(
      SettingsGateway.disconnectDataSourceProvider,
      {
        onCompleted: (data) => {
          if (data) {
            success('Successfully disconnected');
            setDisconnectingProvider(null);
            onDataSourceStatusFetched(data);
            fetchUrls({});
          }
        },
      },
    );

    function disconnect(provider: DataSourceProviders | string) {
      setDisconnectingProvider(provider);
      executeDissconect(provider);
    }

    React.useEffect(() => {
      fetchUrls({});
      execute({});
    }, []);

    return {
      fetch: execute,
      loading: loading || fetching,
      loadingProviders: loading,
      dataSourceStatus,
      connect,
      disconnect,
      disconnecting,
      disconnectingProvider,
    };
  }

  function useGetBadgeCount() {
    const badgeCount = useAppSelector(({settings}) => settings.badgeCount);
    const {execute, loading} = useRequest(
      SettingsGateway.getNotificationBadgeCount,
      {
        onCompleted: (data) => {
          if (data) {
            dispatch(settingsActions.setBadgeCount(data));
          }
        },
      },
    );
    return {fetch: execute, loading, badgeCount};
  }

  function useGetPermissions() {
    const permissions = useAppSelector(
      ({permissions}) => permissions.permissions,
    );
    const {execute, loading} = useRequest(PermissionsGateway.getAllPermisions, {
      onCompleted: (data) => {
        if (data) {
          dispatch(permissionsActions.setPermissions(data));
        }
      },
    });
    return {fetchAll: execute, loading, permissions};
  }

  function useListenBadgeCount() {
    const {appState} = useAppProvider().useAppState();
    const {fetch: getBadgeCount} = useGetBadgeCount();
    const {fetchAll: getPermissions} = useGetPermissions();
    function isTokenValid() {
      return (user?.exp || 0) > Date.now() / 1000;
    }
    React.useEffect(() => {
      if (appState !== 'active' || !user || !isTokenValid()) {
        return;
      }
      getBadgeCount({});
      getPermissions({});
    }, [user, appState]);
  }

  function useCoachInvitationsCount() {
    const {badgeCount} = useGetBadgeCount();
    const {permissions} = useGetPermissions();
    return !badgeCount?.newInvitationsCount
      ? 0
      : permissions.coaches.filter(
          (p) => p.status === PermissionStatus.INCOMING_INVITE,
        ).length;
  }

  function useAthleteInvitationCount() {
    const {badgeCount} = useGetBadgeCount();
    const {permissions} = useGetPermissions();
    return !badgeCount?.newInvitationsCount
      ? 0
      : permissions.athletes.filter(
          (p) => p.status === PermissionStatus.INCOMING_INVITE,
        ).length +
          permissions.coachColleagues.filter(
            (p) => p.status === PermissionStatus.INCOMING_INVITE,
          ).length;
  }

  function useSetNotificationListener() {
    const {fetch} = useGetBadgeCount();
    if (Platform.OS != 'web' && Device.isDevice) {
      onNotificationReceived((event) => fetch({}));
      // (event) => {
      //   // success(
      //   //   `foreground:${event.request?.content?.body}`,
      //   //   () => {},
      //   //   `foreground:${event.request?.content?.title}`
      //   // )
      //   fetch({})
      // },
      // (event) => {
      //   fetch({})
      //   // success(
      //   //   `background:${event.notification.request?.content?.body}`,
      //   //   () => {},
      //   //   `background:${event.notification.request?.content?.title}`
      //   // )
      // });
    }
  }

  return {
    useUserImage,
    useUserSettings,
    useReminderSettings,
    useUploadProfilePhoto,
    useConnectedAccounts,
    useUserPreferences,
    useChangePassword,
    useSendFeedback,
    useDeleteAccount,
    useDataSources,
    useGetBadgeCount,
    useListenBadgeCount,
    useSetNotificationListener,
    useGetPermissions,
    useCoachInvitationsCount,
    useAthleteInvitationCount,
  };
}
