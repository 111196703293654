import {Div} from '../../components/base/Div';
import {GlobalStyles} from '../../styles';

export default function BarChartLines({
  chartWidth,
  numberOfLines = 6,
}: BarChartLinesProps) {
  const lineMap = (lines: number) => {
    const mappedLines: number[] = [];
    for (let i = 1; i <= lines; i++) {
      mappedLines.push(i);
    }
    return mappedLines;
  };
  return (
    <Div
      style={{
        position: 'absolute',
        height: 145,
        justifyContent: 'space-between',
        marginTop: 10,
      }}
    >
      {lineMap(numberOfLines).map((line, index) => (
        <Div
          key={index}
          style={{
            backgroundColor: GlobalStyles.colorsBasic.gray200,
            height: 1,
            width: chartWidth,
          }}
        />
      ))}
    </Div>
  );
}

interface BarChartLinesProps {
  chartWidth: number;
  numberOfLines?: number;
}
