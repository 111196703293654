import React from 'react';

interface IProfileContext {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => any;
}

export const ProfileContext = React.createContext<IProfileContext>({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => hasUnsavedChanges,
});

export const useProfileContext = () => React.useContext(ProfileContext);
