import React from 'react';
import {Div} from '../../../../components/base/Div';
import CustomButton from '../../../../components/shared/CustomButton';
import Form from '../../../../components/shared/Form';
import useCoachService from '../../../../services/useCoachService';
import {GlobalStyles} from '../../../../styles';
import {CreateGroupDTO} from '../../../../types/IGroup';
import {FormDataImage} from '../../../../types/Image';
import TeamLogo from '../../add-team/TeamLogo';
import {useCurrentGroup} from '../GroupContext';

export default function EditTeam() {
  const {update, updating, form} = useCoachService().useEditGroup();
  const onGroupSaveEdit = ({name}: CreateGroupDTO) => {
    const payload: any = {name, groupId: group?.id};
    if (logo) payload.formDataImg = logo;
    update(payload);
  };
  const group = useCurrentGroup();
  const [logo, setLogo] = React.useState<FormDataImage | null>(null);
  return (
    <Div
      style={{
        paddingBottom: 12,
        alignItems: 'center',
      }}
    >
      <TeamLogo
        logoData={logo ? logo : group?.logo ? {uri: group?.logo} : null}
        onImagePicked={setLogo}
      />
      <Form
        style={{marginTop: 12, width: 200}}
        entityState={{name: group?.name || ''}}
        form={form}
        onSubmit={onGroupSaveEdit}
        submitControl={(submit) => (
          <CustomButton
            type="primary"
            title="Update"
            onPress={submit}
            disabled={updating}
            isLoading={updating}
            titleStyle={{
              color: GlobalStyles.colorsBasic.white500,
            }}
            loader={{
              size: 'small',
              color: GlobalStyles.colorsBasic.white500,
            }}
          />
        )}
      />
    </Div>
  );
}
