import * as React from 'react';
import {View, ViewProps} from 'react-native';
import useAppProvider from '../../providers/useAppProvider';

export function Div(props: DivProps) {
  const {useAppState} = useAppProvider();
  const {appState} = useAppState();

  React.useEffect(() => {
    if (!props.onAppFocus) return;
    if (appState == 'active') props.onAppFocus();
  }, [appState]);
  return (
    <View
      {...props}
      style={[
        {height: props.height, width: props.width},
        props.backgroundColor ? {backgroundColor: props.backgroundColor} : null,
        props.style,
      ]}
    >
      {props.children}
    </View>
  );
}

export interface DivProps extends ViewProps {
  width?: string | number;
  height?: string | number;
  backgroundColor?: string;
  onAppFocus?: () => any;
}
