export interface IDataSourceStatus {
  providerInfo: DataProviderInfo[];
  dataMapping: {
    rhr: DataTypeProvider;
    hrv: DataTypeProvider;
    timezone: DataTypeProvider;
    altitude: DataTypeProvider;
  };
}

export type DataSourceProvider = {
  integrator: string;
  provider: string;
  status: string;
  icon: string;
  isConnected: boolean;
};

export interface DataSourceProviderResponse {
  availableIntegrations: DataSourceProvider[];
}

export interface DataSourceProviderConnected {
  integrations: DataSourceProvider[];
}

interface DataProviderInfo {
  provider: DataSourceProviders;
  available: boolean;
  status: DataSourceStatus;
  lastSyns: string; // data-time
}

export interface DataTypeProvider {
  provider: DataSourceProviders;
  defaultSource: boolean;
}

export enum DataSourceProviders {
  OURA = 'oura',
  GARMIN = 'garmin',
  MANUAL = 'manual',
}

export enum DataSourceStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  UNAVAILABLE = 'unavailable',
  SYNC_FAILUER = 'sync_failure',
  AUTH_FAILURE = 'auth_failure',
}

export interface IDataProviderSettings {
  dataType: DataTypeSettings;
  provider: DataSourceProviders;
  default: boolean;
}

export enum DataTypeSettings {
  HRV = 'hrv',
  RHR = 'rhr',
  TIMEZONE = 'timezone',
  ALTITUDE = 'altitude',
}
