import React from 'react';
import assets from '../../../constants/Assets';
import {supportedLanguages} from '../../../constants/Language';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import {GlobalStyles} from '../../../styles';
import Button from '../../base/Button';
import {Div} from '../../base/Div';
import Image from '../../base/Image';
import Modal from '../../base/Modal';
import {Paragraph} from '../../base/Paragraph';
import CustomButton from '../CustomButton';
import CustomRadioButton from '../CustomRadioButton';

export default function LanguagePicker() {
  const [visible, setVisible] = React.useState(false);
  const {useLanguage, setLanguage} = useLocalizationProvider();
  const language = useLanguage();
  const [lang, setLang] = React.useState(language);
  const closeModal = () => {
    setLang(language);
    setVisible(false);
  };
  const openModal = () => {
    setLang(language);
    setVisible(true);
  };
  const saveLanguage = () => {
    setLanguage(lang);
    setVisible(false);
  };
  return (
    <>
      <Button style={{width: 40}} onPress={openModal}>
        <Image
          source={assets.images[language]}
          height={18}
          width={36}
          resizeMode={'stretch'}
        />
      </Button>
      <Modal visible={visible} onBackgroundPress={closeModal}>
        <Div
          style={{
            alignSelf: 'center',
            paddingVertical: 10,
            paddingHorizontal: 20,
            backgroundColor: GlobalStyles.colorsBasic.white500,
            maxHeight: '80%',
            maxWidth: 400,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: GlobalStyles.svexa.green400,
          }}
        >
          {supportedLanguages.map((l, i) => {
            const chooseLanguage = () => {
              setLang(l.value);
            };
            return (
              <CustomRadioButton
                checked={lang}
                value={l.value}
                setChecked={chooseLanguage}
                key={i}
                label={() => (
                  <Button
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 15,
                    }}
                    activeOpacity={1}
                    onPress={chooseLanguage}
                  >
                    <Image
                      source={assets.images[l.value]}
                      height={20}
                      width={40}
                      resizeMode={'stretch'}
                    />
                    <Paragraph style={{marginLeft: 10}}>{l.label}</Paragraph>
                  </Button>
                )}
              />
            );
          })}
          <CustomButton
            type="primary"
            title="Save"
            titleStyle={{color: GlobalStyles.colorsBasic.white500}}
            onPress={saveLanguage}
          />
        </Div>
      </Modal>
    </>
  );
}
