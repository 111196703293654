import React from 'react';
import {StyleSheet} from 'react-native';
import {Div} from '../../../components/base/Div';
import Loader from '../../../components/base/Loader';
import {Paragraph} from '../../../components/base/Paragraph';
import ConfirmationModal from '../../../components/shared/ConfirmationModal';
import useLocalizationProvider from '../../../providers/useLocalizationProvider';
import useCoachService from '../../../services/useCoachService';
import {GlobalStyles} from '../../../styles';
import {GroupMemberType, RemoveMemberFromGroupDTO} from '../../../types/IGroup';
import {useCurrentGroup} from './GroupContext';

export default function TeamPendingMemberCard({
  title,
  hideRemove,
  member,
}: GroupMemberCardProps) {
  const group = useCurrentGroup();
  const {remove, removing} = useCoachService().useRemoveGroupMember();
  const onRemove = () => {
    const payload: RemoveMemberFromGroupDTO = {
      groupId: group?.id || '',
      type: member.type,
      hash: group?.hash || '',
      userEmail: member.email,
    };
    remove(payload);
    close();
  };
  const [visible, setVisible] = React.useState(false);
  const close = () => setVisible(false);
  const open = () => setVisible(true);
  const {translate} = useLocalizationProvider();
  return (
    <>
      <Div style={styles.shadowBox}>
        <Div style={{flex: 1}}>
          {typeof title == 'string' ? (
            <Paragraph numberOfLines={1} ellipsizeMode={'tail'}>
              {title}
            </Paragraph>
          ) : (
            title()
          )}
        </Div>
        {hideRemove ? null : removing ? (
          <Loader size={'small'} color={GlobalStyles.colorsStatus.red} />
        ) : (
          <Paragraph
            onPress={open}
            style={{color: GlobalStyles.colorsStatus.red, marginLeft: 10}}
          >
            Remove
          </Paragraph>
        )}
      </Div>
      <ConfirmationModal
        close={close}
        visible={visible}
        onConfirm={onRemove}
        title={`${translate(
          'Are you sure you want to remove invitation for',
        )} ${title} ${translate('for')} ${group?.name}`}
        confirmButtonTitle="Remove"
        confirmationInProgress={removing}
      />
    </>
  );
}

interface GroupMemberCardProps {
  title: string | (() => React.ReactElement);
  member: {
    type: GroupMemberType;
    id: string | null;
    email: string;
  };
  hideRemove?: boolean;
}

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: GlobalStyles.colorsBasic.white500,
    shadowColor: GlobalStyles.colorsBasic.black500,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    padding: 10,
    marginVertical: 5,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
