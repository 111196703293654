import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IBadgeCount, INotificationSettings} from '../../types/INotification';
import {IAccountEmail} from '../../types/IUser';
import {
  DataSourceProvider,
  DataSourceProviders,
  IDataSourceStatus,
} from '../../types/IDataSource';

export interface SettingsState {
  notificationSettings: INotificationSettings | null;
  conntectedAccounts: IAccountEmail[];
  dataSourceStatus: DataSourceProvider[] | null;
  dataSourceIntegrationUrls: {[value in DataSourceProviders]: string} | object;
  badgeCount: IBadgeCount;
}

const initialState: SettingsState = {
  notificationSettings: null,
  conntectedAccounts: [],
  dataSourceStatus: null,
  dataSourceIntegrationUrls: {},
  badgeCount: {
    newInvitationsCount: 0,
    reminderDateKey: '',
    badgeCount: 0,
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setReminderSettings: (
      state: SettingsState,
      action: PayloadAction<INotificationSettings | null>,
    ) => {
      state.notificationSettings = action.payload;
    },
    setConnectedAccounts: (
      state: SettingsState,
      action: PayloadAction<IAccountEmail[]>,
    ) => {
      state.conntectedAccounts = action.payload;
    },
    setDataSourceStatus: (
      state: SettingsState,
      action: PayloadAction<DataSourceProvider[] | null>,
    ) => {
      state.dataSourceStatus = action.payload;
    },
    setDataSourceUrls: (
      state: SettingsState,
      action: PayloadAction<
        Partial<{[value in DataSourceProviders]: string}> | object
      >,
    ) => {
      state.dataSourceIntegrationUrls = {
        ...state.dataSourceIntegrationUrls,
        ...action.payload,
      };
    },
    setBadgeCount: (
      state: SettingsState,
      action: PayloadAction<IBadgeCount>,
    ) => {
      state.badgeCount = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export default settingsSlice.reducer;
