import {Div} from '../../../../components/base/Div';
import {Paragraph} from '../../../../components/base/Paragraph';
import useCoachService from '../../../../services/useCoachService';
import usePermissionsService from '../../../../services/usePermissionsService';
import {PermissionStatus} from '../../../../types/IPermissions';
import {useCurrentGroup} from '../GroupContext';
import TeamPendingMemberCard from '../TeamPendingMemberCard';

export default function TeamPendingAthletes() {
  const group = useCurrentGroup();
  const {permissions} = usePermissionsService().useFetchPermissions(true);
  const {members} = useCoachService().useGroupMembers(group?.id || '');
  const notAccepted = Object.keys(members?.members || {})
    .filter(
      (a) =>
        !!members?.members[a] &&
        permissions?.athletes?.find(
          (p) =>
            p.id == a &&
            (p.status === PermissionStatus.OUTGOING_EMAIL_INVITE ||
              p.status === PermissionStatus.OUTGOING_INVITE),
        ) &&
        !members?.pending?.find((pm) => pm == members.members[a].email),
    )
    .map((key) => ({...members.members[key], id: key}));
  return (
    <Div>
      {!members?.pending?.length && !notAccepted.length ? (
        <Paragraph>No pending athletes.</Paragraph>
      ) : (
        <>
          {notAccepted.map((athlete, index) => {
            return (
              <TeamPendingMemberCard
                key={index}
                title={() => (
                  <Paragraph>
                    {athlete.firstname} {athlete.lastname} ({athlete.email})
                    {'\n'}
                    <Paragraph style={{fontSize: 12, color: 'gray'}}>
                      needs to login to their account to accept invitation
                    </Paragraph>
                  </Paragraph>
                )}
                member={{id: athlete.id, type: 'member', email: athlete.email}}
              />
            );
          })}

          {members.pending.map((email, index) => (
            <TeamPendingMemberCard
              key={index}
              title={() => (
                <Paragraph>
                  {email}
                  {'\n'}
                  <Paragraph style={{fontSize: 12, color: 'gray'}}>
                    needs to create an account with this email to accept
                    invitation
                  </Paragraph>
                </Paragraph>
              )}
              member={{id: null, type: 'member', email}}
            />
          ))}
        </>
      )}
    </Div>
  );
}
