import {GlobalStyles} from '../../styles';
import {Div} from '../base/Div';
import {Paragraph} from '../base/Paragraph';

export default function ORSeparator() {
  return (
    <Div
      style={{flexDirection: 'row', alignItems: 'center', marginVertical: 20}}
    >
      <Div
        style={{
          flex: 1,
          height: 1,
          backgroundColor: GlobalStyles.svexa.green500,
        }}
      />
      <Paragraph
        style={{
          color: GlobalStyles.svexa.green500,
          fontSize: 18,
          marginHorizontal: 10,
        }}
      >
        OR
      </Paragraph>
      <Div
        style={{
          flex: 1,
          height: 1,
          backgroundColor: GlobalStyles.svexa.green500,
        }}
      />
    </Div>
  );
}
